import {
  UserContentRequest,
  VideoFavoritePaginationOption,
  UserContentLogDurationRequest,
  GetVideoById,
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: VideoFavoritePaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`


  if (typeof paginationOption.search_text !== 'undefined') {
    query += `&search_text=${encodeURIComponent(paginationOption.search_text)}`
  }

  if (typeof paginationOption.sort_by !== 'undefined') {
    query += `&sort_by=${paginationOption.sort_by}`
  }

  if (typeof paginationOption.sort_direction !== 'undefined') {
    query += `&sort_direction=${paginationOption.sort_direction}`
  }

  return query
}


export default class UserContentService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getUserContent(paginationOption: VideoFavoritePaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/me/videos/favorites${query}`, this.options)
  }


  async startViewingVideo({
                            video_id,
                          }: UserContentRequest) {
    return request(
      'POST',
      `/me/videos/${video_id}/start-viewing`,
      this.options
    )
  }

  async setVideoDurationFoUser({
                                 video_id,
                                 duration
                               }: UserContentLogDurationRequest) {
    return request('POST', `/me/videos/${video_id}/log/${duration}`, this.options)
  }

  async createFavoriteVideo({
                              video_id,
                            }: UserContentRequest) {
    return request(
      'POST',
      `/me/videos/${video_id}/favorites`,
      this.options
    )
  }


  async unFavoriteVideo({video_id}: UserContentRequest) {
    return request('DELETE', `/me/videos/${video_id}/un-favorite`, this.options)
  }

  async createView({
                     video_id,
                   }: GetVideoById) {
    return request('POST', `/me/videos/${video_id}/viewed`, this.options)
  }
}
