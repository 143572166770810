import {
  MembersActionTypeEnum,
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";

const initialState = {
  Members: [],
  MembersTotal: null,
  DefaultMembers: [],
  BlockModeMembersLoading: false,
  loading: false
};


const MembersReducer = (state = initialState, action) => {
  switch (action.type) {

    case MembersActionTypeEnum.List:
      return {
        ...state,
        Members: action.members,
        MembersTotal: action.total,
        loading: action.loading
      };
      case MembersActionTypeEnum.Loading:
      return {
        ...state,
        loading: action.data,
      };
    case MembersActionTypeEnum.Filter:
      return {
        ...state,
        Members: action.members,
      };

    case MembersActionTypeEnum.ListInSelect:
      return {
        ...state,
        DefaultMembers: action.members,
      };
    case MembersActionTypeEnum.AddDefaultMembers:
      return {
        ...state,
        DefaultMembers: action.default,
      };
    case MembersActionTypeEnum.Add:
      let newMembersState = !state.Members ? [] : state.Members;
      newMembersState.unshift(action.members);
      return {
        ...state,
        Members: newMembersState,
        MembersTotal: state.MembersTotal + 1
      };
    case MembersActionTypeEnum.Update:
      let membersState = !state.Members ? [] : state.Members;
      let newArrayMembers = updatedArray(membersState, action.members);
      return {
        ...state,
        Members: newArrayMembers,
      };
    case MembersActionTypeEnum.Delete:
      let Members = !state.Members ? [] : state.Members;
      let updMembersArray = arrayReduce(Members, action.members);
      return {
        ...state,
        Members: updMembersArray,
        MembersTotal: state.MembersTotal - 1
      };

    default:
      return state;
  }
};

export default MembersReducer;
