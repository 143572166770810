import {
  FaqRequest,
  CategoryRequest,
  PaginationOption,
  ContactFormRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: PaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`;

  if (paginationOption.active_only) {
    query += `&active_only=${paginationOption.active_only}`;
  }
  return query
}

export default class DailyMailMeService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllCategories(paginationOption: PaginationOption) {
    const query = getPaginationQuery(paginationOption)

    return request('GET', `/categories${query}`, this.options)
  }

  async getAllFaqsByCategory(category_id: number, paginationOption: PaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/categories/${category_id}/faqs${query}`, this.options)
  }

  async getFaqById(faq_id: number) {
    return request('GET', `/faqs/${faq_id}`, this.options)
  }

  async createFaq(data: FaqRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/faqs`, requestOptions)
  }

  async createCategory(data: CategoryRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/categories`, requestOptions)
  }

  async updateFaq(faq_id: number, data: FaqRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request(
      'PUT',
      `/faqs/${faq_id}`,
      requestOptions
    )
  }

  async updateCategory(category_id: number, data: CategoryRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request(
      'PUT',
      `/categories/${category_id}`,
      requestOptions
    )
  }

  async deleteFaq(faq_id: number) {
    return request(
      'DELETE',
      `/faqs/${faq_id}`,
      this.options
    )
  }

  async contactUs(data: ContactFormRequest) {
    const formData = new FormData()
    if(data.attachments) {
      formData.append('attachments', data.attachments);
    }
    formData.append("data", JSON.stringify(data.data));
    
    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request(
      'POST',
      `/contact-us`,
      requestOptions
    )
  }

}
