// eslint-disable-next-line no-unused-vars
import { AxiosRequestConfig } from 'axios'

export interface ImageTagInterface {
  x: number
  y: number
  user_id: number
}

export interface ImageInterface {
  id: number
  nb_likes: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  owner_id: number
  path: string
  album_id: number
  description: string
  tagged_user_ids: Array<ImageTagInterface>
  cropped_data: CroppedDataInterface
  like_by_user_ids: Array<number>
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area?: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

export interface SortedSection {
  section_id?: number
  type: SectionType
  title?: string
  data?: Data
  sort_index?: number
}

export enum SectionType {
  photo = 1,
  value_as_text = 2,
  value_as_list = 3
}

export enum MomentPaginationSortBy {
  created_at = "created_at",
}

export interface Data {
  photo?: Array<ImageInterface>
  value_as_text?: string
  value_as_list?: Array<string>
}

export interface LimitedTemplate {
  template_id: number
  is_default_template: boolean
  name: string
  image?: ImageInterface
}

export interface Template {
  id: number
  name: string
  owner_id?: number
  image?: ImageInterface
  is_default?: boolean,
  is_system?: boolean,
  column1?: Array<SortedSection>
  column2?: Array<SortedSection>
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
}

export interface TemplateCreateOrUpdateRequest {
  name: string
  owner_id?: number
  image?: ImageInterface
  status?: boolean
  is_system?: boolean
  is_default?: boolean
  column1?: Array<SortedSection>
  column2?: Array<SortedSection>
}

export interface DailyCheckIn {
  is_system?: boolean
  computed_title?: string
  options?: Array<string>
  data?: number
}


export interface DailyRoutine {
  id:number
  title: string
  repeating: boolean
  start_from: number
  repeat_until: number
  frequency: number
  frequency_type: number
  data: boolean
}

export interface Momente {

}


export interface DailyBook {
  id: number
  day?: number
  is_favorite?: boolean,
  is_default?: boolean,
  is_system?: boolean,
  column1?: Array<SortedSection>
  column2?: Array<SortedSection>
  daily_checkins: Array<DailyCheckIn>
  daily_routines: Array<DailyRoutine>
  // daily_checkins_state: Array<DailyCheckInState>,
  daily_routings_state: Array<DailyRoutineState>
  owner_id: number
  internal_name: string
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
  daily_quote: DailyQuote | null
}

export interface DailyCheckInState {
  id: number
  daily_book_id: number
  checkin_id: number
  data: number
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
}

export interface DailyRoutineState {
  id: number
  daily_book_id: number
  routine_id: number
  data: boolean
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
}

export interface DailyBookCreateOrUpdateRequest {
  day?: number
  is_favorite?: boolean,
  column1?: Array<SortedSection>
  column2?: Array<SortedSection>
  daily_checkins: Array<DailyCheckIn>
  daily_routines: Array<DailyRoutine>
}

export interface UploadDailyBookImage {
  day: string
  upload: Blob
}

export interface UploadTemplateImage {
  template_id: number
  upload: Blob
}

export interface GetDailyBookByDay {
  day: string
}

export interface DeleteDailyBookUploadImage {
  day: string
  image_id: number
}

export interface DeleteTemplateUploadImage {
  template_id: number
  image_id: number
}

export interface ModuleConfig {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  emoji_set_1: Array<string>
  emoji_set_2: Array<string>
  emoji_set_3: Array<string>
  emoji_set_4: Array<string>
  default_tags: Array<Tag>
  templates: Array<LimitedTemplate>
}

export interface ModuleConfigRequest {
  emoji_set_1: Array<string>
  emoji_set_2: Array<string>
  emoji_set_3: Array<string>
  emoji_set_4: Array<string>
}

export interface QuestionRequest {
  deleted: boolean
  title: string
  tag_ids: Array<number>
}

export interface Tag {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  value: string
  emoticon: string
}

export interface Settings {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  default_emoji_set: number
  daily_citation_visible: boolean
  daily_card_visible: boolean
  daily_check_in_visible: boolean
  daily_ritual_visible: boolean
  daily_special_visible: boolean
  owner_id: number
}

export interface SettingsRequest {
  default_emoji_set: number
  daily_citation_visible: boolean
  daily_card_visible: boolean
  daily_check_in_visible: boolean
  daily_ritual_visible: boolean
  daily_special_visible: boolean
}
export interface DailyCheckinCompleteRequest {
  day: string
  checkin_id: number
  selection: number
}
export interface DailyCheckinDeleteRequest {
  day: string
  checkin_id: number
}
export interface DailyCheckinCreateUpdateRequest {
  is_system: boolean,
  deleted: boolean,
  computed_title: string,
  options: Array<string>
}

export interface DailyRoutineRequest {
  id?:number
  title: string
  repeating: boolean
  start_from?: number
  repeat_until: number
  frequency: number
  frequency_type: number
  data?: boolean
}

export interface DailyQuoteCreateUpdateRequest {
  day: number
  value: string
}

export interface DailyQuote {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  day: number
  internal_name: string
  value: string
}

export interface DailyRoutineUpdateQuery {
  day: string
  routine_id: number
}

// SDK OPTIONS
export interface Options extends AxiosRequestConfig {
  version?: string
  token?: string
  baseURL?: string
  timeout?: number
  socketEndpoint?: string
  userId?: string
  getNewToken?: Function
}

export interface PaginationOption {
  status?: boolean
  offset?: number
  limit?: number
  owner_id?: number
  search_text?: string
  sort_by?: string
  sort_direction?: number
}

export interface MomentePaginationOption {
  month_year?: string
  sort_by?: MomentPaginationSortBy
  offset?: number
  limit?: number
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface Pagination<T> {
  count: number;
  next: string | null;
  previous: string | null;
  result: Array<T>;
}

export interface ErrorSdk {
  detail: string
}
