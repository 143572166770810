import { EventsActionTypeEnum, RubricsActionTypeEnum, ModuleConfigActionTypeEnum } from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";

const initialState = {
  Events: [],
  LiveEventsTotal: 0,
  allData: [],
  ConfigData: {},
  ConfigDataHighlightedEventsIds: [],
  ConfigDataHighlightedEvents: null,
  DefaultLiveEvents: [],
  BlockModeLiveEventsLoading: false,
  ConfigDataTags: [],
  Experts: [],
  DefaultExperts: [],
  DefaultMediathekRubric: {},
  DefaultMediathekVideo: {},
  ConfigDataLoading: true
};

const getObjectsArrayReturnIdsArray = (array) => {
  let tab = [];
  for (let i = 0; i < array.length; i++) {
    tab.push(array[i].id);
  }
  return tab;
};


const LiveEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RubricsActionTypeEnum.AddDefaultRubric:
      return {
        ...state,
        DefaultRubrics: action.default,
      };

    case EventsActionTypeEnum.List:
      return {
        ...state,
        Events: action.events,
        LiveEventsTotal: action.count,
        BlockModeLiveEventsLoading: action.loading
      };
    case EventsActionTypeEnum.ListExperts:
      return {
        ...state,
        Experts: action.experts,
      };

    case EventsActionTypeEnum.DefaultExperts:
      return {
        ...state,
        DefaultExperts: action.expert,
      };

    case EventsActionTypeEnum.DefaultMediathekRubric:
      return {
        ...state,
        DefaultMediathekRubric: action.rubric,
      };

    case EventsActionTypeEnum.DefaultMediathekVideo:
      return {
        ...state,
        DefaultMediathekVideo: action.video,
      };

    case EventsActionTypeEnum.Add:
      let newEventState = !state.Events ? [] : state.Events;
      newEventState = [action.event, ...state.Events]
      return {
        ...state,
        Events: newEventState,
        LiveEventsTotal: newEventState.length,
      };
    case EventsActionTypeEnum.SetEmpty:
      return {
        ...state,
        Events: [],
      };
    case EventsActionTypeEnum.BlockModeLoading:
      return {
        ...state,
        BlockModeLiveEventsLoading: action.data,
      };
    case EventsActionTypeEnum.Update:
      let eventState = !state.Events ? [] : state.Events;
      let newArrayEvent = updatedArray(eventState, action.event);
      return {
        ...state,
        Events: newArrayEvent,
      };
    case EventsActionTypeEnum.Delete:
      let oldEvents = !state.Events ? [] : state.Events;
      let updEventArray = arrayReduce(oldEvents, action.event);
      return {
        ...state,
        Events: updEventArray,
        LiveEventsTotal: updEventArray.length,
      };

    case ModuleConfigActionTypeEnum.GetData:
      return {
        ...state,
        ConfigData: action.configData,
        ConfigDataTags: action.configDataTags,
        ConfigDataHighlightedEvents: action.configEventObjects,
        ConfigDataHighlightedEventsIds: action.configEventId,
        ConfigDataLoading: false
      };

    case ModuleConfigActionTypeEnum.Update:
      let config = !state.ConfigData ? {} : state.ConfigData;

      config = {
        ...config,
        default_tag_ids: getObjectsArrayReturnIdsArray(state.ConfigDataTags),
        highlight_event_ids: state.ConfigDataHighlightedEventsIds
      };

      action.client.updateModuleConfig({
        default_tag_ids: config.default_tag_ids,
        highlight_event_ids: config.highlight_event_ids,
        highlight_rubric_ids: [],
        might_like_live_event_ids: [],
      }).then(async (res) => { }).catch(console.error);

      return {
        ...state,
        ConfigData: config,
      };

    // ADD and DELETE Tags
    case ModuleConfigActionTypeEnum.AddTag:
      let configTagState = !state.ConfigDataTags ? [] : state.ConfigDataTags;
      configTagState.unshift(action.configDataTag);
      return {
        ...state,
        ConfigDataTags: configTagState,
      };
    case ModuleConfigActionTypeEnum.DeleteTag:
      let configTagArray = !state.ConfigDataTags ? [] : state.ConfigDataTags;
      let configTagUpdated = arrayReduce(configTagArray, action.configDataTag);
      return {
        ...state,
        ConfigDataTags: configTagUpdated,
      };

    // ADD and DELETE highlighted Events
    case ModuleConfigActionTypeEnum.AddHighlightEvents:
      let configHighlightedEventsState = !state.ConfigDataHighlightedEvents ? [] : state.ConfigDataHighlightedEvents;
      configHighlightedEventsState.unshift(action.configDataHighlightedEvent);
      return {
        ...state,
        ConfigDataHighlightedEvents: configHighlightedEventsState,
        ConfigDataHighlightedEventsIds: getObjectsArrayReturnIdsArray(configHighlightedEventsState)
      };
    case ModuleConfigActionTypeEnum.DeleteHighlightEvents:
      let configHighlightedEventsArray = !state.ConfigDataHighlightedEvents ? [] : state.ConfigDataHighlightedEvents;
      let configHighlightedEventsUpdated = arrayReduce(
        configHighlightedEventsArray,
        action.configDataHighlightedEvent
      );
      return {
        ...state,
        ConfigDataHighlightEvents: configHighlightedEventsUpdated,
        ConfigDataHighlightedEventsIds: getObjectsArrayReturnIdsArray(configHighlightedEventsUpdated)
      };

    default:
      return state;
  }
};

export default LiveEventsReducer;
