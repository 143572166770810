import { StarredMessageRequest, StarredMessage } from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class StarredMessageService {
  options: Options
  constructor(options: Options) {
    this.options = options
  }

  async getStarredMessages(channelId: string): Promise<Array<StarredMessage>> {
    return request(
      'GET',
      `/${this.options.version}/starredMessages/${channelId}`,
      this.options
    )
  }

  async getStarredMessagesWithOtherUserId(otherUserId: String): Promise<Array<StarredMessage>> {
    return request(
      'GET',
      `/${this.options.version}/starredMessages/me/with/${otherUserId}`,
      this.options
    );
  }

  async createStarredMessage(data: StarredMessageRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/${this.options.version}/starredMessages`, requestOptions)
  }

  async deleteStarredMessages(id: String) {
    return request(
      'DELETE',
      `/${this.options.version}/starredMessages/${id}`,
      this.options
    )
  }

  async deleteStarredMessagesByChannelIdMessageIdAndUserId({channelId, message}: StarredMessageRequest) {
    return request(
      'DELETE',
      `/${this.options.version}/starredMessages/${channelId}/${message}`,
      this.options
    )
  }
}
