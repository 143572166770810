import request from '../../../services.utils/request'
import {
  Options,
  ExpertUpdateRequest,
  ExpertCreateRequest,
  AddImageToExpert,
  ExpertGetAllRequest,
  AttachmentCreateRequest,
  ReferenceCreateRequest,
  AttachmentRemoveRequest,
  ReferenceRemoveRequest,
  ProfileExpertGetAllRequest
} from '../utils/DataSchemas'

function getPaginationQuery(getAllQuery: ExpertGetAllRequest) {
  let query = ``

  if (typeof getAllQuery.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${getAllQuery.offset}`
  }

  if (typeof getAllQuery.limit !== 'undefined') {
    query += `${query ? '&' : '?'}limit=${getAllQuery.limit}`
  }

  if (typeof getAllQuery.active_only !== 'undefined') {
    query += `${query ? '&' : '?'}active_only=${getAllQuery.active_only}`
  }

  if (typeof getAllQuery.search_text !== 'undefined' && getAllQuery.search_text) {
    query += `${query ? '&' : '?'}search_text=${getAllQuery.search_text}`
  }

  if (typeof getAllQuery.filter_by_role !== 'undefined' && getAllQuery.filter_by_role) {
    query += `${query ? '&' : '?'}filter_by_role=${getAllQuery.filter_by_role}`
  }

  if (typeof getAllQuery.sort_direction !== 'undefined' && getAllQuery.sort_direction) {
    query += `${query ? '&' : '?'}sort_direction=${getAllQuery.sort_direction}`
  }

  if (typeof getAllQuery.sort_by !== 'undefined' && getAllQuery.sort_by) {
    query += `${query ? '&' : '?'}sort_by=${getAllQuery.sort_by}`
  }

  return query
}

export default class ExpertService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  // GET

  async getAll(getAllQuery: ExpertGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/experts/${query}`, this.options)
  }

  async getAllPublicExperts(getAllQuery: ExpertGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/public/expert-limited-views/${query}`, this.options)
  }

  async getAllFollowedExpert(getAllQuery: ExpertGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/me/experts/favorites/${query}`, this.options)
  }

  async getAllFollowedExpertByProfile(getAllQuery: ProfileExpertGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/profiles/${getAllQuery.profile_id}/experts/favorites${query}`, this.options)
  }

  async getExpertsById(expert_id: number) {
    return request('GET', `/experts/${expert_id}`, this.options)
  }

  // POST

  async createExpert(data: ExpertCreateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/experts/`, requestOptions)
  }

  async addImageToExpert({ expert_id, image }: AddImageToExpert) {
    const formData = new FormData()
    formData.append('image', image)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      ...this.options.headers,
      data: formData
    }
    return request('POST', `/experts/${expert_id}/images`, requestOptions)
  }

  async favoriteExpert(expert_id: number) {
    return request(
      'POST',
      `/me/experts/${expert_id}/favorites`,
      this.options
    )
  }

  // PUT

  async updateExpert({ expert_id, ...data }: ExpertUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/experts/${expert_id}`, requestOptions)
  }

  // DELETE

  async deleteExpert(expert_id: number) {
    return request('DELETE', `/experts/${expert_id}`, this.options)
  }

  async removeImageOnExpert(expert_id: number, image_id: number) {
    return request('DELETE', `/experts/${expert_id}/images/${image_id}`, this.options)
  }

  async unfavoriteExpert(expert_id: number) {
    return request(
      'DELETE',
      `/me/experts/${expert_id}/un-favorite`,
      this.options
    )
  }

  async addAttachmentToExpert({
    expert_id,
    title,
    attachment
  }: AttachmentCreateRequest) {
    const formData = new FormData()
    formData.append('attachment', attachment)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request(
      'POST',
      `/experts/${expert_id}/attachments?title=${title}`,
      requestOptions
    )
  }

  async addReferenceToExpert({
    expert_id,
    title,
    external_url
  }: ReferenceCreateRequest) {
    const requestOptions: Options = {
      ...this.options
    }
    return request(
      'POST',
      `/experts/${expert_id}/references?title=${title}&external_url=${external_url}`,
      requestOptions
    )
  }

  async removeAttachmentOnExpert({
    expert_id,
    attachment_id
  }: AttachmentRemoveRequest) {
    return request(
      'DELETE',
      `/experts/${expert_id}/attachments/${attachment_id}`,
      this.options
    )
  }

  async removeReferenceOnExpert({
    expert_id,
    reference_id
  }: ReferenceRemoveRequest) {
    return request(
      'DELETE',
      `/experts/${expert_id}/references/${reference_id}`,
      this.options
    )
  }
}
