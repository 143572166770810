import {ImageInterface} from "../../user-v1/utils/DataSchemas";

export interface Treffpunkte {
  id: number
  nb_likes: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  nb_loves: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  name: string
  description: string
  start_dt: number
  end_dt: number
  type: TreffpunkteType
  location: TreffpunkteLocation | null
  url: string | null
  tag_ids: Array<number>
  chat_channel_id: string | null
  status: TreffpunkteState
  image: ImageInterface | null
  square_image: ImageInterface | null
  attendee_ids: Array<number>
  cancellation_reason: string | null
  cancellation_dt: string | null
  tags: Array<Tag>
  host: TreffpunkteHost
  attendees: Array<TreffpunkteAttendees>
  is_attending: boolean
  is_liked_by_user: boolean
}

export interface Tag {
  id: number
  value: string
  emoticon: string
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface TreffpunkteCreateRequest {
  name: string
  description?: string
  start_dt: number
  end_dt: number
  type?: TreffpunkteType
  location?: TreffpunkteLocation
  url?: string
  tag_ids?: Array<number>
  chat_channel_id?: string | null
  invited_user_ids?: Array<number>
  ignore_conflicted_meetups?: boolean
}

export interface TreffpunkteUpdateRequest extends TreffpunkteCreateRequest {
  meetup_id: number
}

export interface AddImageToTreffpunkte extends TreffpunkteId {
  upload: Blob
  image_type: Image_Type
}

export enum Image_Type {
  default = "default",
  square = "square",
  other = "other"
}

export interface TreffpunkteIdImageId extends TreffpunkteId {
  image_id: number
}

export interface TreffpunkteId {
  meetup_id: number
}

export interface TreffpunkteGetAllRequest {
  longitude?: number
  latitude?: number
  meetup_type?: TreffpunkteType
  meetup_status?: TreffpunkteState
  radius?: number
  expired?: boolean
  country?: string
  offset?: number
  limit?: number
}

export interface TreffpunkteGetMyRequest {
  longitude?: number
  latitude?: number
  radius?: number
  is_organizer?: boolean
  expired?: boolean
  offset?: number
  limit?: number
}

export interface Pagination {
  count: number
  next: string | null
  previous: string | null
  result: Array<Treffpunkte>
  recommendations: Array<Treffpunkte>
}

export interface PaginationWithoutRecommendation<T> {
  count: number
  next: string | null
  previous: string | null
  result: Array<T>
}

export enum TreffpunkteType {
  online = "online",
  "in-person" = "in-person"
}

export enum TreffpunkteState {
  active = "active",
  cancelled = "cancelled",
  pending_approval = "pending_approval"
}

export interface TreffpunkteAttendees {
  id: number
  internal_status: number
  email: string
  membership_id: number
  full_name: string
  image_profile: ImageLimited | null
  image_background: ImageLimited | null
  status: string
  motto: string
  tags: Array<Tag>
  is_friend: boolean
  is_blocked: boolean
  is_blocked_by_user: boolean
  is_online: boolean
  online_visible: boolean
  attendee_visible: boolean
  community_chat_visible: boolean
  community_point_visible: boolean
  is_reported: boolean
  direct_message: boolean
  receive_messages: boolean
  friend_ids: Array<number>
}

export interface TreffpunkteHost {
  id: number
  internal_status: number
  email: string
  membership_id: number
  full_name: string
  image_profile: ImageLimited | null
  image_background: ImageLimited | null
  status: string
  motto: string
  tags: Array<Tag>
  is_friend: boolean
  is_blocked: boolean
  is_blocked_by_user: boolean
  is_online: boolean
  online_visible: boolean
  attendee_visible: boolean
  community_chat_visible: boolean
  community_point_visible: boolean
  is_reported: boolean
  direct_message: boolean
  receive_messages: boolean
  friend_ids: Array<number>
}

export interface TreffpunkteCancel extends TreffpunkteId {
  cancellation_reason: string
}

export interface ImageLimited {
  path: string
  cropped_data: CroppedDataInterface
  cropped_path?: string
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface Image {
  id: number
  nb_likes: number
  nb_loves: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  album_id: number
  path: string
  description: string
  owner_id: number
  tagged_user_ids: Array<ImageTagInterface>
  cropped_data: CroppedDataInterface
  cropped_path?: string
  like_by_user_ids: Array<number>
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface ImageTagInterface {
  x: number
  y: number
  user_id: number
}

export interface LimitedUserProfile {
  id: number
  internal_status: number
  email: string
  membership_id: number
  full_name: string
  image_profile: ImageLimited | null
  image_background: ImageLimited | null
  status: string
  motto: string
  tags: Array<Tag>
  is_friend: boolean
  is_blocked: boolean
  is_blocked_by_user: boolean
  is_online: boolean
  online_visible: boolean
  attendee_visible: boolean
  community_chat_visible: boolean
  community_point_visible: boolean
  is_reported: boolean
  direct_message: boolean
  receive_messages: boolean
  friend_ids: Array<number>
  free_user: boolean
  created_at: Date
}

export interface X_Y {
  x: number
  y: number
}

export interface WidthHeightWithXY extends X_Y {
  width: number
  height: number
}

export interface ImageTaggedUserIds extends X_Y {
  user_id: number
}

export interface CroppedData {
  crop: X_Y
  zoom: number
  cropped_area: WidthHeightWithXY
  cropped_area_pixels: WidthHeightWithXY
  rotation: number
}

export interface TreffpunkteLocationPoint {
  type: string
  coordinates: Array<number>
}

export interface TreffpunkteLocation {
  location: string
  address_1: string
  address_2: string
  zip_code: string
  state: string
  city: string
  country_code: string
  geo_position: TreffpunkteLocationPoint
}

export interface getAllImagesRequest {
  meetup_id: number
  sort_direction?: SORT_DIRECTION
  sort_by?: SORT_BY
  offset?: number
  limit?: number
}

export interface getImageWithMeetupId {
  meetup_id: number
  image_id: number
}

export interface UpdateImageRequest {
  album_id: number | null
  description: string | null
  cropped_data: CroppedData | null
  tagged_user_ids: Array<ImageTaggedUserIds>
}


export enum SORT_DIRECTION {
  DESC = "-1",
  ASC = "1"
}

export enum SORT_BY {
  created_at = "created_at"
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface ErrorSdk {
  detail: string
}
