import MemberService from './services/Member'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private memberService!: MemberService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.memberService = new MemberService(this.options)
  }

  /**
   * Get all members
   */
  getAllMember = (
    getAllQuery: dataSchemas.MemberGetAllRequest = {
      active_only: true,
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.Pagination> => this.memberService.getAll({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })

  /**
   * Get member by id
   * @param member_id member id
   */
  getMemberById = (
    member_id: number
  ): Promise<dataSchemas.Member> => this.memberService.getMembersById(member_id)

  /**
   * Create member
   * @param body Body of member
   */
  createMember = (
    body: dataSchemas.MemberCreateRequest
  ): Promise<dataSchemas.Member> => this.memberService.createMember(body)

  /**
   * Update member
   * @param body Body of member
   */
  updateMember = (
    body: dataSchemas.MemberUpdateRequest
  ): Promise<dataSchemas.Member> => this.memberService.updateMember(body)

  /**
   * Delete member
   * @param member_id member id
   */
  deleteMember = async (
    member_id: number,
  ): Promise<void> => this.memberService.deleteMember(member_id)

  /**
   * Create member image
   * @param body Body of member
   */
  addImageToMember = (body: dataSchemas.AddImageToMember): Promise<dataSchemas.ImageInterface> =>
    this.memberService.addImageToMember(body)

  /**
   * Remove member image
   * @param member_id Body of member
   */
  removeImageOnMember = (
    member_id: number,
    image_id: number,
  ): Promise<void> => this.memberService.removeImageOnMember(member_id, image_id)
}

export * as types from './utils/DataSchemas'
