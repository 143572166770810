import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import moment from 'moment'
import { DailyBookClient as Client } from "@happiness-house/sdk";
import { getSectionName } from '../../../../utility/helpers/getSectionName'

// Define a type for the slice state
interface DiaryState {
    loading: boolean,
    currentDate: Date,
    isToday: boolean,
    dailyBook: any,
    tempDailyBook: Array<any>,
    data: any
}

// Define the initial state using that type
const initialState: DiaryState = {
    loading: false,
    currentDate: new Date(),
    isToday: true,
    dailyBook: { column1: [], column2: [] },
    tempDailyBook: [],
    data: {},
}

const client = new Client({
    baseURL: `${process.env.REACT_APP_HH_API}/daily-books/v1`
})

client.configure()

const handleUpdate = async (data: any, useTimer?: boolean) => {
    try {
        await client.editTemplate(data?.id, data)
    } catch (error) {
        console.warn(error);
    }
}

export const diarySlice = createSlice({
    name: 'diary',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        ChangeDate(state, action: PayloadAction<any>) {
            state.currentDate = action.payload
            state.isToday = moment(new Date()).isSame(action.payload, 'day')
            state.tempDailyBook = []
        },
        CreateDailyBook(state, action: PayloadAction<any>) {
            state.dailyBook = action.payload
        },
        UpdateDailyBook(state, action: PayloadAction<any>) {
            let arr = action.payload.column === 1 ? state.dailyBook.column1 : state.dailyBook.column2
            if (action.payload.dataType === 'title') {
                arr[action.payload.position].title = action.payload.value;
            } else if (action.payload.dataType === 'content') {
                arr[action.payload.position].data = { [getSectionName(action.payload.sectionType)]: action.payload.value };
            }
            let newDailyBook = action.payload.column === 1 ? { ...state.dailyBook, column1: arr } : { ...state.dailyBook, column2: arr }
            state.dailyBook = newDailyBook
        },
        AddSection(state, action: PayloadAction<any>) {

            let arr = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook), current(state.dailyBook)] : [current(state.dailyBook)]
            state.tempDailyBook = arr

            let newColumn1: any[] = []
            if (action.payload.type === 1) {
                if (state.dailyBook?.column1?.length > 0) {
                    newColumn1 = [...state.dailyBook.column1, { type: action.payload.type, title: action.payload.data?.title, data: { photo: [] }, sort_index: null }]
                } else {
                    newColumn1.push({ type: action.payload.type, title: action.payload.data?.title, data: { photo: [] }, sort_index: null })
                }
            } else if (action.payload.type === 6) {
                
                let newArr = action.payload.data?.map((item: string) => {
                    return { type: action.payload.type, title: item, data: null, sort_index: null }
                })
                if (state.dailyBook?.column1?.length > 0) {
                    newColumn1 = [...state.dailyBook.column1, ...newArr]
                } else {
                    newColumn1 = [...newArr]
                }
            } else {
                if (state.dailyBook?.column1?.length > 0) {
                    newColumn1 = [...state.dailyBook.column1, { type: action.payload.type, title: "", data: null, sort_index: null }]
                } else {
                    newColumn1.push({ type: action.payload.type, title: "", data: null, sort_index: null })
                }
            }
            const obj = { ...state.dailyBook, column1: newColumn1 }
            state.dailyBook = obj
        },
        RemoveSection(state, action: PayloadAction<any>) {
            let arr = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook), current(state.dailyBook)] : [current(state.dailyBook)]
            state.tempDailyBook = arr

            let newDailyBook = { ...state.dailyBook }
            if (action.payload.column == 1) {
                newDailyBook.column1.splice(action.payload.position, 1)
            } else if (action.payload.column == 2) {
                newDailyBook.column2.splice(action.payload.position, 1)
            }
            state.dailyBook = newDailyBook
        },
        ReplaceSection(state, action: PayloadAction<any>) {
            let arr = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook), current(state.dailyBook)] : [current(state.dailyBook)]
            state.tempDailyBook = arr

            let newDailyBook = state.dailyBook
            if (action.payload.column == 1) {
                newDailyBook.column1.splice(action.payload.position, 1, action.payload.data)
            } else {
                newDailyBook.column2.splice(action.payload.position, 1, action.payload.data)
            }
            state.dailyBook = newDailyBook
        },
        MoveFromLeftToRight(state, action: PayloadAction<any>) {
            let arr = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook), current(state.dailyBook)] : [current(state.dailyBook)]
            state.tempDailyBook = arr

            let column2: any[] = []
            if (state.dailyBook.column2.length !== 0) {
                column2 = [...state.dailyBook.column2]
                column2.splice(action.payload.endPosition, 0, state.dailyBook.column1[action.payload.startPosition])
            } else {
                column2 = [...state.dailyBook.column2]
                column2[action.payload.endPosition] = state.dailyBook.column1[action.payload.startPosition]
            }
            let column1 = [...state.dailyBook.column1].filter((elt: any, index: number) => {
                return index !== action.payload.startPosition
            })
            const obj = { ...state.dailyBook, column1: column1, column2: column2 }
            state.dailyBook = obj
        },
        MoveFromRightToLeft(state, action: PayloadAction<any>) {
            let arr = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook), current(state.dailyBook)] : [current(state.dailyBook)]
            state.tempDailyBook = arr

            let column1: any[] = []
            if (state.dailyBook.column1.length !== 0) {
                column1 = [...state.dailyBook.column1]
                column1.splice(action.payload.endPosition, 0, state.dailyBook.column2[action.payload.startPosition])
            } else {
                column1 = [...state.dailyBook.column1, state.dailyBook.column2[action.payload.startPosition]]
            }
            let column2 = state.dailyBook.column2.filter((elt: any, index: number) => {
                return index !== action.payload.startPosition
            })
            const obj = { ...state.dailyBook, column1: column1, column2: column2 }
            state.dailyBook = obj
        },
        ChangeOrderInColumn(state, action: PayloadAction<any>) {
            let oldData = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook), current(state.dailyBook)] : [current(state.dailyBook)]
            state.tempDailyBook = oldData

            let newDailyBook = null
            let arr = action.payload.startColumn == 'column1' ? [...current(state.dailyBook.column1)] : [...current(state.dailyBook.column2)]
            let obj = arr[action.payload.startPosition]

            arr.splice(action.payload.startPosition, 1)
            arr.splice(action.payload.endPosition, 0, obj)
            if (action.payload.startColumn == 'column1') {
                newDailyBook = { ...state.dailyBook, column1: arr, }
            } else {
                newDailyBook = { ...state.dailyBook, column2: arr }
            }
            state.dailyBook = newDailyBook

        },
        SaveTemplate(state, action: PayloadAction<any>) {
            client.createTemplate({
                column1: state.dailyBook.column1,
                column2: state.dailyBook.column2,
                name: action.payload.title,
                status: action.payload.status,
                owner_id: action.payload.id
            }).catch(err => {
                console.error(err);
            })
        },
        EditTemplate(state, action: PayloadAction<any>) {
            state.loading = true;
            client.editTemplate(state.dailyBook.id, {
                ...state.dailyBook,
                column1: state.dailyBook.column1,
                column2: state.dailyBook.column2,
                name: action.payload.title,
                status: action.payload.status,
                image: action.payload.image
            })
        },
        UseTemplate(state, action: PayloadAction<any>) {
            if (action.payload.column1.length !== 0 && action.payload.column2.length !== 0) {
                let arr = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook), current(state.dailyBook)] : [current(state.dailyBook)]
                state.tempDailyBook = arr
            }
            const obj = { ...state.dailyBook, column1: action.payload.column1, column2: action.payload.column2 }
            state.dailyBook = obj
        },
        UndoAction(state, action: PayloadAction<any>) {
            let arr = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook)] : []
            if (arr.length > 0) {
                let item = arr.pop()
                state.dailyBook = item
                let obj = arr
                state.tempDailyBook = obj
                handleUpdate(item, false)
            }
        },
        ClearAllSection(state, action: PayloadAction<any>) {
            let arr = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook), current(state.dailyBook)] : [current(state.dailyBook)]
            state.tempDailyBook = arr

            const obj = {
                ...state.dailyBook,
                column1: [],
                column2: []
            }
            state.dailyBook = obj
        },
        UpdateSectionType(state, action: PayloadAction<any>) {
            let oldData = state.tempDailyBook.length > 0 ? [...current(state.tempDailyBook), current(state.dailyBook)] : [current(state.dailyBook)]
            state.tempDailyBook = oldData

            let arr = action.payload.column == 1 ? state.dailyBook.column1 : state.dailyBook.column2
            arr[action.payload.position].type = action.payload.newType;
            if (arr[action.payload.position].data) {
                let oldData = arr[action.payload.position].data[getSectionName(action.payload.oldType)]
                arr[action.payload.position].data = { [getSectionName(action.payload.newType)]: oldData };
            }
            let newDailyBook = action.payload.column == 1 ? { ...state.dailyBook, column1: arr } : { ...state.dailyBook, column2: arr }
            state.dailyBook = newDailyBook
        },
        SetFavorite(state) {
            let newDailyBook = { ...state.dailyBook, is_favorite: !state.dailyBook.is_favorite }
            state.dailyBook = newDailyBook
        },

        SetDailyBook(state, action) {
            state.dailyBook = action.payload
        },
    },
})

export const {
    ChangeDate,
    UpdateDailyBook,
    CreateDailyBook,
    AddSection,
    RemoveSection,
    MoveFromLeftToRight,
    MoveFromRightToLeft,
    ChangeOrderInColumn,
    ReplaceSection,
    SaveTemplate,
    UseTemplate,
    UndoAction,
    ClearAllSection,
    UpdateSectionType,
    SetDailyBook,
    EditTemplate
} = diarySlice.actions

// Other code such as selectors can use the imported `RootState` type
export const getDiaryInfo = (state: any) => state.diary

export default diarySlice.reducer