export enum GoodNewsActionTypeEnum {
    ListDailyNews = "LIST_DAILYNEWS",
    FilterDailyNews = "FILTER_DAILYNEWS",
    ListInSelectDailyNews = "SELECT_LIST_DAILYNEWS",
    AddDailyNews = "ADD_DAILYNEWS",
    UpdateDailyNews = "UPDATE_DAILYNEWS",
    DeleteDailyNews = "DELETE_DAILYNEWS",
    AddDefaultDailyNews = "ADD_DEFAULT_DAILYNEWS",
    GoodNewsLoading = "LOADIN_GOOD_NEWS",
}
