import { DiariesActionTypeEnum } from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";

const initialState = {
  Diaries: [],
  DiariessTotal: 0,
  BlockModeDiariesLoading: false,
  Saving: false,
  DailyQuotes: [],
  DailyQuotesLoading: false,
  DailyQuotesTotal: 0,
};

const DiariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DiariesActionTypeEnum.List:
      return {
        ...state,
        Diaries: action.diaries,
        DiariessTotal: action.count,
        BlockModeDiariesLoading: action.loading
      };

    case DiariesActionTypeEnum.Add:
      let diaryState = !state.Diaries ? [] : state.Diaries;
      diaryState.unshift(action.diary);
      return {
        ...state,
        Diaries: diaryState,
        DiariessTotal: diaryState.length,
        Saving: false
      };


    case DiariesActionTypeEnum.Loading:
      return {
        ...state,
        BlockModeDiariesLoading: action.data,
      };
    case DiariesActionTypeEnum.Update:
      let newdiaryState = !state.Diaries ? [] : state.Diaries;
      let newArrayBanner = updatedArray(newdiaryState, action.diary);
      return {
        ...state,
        Diaries: newArrayBanner,
        Saving: false
      };
    case DiariesActionTypeEnum.Delete:
      let DIARIES = !state.Diaries ? [] : state.Diaries;
      let updBannerArray = arrayReduce(DIARIES, action.diary);
      return {
        ...state,
        Diaries: updBannerArray,
        diariesTotal: updBannerArray.length
      };
    case DiariesActionTypeEnum.Saving:
      return {
        ...state,
        Saving: true,
      };
  
    // Daily Quotes
    case DiariesActionTypeEnum.DailyQuoteList:
      return {
        ...state,
        DailyQuotes: action.dailyQuotes,
        DailyQuotesTotal: action.count,
        DailyQuotesLoading: action.loading
      };

    case DiariesActionTypeEnum.DailyQuoteAdd:
      let dailyQuotesState = !state.DailyQuotes ? [] : state.DailyQuotes;
      dailyQuotesState.unshift(action.data);
      return {
        ...state,
        DailyQuotes: dailyQuotesState,
        DailyQuotesTotal: dailyQuotesState.length,
        Saving: false
      };
    
    case DiariesActionTypeEnum.DailyQuoteUpdate:
      let oldData = !state.DailyQuotes ? [] : state.DailyQuotes;
      let newData = updatedArray(oldData, action.data);
      return {
        ...state,
        DailyQuotes: newData,
        Saving: false
      };
    
    case DiariesActionTypeEnum.DailyQuoteDelete:
      let _oldData = !state.DailyQuotes ? [] : state.DailyQuotes;
      let _newData = arrayReduce(_oldData, action.data);
      return {
        ...state,
        DailyQuotes: _newData,
        DailyQuotesTotal: _newData.length
      };
    

    case DiariesActionTypeEnum.DailyQuoteLoading:
      return {
        ...state,
        DailyQuotesLoading: action.loading,
      };
    
    default:
      return state;
  }
};

export default DiariesReducer;
