import {
  Options,
  EventGetAllRequest,
  EventImageRequest,
  UpdateEventRequest,
  AttachmentCreateRequest,
  ReferenceCreateRequest,
  AttachmentRemoveRequest,
  ReferenceRemoveRequest,
  EventRequest, ReferenceUpdateRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'

export default class EventService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAll(paginationOption: EventGetAllRequest) {
    let query = ''

    if (typeof paginationOption.status !== "undefined") {
      query
        ? (query += `&status=${paginationOption.status}`)
        : (query += `?status=${paginationOption.status}`)
    }

    if (typeof paginationOption.highlighted_only !== "undefined" && paginationOption.highlighted_only !== null) {
      query
        ? (query += `&highlighted_only=${paginationOption.highlighted_only}`)
        : (query += `?highlighted_only=${paginationOption.highlighted_only}`)
    }

    if (typeof paginationOption.search_text !== "undefined" && paginationOption.search_text !== "") {
      query
        ? (query += `&search_text=${paginationOption.search_text}`)
        : (query += `?search_text=${paginationOption.search_text}`)
    }

    if (typeof paginationOption.category_id !== "undefined") {
      query
        ? (query += `&category_id=${paginationOption.category_id}`)
        : (query += `?category_id=${paginationOption.category_id}`)
    }

    if (typeof paginationOption.expert_ids !== "undefined") {
      const expertsIds: string = paginationOption.expert_ids
        .map((p: any, index: number) =>
          index === 0 && !query ? `?expert_ids=${p}` : `&expert_ids=${p}`
        )
        .join('')
      query += expertsIds
    }

    if (typeof paginationOption.passed_event_only !== "undefined") {
      query
        ? (query += `&passed_event_only=${paginationOption.passed_event_only}`)
        : (query += `?passed_event_only=${paginationOption.passed_event_only}`)
    }

    if (typeof paginationOption.offset !== "undefined") {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (typeof paginationOption.limit !== "undefined") {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (typeof paginationOption.filter_period !== "undefined") {
      query
        ? (query += `&filter_period=${paginationOption.filter_period}`)
        : (query += `?filter_period=${paginationOption.filter_period}`)
    }

    return request('GET', `/events/${query}`, this.options)
  }

  async getAllPublicEvent(paginationOption: EventGetAllRequest) {
    let query = ''

    if (typeof paginationOption.status !== "undefined") {
      query
        ? (query += `&status=${paginationOption.status}`)
        : (query += `?status=${paginationOption.status}`)
    }

    if (typeof paginationOption.highlighted_only !== "undefined" && paginationOption.highlighted_only !== null) {
      query
        ? (query += `&highlighted_only=${paginationOption.highlighted_only}`)
        : (query += `?highlighted_only=${paginationOption.highlighted_only}`)
    }

    if (typeof paginationOption.search_text !== "undefined" && paginationOption.search_text !== "") {
      query
        ? (query += `&search_text=${paginationOption.search_text}`)
        : (query += `?search_text=${paginationOption.search_text}`)
    }

    if (typeof paginationOption.category_id !== "undefined") {
      query
        ? (query += `&category_id=${paginationOption.category_id}`)
        : (query += `?category_id=${paginationOption.category_id}`)
    }

    if (typeof paginationOption.expert_ids !== "undefined") {
      const expertsIds: string = paginationOption.expert_ids
        .map((p: any, index: number) =>
          index === 0 && !query ? `?expert_ids=${p}` : `&expert_ids=${p}`
        )
        .join('')
      query += expertsIds
    }

    if (typeof paginationOption.passed_event_only !== "undefined") {
      query
        ? (query += `&passed_event_only=${paginationOption.passed_event_only}`)
        : (query += `?passed_event_only=${paginationOption.passed_event_only}`)
    }

    if (typeof paginationOption.offset !== "undefined") {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (typeof paginationOption.limit !== "undefined") {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (typeof paginationOption.filter_period !== "undefined") {
      query
        ? (query += `&filter_period=${paginationOption.filter_period}`)
        : (query += `?filter_period=${paginationOption.filter_period}`)
    }

    return request('GET', `/public/events/${query}`, this.options)
  }

  async getUserEvent(paginationOption: EventGetAllRequest) {
    let query = ''

    if (paginationOption.status) {
      query
        ? (query += `&status=${paginationOption.status}`)
        : (query += `?status=${paginationOption.status}`)
    }

    if (paginationOption.search_text) {
      query
        ? (query += `&search_text=${paginationOption.search_text}`)
        : (query += `?search_text=${paginationOption.search_text}`)
    }

    if (paginationOption.category_id) {
      query
        ? (query += `&category_id=${paginationOption.category_id}`)
        : (query += `?category_id=${paginationOption.category_id}`)
    }

    if (typeof paginationOption.expert_ids !== "undefined") {
      const expertsIds: string = paginationOption.expert_ids
        .map((p: any, index: number) =>
          index === 0 && !query ? `?expert_ids=${p}` : `&expert_ids=${p}`
        )
        .join('')
      query += expertsIds
    }

    if (paginationOption.passed_event_only) {
      query
        ? (query += `&passed_event_only=${paginationOption.passed_event_only}`)
        : (query += `?passed_event_only=${paginationOption.passed_event_only}`)
    }

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (paginationOption.filter_period) {
      query
        ? (query += `&filter_period=${paginationOption.filter_period}`)
        : (query += `?filter_period=${paginationOption.filter_period}`)
    }

    if (paginationOption.rubric_id) {
      query
        ? (query += `&rubric_id=${paginationOption.rubric_id}`)
        : (query += `?rubric_id=${paginationOption.rubric_id}`)
    }


    return request('GET', `/me/events/${query}`, this.options)
  }

  async getById(eventId: number) {
    return request('GET', `/events/${eventId}`, this.options)
  }

  async getPublicEventById(eventId: number) {
    return request('GET', `/public/events/${eventId}`, this.options)
  }

  async getAllUpcommingEvents(paginationOption: EventGetAllRequest) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }


    return request('GET', `/events/upcomming/${query}`, this.options)
  }

  async getEventCalendar(event_id: number) {

    return request('GET', `/me/events/${event_id}/calendar`, this.options)
  }

  async getPublicEventCalendar(event_id: number) {

    return request('GET', `/public/events/${event_id}/calendar`, this.options)
  }

  async createEvent(data: EventRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/events/`, requestOptions)
  }

  async createEventImage(data: EventImageRequest) {
    const formData = new FormData()

    formData.append('upload', data.upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request('POST', `/admin/events/${data.event_id}/images`, requestOptions)
  }

  async likeEvent(eventId: number) {
    return request('POST', `/me/events/${eventId}/likes`, this.options)
  }

  async attendeeEvent(eventId: number) {
    return request('POST', `/me/events/${eventId}/attend`, this.options)
  }

  async updateEvent(data: UpdateEventRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data.event
    }
    return request('PUT', `/admin/events/${data.event_id}`, requestOptions)
  }

  async deleteEvent(eventId: number) {
    return request('DELETE', `/admin/events/${eventId}`, this.options)
  }

  async cloneEvent(eventId: number) {
    return request('PUT', `/admin/events/${eventId}/clone`, this.options)
  }

  async cancelEvent(eventId: number, cancellationReason: string) {
    const requestOptions: Options = {
      ...this.options,
      data: { cancellation_reason: cancellationReason }
    }
    return request('PUT', `/admin/events/${eventId}/cancel`, requestOptions)
  }

  async deleteEventImage(eventId: number, imageId: number) {
    return request('DELETE', `/admin/events/${eventId}/images/${imageId}`, this.options)
  }

  async unLikeEvent(eventId: number) {
    return request('DELETE', `/me/events/${eventId}/unlike`, this.options)
  }

  async unAttendeeEvent(eventId: number) {
    return request('DELETE', `/me/events/${eventId}/un-attend`, this.options)
  }

  async addAttachmentToEvent({
    event_id,
    title,
    attachment
  }: AttachmentCreateRequest) {
    const formData = new FormData()
    formData.append('attachment', attachment)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request(
      'POST',
      `/admin/events/${event_id}/attachments?title=${title}`,
      requestOptions
    )
  }

  async addReferenceToEvent({
    event_id,
    title,
    external_url
  }: ReferenceCreateRequest) {
    const requestOptions: Options = {
      ...this.options
    }
    return request(
      'POST',
      `/admin/events/${event_id}/references?title=${title}&external_url=${external_url}`,
      requestOptions
    )
  }

  async editReferenceToEvent({
    event_id,
    reference_id,
    title,
    external_url
  }: ReferenceUpdateRequest) {
    const requestOptions: Options = {
      ...this.options
    }
    return request(
      'PUT',
      `/admin/events/${event_id}/references/${reference_id}?title=${title}&external_url=${external_url}`,
      requestOptions
    )
  }

  async removeAttachmentOnEvent({
    event_id,
    attachment_id
  }: AttachmentRemoveRequest) {
    return request(
      'DELETE',
      `/admin/events/${event_id}/attachments/${attachment_id}`,
      this.options
    )
  }

  async removeReferenceOnEvent({
    event_id,
    reference_id
  }: ReferenceRemoveRequest) {
    return request(
      'DELETE',
      `/admin/events/${event_id}/references/${reference_id}`,
      this.options
    )
  }
}
