import RubrikService from './services/rubriks'
import ArtikelService from './services/articles'
import UserContentService from './services/userContent'
import ModuleConfigService from './services/moduleConfig'
import AdminService from './services/admin'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
//import { ArtikelPaginationOption } from "./utils/DataSchemas";
import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private rubrikService!: RubrikService
  private artikelService!: ArtikelService
  private userContentService!: UserContentService
  private moduleConfigService!: ModuleConfigService
  private adminService!: AdminService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.rubrikService = new RubrikService(this.options)
    this.artikelService = new ArtikelService(this.options)
    this.userContentService = new UserContentService(this.options)
    this.moduleConfigService = new ModuleConfigService(this.options)
    this.adminService = new AdminService(this.options)
  }

  /**
   * Magazin rubrics
   */

  /**
   * Get all rubrics
   */
  getAllRubriks = (
    paginationOption: dataSchemas.PaginationOption = { offset: 1, limit: 100, published: true, sort_direction: -1 }
  ): Promise<dataSchemas.Pagination<dataSchemas.Rubrik>> =>
    this.rubrikService.getAll({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get rubrics by id
   * @param rubrikId Rubrik id
   */
  getRubrikById = (rubrikId: number): Promise<dataSchemas.Rubrik> =>
    this.rubrikService.getById(rubrikId)

  /**
   * Post rubrics
   * @param body Body of rubrics
   */
  createRubriks = (
    body: dataSchemas.RubrikRequest
  ): Promise<dataSchemas.Rubrik> => this.rubrikService.createRubrik(body)

  /**
   * Post rubrics
   * @param rubrikId Rubrik id
   * @param body Body of rubrics
   */
  createRubriksImage = (
    rubrikId: number,
    body: dataSchemas.ImageRequest
  ): Promise<string> => this.rubrikService.createRubrikImage(rubrikId, body)

  /**
   * Update rubric
   * @param rubrikId Rubrik id
   * @param body Body of rubric
   */
  updateRubrik = (
    rubrikId: number,
    body: dataSchemas.RubrikRequest
  ): Promise<dataSchemas.Rubrik> =>
    this.rubrikService.editRubrik(rubrikId, body)

  /**
   * Delete rubric
   * @param rubrikId Rubrik id
   */
  deleteRubriks = async (rubrikId: number) => {
    const rubric = await this.getRubrikById(rubrikId)
    return this.rubrikService
      .deleteRubrik(rubric.id)

  }

  /**
   * Delete rubric
   * @param rubrikId Rubrik id
   */
  deleteRubriksImage = async (rubrikId: number) => {
    const rubric = await this.getRubrikById(rubrikId)
    return this.rubrikService
      .deleteRubrikImage(rubric.id)

  }

  /**
   * Magazin Articles
   */

  /**
   * Get all article
   */
  getAllArticles = (
    paginationOption: dataSchemas.ArtikelPaginationOption
  ): Promise<dataSchemas.ArticlePagination<dataSchemas.Artikel>> =>
    this.artikelService.getAll({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get all article
   */
  getAllArticlesForPublic = (
    paginationOption: dataSchemas.ArtikelPaginationOption
  ): Promise<dataSchemas.ArticlePagination<dataSchemas.Artikel>> =>
    this.artikelService.getAllArticlesForPublic({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get article by id
   * @param articleId Article id
   */
  getArticleById = (articleId: number): Promise<dataSchemas.Artikel> =>
    this.artikelService.getById(articleId)

  /**
   * Get article by id
   * @param articleId Article id
   */
  getArticleByIdForPublic = (articleId: number): Promise<dataSchemas.Artikel> =>
    this.artikelService.getByIdForPublic(articleId)

  /**
   * Post article
   * @param body Body of article
   */
  createArticle = (
    body: dataSchemas.ArtikelRequest
  ): Promise<dataSchemas.Artikel> => this.adminService.createArticle(body)

  /**
   * Post article image
   * @param articleId Article id
   * @param body Body of article
   */
  createArticleImage = (
    articleId: number,
    body: dataSchemas.ImageRequest
  ): Promise<dataSchemas.Reference> =>
    this.adminService.createArticleImage(articleId, body)

  /**
   * Post article video
   * @param articleId Article id
   * @param body Body of article
   */
  createArticleVideo = (
    articleId: number,
    body: dataSchemas.VideoRequest
  ): Promise<dataSchemas.Reference> =>
    this.adminService.createArticleVideo(articleId, body)

  /**
   * Post article audio
   * @param articleId Article id
   * @param body Body of article
   */
  createArticleAudio = (
    articleId: number,
    body: dataSchemas.AudioRequest
  ): Promise<dataSchemas.Reference> =>
    this.adminService.createArticleAudio(articleId, body)

  /**
   * Post article attachment
   * @param articleId Article id
   * @param body Body of article
   */
  createArticleAttachment = (
    articleId: number,
    body: dataSchemas.AttachmentRequest
  ): Promise<dataSchemas.Reference> =>
    this.adminService.createArticleAttachment(articleId, body)

  /**
   * Post article reference
   * @param articleId Article id
   * @param body Body of article
   */
  createArticleReference = (
    articleId: number,
    body: dataSchemas.ReferenceRequest
  ): Promise<dataSchemas.Reference> =>
    this.adminService.createArticleReference(articleId, body)

  /**
   * Update article
   * @param articleId Article id
   * @param body Body of article
   */
  updateArticle = (
    articleId: number,
    body: dataSchemas.ArtikelRequest
  ): Promise<dataSchemas.Artikel> =>
    this.adminService.editArticle(articleId, body)

  /**
   * Delete article
   * @param articleId Article id
   */
  deleteArticle = async (articleId: number) => {
    const video = await this.getArticleById(articleId)
    return this.adminService
      .deleteArticle(video.id)

  }

  /**
   * Delete article image
   * @param articleId Article id
   * @param imageId Image id
   */
  deleteArticleImage = async (articleId: number, imageId: number) => {
    const video = await this.getArticleById(articleId)
    return this.adminService
      .deleteArticleImage(video.id, imageId)

  }

  /**
   * Delete article video
   * @param articleId Article id
   * @param videoId Video id
   */
  deleteArticleVideo = async (articleId: number, videoId: number) => {
    const video = await this.getArticleById(articleId)
    return this.adminService
      .deleteArticleVideo(video.id, videoId)

  }

  /**
   * Delete article audio
   * @param articleId Article id
   * @param audioId Audio id
   */
  deleteArticleAudio = async (articleId: number, audioId: number) => {
    const video = await this.getArticleById(articleId)
    return this.adminService
      .deleteArticleAudio(video.id, audioId)

  }

  /**
   * Delete article attachment
   * @param articleId Audio id
   * @param attachmentId Attachment id
   */
  deleteArticleAttachment = async (articleId: number, attachmentId: number) => {
    const video = await this.getArticleById(articleId)
    return this.adminService
      .deleteArticleAttachment(video.id, attachmentId)
  }

  /**
   * Delete article reference
   * @param articleId Article id
   * @param referenceId Reference id
   */
  deleteArticleReference = async (articleId: number, referenceId: number) => {
    const video = await this.getArticleById(articleId)
    return this.adminService
      .deleteArticleReference(video.id, referenceId)

  }

  /**
   * Magazin User content
   */

  /**
   * Get all article
   */
  getFavoriteArticles = (
    paginationOption: dataSchemas.ArtikelPaginationOption
  ): Promise<dataSchemas.ArticlePagination<dataSchemas.Artikel>> =>
    this.userContentService.getAll({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * ModuleConfig
   */

  /**
   * Get the module configuration
   */
  getModuleConfig = (): Promise<dataSchemas.ModuleConfig> =>
    this.moduleConfigService.getModuleConfig()

  /**
   * Update the page configuration
   * @param body Body of page configuration
   */
  updateModuleConfig = (
    body: dataSchemas.ModuleConfigRequest
  ): Promise<dataSchemas.ModuleConfig> =>
    this.moduleConfigService.updateModuleConfig(body)

  /**
   * Post a favorite article
   * @param article_id article id
   */
  createFavoriteArticle = (
    article_id: number
  ): Promise<string> =>
    this.userContentService.createFavorite(article_id)


  /**
   * un favorite a article
   * @param article_id article id
   */
  unFavoritearticle = (
    article_id: number
  ): Promise<string> =>
    this.userContentService.unFavorite(article_id)

  /**
   * create a article view
   * @param article_id article id
   * @param course_id article id
   */
  createView = (
    article_id: number
  ): Promise<string> =>
    this.userContentService.createView(article_id)

  /**
   * get public article count
   * @param browserFingerprint
   */
  getCurrentRate = (
    browserFingerprint: string
  ) =>
    this.artikelService.getCurrentRate(browserFingerprint)

  /**
   * set get public article count
   * @param browserFingerprint
   */
  setCurrentRateArticle = (
    browserFingerprint: string
  ) =>
    this.artikelService.setCurrentRateArticle(browserFingerprint)

  /**
   * Re-sync article duration
   */
  reSynDuration = () =>
    this.adminService.reSynDuration()
}

export * as types from './utils/DataSchemas'
