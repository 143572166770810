export interface ImageTagInterface {
  x: number
  y: number
  user_id: number
}

export interface ImageInterface {
  id: number
  nb_likes: number
  nb_loves: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  album_id: number
  path: string
  description: string
  owner_id: number
  tagged_user_ids: Array<ImageTagInterface>
  cropped_data: CroppedDataInterface
  like_by_user_ids: Array<number>
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

export interface Tag {
  id: number
  value: string
  emoticon: string | null
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface Event {
  id: number
  nb_likes: number
  nb_loves: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  name: string
  description: string,
  start_dt: number,
  end_dt: number,
  url: string,
  status: boolean,
  category: number,
  tag_ids: Array<number>
  image: ImageInterface
  logo_image: ImageInterface
  tags: Array<Tag>
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface EventRequest {
  name: string
  description?: string
  start_dt: number
  end_dt: number
  url?: string
  status?: boolean
  category: number
  tag_ids?: Array<number>
}

export interface GetEventByIdRequest {
  event_id: number
}

export interface UpdateEventImageRequest {
  event_id: number
  logo_image?: boolean
  upload: Blob
}

export interface DeleteEventImageRequest {
  event_id: number
  image_id: number
}

export interface PaginationOption {
  status?: boolean
  offset?: number
  limit?: number
  search_text?: string
  sort_by?: PaginationOptionSort
  hide_expired?: boolean
  sort_direction?: -1 | 1
}

export enum PaginationOptionSort {
  'created_at' = 'created_at',
  'start_dt' = 'start_dt'
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface Pagination<T> {
  count: number;
  next: string | null;
  previous: string | null;
  result: Array<T>;
}

export interface ErrorSdk {
  detail: string
}
