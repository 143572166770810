import {
  EmailActionTypeEnum,
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";

const initialState = {
  Emails: [],
  EmailsTotal: null,
  DefaultEmails: [],
  DataLoading: false
};

const EmailsReducer = (state = initialState, action) => {
  switch (action.type) {

    case EmailActionTypeEnum.List:
      return {
        ...state,
        Emails: action.emails,
        EmailsTotal: action.total,
        DataLoading: action.loading
      };

    case EmailActionTypeEnum.Filter:
      return {
        ...state,
        Emails: action.emails,
      };

    case EmailActionTypeEnum.Update:
      let emailsState = !state.Emails ? [] : state.Emails;
      let newArrayEmails = updatedArray(emailsState, action.email);
      return {
        ...state,
        Emails: newArrayEmails,
      };


    case EmailActionTypeEnum.Loading:
      return {
        ...state,
        DataLoading: action.loading
      };

    default:
      return state;
  }
};

export default EmailsReducer;
