import { EventType, Event } from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class EventService {
  options: Options
  constructor(options: Options) {
    this.options = options
  }

  async getUnreadEvents(): Promise<Array<Event>> {
    return request(
      'GET',
      `/${this.options.version}/events/me/${EventType.MESSAGE_UNREAD}`,
      this.options
    )
  }

  async getUnreadEventsLimitedInfo(): Promise<Array<string>> {
    return request(
      'GET',
      `/${this.options.version}/events/me/limited/${EventType.MESSAGE_UNREAD}`,
      this.options
    )
  }

  async notifyThatIReceivedAllMessages(): Promise<boolean> {
    return request(
      'GET',
      `/${this.options.version}/events/notifyThatIReceivedAllMessages`,
      this.options
    )
  }

  /* async createEvent(data: EventRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/${this.options.version}/events`, requestOptions)
  }

  async deleteEvents(eventId: String) {
    return request(
      'DELETE',
      `/${this.options.version}/events/${eventId}`,
      this.options
    )
  } */

  async deleteEventsByUserIdAndEventType(
    channelId: string,
    isUserReadView: boolean = true ,
    eventType: EventType = EventType.MESSAGE_UNREAD
  ) {
    return request(
      'DELETE',
      `/${this.options.version}/events/${channelId}/${eventType}?isUserReadView=${isUserReadView}`,
      this.options
    )
  }
}
