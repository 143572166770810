import { ModuleGetAllRequest, Options } from '../utils/DataSchemas'
import request from '../../../services.utils/request'

export default class ModuleConfigService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getModuleConfig(paginationOption: ModuleGetAllRequest) {
    let query = ''

    if (paginationOption.expand) {
      query
        ? (query += `&expand=${paginationOption.expand}`)
        : (query += `?expand=${paginationOption.expand}`)
    }

    return request('GET', `/module-config/${query}`, this.options)
  }

}
