import EventService from './services/eventService'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private eventService!: EventService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.eventService = new EventService(this.options)
  }

  /**
   * Get all event
   */
  getAllEvents = (
    paginationOption: dataSchemas.PaginationOption = { offset: 0, limit: 20 }
  ): Promise<dataSchemas.Pagination<dataSchemas.Event>> =>
    this.eventService.getAllEvents({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })


  /**
   * Get event by id
   * @param eventId
   */
  getById = (eventId: number): Promise<dataSchemas.Event> =>
    this.eventService.getById(eventId)

  /**
   * Post event
   * @param body Body of event
   */
  createEvent = (
    body: dataSchemas.EventRequest
  ): Promise<dataSchemas.Event> => this.eventService.createEvent(body)

  /**
   * Update event
   * @param eventId
   * @param body Body of event
   */
  editEvent = (
    eventId: number,
    body: dataSchemas.Event
  ): Promise<dataSchemas.Event> =>
    this.eventService.editEvent(eventId, body)


  /**
   * Post event image
   * @param eventId
   * @param body Body of event image
   */
  createEventImage = (
    body: dataSchemas.UpdateEventImageRequest
  ): Promise<any> => this.eventService.createEventImage(body)


  /**
   * Delete event
   * @param eventId
   */
  deleteEvent = async (body: dataSchemas.GetEventByIdRequest) => {
    return this.eventService
      .deleteEvent(body)
  }

  /**
   * Delete event image
   * @param eventId
   */
  deleteEventImage = async (body: dataSchemas.DeleteEventImageRequest) => {
    return this.eventService
      .deleteEventImage(body)
  }
}

export * as types from './utils/DataSchemas'
