import { BannersActionTypeEnum } from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";


const initialState = {
  Banners: [],
  BannersTotal: 0,
  BlockModeBannersLoading: false,
  IsLoading: false,
};

const getObjectsArrayReturnIdsArray = (array) => {
  let tab = [];
  for (let i = 0; i < array.length; i++) {
    tab.push(array[i].id);
  }
  return tab;
};


const BannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case BannersActionTypeEnum.List:
      return {
        ...state,
        Banners: action.banners,
        BannersTotal: action.count,
        IsLoading: action.loading
      };

    case BannersActionTypeEnum.Add:
      let bannerState = !state.Banners ? [] : state.Banners;
      bannerState.unshift(action.banner);
      return {
        ...state,
        Banners: bannerState,
        BannersTotal: bannerState.length
      };


    case BannersActionTypeEnum.BlockModeLoading:
      return {
        ...state,
        BlockModeBannersLoading: action.data,
      };
    case BannersActionTypeEnum.Update:
      let newbannerState = !state.Banners ? [] : state.Banners;
      let newArrayBanner = updatedArray(newbannerState, action.banner);
      return {
        ...state,
        Banners: newArrayBanner,
      };
    case BannersActionTypeEnum.Delete:
      let BANNERS = !state.Banners ? [] : state.Banners;
      let updBannerArray = arrayReduce(BANNERS, action.banner);
      return {
        ...state,
        Banners: updBannerArray,
        bannersTotal: updBannerArray.length
      };
    case BannersActionTypeEnum.Loading:
      return {
        ...state,
        IsLoading: action.data,
      };


    default:
      return state;
  }
};

export default BannersReducer;
