// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import { Options } from '../../services.utils/request-types';
import API from '../../services.utils/config'
import AuthService from './services/auth'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private authService!: AuthService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.authService = new AuthService(this.options)
  }

  /**
   * Register a user
   *
   * @param data RegisterRequest
   *
   * @returns Promise
   */
  register = (data: dataSchemas.RegisterRequest): Promise<Record<string, any>> => {
    return this.authService.register(data)
  }

  /**
   * Confirmation code for a registered user
   *
   * @param data RegisterConfirmationCodeRequest
   *
   * @returns Promise
   */
  confirmationCode = (data: dataSchemas.RegisterConfirmationCodeRequest): Promise<Record<string, any>> => {
    return this.authService.confirmationCode(data)
  }

  /**
   * Authorize a user
   *
   * @param data AuthorizeRequest
   *
   * @returns Promise
   */
  authorize = (data: dataSchemas.AuthorizeRequest): Promise<dataSchemas.AuthorizeResponse> => {
    return this.authService.authorize(data)
  }

  /**
   * Change a user password
   *
   * @param data ChangePasswordRequest
   *
   * @returns Promise
   */
  changePassword = (data: dataSchemas.ChangePasswordRequest): Promise<Record<string, any>> => {
    return this.authService.changePassword(data)
  }

  /**
   * Refresh token
   *
   * @returns Promise
   */
  refreshToken = (): Promise<dataSchemas.RefreshTokenResponse> => {
    return this.authService.refreshToken()
  }

  /**
   * Logout
   *
   *
   * @returns Promise
   */
  logout = (): Promise<string> => {
    return this.authService.logout()
  }

  /**
   * Reset Password code
   *
   * @param data ResetPasswordCodeRequest
   *
   * @returns Promise
   */
  resetPasswordCode = (data: dataSchemas.ResetPasswordCodeRequest): Promise<Record<string, any>> => {
    return this.authService.resetPasswordCode(data)
  }

  /**
   * Change password with reset code
   *
   * @param data PasswordResetCodeRequest
   *
   * @returns Promise
   */
  passwordResetCode = (data: dataSchemas.PasswordResetCodeRequest): Promise<Record<string, any>> => {
    return this.authService.passwordResetCode(data)
  }


}

export * as types from './utils/DataSchemas'
