export interface ImageTagInterface {
  x: number
  y: number
  user_id: number
}

export interface ImageInterface {
  id: number
  nb_likes: number
  created_at: Date
  created_by: 0
  updated_at: Date
  updated_by: 0
  owner_id: number
  path: string
  album_id: number
  description: string
  tagged_user_ids: Array<ImageTagInterface>
  cropped_data: CroppedDataInterface
  like_by_user_ids: Array<number>
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area?: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

export interface Banner {
  id: number
  title: string
  description: string
  footer: string
  links: Array<Link>
  image: ImageInterface
  active: ActiveEnum
  repeat_weekly: boolean
  start_dt: number
  end_dt: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface BannerRequest {
  title: string
  description: string
  footer?: string
  links?: Array<Link>
  image?: ImageInterface
  active?: ActiveEnum
  repeat_weekly?: boolean
  start_dt?: number
  end_dt?: number
}

export interface GetBannerByIdRequest {
  banner_id: number
}

export interface CreateBannerImageRequest {
  banner_id: number
  upload: Blob
}

export interface Link {
  title?: string
  url?: string
}

export enum ActiveEnum {
  INACTIVE = 0,
  ACTIVE = 1,
}

export interface BannerGetAllRequest {
  offset?: number
  limit?: number
  search_text?: string
  active?: ActiveEnum
}

export interface Pagination<T> {
  count: number
  next: string | null
  previous: string | null
  result: Array<T>
}