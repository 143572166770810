import { Link } from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class LinkService {
  options: Options
  constructor(options: Options) {
    this.options = options
  }

  async getLinks(channelId: string): Promise<Array<Link>> {
    return request(
      'GET',
      `/${this.options.version}/links/${channelId}`,
      this.options
    )
  }

  async getLinksWithOtherUserId(otherUserId: String): Promise<Array<Link>> {
    return request(
      'GET',
      `/${this.options.version}/links/me/with/${otherUserId}`,
      this.options
    );
  }

  async deleteLinks(id: String) {
    return request(
      'DELETE',
      `/${this.options.version}/links/${id}`,
      this.options
    )
  }
}
