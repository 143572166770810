import {
  SectionRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class RubrikConfigService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getRubrikConfig() {

    return request('GET', `/rubric-config/`, this.options)
  }

  async createSection(data: SectionRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('POST', `/rubric-config/sections`, requestOptions)
  }

  async updateSection(sectionId: number, data: SectionRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request(
      'PUT',
      `/rubric-config/sections/${sectionId}`,
      requestOptions
    )
  }

  async deleteSection(sectionId: number) {
    return request(
      'DELETE',
      `/rubric-config/sections/${sectionId}`,
      this.options
    )
  }
}
