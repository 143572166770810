export enum CommunityActionTypeEnum {
    ListPost = "LIST_ABUSE_POSTS",
    FilterPost = "FILTER_ABUSE_POSTS",
    UpdatePost = "UPDATE_ABUSE_POSTS",
    ListComment = "LIST_ABUSE_COMMENTS",
    FilterComment = "FILTER_ABUSE_COMMENTS",
    UpdateComment = "UPDATE_ABUSE_COMMENTS",
    SetCommentLoading = "SET_COMMENT_LOADING",
    SetPostLoading="SET_POST_LOADING",
    ListUser = "LIST_ABUSE_USERS",
    FilterUser = "FILTER_ABUSE_USERS",
    UpdateUser = "UPDATE_ABUSE_USERS",
    SetUserLoading="SET_USER_LOADING",
}
