import {
    CommunityUserJoinRequest,
    ReportPost,
    BasePaginationOption
  } from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: BasePaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

  if (paginationOption.search_text !== undefined) {
    query += `&search_text=${paginationOption.search_text}`
  }

  if (paginationOption.sort_by !== undefined) {
    query += `&sort_by=${paginationOption.sort_by}`
  }

  if (paginationOption.sort_direction !== undefined) {
    query += `&sort_direction=${paginationOption.sort_direction}`
  }

  return query
}
  export default class UserService {
    options: Options

    constructor(options: Options) {
      this.options = options
    }

    // GET ALL
    async getAllMyPost(getAllQuery: BasePaginationOption) {
      const query = getPaginationQuery(getAllQuery)
      return request('GET', `/me/posts${query}`, this.options)
    }
    // JOIN A GROUP
    async JoinGroup({ group_id, reason}: CommunityUserJoinRequest) {

      return request('POST', `/me/groups/${group_id}/join?reason=${reason}`, this.options)
    }

    // GET DASHBOARD
    async getDashboard() {
      return request('GET', `/me/dashboard`, this.options)
    }



    // REPORT ABUSE
    async reportAbuse(data: ReportPost) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
      return request('POST', `/me/posts/report-abuse`, requestOptions)
    }

    //CANCEL REPORT
    async cancelReport(postId: number) {
      return request('DELETE', `/me/posts/${postId}/cancel-abuse`, this.options)
    }

    // POST ACTIVATE NOTIFICATION
    async subscribe(postId: number) {
      return request('POST', `/me/posts/${postId}/subscribe`, this.options)
    }

    // POST DESACTIVATE NOTIFICATION
    async unsubscribe(postId: number) {
      return request('DELETE', `/me/posts/${postId}/unsubscribe`, this.options)
    }
    // GROUP ACTIVATE NOTIFICATION
    async subscribeGroup(groupId: number) {
      return request('POST', `/me/groups/${groupId}/subscribe`, this.options)
    }

    // GROUP DESACTIVATE NOTIFICATION
    async unsubscribeGroup(groupId: number) {
      return request('DELETE', `/me/groups/${groupId}/unsubscribe`, this.options)
    }

    // LEAVE A GROUP
    async leaveGroup(groupId: number) {
      return request('DELETE', `/me/groups/${groupId}/leave`, this.options)
    }

  }
