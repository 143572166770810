import {
  ContentRequest,
  Options, TdmBodyRequest, TdmRequestMonthYear, TdmRequestMonthYearFilename
} from '../utils/DataSchemas';
import request from '../../../services.utils/request'

export default class VideoService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getByMonthYear({ month, year }: TdmRequestMonthYear) {
    return request('GET', `/topics/${month}-${year}`, this.options)
  }

  async getByMonthYearPublic({ month, year }: TdmRequestMonthYear) {
    return request('GET', `/public/topics/${month}-${year}`, this.options)
  }

  async editTdmMonthYear({ month, year }: TdmRequestMonthYear, data: TdmBodyRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/topics/${month}-${year}`, requestOptions)
  }

  async deleteTdmMonthYear({ month, year }: TdmRequestMonthYear) {
    return request('DELETE', `/topics/${month}-${year}`, this.options)
  }

  async createTdmContent({ month, year }: TdmRequestMonthYear, { content }: ContentRequest) {
    const formData = new FormData()
    formData.append('content', content)
    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request('POST', `/topics/${month}-${year}/upload`, requestOptions)
  }


  async deleteTdmContent({ month, year, filename }: TdmRequestMonthYearFilename) {
    return request('DELETE', `/topics/${month}-${year}/contents/?filename=${filename}`, this.options)
  }
}
