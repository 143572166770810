// eslint-disable-next-line no-unused-vars
import { AxiosRequestConfig } from 'axios'

export interface Tag {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  value: string
  emoticon: string | null
}
export interface TagSet {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  name: string
  tags: Array<Tag>
  tag_ids: Array<number>
}

export interface TagRequest {
  value: string
  emoticon: string
}

export interface TagSetRequest {
  name: string
  tag_ids: Array<number>
}

// SDK OPTIONS
export interface Options extends AxiosRequestConfig {
  version?: string
  token?: string
  baseURL?: string
  timeout?: number
  socketEndpoint?: string
  userId?: string
  getNewToken?: Function
}

export interface PaginationOption {
  offset?: number
  limit?: number
  search_text?: string
  sort_by?: sortEnum
  sort_direction?: sortDirectionEnum
}

export enum sortEnum {
  value = 'value'
}

export enum sortDirectionEnum {
  ASC = 1,
  DESC = -1,
}

export interface PaginationOptionTag extends PaginationOption {
  tag_ids?: Array<number>
}


export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface Pagination<T> {
  count: number;
  next: string | null;
  previous: string | null;
  result: Array<T>;
}

export interface ErrorSdk {
  detail: string
}
