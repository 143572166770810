import {
  } from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class TagService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllTags() {
    return request('GET', `/profiles/default-tags`, this.options)
  }

  
}
