import {
  DailyMailsActionTypeEnum,
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";


const initialState = {
  DailyMails: [],
  DailyMailsTotal: null,
  DefaultDailyMails: null,
  DailyMessages: [],
  DailyMessagesTotal: null,
  DefaultDailyMessages: null,
  BlockModeDailyMailsLoading: false,
  DefaultCategory: null,
  DefaultMessage: null,
  DailyNews: [],
  DailyNewsTotal: null,
  DefaultDailyNews: null,
  BlockModeDailyMessageLoading: false,
};


const DailyMailsReducer = (state = initialState, action) => {
  switch (action.type) {

    case DailyMailsActionTypeEnum.List:
      return {
        ...state,
        DailyMails: action.dailymails,
        DailyMailsTotal: action.total,
        BlockModeDailyMailsLoading: action.loading
      };
      case DailyMailsActionTypeEnum.DailyMailLoading:
      return {
        ...state,
        BlockModeDailyMailsLoading: action.data,
      };
    case DailyMailsActionTypeEnum.Filter:
      return {
        ...state,
        DailyMails: action.dailymails,
      };

    case DailyMailsActionTypeEnum.ListInSelect:
      return {
        ...state,
        DefaultDailyMails: action.dailymails,
      };
    case DailyMailsActionTypeEnum.AddDefaultDailyMails:
      return {
        ...state,
        DefaultDailyMails: action.default,
      };
    case DailyMailsActionTypeEnum.Add:
      let newDailyMailsState = !state.DailyMails ? [] : state.DailyMails;
      newDailyMailsState.unshift(action.dailymails);
      return {
        ...state,
        DailyMails: newDailyMailsState,
        DailyMailsTotal: newDailyMailsState.length
      };
    case DailyMailsActionTypeEnum.Update:
      let dailymailsState = !state.DailyMails ? [] : state.DailyMails;
      let newArrayDailyMails = updatedArray(dailymailsState, action.dailymails);
      return {
        ...state,
        DailyMails: newArrayDailyMails,
      };
    case DailyMailsActionTypeEnum.Delete:
      let DAILYMAILS = !state.DailyMails ? [] : state.DailyMails;
      let updDailyMailsArray = arrayReduce(DAILYMAILS, action.dailymails);
      return {
        ...state,
        DailyMails: updDailyMailsArray,
        DailyMailsTotal: updDailyMailsArray.length
      };
    case DailyMailsActionTypeEnum.AddDefaultCategory:
      return {
        ...state,
        DefaultCategory: action.default,
      };

    // Daily message Reducers
    case DailyMailsActionTypeEnum.ListDailyMessages:
      return {
        ...state,
        DailyMessages: action.dailymessages,
        DailyMessagesTotal: action.total,
        BlockModeDailyMessageLoading: action.loading
      };
    case DailyMailsActionTypeEnum.DailyMessageLoading:
      return {
        ...state,
        BlockModeDailyMessageLoading: action.data,
      };
    case DailyMailsActionTypeEnum.FilterDailyMessages:
      return {
        ...state,
        DailyMessages: action.dailymessages,
      };

    case DailyMailsActionTypeEnum.ListInSelectDailyMessages:
      return {
        ...state,
        DefaultDailyMessages: action.dailymessages,
      };
    case DailyMailsActionTypeEnum.AddDefaultDailyMessages:
      return {
        ...state,
        DefaultDailyMessages: action.default,
      };
    case DailyMailsActionTypeEnum.AddDailyMessages:
      let newDailyMessagesState = !state.DailyMessages ? [] : state.DailyMessages;
      newDailyMessagesState.unshift(action.dailymessage);
      return {
        ...state,
        DailyMessages: newDailyMessagesState
      };
    case DailyMailsActionTypeEnum.UpdateDailyMessages:
      let dailymessagesState = !state.DailyMessages ? [] : state.DailyMessages;
      let newArrayDailyMessages = updatedArray(dailymessagesState, action.dailymessage);
      return {
        ...state,
        DailyMessages: newArrayDailyMessages,
      };
    case DailyMailsActionTypeEnum.DeleteDailyMessages:
      let DAILYMESSAGES = !state.DailyMessages ? [] : state.DailyMessages;
      let updDailyMessagesArray = arrayReduce(DAILYMESSAGES, action.dailymessage);
      return {
        ...state,
        DailyMessages: updDailyMessagesArray,
        DailyMessagesTotal: updDailyMessagesArray.length
      };

    default:
      return state;
  }
};

export default DailyMailsReducer;
