import {
  CommunityGroupRequest,
  CommunityGroupImage,
  CommunityApproveRequest,
  CommunityDeleteImageRequest,
  CommunityGroupParamsWithIsBackground,
  PaginationGroupOption,
  CommunityGroupParams,
  GroupRequestOption, CommunityGroupDeleteParams
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: PaginationGroupOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`


  if (typeof paginationOption.published_only !== 'undefined') {
    query += `&published_only=${paginationOption.published_only}`
  }

  if (typeof paginationOption.search_text !== 'undefined') {
    query += `&search_text=${paginationOption.search_text}`
  }

  if (typeof paginationOption.private !== 'undefined') {
    query += `&private=${paginationOption.private}`
  }

  if (typeof paginationOption.sort_by !== 'undefined') {
    query += `&sort_by=${paginationOption.sort_by}`
  }

  if (typeof paginationOption.sort_direction !== 'undefined') {
    query
      ? (query += `&sort_direction=${paginationOption.sort_direction}`)
      : (query += `?sort_direction=${paginationOption.sort_direction}`)
  }

  if (typeof  paginationOption.tags !== 'undefined' && paginationOption.tags) {
    const tagsFormat: string = paginationOption.tags
      .map((tag) => `&tags=${tag}`)
      .join('')
    query += tagsFormat
  }

  return query
}

function getGRoupRequestPaginationQuery(paginationOption: GroupRequestOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

  if (paginationOption.request_status !== undefined) {
    query += `&request_status=${paginationOption.request_status}`
  }

  if (paginationOption.group_id !== undefined) {
    query += `&group_id=${paginationOption.group_id}`
  }
  return query
}

export default class GroupService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  // POST
  async createGroup( data: CommunityGroupRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/groups`, requestOptions)
  }

  // GET ALL

  async getAllGroup( getAllQuery: PaginationGroupOption) {
    const query = getPaginationQuery(getAllQuery)
    return request('GET', `/groups${query}`, this.options)
  }

  // GET GROUP BY ID
  async getGroupById({ group_id }: CommunityGroupParams) {
    return request('GET', `/groups/${group_id}`, this.options)
  }

  // GET USER GROUP
  async getGroupByUser() {
    return request('GET', `/me/groups`, this.options)
  }

  // UPDATE A GROUP
  async editGroup(
    { group_id }: CommunityGroupParams,
    data: CommunityGroupRequest
  ) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request(
      'PUT',
      `/groups/${group_id}`,
      requestOptions
    )
  }

  // DELETE A GROUP
  async deleteGroup(
    { group_id, force_deletion=false }: CommunityGroupDeleteParams
  ) {
    return request(
      'DELETE',
      `/admin/groups/${group_id}?force_deletion=${force_deletion}`,
      this.options
    )
  }

  // GET GROUP REQUESTS
  async getGroupRequests(
    paginationOption: GroupRequestOption
  ) {
    const query = getGRoupRequestPaginationQuery(paginationOption)
    return request(
      'GET',
      `/admin/groups/requests${query}`,
      this.options
    )
  }

  // GET GROUP BY ID
  async getGroupRequestsById({
    request_id
  }: CommunityApproveRequest) {
    return request(
      'GET',
      `/admin/groups/requests/${request_id}`,
      this.options
    )
  }

  // APPROVE A REQUEST
  async approveRequest({
    request_id
  }: CommunityApproveRequest) {
    return request(
      'PUT',
      `/admin/groups/requests/${request_id}/approve`,
      this.options
    )
  }

  // POST A GROUP IMAGE
  async addGroupImage(
    {
      group_id,

      is_background = false
    }: CommunityGroupParamsWithIsBackground,
    data: CommunityGroupImage
  ) {
    const formData = new FormData()
    let query = ''

    formData.append('upload', data.upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }

    if (is_background) {
      query = `?is_background=true`
    }

    return request(
      'POST',
      `/groups/${group_id}/images${query}`,
      requestOptions
    )
  }

  // DELETE A GROUP IMAGE

  async deleteGroupImage({
    group_id,
    image_id,
    is_background = false
  }: CommunityDeleteImageRequest) {
    const query = `?is_background=${is_background}`

    return request(
      'DELETE',
      `/groups/${group_id}/images/${image_id}${query}`,
      this.options
    )
  }

  // GET GROUP MEMBER
  async getGroupMember(paginationOption: GroupRequestOption) {
    let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`
    return request('GET', `/admin/groups/${paginationOption?.group_id}/members/${query}`, this.options)
  }

  // ADD GROUP MEMBER
  async addGroupMember(group_id: number, user_id: number) {
    return request('POST', `/admin/groups/${group_id}/add/${user_id}`, this.options)
  }

  // DELETE GROUP MEMBER
  async deleteGroupMember(group_id: number, user_id: number) {
    return request('DELETE', `/admin/groups/${group_id}/remove/${user_id}`, this.options)
  }
}
