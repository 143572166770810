import BannersService from './services/banners'
import { Options } from '../../services.utils/request-types'
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import API from '../../services.utils/config'
import { escapedString } from '../../services.utils/helpers'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private bannersService!: BannersService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.bannersService = new BannersService(this.options)
  }

  /**
   * Get all banner
   */
  getAllBanners = (
    getAllQuery: dataSchemas.BannerGetAllRequest = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.Pagination<dataSchemas.Banner>> => this.bannersService.getAllBanners({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })


  /**
   * Get weekly banners
   */
  getWeekly = (
    getAllQuery: dataSchemas.BannerGetAllRequest = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.Pagination<dataSchemas.Banner>> => this.bannersService.getWeekly(getAllQuery)

  /**
   * Get banner by id
   * @param banner_id banner id
   */
  getBannerById = (
    banner_id: number
  ): Promise<dataSchemas.Banner> => this.bannersService.getBannerById(banner_id)

  /**
   * Create banner
   * @param body Body of banner
   */
  createBanner = (
    body: dataSchemas.BannerRequest
  ): Promise<dataSchemas.Banner> => this.bannersService.createBanner(body)

  /**
   * Update banner
   * @param body Body of banner
   */
  updateBanner = (
    banner_id: number,
    body: dataSchemas.BannerRequest
  ): Promise<dataSchemas.Banner> => this.bannersService.updateBanner(banner_id, body)

  /**
   * Delete banner
   * @param banner_id Object with banner id
   */
  deleteBanner = async (
    banner_id: number,
  ): Promise<void> => this.bannersService.deleteBanner(banner_id)

  /**
   * Add banner image
   * @param data create image Object
   */
  createBannerImage = async (
    data: dataSchemas.CreateBannerImageRequest,
  ): Promise<dataSchemas.ImageInterface> => this.bannersService.createBannerImage(data)

  /**
   * Delete banner image
   * @param banner_id banner id
   */
  deleteBannerImage = async (
    banner_id: number,
  ): Promise<any> => this.bannersService.deleteBannerImage(banner_id)

}

export * as types from './utils/DataSchemas'
