export enum DailyMailsActionTypeEnum {
    List = "LIST_DAILYMAILS",
    Filter = "FILTER_DAILYMAILS",
    ListInSelect = "SELECT_LIST_DAILYMAILS",
    Add = "ADD_DAILYMAILS",
    Update = "UPDATE_DAILYMAILS",
    Delete = "DELETE_DAILYMAILS",
    AddDefaultDailyMails = "ADD_DEFAULT_DAILYMAILS",
    ListDailyMessages = "LIST_DAILYMESSAGES",
    FilterDailyMessages = "FILTER_DAILYMESSAGES",
    ListInSelectDailyMessages = "SELECT_LIST_DAILYMESSAGES",
    AddDailyMessages = "ADD_DAILYMESSAGES",
    UpdateDailyMessages = "UPDATE_DAILYMESSAGES",
    DeleteDailyMessages = "DELETE_DAILYMESSAGES",
    AddDefaultDailyMessages = "ADD_DEFAULT_DAILYMESSAGES",
    AddDefaultCategory = "ADD_DEFAULT_CATEGORY",
    ListDailyNews = "LIST_DAILYNEWS",
    FilterDailyNews = "FILTER_DAILYNEWS",
    ListInSelectDailyNews = "SELECT_LIST_DAILYNEWS",
    AddDailyNews = "ADD_DAILYNEWS",
    UpdateDailyNews = "UPDATE_DAILYNEWS",
    DeleteDailyNews = "DELETE_DAILYNEWS",
    AddDefaultDailyNews = "ADD_DEFAULT_DAILYNEWS",
    DailyMailLoading = "LOADIN_DAILY_MAIL",
    DailyMessageLoading = "LOADING_DAILY_MESSAGE",
}
