export enum DailySetsActionTypeEnum {
    List = "LIST_DAILY_SETS",
    Filter = "FILTER_DAILY_SETS",
    ListInSelect = "SELECT_LIST_DAILY_SETS",
    AddDefaultDailySets = "ADD_DEFAULT_DAILY_SET",
    Add = "ADD_DAILY_SET",
    Update = "UPDATE_DAILY_SET",
    Delete = "DELETE_DAILY_SET",
    Loading = "DATA_LOADING"
}

export enum DailyCardActionTypeEnum {
    List = "LIST_DAILY_CARDS",
    BlockModeLoading = "SET_BLOCK_MODE_DAILY_CARDS_LOADING",
    SetEmpty = "SET_DAILY_CARDS_EMPTY",
    Update = "UPDATE_DAILY_CARD",
    Add = "ADD_DAILY_CARD",
    Delete = "DELETE_DAILY_CARD"
}