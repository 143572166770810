import { DailyCardImageRequest, DailyCardRequest, DailySetRequest, PaginationOption } from '../utils/DataSchemas';
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: PaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`;
  
  return query
}

export default class DailyCardAdminService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  

  async getAllDailySets(paginationOption: PaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/admin/daily-sets/${query}`, this.options)
  }

  async createDailySet(data: DailySetRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/daily-sets/`, requestOptions);
  }

  async getDailySetById(dailySetId: number) {
    return request('GET', `/admin/daily-sets/${dailySetId}`, this.options);
  }

  async editDailySet(dailySetId: number, data: DailySetRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/admin/daily-sets/${dailySetId}`, requestOptions);
  }

  async deleteDailySet(dailySetId: number) {
    return request('DELETE', `/admin/daily-sets/${dailySetId}`, this.options);
  }

  async createDailySetImage( dailySetId: number, { upload }: DailyCardImageRequest
    ) {
      const formData = new FormData()
  
      formData.append('upload', upload)
  
      const requestOptions: Options = {
        ...this.options,
        headers: {
          'Content-Type': 'multipart/form-data',
          ...this.options.headers
        },
        data: formData
      }
      return request('POST', `/admin/daily-sets/${dailySetId}/images`, requestOptions)
    }

    async deleteDailySetImage(dailySetId: number) {
      return request(
        'DELETE',
        `/admin/daily-sets/${dailySetId}/images`,
        this.options
      )
    }

    async createDailyCard(dailySetId: number, data: DailyCardRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
      return request('POST', `/admin/daily-sets/${dailySetId}/cards/`, requestOptions);
    }
  

    async getDailyCardById(dailySetId: number, dailyCardId: number) {
      return request('GET', `/admin/daily-sets/${dailySetId}/cards/${dailyCardId}`, this.options);
    }
  

    async editDailyCard(dailySetId: number, dailyCardId: number, data: DailyCardRequest) {
      const requestOptions: Options = {
        ...this.options,
        data: data
      }
      return request('PUT', `/admin/daily-sets/${dailySetId}/cards/${dailyCardId}`, requestOptions);
    }
  
    async deleteDailyCard(dailySetId: number, dailyCardId: number,) {
      return request('DELETE', `/admin/daily-sets/${dailySetId}/cards/${dailyCardId}`, this.options);
    }
  
    async createDailyCardImage( dailySetId: number, dailyCardId: number, { upload }: DailyCardImageRequest
      ) {
        const formData = new FormData()
    
        formData.append('upload', upload)
    
        const requestOptions: Options = {
          ...this.options,
          headers: {
            'Content-Type': 'multipart/form-data',
            ...this.options.headers
          },
          data: formData
        }
        return request('POST', `/admin/daily-sets/${dailySetId}/cards/${dailyCardId}/images`, requestOptions)
      }
  
      async deleteDailyCardImage(dailySetId: number, dailyCardId: number,) {
        return request(
          'DELETE',
          `/admin/daily-sets/${dailySetId}/cards/${dailyCardId}/images`,
          this.options
        )
      }

 
}
