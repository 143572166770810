export enum ExpertsActionTypeEnum {
    List = "LIST_EXPERTS",
    Filter = "FILTER_EXPERTS",
    ListInSelect = "SELECT_LIST_EXPERTS",
    Add = "ADD_EXPERTS",
    Update = "UPDATE_EXPERTS",
    Delete = "DELETE_EXPERTS",
    AddDefaultExperts = "ADD_DEFAULT_EXPERT",
    Loading = "LOADING_EXPERT"
}
