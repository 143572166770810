// ** Redux Imports
import { combineReducers } from "redux"

// ** Reducers Imports
import auth from "./auth"
import navbar from "./navbar"
import layout from "./layout"
import magazinData from "@src/views/hh/Magazin/store/reducer"
import meditationData from "@src/views/hh/Meditation/store/reducer"
import mediathekData from "@src/views/hh/Mediathek/store/reducer"
import AcademyData from "@src/views/hh/Academy/store/reducer"
import ExpertData from "@src/views/hh/Experts/store/reducer"
import CommunityAbuseData from "@src/views/hh/Community/abuses/store/reducer"
import LiveEventsData from "@src/views/hh/LiveEvents/store/reducer"
import CommunityData from "@src/views/hh/Community/store/reducer"
import DailyMailsData from "@src/views/hh/DailyMails/store/reducer"
import DailyNewsData from "@src/views/hh/GoodNews/store/reducer"
import UsersData from "@src/views/hh/Users/store/reducer"
import EmailsData from "@src/views/hh/Email/store/reducer"
import CmsData from "@src/views/hh/Cms/store/reducer"
import EventsData from "@src/views/hh/Events/store/reducer"
import BannersData from "@src/views/hh/Dashboard/store/reducer"
import DiariesData from "@src/views/hh/Diary/store/reducer"
import DiaryReducer from "@src/views/hh/Diary/store/diarySlice"
import MemberData from "@src/views/hh/Team/store/reducer"
import TreffpunkteData from "@src/views/hh/Treffpunkte/store/reducer"
import DailySetData from "@src/views/hh/DailySets/store/reducer"
import SupportData from "@src/views/hh/Support/store/reducer"
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  magazinData,
  meditationData,
  mediathekData,
  AcademyData,
  ExpertData,
  CommunityAbuseData,
  LiveEventsData,
  CommunityData,
  DailyMailsData,
  UsersData,
  EmailsData,
  CmsData,
  EventsData,
  BannersData,
  DiariesData,
  DiaryReducer,
  MemberData,
  DailyNewsData,
  TreffpunkteData,
  DailySetData,
  SupportData
})

export default rootReducer
