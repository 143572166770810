import request from '../../../services.utils/request'
import { Options } from '../../../services.utils/request-types'
import {
    DeleteTemplateUploadImage,
    PaginationOption,
    DailyQuoteCreateUpdateRequest,
    Template,
    TemplateCreateOrUpdateRequest,
    UploadTemplateImage,
    ModuleConfigRequest,
} from '../utils/DataSchemas'

function getPaginationQuery(paginationOption: PaginationOption) {
    let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

    if (typeof paginationOption.status !== 'undefined') {
        query += `&status=${paginationOption.status}`
    }

    if (typeof paginationOption.owner_id !== 'undefined') {
        query += `&owner_id=${paginationOption.owner_id}`
    }

    if (typeof paginationOption.search_text !== 'undefined') {
        query += `&search_text=${paginationOption.search_text}`
    }

    if (typeof paginationOption.sort_by !== 'undefined') {
        query += `&sort_by=${paginationOption.sort_by}`
    }

    if (typeof paginationOption.sort_direction !== 'undefined') {
        query += `&sort_direction=${paginationOption.sort_direction}`
    }

    return query
}
export default class DailyBookAdminService {
    options: Options

    constructor(options: Options) {
        this.options = options
    }

    async getAllDailyQuotes(paginationOption: PaginationOption) {
        const query = getPaginationQuery(paginationOption)
        return request('GET', `/admin/daily-quotes${query}`, this.options)
    }

    async getDailyQuote(dailyQuoteId: number,) {
        return request('GET', `/admin/daily-quotes/${dailyQuoteId}`, this.options)
    }

    async createDailyQuote(data: DailyQuoteCreateUpdateRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data
        }
        return request(
            'POST',
            `/admin/daily-quotes`,
            requestOptions
        )
    }

    async editDailyQuote(dailyQuoteId: number, data: DailyQuoteCreateUpdateRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data
        }
        return request(
            'PUT',
            `/admin/daily-quotes/${dailyQuoteId}`,
            requestOptions
        )
    }

    async deleteDailyQuote(dailyQuoteId: number) {
        return request(
            'DELETE',
            `/admin/daily-quotes/${dailyQuoteId}`,
            this.options
        )
    }

    async getAllTemplates(paginationOption: PaginationOption) {
        const query = getPaginationQuery(paginationOption)
        return request('GET', `/admin/templates${query}`, this.options)
    }

    async createTemplate(data: TemplateCreateOrUpdateRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data
        }
        return request('POST', `/admin/templates`, requestOptions)
    }

    async createTemplateImage({
        upload,
        template_id,
    }: UploadTemplateImage) {
        const formData = new FormData()

        formData.append('upload', upload)

        const requestOptions: Options = {
            ...this.options,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...this.options.headers
            },
            data: formData
        }

        return request('POST', `/admin/templates/${template_id}/images`, requestOptions)
    }

    async editTemplate(templateId: number, data: Template) {
        const requestOptions: Options = {
            ...this.options,
            data: data
        }
        return request('PUT', `/admin/templates/${templateId}`, requestOptions)
    }

    async deleteTemplateImage({ template_id, image_id }: DeleteTemplateUploadImage) {
        return request(
            'DELETE',
            `/admin/templates/${template_id}/images/${image_id}`,
            this.options
        )
    }

    async deleteTemplate(templateId: number) {
        return request('DELETE', `/admin/templates/${templateId}`, this.options)
    }

    async getAdminModuleConfig() {
        return request('GET', `/admin/module-config`, this.options)
      }
    
    async editAdminModuleConfig(data: ModuleConfigRequest) {
    const requestOptions: Options = {
        ...this.options,
        data: data
    }

    return request('PUT', `/admin/module-config/`, requestOptions)
    }
    
}
