import {
  DailyMailImageRequest,
  DailyMessageRequest,
  DailyMailRequest,
  PaginationOption,
  DailyNewsRequest,
  PaginationDailyNewsOption
} from '../utils/DataSchemas';
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: PaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`;

  if (typeof paginationOption.search_text !== "undefined") {
    query += `&search_text=${paginationOption.search_text}`;
  }

  if (typeof paginationOption.month !== "undefined") {
    query += `&month=${paginationOption.month}`;
  }

  if (typeof paginationOption.year !== "undefined") {
    query += `&year=${paginationOption.year}`;
  }

  if (typeof paginationOption.active !== "undefined") {
    query += `&active=${paginationOption.active}`;
  }

  if (typeof paginationOption.sort_direction !== "undefined") {
    query += `&sort_direction=${paginationOption.sort_direction}`;
  }

  if (typeof paginationOption.sort_by !== "undefined") {
    query += `&sort_by=${paginationOption.sort_by}`;
  }

  return query
}

export default class DailyMailAdminService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }



  async getById(dailyMailId: number) {
    return request('GET', `/admin/daily-mails/${dailyMailId}`, this.options);
  }

  async createDailyMail(data: DailyMailRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/daily-mails/`, requestOptions);
  }

  async editDailyMail(dailyMailId: number, data: DailyMailRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/admin/daily-mails/${dailyMailId}`, requestOptions);
  }

  async deleteDailyMail(dailyMailId: number) {
    return request('DELETE', `/admin/daily-mails/${dailyMailId}`, this.options);
  }

  async createDailyMessage(data: DailyMessageRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/daily-messages/`, requestOptions);
  }

  async getDailyMessageById(messageId: number) {
    return request('GET', `/admin/daily-messages/${messageId}`, this.options);
  }

  async editDailyMessage(messageId: number, data: DailyMessageRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/admin/daily-messages/${messageId}`, requestOptions);
  }

  async deleteDailyMessage(messageId: number) {
    return request('DELETE', `/admin/daily-messages/${messageId}`, this.options);
  }


  async createDailyMailImage(dailyMailId: number, { upload }: DailyMailImageRequest
  ) {
    const formData = new FormData()

    formData.append('upload', upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request('POST', `/admin/daily-mails/${dailyMailId}/images`, requestOptions)
  }


  async deleteDailyMailImage(dailyMailId: number) {
    return request(
      'DELETE',
      `/admin/daily-mails/${dailyMailId}/images`,
      this.options
    )
  }

  async getAllCategories() {
    return request('GET', `/admin/categories`, this.options)
  }

  async getAllMessages(paginationOption: PaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/admin/daily-messages/${query}`, this.options)
  }

  async getAllDailyMails(paginationOption: PaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/admin/daily-mails/${query}`, this.options)
  }

  async getAllDailyNews(paginationOption: PaginationDailyNewsOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/admin/daily-news/${query}`, this.options)
  }

  async getDailyNewById(dailyNewId: number) {
    return request('GET', `/admin/daily-news/${dailyNewId}`, this.options);
  }

  async createDailyNew(data: DailyNewsRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/daily-news/`, requestOptions);
  }

  async editDailyNew(dailyNewId: number, data: DailyNewsRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/admin/daily-news/${dailyNewId
      }`, requestOptions);
  }

  async deleteDailyNew(dailyNewId: number) {
    return request('DELETE', `/admin/daily-news/${dailyNewId}`, this.options);
  }

  async createDailyNewImage(dailyNewId: number, { upload }: DailyMailImageRequest
  ) {
    const formData = new FormData()

    formData.append('upload', upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request('POST', `/admin/daily-news/${dailyNewId}/images`, requestOptions)
  }

  async deleteDailyNewImage(dailyNewId: number) {
    return request(
      'DELETE',
      `/admin/daily-news/${dailyNewId}/images`,
      this.options
    )
  }


  async createDailyMessageImage(dailyMessageId: number, { upload }: DailyMailImageRequest
  ) {
    const formData = new FormData()

    formData.append('upload', upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request('POST', `/admin/daily-messages/${dailyMessageId}/images`, requestOptions)
  }


  async deleteDailyMessageImage(dailyMessageId: number) {
    return request(
      'DELETE',
      `/admin/daily-messages/${dailyMessageId}/images`,
      this.options
    )
  }
}
