import {
  ArtikelPaginationOption
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import { Options } from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: ArtikelPaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`;

  if (paginationOption.published !== undefined) {
    query += `&published=${paginationOption.published}`;
  }

  if (Number.isFinite(paginationOption.user_id)) {
    query += `&user_id=${paginationOption.user_id}`;
  }

  if (paginationOption.tags) {
    const tagsFormat: string = paginationOption.tags
      .map((tag) => `&tags=${tag}`)
      .join('');
    query += tagsFormat;
  }

  if (paginationOption.rubric_ids) {
    const rubrikIdsFormat: string = paginationOption.rubric_ids
      .map((id) => `&rubric_ids=${id}`)
      .join('');
    query += rubrikIdsFormat;
  }

  if (paginationOption.search_text) {
    query += `&search_text=${paginationOption.search_text}`;
  }

  if (paginationOption.sort_by) {
    query += `&sort_by=${paginationOption.sort_by}`;
  }

  if (Number.isFinite(paginationOption.reading_duration)) {
    query += `&reading_duration=${paginationOption.reading_duration}`;
  }

  if (typeof paginationOption.expert_ids !== "undefined") {
    const expertsIds: string = paginationOption.expert_ids
      .map((p: any, index: number) =>
        index === 0 && !query ? `?expert_ids=${p}` : `&expert_ids=${p}`
      )
      .join('')
    query += expertsIds
  }

  return query
}
export default class ArtikelService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAll(paginationOption: ArtikelPaginationOption) {
    const query = getPaginationQuery(paginationOption)

    return request('GET', `/articles/${query}`, this.options)
  }

  async getById(articleId: number) {
    return request('GET', `/articles/${articleId}`, this.options)
  }

  async getAllArticlesForPublic(paginationOption: ArtikelPaginationOption) {
    const query = getPaginationQuery(paginationOption)

    return request('GET', `/public/articles/${query}`, this.options, false)
  }

  async getByIdForPublic(articleId: number) {
    return request('GET', `/public/articles/${articleId}`, this.options, false)
  }

  async getCurrentRate(browserFingerprint: string) {
    return request('GET', `/public/articles/current-rate/${browserFingerprint}`, this.options, false)
  }

  async setCurrentRateArticle(browserFingerprint: string) {
    return request('PUT', `/public/articles/set-current-rate/${browserFingerprint}`, this.options, false)
  }
}
