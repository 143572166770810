import { LOCALES } from "../constants";

// "nameOfThePage_namwOfTheWord": word
export default {
    [LOCALES.GERMAN]: {
        adminHome_menu: "MENU",
        adminHome_menuThemaDesMonats: "Thema des Monats",
        adminHome_menuMediathek: "Mediathek",
        adminHome_menuMediathekSubMmenu1: "Allgemein",
        adminHome_menuMediathekSubMmenu2: "Rubrics settings",
        adminHome_menuMediathekSubMmenu3: "Mediathek",
        adminHome_menuMeditation: "Meditation",
        adminHome_menuMeditationSubMmenu1: "General",
        adminHome_menuMeditationSubMmenu2: "Rubrikeinstellungen",
        adminHome_menuMeditationSubMmenu3: "Meditation",
        adminHome_menuMagazin: "Magazin",
        adminHome_menuMagazinSubMmenu1: "Allgemein",
        adminHome_menuMagazinSubMmenu2: "Artikel",

        themaDesMonats_title: "Willkommen zum Thema des Monats",
        themaDesMonats_userMode: "User Mode",
        themaDesMonats_adminMode: "Admin Mode",
        themaDesMonats_description: "Hier findest Du jeden Monat ein neues Thema. Jeweils zu Beginn des Monats schalten wir für Dich eine neue Welt frei. Hier gibt es unsere Masterclasses, Meditationen, Affirmationen und Übungen, live und zum Downloaden. Du kannst jeden Monat für Dich zur Unterstützung Dein eigenes Foto hochladen und Deine Ziele und Affirmationen hineinschreiben. Du kannst sogar einen Brief an Dich selbst schreiben, den wir dann sechs Wochen später an Dich abschicken.",
        themaDesMonats_topicStatus: "Das Thema des Monats lautet",
        themaDesMonats_topicStatusReleased: "Veröffentlicht",
        themaDesMonats_topicStatusUnpublished: "Unveröffentlicht",
        themaDesMonats_textColor: "Texte-Farbe z.B #000000 oder rgba(255, 255, 255, 1)",
        themaDesMonats_backgroundColor: "Hintergrund-Farbe z.B #000000 oder rgba(255, 255, 255, 1)",
        themaDesMonats_emailHeaderImage: "Bild für Email Header",
        themaDesMonats_homePageImage: "Bild für Startseite",
        themaDesMonats_save: "Speichern",
        themaDesMonats_ToastErrorTitle: "Error!.",
        themaDesMonats_ToastSuccessTitle: "Erfolg!.",
        themaDesMonats_ToastError: "Fehler, Daten wurden nicht gespeichert.",
        themaDesMonats_ToastSuccess: "Daten wurden erfolgreich gespeichert.",
        themeDesMonats_boxTitle: "Box editieren",
        themeDesMonats_boxOption: "Box Optionen.",
        themeDesMonats_save: "Speichern",
        themeDesMonats_cancel: "Abbrechen",
        themeDesMonats_boxdelete: "Box löschen",
        themeDesMonats_size: "Größe auswählen",
        themeDesMonats_sizeLabel: "Größe: Header",
        themeDesMonats_template: "Template auswählen",
        themeDesMonats_templateLabel: "Vorlage: Bild",
        themeDesMonats_position: "Position",
        themeDesMonats_ClearImage: "Bild löschen",

        mediathek_generalHighlightVideo: "Highlight Videos",
        mediathek_generalHighlightVideoTitle: "Titel des Videos",
        mediathek_generalHighlightVideoSelect: "Video auswählen",
        mediathek_highlightVideoBtnInsert: "Einfügen",
        mediathek_highlightVideoBtnAlert: "Dieses Video wird bereits verwendet",

        mediathek_generalPopularVideo: "Beliebteste Videos",
        mediathek_generalPopularVideoTitle: "Beliebteste Videos",
        mediathek_generalPopularVideoSelect: "Video auswählen",
        mediathek_generalPopularVideoBtnInsert: "Einfügen",
        mediathek_generalPopularVideoBtnAlert: "Dieses Video wird bereits verwendet",

        mediathek_generalRecommendedVideo: "Empfohlene Videos",
        mediathek_generalRecommendedVideoTitle: "Das könnte Dir gefallen",
        mediathek_generalRecommendedVideoSelect: "Video auswählen",
        mediathek_generalRecommendedVideoBtnInsert: "Einfügen",
        mediathek_generalRecommendedVideoBtnAlert: "Dieses Video wird bereits verwendet",

        mediathek_generalNewestVideo: "Neueste Videos",
        mediathek_generalNewestVideoTitle: "Neueste Videos",
        mediathek_generalNewestVideoSelect: "Video auswählen",
        mediathek_generalNewestVideoBtnInsert: "Einfügen",
        mediathek_generalNewestVideoBtnAlert: "Dieses Video wird bereits verwendet",

        mediathek_generalTagSet: "TagSets",
        mediathek_generalTags: "Tags",
        mediathek_generalTagsTitle: "Tags",
        mediathek_generalTagsInput: "Tag-Input",
        mediathek_generalTagsBtn: "Erstellen",

        mediathek_generalRubrics: "Rubriken",
        mediathek_generalRubricsTitle: "Highlight Rubriken",
        mediathek_generalRubricsSelect: "Rubrik auswählen",
        mediathek_generalRubricsBtnInsert: "Einfügen",
        mediathek_generalRubricsBtnAlert: "Dieses Video wird bereits verwendet",

        mediathek_rubricsSettingAddSection: "Abschnitt hinzufügen",
        mediathek_rubricsSettingAddSectionModal: "Neuen Abschnitt hinzufügen",
        mediathek_rubricsSettingAddSectionTitle: "Titel",
        mediathek_rubricsSettingAddSectionNbelement: "Elementnummern",
        mediathek_rubricsSettingAddSectionSave: "Speichern",
        mediathek_rubricsSettingAddSectionLoad: "Wird geladen...",
        mediathek_rubricsSettingAddSectionCancel: "Abbrechen",
        mediathek_rubricsSettingAddSectionEmpty: "Gib bitte einen Titel und die Anzahl der Elemente an, die Du anzeigen lassen möchtest.",
        mediathek_rubricsSettingAddSectionError: "Fehler!",

        mediathek_mediathekRubrics: "Rubriken",
        mediathek_mediathekRubricsTitle: "TITEL",
        mediathek_mediathekRubricsStatus: "Status",
        mediathek_mediathekRubricsPublish: "Veröffentlicht",
        mediathek_mediathekRubricsUnpublish: "Unveröffentlicht",
        mediathek_mediathekRubricsSortDesc: "KURZBESCHREIBUNG",
        mediathek_mediathekRubricsAction: "BEARBEITEN",
        mediathek_mediathekRubricsSearch: "Suche",
        mediathek_mediathekRubricsFilter: "Filter",
        mediathek_mediathekVideoShortDesc: "Kurzbeschreibung (In der Übersicht unter dem Vorschaubild)",
        mediathek_mediathekVideoDesc: `Beschreibung (Unter dem Video beim Punkt "Information")`,
        mediathek_mediathekVideoRelatedDesc: "Listen-Beschreibung (In der Video-Seitenleiste)",
        mediathek_mediathekVideoDuration: "Dauer",
        mediathek_mediathekVideoImage: "Bild",
        mediathek_mediathekVideoDelImage: "Bild löschen",
        mediathek_mediathekVideoAttachments: "Anhänge",
        mediathek_mediathekVideoReferences: "Referenzen",
        mediathek_mediathekVideoUrl: "Video Url",
        mediathek_mediathekVideoSave: "Speichern",
        mediathek_mediathekVideoCancel: "Abbrechen",

        mediathek_mediathekVideoAddSection: "Rubrik erstellen",
        mediathek_mediathekVideoAddTitle: "Titel",
        mediathek_mediathekVideoAddDesc: "Beschreibung",
        mediathek_mediathekVideoAddTags: "Tags",
        mediathek_mediathekVideoAddImage: "Bild",
        mediathek_mediathekVideoAddDelImage: "Bild löschen",
        mediathek_mediathekVideoAddSave: "Speichern",
        mediathek_mediathekVideoAddCancel: "Abbrechen",

        medidation_generalHighlightVideo: "Highlight Videos",
        medidation_generalHighlightVideoTitle: "Highlight Videos",
        medidation_generalHighlightVideoSelect: "Ein Video auswählen",
        medidation_generalhighlightVideoBtnInsert: "Einfügen",
        medidation_generalhighlightVideoBtnAlert: "Dieses Video wird bereits verwendet",

        medidation_generalPopularVideo: "Beliebteste Videos",
        medidation_generalPopularVideoTitle: "Beliebteste Videos",
        medidation_generalPopularVideoSelect: "Ein Video auswählen",
        medidation_generalPopularVideoBtnInsert: "Einfügen",
        medidation_generalPopularVideoBtnAlert: "Dieses Video wird bereits verwendet",

        medidation_generalRecommendedVideo: "Empfohlene Videos",
        medidation_generalRecommendedVideoTitle: "Titel des Videos",
        medidation_generalRecommendedVideoSelect: "Ein Video auswählen",
        medidation_generalRecommendedVideoBtnInsert: "Einfügen",
        medidation_generalRecommendedVideoBtnAlert: "Dieses Video wird bereits verwendet",

        medidation_generalNewestVideo: "Neueste Videos",
        medidation_generalNewestVideoTitle: "Neueste Videos",
        medidation_generalNewestVideoSelect: "Ein Video auswählen",
        medidation_generalNewestVideoBtnInsert: "Einfügen",
        medidation_generalNewestVideoBtnAlert: "Dieses Video wird bereits verwendet",

        medidation_generalTags: "Tags",
        medidation_generalTagsTitle: "Tags",
        medidation_generalTagsInput: "Tag-Input",
        medidation_generalTagsBtn: "Erstellen",

        medidation_generalRubrics: "Rubriken",
        medidation_generalRubricsTitle: "Highlight Rubriken",
        medidation_generalRubricsSelect: "Rubrik auswählen",
        medidation_generalRubricsBtnInsert: "einfügen",
        medidation_generalRubricsBtnAlert: "Dieses Video wird bereits verwendet",

        medidation_rubricsSettingAddSection: "Abschnitt hinzufügen",
        medidation_rubricsSettingAddSectionTitle: "Titel",
        medidation_rubricsSettingAddSectionNbelement: "Position",
        medidation_rubricsSettingAddSectionSave: "Speichern",
        medidation_rubricsSettingAddSectionCancel: "Abbrechen",
        medidation_rubricsSettingAddSectionModal: "Neuen Abschnitt hinzufügen",
        medidation_rubricsSettingAddSectionLoad: "Wird geladen...",
        medidation_rubricsSettingAddSectionEmpty: "Gib bitte einen Titel und die Anzahl der Elemente an, die Du anzeigen lassen möchtest.",
        medidation_rubricsSettingAddSectionError: "Fehler!",
        medidation_MedidationUpdateSection: "Abschnitt aktualisieren",
        medidation_MedidationRemoveSection: "Abschnitt löschen",

        medidation_MedidationRubrics: "Rubriken",
        medidation_MedidationVideo: "Video",
        medidation_MedidationVideoShortDesc: "Kurze Beschreibung",
        medidation_MedidationVideoDesc: "Beschreibung",
        medidation_MedidationVideoRelatedDesc: "Verwandte Beschreibung",
        medidation_MedidationVideoDuration: "Dauer",
        medidation_MedidationVideoImage: "Bild",
        medidation_MedidationVideoDelImage: "Bild löschen",
        medidation_MedidationVideoAttachments: "Anhänge",
        medidation_MedidationVideoReferences: "Referenzen",
        medidation_MedidationVideoUrl: "Video Url",
        medidation_MedidationVideoSave: "Speichern",
        medidation_MedidationVideoCancel: "Abbrechen",

        meditation_meditationVideoAddSection: "Rubrik erstellen",
        meditation_meditationVideoAddTitle: "Titel",
        meditation_meditationVideoAddDesc: "Beschreibung",
        meditation_meditationVideoAddTags: "Tags",
        meditation_meditationVideoAddImage: "Bild",
        meditation_meditationVideoAddDelImage: "Bild löschen",
        meditation_meditationVideoAddSave: "Speichern",
        meditation_meditationVideoAddCancel: "Abbrechen",

        magazin_generalHighlightedArticles: "Highlight Artikel",
        magazin_generalHighlightedArticlesTitle: "Highlight Artikel",
        magazin_generalHighlightedArticlesSelect: "Artikel auswählen",
        magazin_generalHighlightedArticlesBtnInsert: "Einfügen",
        magazin_generalHighlightedArticlesBtnAlert: "Dieses Video wird bereits verwendet",

        magazin_header_1: "Magazin",
        magazin_header_2: "erstellen",
        magazin_title: "Title",
        magazin_generalSuggestedArticles: "Empfohlene Artikel",
        magazin_generalSuggestedArticlesTitle: "Empfohlene Artikel",
        magazin_generalSuggestedArticlesSelect: "Artikel auswählen",
        magazin_generalSuggestedArticlesBtnInsert: "Einfügen",
        magazin_generalSuggestedArticlesBtnAlert: "Dieses Video wird bereits verwendet",

        magazin_generalMostViewedArticles: "Meist gelesene Artikel",
        magazin_generalMostViewedArticlesTitle: "Meist gelesene Artikel",
        magazin_generalMostViewedArticlesSelect: "Artikel auswählen",
        magazin_generalMostViewedArticlesBtnInsert: "Einfügen",
        magazin_generalMostViewedArticlesBtnAlert: "Dieses Video wird bereits verwendet",

        magazin_generalTags: "Tags",
        magazin_generalTagsTitle: "Tags",
        magazin_generalTagsInput: "Tag-Input",
        magazin_generalTagsBtn: "Erstellen",

        magazin_articleTitle: "Artikel",
        magazin_articleSearch: "Suche",
        magazin_articleFilter: "Filter",
        magazin_articleFilterLabel: "Alle",
        magazin_articleFilterStatusPublished: "Veröffentlicht",
        magazin_articleFilterStatusUnpublished: "Unveröffentlicht",

        magazin_articleCreateSection: "Artikel schreiben",
        magazin_articleCreateTitle: "Titel",
        magazin_articleCreateReadingTime: "Lesezeit",
        magazin_articleCreateIndex: "Index",
        magazin_articleCreateBriefDesc: "Kurzbeschreibung",
        magazin_articleCreateDesc: "Beschreibung",
        magazin_articleCreateTags: "Tags",
        magazin_articleCreateRubrics: "Rubriken",
        magazin_articleCreateAuthors: "Autoren",
        magazin_articleCreateNote: "ANMERKUNG: Du kannst erst Bilder, Videos und Audios hochladen wenn der Artikel zum ersten Mal gespeichert wurde.",
        magazin_articleCreateSave: "Speichern",
        magazin_articleCreateCancel: "Abbrechen",
        magazin_article_public: "Öffentlich",
        magazin_article_private: 'Privat',

        adminLogin_welcome: "Willkommen bei Happiness House!",
        adminLogin_note: "Bitte melde Dich bei Deinem Konto an und starte das Abenteuer !",
        adminLogin_email: "Email",
        adminDigistore_email: "Digistore Email",
        adminLogin_password: "Passwort",
        adminLogin_passwordForget: "Passwort vergessen?",
        adminLogin_remember: "Eingeloggt bleiben",
        adminLogin_signIn: "Anmelden",
        adminLogin_account: "Bist Du neu bei uns?",
        adminLogin_accountLink: "Ein Konto erstellen",
        adminLogin_signSuccesscClient: "Du hast Dicherfolgreich als Client-Benutzer bei Vuexy angemeldet. Du kannst jetzt mit der Erkundung beginnen. Viel Spaß!",
        adminLogin_signSuccesscAdmin: "Du hast Dicherfolgreich als Admin-Benutzer bei Vuexy angemeldet. Du kannst jetzt mit der Erkundung beginnen. Viel Spaß!",
        adminLogin_welcomeUser: "Willkommen",
        adminEmail_warning_title: "🚨🚨🚨 Warnung 🚨🚨🚨",
        adminEmail_warning_description: "Gefährlicher Bereich kann Benutzer vom Login auf dem Portal abhalten oder die Digistore-Integration unterbrechen ",
        adminEmail_change_email: "So ändern Sie die für die Anmeldung am Portal verwendete Benutzer-E-Mail",
        adminEmail_change_digistore: "So ändern Sie das E-Mail-Konto des Benutzers digistore",


        academy_generalHighlightedCourses: "Highlight Kurse",
        academy_generalHighlightedTag: "Tags",
        academy_courseBtn: "Einfügen",
        academy_courseSelect: "Kurs auswählen",
        academy_TagBtn: "Einfügen",
        academy_action: "AKTION",
        academy_course: "Kurse",
        academy_title: "TITEL",
        academy_description: 'Beschreibung (Unter dem Video beim Punkt "Information")',
        academy_shortDescription: "Kurzbeschreibung (In der Übersicht unter dem Vorschaubild)",
        academy_relatedDescription: "Listen-Beschreibung (In der Video-Seitenleiste)",
        academy_Duration: "Dauer",
        academy_IsPublished: "Veröffentlichen",
        academy_Image: "Bild",
        academy_DelImage: "Bild löschen",
        academy_largeImage: "Großes Bild",
        academy_Attachments: "Anhänge",
        academy_References: "Referenzen",
        academy_Url: "Video-URL",
        academy_Save: "Speichern",
        academy_Cancel: "Abbrechen",

        expert_image: "Bild",
        expert_title: "Experte",
        expert_firstname: "Vorname",
        expert_position: "Position",
        expert_lastname: "Nachname",
        expert_role: "Rolle",
        team_role: "Jobposition",
        expert_shortDesc: "Kurzbeschreibung",
        expert_description: "Beschreibung",
        expert_statu: "Status",
        expert_action: "Bearbeiten",
        expert_statuActive: "Aktiviert",
        expert_statuDesactived: "Deaktiviert",
        expert_save: "Speichern",
        expert_cancel: "Abbrechen",
        expert_Search: "Suche",
        expert_Filter: "Filter",
        expert_childrenImage: "Kinderbild",
        expert_currentImage: "Aktuelles Bild",
        expert_delimage: "Bild löschen",

        academy_sideBarTitle: "Academy",
        academyGeneral_sideBarTitle: "Allgemein",
        academyRubricsSettings_sideBarTitle: "Rubrikeinstellungen",
        dashboard_sideBarTitle: "Dashboard",
        mediathek_sideBarTitle: "Mediathek",
        mediathekGeneral_sideBarTitle: "Allgemein",
        mediathekRubricsSettings_sideBarTitle: "Rubrikeinstellungen",
        meditation_sideBarTitle: "Meditation",
        meditationGeneral_sideBarTitle: "Allgemein",
        meditationRubricsSettings_sideBarTitle: "Rubrikeinstellungen",
        themaDesMonats_sideBarTitle: "Thema des Monats",
        magazin_sideBarTitle: "Magazin",
        magazinArtikel_sideBarTitle: "Artikel",
        magazinGeneral_sideBarTitle: "Allgemein",
        expert_sideBarTitle: "Experten/Autoren",

        live_events_datatable_header: "Events",
        live_events_datatable_sub_header: "Erstellen",
        live_events_datatable_search: "Suche",
        live_events_datatable_filter: "Filter",
        live_events_datatable_type: "Live-Event Typ wählen",
        live_events_datatable_noData: "Es gibt keine Aufzeichnungen",
        live_events_datatable_image: "Großes Bild",
        live_events_datatable_title: "Titel",
        live_events_datatable_category: "Kategorie",
        live_events_datatable_description: "Kurzbeschreibung",
        live_events_datatable_attendees: "Teilnehmer",
        live_events_datatable_start: "Startdatum",
        live_events_datatable_status: "Status",
        live_events_datatable_action: "Aktionen",
        live_events_event_title: "Event-Titel",
        live_events_event_internalTitle: "Interner Name",
        live_events_event_description: "Event Beschreibung",
        live_events_event_long_description: "Lange Beschreibung",
        live_events_event_duration: "Event - Dauer",
        live_events_event_videoLink: "Video Link",
        live_events_event_released: "Event veröffentlichen",
        live_events_event_recorded: "Aufgezeichnet",
        live_events_event_public: "Öffentlicher Stream",
        live_events_event_notifyUser: "Erinnerungsmail an alle User schicken",
        live_events_event_weekly: "Wöchentliches Event",
        live_events_event_highlighted: "Hervorgehoben",
        live_events_event_image: "Bild",
        live_events_event_square_image: "Quadratisches Bild",
        live_events_event_delete_image: "Bild löschen",
        live_events_event_submit: "Speichern",
        live_events_event_cancel: "Abbrechen",
        live_events_event_category: "Kategorie",
        live_events_event_attendees: "Teilnehmer",
        live_events_event_start_dt: "Startdatum",
        live_events_event_end_dt: "Enddatum",
        live_events_rubric_title: "Rubrik-Titel",
        live_events_rubric_description: "Kurzbeschreibung",
        live_events_rubric_published: "Veröffentlicht",
        live_events_rubric_header: "Rubriken",
        live_events: "Live Events",
        live_events_general: "Allgemein",
        live_events_select_event: "Event auswählen",
        live_events_empty: "Keine Events",

        community_sideBarTitle: "Community",
        community_groups_create: "Erstellen",
        community_groups_update: "Aktualisieren",
        community_groups: "Gruppen",
        community_groups_image: "Bild",
        community_groups_background_image: "Titelbild",
        community_groups_name: "Name",
        community_groups_description: "Beschreibung",
        community_groups_members: "Mitglieder",
        community_groups_type: "Typ",
        community_groups_is_private: "Dies ist eine private Gruppe",
        community_groups_private: "Private Gruppe",
        community_groups_public: "Öffentliche Gruppe",
        community_groups_action: "Handlung",
        community_groups_tag: "Stichworte",
        community_groups_request_join_date: "Beitrittsdatum",
        community_groups_request_join_reason: "Grund",
        community_groups_request_join_request: "Beitrittsanfrage",
        fileDropzone_fotoPerDragDropHierReinziehen: "Fotos per drag & drop hier reinziehen",
        fileDropzone_oderDateienAussuchen: "oder Dateien aussuchen",

        login_email: "Email",
        login_password: "Passwort",
        login_signIn: "Einloggen",
        login_welcome: "Willkommen",
        login_forgotPassword: "Passwort vergessen",
        login_pleaseSignInToYourAccountAndStartTheAdventure: "Bitte melde Dich bei Deinem Konto an und starte das Abenteuer ",
        login_passwordNeedToBeChanged: "Dies ist Deine erste Anmeldung und das Passwort muss geändert werden.",
        login_changePassword: "Bestätigen",
        login_ichHabeDie: "Ich habe die Nutzungsbedingungen gelesen",
        login_eingeloggtBleiben: "Eingeloggt bleiben",

        passwordForget_email: "Email",
        passwordForget_sendResetLink: "Link zum Zurücksetzen senden",
        passwordForget_backToLogin: "Zurück zum Login",
        passwordForget_forgetPassword: "Passwort vergessen",
        passwordForget_password: "Passwort",
        passwordForget_newPassword: "Neues Passwort",
        passwordForget_changePassword: "Passwortänderung",
        passwordForget_receivedCode: "Code erhalten",
        passwordForget_codeSent: "Der Code wurde per E-Mail verschickt",
        passwordForget_passwordHasBeenReset: "Das Passwort wurde geändert",
        passwordForget_sendCode: "Code senden",
        Live_events_Category_all: "Alle Kategorien",
        Live_events_Category_1: "Live Stream",
        Live_events_Category_2: "Live Video",
        Live_events_Category_3: "Live Chat",
        Live_events_Category_4: "Zoom Event",

        Video_chapter: "Videokapitel",
        Video_chapter_add: "Füge ein neues Kapitel hinzu",

        community_abuses: "Missbrauch",
        community_group: "Gruppen",
        Users: "Benutzer",
        Posts: "Beiträge",
        comment: "Kommentare",
        community_reporter: "Verfasser",
        community_report_date: "Datum des Posts",
        community_status: "Status",
        community_owner_object: "Eigentümer-Objekt",
        community_owner_domain: "Eigentümer-Domäne",
        community_domain: "Rubrik",
        community_report: "Bericht",
        community_content: "Inhalt",
        community_reason: "Grund",
        community_resolve_abuse: "Meldung eines Users",
        community_reported_comment: "Gemeldeter Kommentar",
        community_like_number: "Anzahl der Likes",
        community_replies_number: "Anzahl der Antworten",
        community_comment_author: "Autor des Kommentars",
        community_resolve: "Gelöst",
        community_resolve_abuse_post: "Missbrauchsbeitrag melden",
        community_resolve_abuse_data: "Angaben zum unangemessenen Verhalten",
        community_reported_post: "Gemeldeter Beitrag",
        community_post_author: "Verfasser des Beitrags",
        community_title: "Titel",

        delete_confirmation_title: "Möchtest Du diesen Inhalt wirklich löschen?",
        delete_confirmation_text: "Der Löschvorgang ist unwiderruflich und kann nicht rückgängig gemacht werden.",
        delete_confirmation_button_text: "Ja, bitte löschen",
        community_content_deleted: "(Gelöschter Inhalt)",
        community_delete_comment: "Kommentar löschen",
        community_delete_post: "Beitrag löschen",
        community_post_title: "Titel des Beitrags",
        community_post_status: "Status des Beitrags",
        community_user_reported: "Gemeldetes Mitglied",
        community_creation_date: "Erstellungsdatum",
        community_post_list: "Liste der Beiträge",
        community_select_a_group: "Gruppe auswählen",
        community_search_a_post: "Beitrag suchen",

        community_groups_user_search: "Suchen",
        community_groups_user_add_title: "Neues Mitglied hinzufügen",
        community_groups_user_add_button: "Hinzufügen",
        community_groups_no_member: "Kein Mitglied gefunden",
        community_groups_manage_title: "Verwaltung von Gruppenmitgliedern: ",
        community_groups_manage_body: "Liste der Gruppenmitglieder",
        community_groups: "Gruppen",
        community_requests: "Gruppenanfragen",
        community_posts: "Beiträge",
        No_tags: "Keine Tags",
        No_reason: "Kein Grund",
        approved: "Genehmigt",
        cant_manage: "Sie können Ihre eigene Anfrage nicht verwalten",

        magazin_article_header_1: "Artikel",
        magazin_article_header_2: "Schreiben",
        magazin_article_title: "Titel",
        magazin_article_duration: "Lesezeit",
        magazin_article_index: "Index",
        magazin_article_pubished: "Veröffentlicht",
        magazin_article_rubric: "Rubrik",
        magazin_article_auteur: "Autoren",
        magazin_article_short_description: "Kurzbeschreibung",
        magazin_article_description: "Beschreibung",
        magazin_article_tips_1: "Du kannst erst mit dem Schreiben des Artikels beginnen, nachdem der Artikel zum ersten Mal gespeichert wurde.",
        magazin_article_cover_picture: "Titelbild",
        magazin_article_square_picture: "Quadratisches Bild",
        magazin_article_delete_picture: "Foto löschen",
        magazin_article_delete_audio: "Tonspur löschen ",
        magazin_article_attachement: "Anhänge",
        magazin_article_reference: "Verweise",
        magazin_article_soumettre: "Speichern",
        magazin_article_annuler: "Abbrechen",
        daily_mails_sideBarTitle: "Daily Mails",
        daily_mails_dt: "Datum",
        daily_mails_Tagesbotschaft: "Tagesbotschaft",
        daily_mails_Nachrichten: "Nachrichten",
        daily_mails_date: "Datum",
        daily_mails_name: "Interner Name",
        daily_mails_headline: "Überschrift",
        daily_mails_search: "Suche",
        daily_mails_month: "Monat",
        daily_mails_year: "Jahr",
        daily_mails_category: "Kategorie",
        daily_mails_message: "Tagesbotschaft",
        daily_mails_content: "Textbaustein 1",
        daily_mails_signature: "Textbaustein 2",
        daily_mails_citation: "Glückstagebuch - Tageszitat",
        daily_mails_image: "Bild",
        daily_maessage: "Titel",
        daily_maessage_title: "Beitrag des Tages",
        good_news_sideBarTitle: "Good News",
        treffpunkte_sideBarTitle: "Treffpunkte",
        january: "Januar",
        february: "Februar",
        march: "März",
        april: "April",
        may: "Mai",
        june: "Juni",
        july: "Juli",
        august: "August",
        september: "September",
        october: "Oktober",
        november: "November",
        december: "Dezember",
        RegistrationDate: "Registrierungsdatum",
        attachments: "Anhänge",
        chat_sideBarTitle: "Chat",
        chat_chatInfo: "Broadcast-Kanal",
        chat_dataTable_name: "Name",
        chat_dataTable_members: "Mitglieder",
        chat_dataTable_status: "Status",
        chat_dataTable_activated: "Aktiviert",
        chat_dataTable_desactivated: "Deaktiviert",
        chat_channelModal_isActive: "Ist aktiv?",
        chat_channelModal_full_name: "Vollständiger Name",
        chat_channelModal_add_member: "Mitglied hinzufügen",
        chat_sidemode_messages: "Mitteilungen",
        chat_messageDataTable_message: "Botschaft",
        chat_modalMessage_fileupload: "Datei hochladen",
        chat_modalMessage_size: "Größe",
        userAUthorization_choose: "Auswählen",

        system: "System",
        email_template: "E-Mail-Vorlage",
        email_template_name: "NACHNAME",
        email_template_content: "INHALT",
        email_template_html_content: "HTML INHALT",
        email_template_action: "AKTIONEN",
        email_template_search: "Suche",
        email_template_save: "Speichern",
        email_template_cancel: "Abbrechen",
        email_template_parameters: "Einstellungen",

        cms: "CMS-Seite",
        cms_internal_name: "Interner Name",
        cms_title: "TITEL",
        cms_sub_title: "Untertitel",
        cms_button_1_label: "Titel Button 1",
        cms_button_1_link: "Link 1",
        cms_button_2_label: "Titel Button 2",
        cms_button_2_link: "Link 2",
        cms_iframe_link: "Iframe Link",
        cms_actions: "AKTIONEN",
        cms_content: "Inhalt",
        cms_published: "Veröffentlicht",
        cms_public: "Öffentlich zugänglich",
        cms_search: "Suche",
        cms_save: "Speichern",
        cms_cancel: "Abbrechen",

        tag_value: "Wert",
        tag_set: "Tag set",
        mediathek_generalDefaultTagSelect: "Schlagwort auswählen",
        daily_news_title: "TITEL",
        daily_news_content: "Inhalt",
        daily_news_image: "BILD",
        daily_news_active: "AKTIV",
        daily_news_date: "DATIERT",

        events: "Ereignisse",
        events_Category_all: "Alle",
        events_Category_1: "Seminar",
        events_Category_2: "Workshop",
        events_Category_3: "Webinar",
        events_datatable_image: "Bild",
        events_datatable_name: "Name",
        events_datatable_description: "Beschreibung",
        events_datatable_category: "Kategorie",
        events_datatable_status: "Status",
        events_datatable_action: "Aktionen",
        events_datatable_search: "Suche",
        events_datatable_type: "Typ",
        events_datatable_filter: "Filter",
        events_event_start_dt: "Startdatum",
        events_event_end_dt: "Enddatum",
        events_released: "Freigabe",
        events_submit: "Speichern",
        events_cancel: "Abbrechen",
        events_delete_image: "Bild löschen",
        events_datatable_sub_header: "Erstellt",

        dashboard_banner_header: "Banner",
        dashboard_banner_sub_header: "Erstellen",
        dashboard_banner_title: "Titel des Banners",
        dashboard_banner_description: "Beschreibung",
        dashboard_banner_footer: "Banner-Fußzeilentext",
        dashboard_banner_weekly_repeat: "Wöchentlich wiederholen",
        dashboard_banner_links: "Verknüpfungen",
        dashboard_banner_add_links: "Fügen Sie einen neuen Link hinzu",


        diary_template_header: "Vorlage",
        daily_special_header: "Daily special",
        diary_header: "Diary",

        diary_alert_title: "Bist Du sicher, dass Du diesen Abschnitt löschen möchtest?",
        diary_alert_cancel: "Abbrechen",
        diary_alert_confirm: "Löschen",
        diary_upload_error: "Error! Diese Datei kann nicht hochgeladen werden",
        diary_image_title: "Bitte geben Sie einen Bildtitel ein",
        diary_select_image: "Bitte wählen Sie ein Bild aus",
        diary_upload_button: "Hochladen",
        diary_cancel_button: "Annullieren",
        diary_section_title: "Abschnitt hinzufügen",
        diary_section_subtitle: "Wählen Sie einen Abschnitt zum Hinzufügen",
        diary_question_title: "Wählen Sie Ihre Frage",
        diary_question_categories: "Kategorien",
        diary_question_question: "Fragen",
        diary_mark_default: "Als Standardvorlage markieren",
        diary_go_back: "Zurück",
        diary_use_template: "Vorlage verwenden",
        diary_empty_page_tip: "Es sieht so aus, als wäre hier nichts! Fügen Sie einen Abschnitt hinzu, um mit dem Ausfüllen Ihres Tagebuchs zu beginnen",
        diary_empty_page_button: "Leere Seite",
        diary_browse_template_button: "Vorlagen durchsuchen",
        diary_your_template_button: "Ihre Vorlage",
        diary_dashboard_button: "Zurück zum Dashboard",
        diary_pick_template: "Wählen Sie Ihre Vorlage",
        diary_photo_limit: "* Sie können nicht mehr als 5 Bilder gleichzeitig auswählen!",
        diary_photo_limit_reached: "Sie können nicht mehr als 6 Bilder gleichzeitig hochladen!",
        diary_template_saved: "Ihr Tagebuch wurde erfolgreich als Vorlage gespeichert!",

        community_groups_is_prublished: "Aufzeichnung veröffentlichen",
        rubrik_is_prublished: "Veröffentlichen",
        community_groups_is_prublished_update: "Gruppe veröffentlichen",

        live_events_datatable_published: "Veröffentlicht",
        live_events_datatable_unpublished: "Unveröffentlicht",
        live_events_datatable_released: "Freigeben",
        live_events_datatable_unreleased: "Nicht freigeben",

        event_page_logo_image: "LogoBild",
        mediathek_rubricConfig_cant_delete_every_rubric_section: "Sie können nicht jede Rubrik im Abschnitt löschen, Sie müssen mindestens eine Rubrik haben !",
        magazine_config_page_wahle_einen_artikel: "Wähle einen Artikel",
        community_group_request_username: "Benutzer",

        limit_upload_files: "Sie können die Grenze der zulässigen Bilder nicht überschreiten",
        unblock: "Entsperren",
        block: "Sperren",
        cant_manage_post: "Sie können Ihren Beitrag nur verwalten",

        team_sideBarTitle: "Team",
        team_title: "Team",
        add_note: "Notizen",
        create_group: "Speichern",
        comment_abuse_resolve: "Meldung Auflösen",
        comment_abuse_delete: "Kommentar Löschen",
        post_abuse_resolve: "Meldung Auflösen",
        post_abuse_delete: "Beitrag Löschen",
        group_management_confirmation_title: "Bist Du Dir sicher?",
        group_management_confirmation_text: "Du kannst diesen Vorgang nicht rückgängig machen. !",
        group_management_confirmation_confirmButtonText: "Ja, Mitglied löschen",

        tdm_template_title: "Titel",
        tdm_template_description: "Beschreibung",
        tdm_Picture_label: "Bild",
        tdm_Audio_label: "Audio",
        tdm_Document_label: "Dokument",
        tdm_template_placeholder_text: "Platzhaltertext",
        tdm_template_link: "Link",
        tdm_template_audio_file_name: "Audio-Link ",
        tdm_template_video_url: "Video-Upload-Url",
        tdm_template_target: "Target(\"_blank\" für neues Fenster, \"_self\" für selbes Fenster)",
        tdm_template_audio_with_muzik: "Mit Musik",
        tdm_template_audio_no_muzik: "Ohne Musik",

        cms_footbal_rule: "Hier gehts zu den Spielregeln:",
        cms_footbal_rule_description: "Den Spielregeln:",
        cms_footbal_feed: "Hier gehts zum Community Thread:",
        start_date_is_in_future: "Bitte wählen Sie ein gültiges Datum, das kleiner als das Enddatum ist!",
        invalid_start_date: "Das Startdatum darf nicht in der Vergangenheit liegen!",
        end_date_is_in_past: "Bitte wählen Sie ein gültiges Datum, das höher als das Startdatum ist!",
        invalid_end_date: "Das Enddatum darf nicht in der Vergangenheit liegen!",
        loading: "Wird geladen...",
        sucess_message_toast: "Daten erfolgreich gespeichert",
        error_message_toast: "Fehler, Daten nicht gespeichert.",
        url_is_incorrect: "Der Link ist nicht gültig !",
        mediathek_time_is_incorrect: "Zeitraum ist nicht gültig !",
        academy_sorting: "Sortierung",
        alle: "Alle",

        daily_set_sideBarTitle: "Tageskarte",
        daily_set_title: "Tageskarte",
        daily_set_name: "Name",
        daily_set_description: "Bezeichnung",
        daily_set_game_rules: "Spielregeln",
        daily_set_image: "hinteres Bild",
        pending: "Ausstehend",
        approved: "Genehmigt",
        resolved: "Gelöst",
        cancelled: "Abgesagt",
        inactive: "Inaktiv",
        active: "Aktivieren",

        images: "Bilder",
        files: 'Dateien',
        audios: 'Audio',

        users_creation_date: "ERSTELLUNGSDATUM",
        users_image: "BILD",
        users_email: "EMAIL",
        users_name: "NAME",
        users_status: "STATUS",
        users_actions: "BEARBEITEN",
        no_data: "Keine Daten",
        file_too_big: "Die Datei ist zu groß oder ungültig! Bitte wählen Sie eine Datei, die kleiner als 64 MB ist.",
        expert_Filter_role: "Rolle",

        chat_search_member: "Suche",
        chat_add_member: "Mitglied hinzufügen",

        module_AddAttachments: "Titel des Anhangs hinzufügen",
        module_AddAttachmentsChooseFiles: "Datei hinzufügen",
        module_AddRefenceTitle: "Titel der Referenz hinzufügen",
        module_AddReferenceLink: "Link der Referenz hinzufügen",
        module_AddChapterTitle: "Kapitelüberschrift",
        module_AddChapterTiming: "Zeitpunkt",
        module_AddChapterInputTitle: "Titel",
        module_AddChapterAction: "Aktion",
        module_DragAnDrop: "Bild per Drag'n'Drop auswählen oder Datei anklicken",
        module_DragAnDropFive_images: "Auswahl von bis zu 5 Bildern per Drag'n'Drop oder Klick auf die Datei",
        module_DragAnDropFive_audio: "Tonspur per Drag’n’Drop hineinziehen oder Datei auswählen",
        module_AddButon: "Hinzufügen",
        module_Select: "Auswählen",

        add_attachment: "Titel des Anhangs hinzufügen",
        choose_file: "Datei hinzufügen",
        add_reference_title: "Titel der Referenz hinzufügen",
        add_reference_link: "Link der Referenz hinzufügen ",
        chapter_title: "Kapitelüberschrift ",
        timing: "Zeitpunkt",
        drag_one_image: "Bild per Drag'n'Drop auswählen oder Datei anklicken",
        drag_many_files: "Ziehen Sie bis zu 5 Dateien per Drag-and-Drop oder klicken Sie, um Dateien auszuwählen",

        live_events_button_preview: 'Vorschau',
        live_events_button_copy: 'Kopieren',
        live_events_button_edit: 'Bearbeiten',
        live_events_button_cancel: 'Abbrechen',
        live_events_button_delete: 'Löschen',
        comments: 'Kommentare',

        community_resolve_abuse_post: "Meldung eines Beitrags",
        community_resolve_abuse_comment: "Meldung eines Kommentars",
        support_sideBarTitle: "Support",
        category_name: "Name",
        support_question: "Frage",
        support_response: "Antwort",

        expert_role_expert: "Experte",
        expert_role_guest: "Gast",

        all_module_add_bouton: "Hinzufügen",
        expert_selected: "Auswählen",

        live_event_internal_name: "Interner Name",
        live_event_rubrik_link: "Rubrik",
        live_event_video_link: "Videolink",
        live_event_zoom_link: "Zoomlink",
        live_event_event_url: "Veranstaltung url",

        live_event_upload_large_image_error: "Beim Speichern des großen Bildes ist ein Fehler aufgetreten, bitte versuchen Sie es noch einmal!",
        live_event_upload_square_image_error: "Beim Speichern des quadratischen Bildes ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal!",

        passed: "Bestanden",
        schließen: "Schließen",
        Profile_overview_abbrechen: "Abbrechen",
        playerDownloadAudioTmp_download: "Download",
        tdm_template_display_text: "Anzeigetext",

        reference_button_label: "Referenzen hinzufügen",

        all_module_placeholder_title: "Titel",
        all_module_placeholder_description: "Beschreibung",
        tdm_audio_download: "AFFIRMATIONEN ZUM ANHÖREN UND DOWNLOADEN",

        daily_set: "Tageskarten-Set",
        daily_card: "Tageskarte",
        daily_cards: "Tageskarten",
        
        checkbox_status: "Status",

        dashboard_sections: "Bereiche",
        dashboard_onbording: "Onboarding Nachricht",
        dashboard_bookclub: "Bücherclub",
        dashboard_areaOfHappiness: "5 Bereiche des Glücks",

        sections_color: "Farbe",
        sections_link: "Link",
        sections_text: "Titel",

        area_of_happiness_relationship: "Erfüllte beziehungen",
        area_of_happiness_enjoyment_of_life: "Lebensfreude",
        area_of_happiness_inner_peace: "Innerer frieden",
        area_of_happiness_autonomy: "Autonomie",
        area_of_happiness_prosperity: "Wohlstand",
        
        tag: "Tag",
        bucherclub: "Bücherclub",

        daily_book_module_config_question: "Frage",
        daily_book_module_config_emojis: "Emojis",
        daily_book_module_config_emoji_set: "Emojis Set",
        daily_book_module_config_question_title: "Titel",

        daily_book_module_config_emojis_set_1: "Emojis Set 1",
        daily_book_module_config_emojis_set_2: "Emojis Set 2",
        daily_book_module_config_emojis_set_3: "Emojis Set 3",
        daily_book_module_config_emojis_set_4: "Emojis Set 4",
        daily_book_module_config_emojis_set_add: "Emoji hinzufügen",
        daily_book_module_config_emojis_set_clear: "klar",

        daily_book_module_config_daily_checkin_title: "Täglicher Check-In",
        daily_book_module_config_daily_checkin_name: "Name des täglichen Check-In",
        daily_book_module_config_daily_checkin_options: "Option für den täglichen Check-In",
        daily_book_module_config_daily_checkin_options_name: "Daily Check-In option name",
        daily_book_module_config_emoji_set_length: "Du musst 6 Emojis auswählen*",
        daily_book_emoji_action: " Emoji hinzufügen",
        daily_book_module_config_daily_checkin_add: "Antwort speichern",
        data_table_row_per_page: "Reihen pro Seite",
        tag_emoji: "Emoji",
        academy_course_title: "Kurstitel",
        yes: "Ja",
        no: "Nein",
        roles: "Rollen",
        admin : 'Admin',
        live_event_manager : 'Live event manager',
        community_manager : 'Community manager',
        tdm_manager : 'TDM manager',
        academy_manager : 'Academy manager',
        magazin_manager : 'Magazin manager',
        mediathek_manager : 'Mediathek manager',
        meditation_manager : 'Meditation manager',
        experts_manager : 'Experts manager',
        free_member: "Freies Mitglied",
        user_status_deleted: "Gelöscht",
        user_status_disabled: "Deaktiviert",
        user_status_active: "Aktiv",
        user_status_pending_email_confirmation: "E-Mail-Bestätigung",
        user_status_free: "Kostenlos",
        user_status_free_member: "Freies Mitglied",
        user_status_paid: "Paid",
        user_membership: "Mitgliedschaft",
        user_status: "Status",
        password_reset_success: 'Passwort erfolgreich zurückgesetzt!',
        password_reset_error: 'Fehler beim Zurücksetzen des Passworts',
        membership_title: "Mitgliedschaft",
        membership_last_update: "Letztes update der digistore-daten",
        membership_user_id: "Digistore-Benutzer-ID",
        membership_purchase_id: "Digistore-Kauf-ID",
        membership_payment_title: "Zahlung",
        membership_payment_plan: "Zahlungsplan",
        membership_last_payment: "Letzte zahlung",
        membership_next_payment: "Nächste Zahlung",
        membership_billing_status: "Status der Abrechnung",
        user_no_group: "Keine Gruppe",
        chat_group: "Chat Gruppe",
        chat_group_reported: "Gemeldete chat gruppe",
        tdm_livestream: "LIVESTREAM AM:",
        tdm_online: "DANN IST VIDEO ONLINE",
        daily_book_module_config_spcial_days: "Besondere Tage",
        daily_book_module_config_spcial_days_description: "Beschreibung",
        daily_book_module_config_spcial_days_day: "Tag",
        daily_book_special_days_sort_created_date: "Erstellt am",
        daily_book_special_days_sort_day: "Tag",
        module_config_section_manually: "Link manuell eintragen?",
        diary_section_label_placeholder: "Titel des Textes",
        diary_section_content_fee_text_placeholder: "Das ist ein Textabschnitt",
        diary_section_highlight_text_placeholder: "Das ist ein markierter Text",
        diary_section_quote_placeholder: "Schreibe hier Dein Zitat",
        diary_section_photo_placeholder: "Titel des Fotos",
        diary_section_question_placeholder: "Schreibe hier Deine Antwort",
        diary_add_section: "Abschnitt hinzufügen",
        diary_add_section_chose_section: "Wähle einen Abschnitt zum Hinzufügen",
        diary_add_section_photo: "Foto",
        diary_add_section_quotes: "Zitat",
        diary_add_section_free_text: "Text",
        diary_add_section_questions: "Fragen",
        diary_add_section_highlight: "Markierter Text",
        diary_add_section_numbered: "Nummerierte Liste",
        diary_add_section_bullet: "Aufzählung",
        diary_add_section_clear_all: "Alles löschen",
        diary_section_label_bullet_list: "Titel der Aufzählung",
        diary_section_label_bullet_list_item: "Schreibe hier Deinen Text",
        diary_section_label_numbered_list: "Titel der nummerierten Liste",
        diary_section_label_numbered_list_item: "Schreibe hier Deinen Text",
        diary_question_modal_title: "Wähle eine Frage aus",
        diary_question_modal_questions: "Fragen",
        diary_question_modal_categories: "Kategorien",
        login_invalid_bad_user_or_password: "Ungültiger Benutzername oder ungültiges Passwort",
        diary_alert_description: "Das Löschen kann nicht rückgängig gemacht werden.",
        live_events_event_duplicate: "Event duplizieren",
        video_duplicate: "Video duplizieren",
        sections_text_color: "Schriftfarbe",
        register_password_needs: "Dein Passwort muss folgende Anforderungen enthalten",
        register_request_ABC: "Großbuchstaben",
        register_request_abc: "Kleinbuchstaben",
        register_request_special_characters: "Sonderzeichen",
        weak: "Schwach",
        okay: "Okay",
        good: "Gut",
        strong: "Stark",
        too_short: "Zu kurz",
        no_match_password: "Passwörter stimmen nicht überein",
        user_already_exists: "Benutzer existiert bereits",
        user_last_activities: "Letzte Aktivität",
        no_activities_yet: "Noch keine Aktivitäten",
        adminEmail_deactivation_message: "Wählen Sie das Datum, an dem das Konto deaktiviert werden soll.",

    }
}
