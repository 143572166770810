import {
  ModuleConfigRequest,
} from '../utils/DataSchemas'

import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class ModuleConfigService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getModuleConfig() {
    return request('GET', `/module-config/`, this.options)
  }

  async updateModuleConfig(data: ModuleConfigRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/module-config/`, requestOptions)
  }
}
