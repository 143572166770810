import MeService from './services/meService'
import ModuleConfigService from './services/moduleConfigService'
import AdminService from './services/adminService'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'
import { Pagination } from './utils/DataSchemas'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private meService!: MeService
  private moduleConfigService!: ModuleConfigService
  private adminService!: AdminService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.meService = new MeService(this.options)
    this.moduleConfigService = new ModuleConfigService(this.options)
    this.adminService = new AdminService(this.options)
  }

  /**
   * Get all template
   */
  getAllTemplates = (
    paginationOption: dataSchemas.PaginationOption = { offset: 0, limit: 20 }
  ): Promise<dataSchemas.Pagination<dataSchemas.Template>> =>
    this.adminService.getAllTemplates(paginationOption)

  /**
   * Get one template
   */
  getTemplate = (
    templateId: number
  ): Promise<dataSchemas.Template> =>
    this.meService.getTemplate(templateId)


  /**
   * Get default template
   */
  getDefaultTemplate = (): Promise<dataSchemas.Template> =>
    this.meService.getDefaultTemplate()

  /**
   * Post template
   * @param body Body of template
   */
  createTemplate = (
    body: dataSchemas.TemplateCreateOrUpdateRequest
  ): Promise<dataSchemas.Template> => this.adminService.createTemplate(body)

  /**
   * Post template
   * @param body Body of template
   */
  createDefaultTemplate = (
    body: dataSchemas.TemplateCreateOrUpdateRequest
  ): Promise<dataSchemas.Template> => this.meService.createDefaultTemplate(body)

  /**
   * Update template
   * @param templateId
   * @param body Body of template
   */
  editTemplate = (
    templateId: number,
    body: dataSchemas.Template
  ): Promise<dataSchemas.Template> =>
    this.adminService.editTemplate(templateId, body)

  /**
   * Update default template
   * @param body Body of template
   */
  editDefaultTemplate = (
    body: dataSchemas.Template
  ): Promise<dataSchemas.Template> =>
    this.meService.editDefaultTemplate(body)


  /**
   * Post template image
   * @param body Body of template image
   */
  createTemplateImage = (
    body: dataSchemas.UploadTemplateImage
  ): Promise<dataSchemas.ImageInterface> => this.adminService.createTemplateImage(body)

  /**
   * Delete template
   * @param templateId template id
   */
  deleteTemplate = async (templateId: number) => {
    return this.adminService
      .deleteTemplate(templateId)
  }

  /**
   * Delete template image
   * @param params Params of template image
   */
  deleteTemplateImage = async (params: dataSchemas.DeleteTemplateUploadImage) => {
    return this.adminService
      .deleteTemplateImage(params)
  }

  /**
   * Get today daily book
   */
  getTodayDailyBook = (): Promise<dataSchemas.DailyBook> =>
    this.meService.getTodayDailyBook()

  /**
   * Get daily book by day
  * @param params day of daily book
   */
  getDailyBookByDay = (params: dataSchemas.GetDailyBookByDay): Promise<dataSchemas.DailyBook> =>
    this.meService.getDailyBookByDay(params)

  /**
   * Get momente
   */
  getMoment = (): Promise<dataSchemas.Momente> =>
    this.meService.getMoment()

  /**
   * Get settings
   */
  getSettings = (): Promise<dataSchemas.Settings> =>
    this.meService.getSettings()

  /**
  * Get momente image
 * @param params momente pagination properties
  */
  getMomentsImages = (params: dataSchemas.MomentePaginationOption): Promise<dataSchemas.Pagination<dataSchemas.ImageInterface>> =>
    this.meService.getMomentsImages(params)

  /**
     * Update daily book
     * @param params daily book day
     * @param body Body of daily book
     */
  editDailyBook = (
    params: string,
    body: dataSchemas.DailyBookCreateOrUpdateRequest
  ): Promise<dataSchemas.DailyBook> =>
    this.meService.editDailyBook(params, body)

  /**
     * Update settings
     * @param body Body of setting request
     */
  editSetting = (
    body: dataSchemas.SettingsRequest
  ): Promise<dataSchemas.Settings> =>
    this.meService.editSetting(body)

  /**
     * Post daily book image
     * @param body Body of daily book image
     */
  createDailyBookImage = (
    body: dataSchemas.UploadDailyBookImage
  ): Promise<dataSchemas.ImageInterface> => this.meService.createDailyBookImage(body)

  /**
   * Delete daily book image
   * @param params Params of daily book image delete
   */
  deleteDailyBookImage = async (params: dataSchemas.DeleteDailyBookUploadImage) => {
    return this.meService
      .deleteDailyBookImage(params)
  }

  /**
  * Get module config
  */
  getModuleConfig = (): Promise<dataSchemas.ModuleConfig> =>
    this.moduleConfigService.getModuleConfig()

  /**
     * Update module config
     * @param body Body module config request
     */
  editModuleConfig = (
    body: dataSchemas.ModuleConfigRequest
  ): Promise<dataSchemas.ModuleConfig> =>
    this.moduleConfigService.editModuleConfig(body)

  /**
  * Get admin module config
  */
  getAdminModuleConfig = (): Promise<dataSchemas.ModuleConfig> =>
    this.adminService.getAdminModuleConfig()

  /**
     * Update admin module config
     * @param body Body module config request
     */
  editAdminModuleConfig = (
    body: dataSchemas.ModuleConfigRequest
  ): Promise<dataSchemas.ModuleConfig> =>
    this.adminService.editAdminModuleConfig(body)

  /**
     * Complete daily checking
     */
  completeDailyChecking = (
    data: dataSchemas.DailyCheckinCompleteRequest,
  ): Promise<string> =>
    this.meService.completeDailyChecking(data)

  /**
     * Delete daily checking
     */
  unsetDailyChecking = (
    data: dataSchemas.DailyCheckinDeleteRequest,
  ): Promise<string> =>
    this.meService.unsetDailyChecking(data)

  /**
     * create daily checking
     */
  createDailyChecking = (
    data: dataSchemas.DailyCheckinCreateUpdateRequest,
  ): Promise<dataSchemas.DailyCheckIn> =>
    this.moduleConfigService.createDailyChecking(data)

  /**
     * update daily checking
     */
  updateDailyChecking = (
    dailyCheckin: number,
    data: dataSchemas.DailyCheckinCreateUpdateRequest,
  ): Promise<dataSchemas.DailyCheckIn> =>
    this.moduleConfigService.editDailyChecking(dailyCheckin, data)

  /**
     * Delete daily checking
     */
  deleteDailyChecking = (
    dailyCheckin: number
  ): Promise<string> =>
    this.moduleConfigService.deleteDailyChecking(dailyCheckin)

  /**
 * Create daily routine
 * @param day day of the daily routine
 * @param data Params of daily routine request
 */
  createDailyRoutine = async (day: string, params: dataSchemas.DailyRoutineRequest): Promise<dataSchemas.DailyRoutine> => {
    return this.meService
      .createDailyRoutine(day, params)
  }

  /**
 * Update daily routine
 * @param params1 informations of the day and the routine id
 * @param params2 Params of daily routine request
 */
  editDailyRoutine = async (params1: dataSchemas.DailyRoutineUpdateQuery, params2: dataSchemas.DailyRoutineRequest): Promise<dataSchemas.DailyRoutine> => {
    return this.meService
      .editDailyRoutine(params1, params2)
  }
  /**
 * Delete daily routine
 * @param params informations of the day and the routine id
 */
  deleteDailyRoutine = async (params: dataSchemas.DailyRoutineUpdateQuery): Promise<string> => {
    return this.meService
      .deleteDailyRoutine(params)
  }


  /**
 * Get all daily quote
 * @param params informations of the daily quote
 */
  getAllDailyQuotes = async (params: dataSchemas.PaginationOption): Promise<Pagination<dataSchemas.DailyQuote>> => {
    return this.adminService
      .getAllDailyQuotes(params)
  }

  /**
 * Get a daily quote
 */
  getDailyQuote = async (id: number): Promise<dataSchemas.DailyQuote> => {
    return this.adminService
      .getDailyQuote(id)
  }

  /**
 * Create a daily quote
 * @param params informations of the daily quote
 */
  createDailyQuote = async (params: dataSchemas.DailyQuoteCreateUpdateRequest): Promise<dataSchemas.DailyQuote> => {
    return this.adminService
      .createDailyQuote(params)
  }

  /**
 * Edit a daily quote
 * @param params informations of the daily quote
 */
  editDailyQuote = async (id: number, params: dataSchemas.DailyQuoteCreateUpdateRequest): Promise<dataSchemas.DailyQuote> => {
    return this.adminService
      .editDailyQuote(id, params)
  }

  /**
 * Delete a daily quote
 */
  deleteDailyQuote = async (id: number): Promise<string> => {
    return this.adminService
      .deleteDailyQuote(id)
  }
}

export * as types from './utils/DataSchemas'
