import {
  VideoCreateRequest,
  AttachmentCreateRequest,
  AttachmentRemoveRequest,
  ReferenceCreateRequest,
  ReferenceRemoveRequest,
  CourseVideoId,
  AddImageToVideo,
  VideoUpdateRequest,
  VideoGetAllRequest
} from '../utils/DataSchemas'

import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'


function getPaginationQuery(getAllQuery: VideoGetAllRequest) {
  let query: string = '';

  if (getAllQuery.offset) {
    query = `?offset=${getAllQuery.offset}`;
  } else {
    query = `?offset=0`;
  }

  if (typeof getAllQuery.limit !== 'undefined') {
    query += `&limit=${getAllQuery.limit}`
  }


  if (typeof getAllQuery.published !== 'undefined') {
    query += `&published=${getAllQuery.published}`
  }

  if (typeof getAllQuery.search_text !== 'undefined') {
    query += `&search_text=${getAllQuery.search_text}`
  }

  if (typeof getAllQuery.sort_by !== 'undefined') {
    query += `&sort_by=${getAllQuery.sort_by}`
  }

  if (typeof getAllQuery.sort_direction !== 'undefined') {
    query += `&sort_direction=${getAllQuery.sort_direction}`
  }


  return query
}

export default class VideoService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllVideo(getAllQuery: VideoGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/admin/videos/${query}`, this.options)
  }
  

  async getVideoById({ course_id, video_id }: CourseVideoId) {

    return request(
      'GET',
      `/courses/${course_id}/videos/${video_id}`,
      this.options
    )
  }

  async getAdminVideoById({ course_id, video_id }: CourseVideoId) {

    return request(
      'GET',
      `/admin/courses/${course_id}/videos/${video_id}`,
      this.options
    )
  }

  async getWacthList({ course_id, video_id }: CourseVideoId) {

    return request(
      'GET',
      `/courses/${course_id}/videos/${video_id}/watchlist`,
      this.options
    )
  }

  async createVideo({ course_id, ...data }: VideoCreateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/courses/${course_id}/videos/`, requestOptions)
  }

 
  async addImageToVideo({ course_id, video_id, image }: AddImageToVideo) {
    const formData = new FormData()
    formData.append('image', image)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request(
      'POST',
      `/admin//courses/${course_id}/videos/${video_id}/images`,
      requestOptions
    )
  }

  async addAttachmentToVideo({
    course_id,
    video_id,
    title,
    attachment
  }: AttachmentCreateRequest) {
    const formData = new FormData()
    formData.append('attachment', attachment)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request(
      'POST',
      `/admin/courses/${course_id}/videos/${video_id}/attachments?title=${title}`,
      requestOptions
    )
  }

  async addReferenceToVideo({
    course_id,
    video_id,
    title,
    external_url
  }: ReferenceCreateRequest) {
    const requestOptions: Options = {
      ...this.options
    }
    return request(
      'POST',
      `/admin/courses/${course_id}/videos/${video_id}/references?title=${title}&external_url=${external_url}`,
      requestOptions
    )
  }

  // PUT



  async updateVideo({ course_id, video_id, ...data }: VideoUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request(
      'PUT',
      `/admin/courses/${course_id}/videos/${video_id}`,
      requestOptions
    )
  }

  
  async deleteVideo({ course_id, video_id }: CourseVideoId) {
    return request(
      'DELETE',
      `/admin/courses/${course_id}/videos/${video_id}`,
      this.options
    )
  }


  async removeImageOnVideo({ course_id, video_id }: CourseVideoId) {
    return request(
      'DELETE',
      `/admin/courses/${course_id}/videos/${video_id}/images`,
      this.options
    )
  }

  async removeAttachmentOnVideo({
    course_id,
    video_id,
    attachment_id
  }: AttachmentRemoveRequest) {
    return request(
      'DELETE',
      `/admin/courses/${course_id}/videos/${video_id}/attachments/${attachment_id}`,
      this.options
    )
  }

  async removeReferenceOnVideo({
    course_id,
    video_id,
    reference_id
  }: ReferenceRemoveRequest) {
    return request(
      'DELETE',
      `/admin/courses/${course_id}/videos/${video_id}/references/${reference_id}`,
      this.options
    )
  }

}
