import {
  WithUserIdCourseId,
  WithUserIdCourseIdVideoId,
  VideoDurationByUserRequest,
  UserContentRequest,
  VideoPaginationOption
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'


function getPaginationQuery(paginationOption: VideoPaginationOption) {
  let query = ``;

  
  if (paginationOption.tags) {
    const tagsFormat: string = paginationOption.tags
      .map((tag: any) => `&tags=${tag}`)
      .join('')
    query += tagsFormat
  }

  
  
  return query
}
export default class UserConsentService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async likeCourse({ course_id }: WithUserIdCourseId) {
    return request(
      'POST',
      `/me/courses/${course_id}/likes`,
      this.options
    )
  }


  async dislikeCourse({ course_id }: WithUserIdCourseId) {
    return request(
      'DELETE',
      `/me/courses/${course_id}/likes`,
      this.options
    )
  }


  async startViewingVideo({
    course_id,
    video_id
  }: WithUserIdCourseIdVideoId) {
    return request(
      'POST',
      `/me/courses/${course_id}/videos/${video_id}/start-viewing`,
      this.options
    )
  }

  async stopViewingCourse(course_id: number) {
    return request(
      'DELETE',
      `/me/courses/${course_id}/un-start`,
      this.options
    )
  }

  async setVideoDurationFoUser({
    course_id,
    video_id,
    duration
  }: VideoDurationByUserRequest) {
    return request(
      'POST',
      `/me/courses/${course_id}/videos/${video_id}/log/${duration}`,
      this.options
    )
  }

  async getUserFavoriteCourses() {
    return request('GET', `/me/courses/favorites`, this.options)
  }

  async createFavoriteCourse({
    course_id,
  }: UserContentRequest) {
    return request(
      'POST',
      `/me/courses/${course_id}/favorites`,
      this.options
    )
  }


  async unFavoriteCourse({course_id} : UserContentRequest) {
    return request(
      'DELETE',
      `/me/courses/${course_id}/un-favorite`,
      this.options
    )
  }

  async favoriteVideo({ course_id, video_id }: WithUserIdCourseIdVideoId) {
    return request(
      'POST',
      `/me/courses/${course_id}/videos/${video_id}/favorites`,
      this.options
    )
  }

  async unfavoriteVideo({
    course_id,
    video_id,
  }: WithUserIdCourseIdVideoId) {
    return request(
      'DELETE',
      `/me/courses/${course_id}/videos/${video_id}/un-favorite`,
      this.options
    )
  }

  async getUserFavoriteVideos(paginationOption: VideoPaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/me/videos/favorites${query}`, this.options)
  }
}
