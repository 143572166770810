import reactStringReplace from 'react-string-replace'
import {DEFAULT_PROFILE_PICTURE_CROP_DATA, TAG_REGEX, TAG_REGEX_VERSION_WITH_FULL_NAME3, EMOJI_REGEX} from './constants'
import * as heic2any from 'heic2any';

const baseImageUrl = process.env.REACT_APP_HH_STATIC;

export const updatedArray = (array, element) => {
  let index = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === element.id) {
      array[i] = element;
      index = i;
      break;
    }
  }
  return array;
};

export const arrayReduce = (array, element) => {
  let tab = array;
  const index = tab.indexOf(element);
  if (index > -1) {
    tab.splice(index, 1);
  }
  return tab;
};

/*
* Sometimes the image url start with / and sometimes not and fail the image display.
* this function check if there is a / at the staring of the url and removes it.
 */
export const formatStaticUrl = (url: string) => {
  if (url && url[0] === "/") return url.slice(1)
  else return url
}

export const truncateString = (str: string, length: number) => {
  return str.length > length ? str.substring(0, length) + "..." : str
}

export function formatTargetUsersWithStringReturn(text: string, tagged_users: any[]): string {

  function replacerForString(match: string, i: number): any {
    const userId_: number = parseInt(match)

    const name = getName(tagged_users, userId_)

    return `@${name}`;
  }

  return reactStringReplace(text, TAG_REGEX, replacerForString).join("")
}

export function formatTargetUsersWithStringReturnVersion3(text: string, tagged_users: any[]): string {

  function replacerForString(match: string, i: number): any {
    const userId_: number = parseInt(match)

    const name = getName(tagged_users, userId_)

    return `{{{${name}}}}`;
  }

  return reactStringReplace(text, TAG_REGEX, replacerForString).join("")
}

export function formatTargetUsersWithStringReturnVersion2(text: string, tagged_users: any[]): [string, Array<number>] {
  let content: string = ""
  let userIdArray: Array<number> = []

  function replacerForString(match: string, i: number): any {
    const id = getId(tagged_users, match)
    if (typeof id !== "undefined") {
      if (!userIdArray.includes(id)) {
        userIdArray.push(id)
      }

      return `@${id}`;
    } else {
      return `@${match}`;
    }
  }

  content = reactStringReplace(text, TAG_REGEX_VERSION_WITH_FULL_NAME3, replacerForString).join(" ")

  return [content, userIdArray]
}

export const getName = (users: any[], userId: number) => {

  let user = users?.find((elt: any, index: number) => {
    return elt?.id === userId
  })
  return user?.full_name
}

const getId = (users: any[], fullname: string) => {

  let user = users?.find((elt: any, index: number) => {
    return elt?.full_name?.trim() === fullname.trim()
  })
  return user?.id
}

export const readLess = (message: string, size: number = 0): string => {

  if (message === '' || size === 0) {
    return message
  } else {
    return `${message.slice(0, size)}${message.length > size ? "..." : ""}`
  }
}

export const isFileTypeAllowed = (file: any, accept: string) => {
  const acceptArray = accept.split(",");
  const fileExtension = file?.name?.split(".")?.pop();
  return acceptArray.includes(`.${fileExtension}`);
}

export const isFilesTypeAllowed = (files: any[], accept: string) => {
  let isAllowed = true;
  files.forEach((file: any) => {
    if (!isFileTypeAllowed(file, accept)) {
      isAllowed = false;
    }
  });
  return isAllowed;
}

export async function convertHEICToJPEG_(blob: any): Promise<Blob> {
  const newBlob: any = await heic2any.default({ blob: blob, toType: "image/jpeg", quality: 1 })

  const blobTemp: any = new Blob([newBlob], { type: 'image/jpeg' })

  if(blob?.name?.includes("heic")){
    blobTemp.name = blob?.name?.replace("heic", "jpeg");
    blobTemp.filename = blob?.name?.replace("heic", "jpeg");
  }else if (blob?.name?.includes("HEIC")) {
    blobTemp.name = blob?.name?.replace("HEIC", "jpeg");
    blobTemp.filename = blob?.name?.replace("HEIC", "jpeg");
  }
  
  return blobTemp
}

export function convertHEICToJPEG(blob: Blob): Promise<Blob> {
  return convertHEICToJPEG_(blob).then((blobTemp: Blob) => {
    return blobTemp
  }).catch((error: any) => {
    if (error?.code === 1 && (error)?.message?.includes("image/jpeg")){
      return blob
    } else {
      throw error
    }
  })
}

export function getExpertName(name: string){
  const updatedName: string[] = name.split(' ')
  const lastName: string = updatedName[updatedName.length - 1]
  const firstName = arrayReduce(updatedName, lastName).join(' ')
  return { firstName, lastName }
}

export function base64Check(_base64: string) {
  const regex:RegExp = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
  return regex.test(_base64);
}

export function blobCheck(_blob): boolean {
  const test_blob = new Blob([_blob], {type : 'text/plain'});
  return _blob instanceof Blob;
}

export function applyEmojiFont(text: string | undefined | null): string {
  if (text) {
      return text.replace(EMOJI_REGEX, '<span class="emoji-font-family">$1</span>');
  } else {
      return ''
  }
};