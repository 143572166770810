import {
  ChannelRequest,
  GetAllWithOptions,
  actionTypes,
  searchTextAllInfo,
  paginationQuery
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class ChannelService {
  options: Options
  constructor(options: Options) {
    this.options = options
  }

  async getAll(options: paginationQuery) {
    let query = ''

    if (typeof options?.limit !== 'undefined') {
      query += `?limit=${options.limit}`
    }

    if (typeof options?.offset !== 'undefined') {
      query += `${query ? '&' : '?'}offset=${options.offset}`
    }


    return request(
      'GET',
      `/${this.options.version}/channels/me${query}`,
      this.options
    )
  }

  async getAllBroadCast(options?: GetAllWithOptions) {
    let query = ''

    if (typeof options?.limit !== 'undefined') {
      query += `?limit=${options.limit}`
    }

    if (typeof options?.offset !== 'undefined') {
      query += `${query ? '&' : '?'}offset=${options.offset}`
    }

    if (typeof options?.search !== 'undefined') {
      query += `${query ? '&' : '?'}search=${options.search}`
    }

    return request(
      'GET',
      `/${this.options.version}/channels/getAllBroadcast${query}`,
      this.options
    )
  }

  async getById(channelId: String) {
    return request(
      'GET',
      `/${this.options.version}/channels/${channelId}`,
      this.options
    )
  }

  async getMainChannel() {
    return request(
      'GET',
      `/${this.options.version}/channels/main`,
      this.options
    )
  }

  async getChannelUnreadMessages(channelId: String) {
    return request(
      'GET',
      `/${this.options.version}/channels/${channelId}/UnreadMessages`,
      this.options
    )
  }

  async createChannel(data: ChannelRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/${this.options.version}/channels`, requestOptions)
  }

  async editChannel(channelId: string, data: ChannelRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request(
      'PUT',
      `/${this.options.version}/channels/${channelId}`,
      requestOptions
    )
  }

  async manageMember(channelId: string, userId: string, action: actionTypes) {
    return request(
      'PUT',
      `/${this.options.version}/channels/${channelId}/manage/${action}/${userId}`,
      this.options
    )
  }

  async editChannelUnread(channelId: string) {
    return request(
      'PUT',
      `/${this.options.version}/channels/${channelId}/unread`,
      this.options
    )
  }

  async editChannelFavorite(channelId: string) {
    return request(
      'PUT',
      `/${this.options.version}/channels/${channelId}/favorite`,
      this.options
    )
  }

  async editChannelArchive(channelId: string) {
    return request(
      'PUT',
      `/${this.options.version}/channels/${channelId}/archive`,
      this.options
    )
  }

  async editChannelNotification(channelId: string) {
    return request(
      'PUT',
      `/${this.options.version}/channels/${channelId}/notification`,
      this.options
    )
  }

  async handleBlockUser(channelId: string, userId: string) {
    return request(
      'PUT',
      `/${this.options.version}/channels/${channelId}/handleBlockUser/${userId}`,
      this.options
    )
  }

  async handleBlockUserFromIds(userId: string) {
    return request(
      'PUT',
      `/${this.options.version}/channels/handleBlockUserFromIds/${userId}`,
      this.options
    )
  }

  async handleAdminUser(channelId: string, userId: string) {
    return request(
      'PUT',
      `/${this.options.version}/channels/${channelId}/handleAdminUser/${userId}`,
      this.options
    )
  }

  async deleteChannel(channelId: String) {
    return request(
      'DELETE',
      `/${this.options.version}/channels/${channelId}`,
      this.options
    )
  }

  async search(channelId: String, searchText: string) {
    return request(
      'GET',
      `/${this.options.version}/channels/${channelId}/search/${searchText}`,
      this.options
    )
  }

  async searchAll({searchText, offset, limit}: searchTextAllInfo) {
    let query = ''

    if (typeof limit !== 'undefined') {
      query += `?limit=${limit}`
    }

    if (typeof offset !== 'undefined') {
      query += `${query ? '&' : '?'}offset=${offset}`
    }


    return request(
      'GET',
      `/${this.options.version}/channels/search/${searchText}${query}`,
      this.options
    )
  }

  async getCommonGroupChannel(otherUserId: string) {
    return request(
      'GET',
      `/${this.options.version}/channels/me/common/${otherUserId}`,
      this.options
    )
  }
}
