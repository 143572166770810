enum ArticleActionTypeEnum {
  List = "LIST_ARTICLE_MAGAZINE",
  Emtpy = "SET_ARTICLE_EMPTY_MAGAZINE",
  Add = "ADD_ARTICLE_MAGAZINE",
  Update = "UPDATE_ARTICLE_MAGAZIN",
  Delete = "DELETE_ARTICLE_MAGAZIN",
  UpdateContent = "UPDATE_ARTICLE_CONTENT_MAGAZIN",
  EmtpyContent = "EMPTY_ARTICLE_CONTENT_MAGAZIN",
  DefaultExperts = "DEFAULT_EXPERTS",
  Loading = "LOADING_ARTICLE",
}

export default ArticleActionTypeEnum