import EmailService from './services/Email'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private emailService!: EmailService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.emailService = new EmailService(this.options)
  }

  /**
   * Get all email templates
   */
  getAllEmail = (
    getAllQuery: dataSchemas.EmailGetAllRequest = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.Pagination> => this.emailService.getAll(getAllQuery)

  /**
   * Get email template by id
   * @param email_template_id email template id
   */
  getEmailById = (
    email_template_id: number
  ): Promise<dataSchemas.Email> => this.emailService.getEmailById(email_template_id)

  /**
   * Create email template
   * @param body Body of email template
   */
  createEmail = (
    body: dataSchemas.CreateEmailRequest
  ): Promise<dataSchemas.Email> => this.emailService.createEmail(body)

  /**
   * Update email template
   * @param body Body of email template
   */
  updateEmail = (
    body: dataSchemas.UpdateEmailRequest
  ): Promise<dataSchemas.Email> => this.emailService.updateEmail(body)

  /**
   * Delete email template
   * @param email_template_id Object with email template id
   */
  deleteEmail = async (
    email_template_id: number,
  ): Promise<string> => this.emailService.deleteEmail(email_template_id)
}

export * as types from './utils/DataSchemas'
