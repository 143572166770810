import {
  Event,
  EventRequest,
  PaginationOption,
  UpdateEventImageRequest,
  DeleteEventImageRequest,
  GetEventByIdRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: PaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

  if (typeof paginationOption.status !== 'undefined') {
    query += `&status=${paginationOption.status}`
  }

  if (typeof paginationOption.search_text !== 'undefined') {
    query += `&search_text=${paginationOption.search_text}`
  }

  if (typeof paginationOption.sort_by !== 'undefined') {
    query += `&sort_by=${paginationOption.sort_by}`
  }

  if (typeof paginationOption.hide_expired !== 'undefined') {
    query += `&hide_expired=${paginationOption.hide_expired}`
  }

  if (typeof paginationOption.sort_direction !== 'undefined') {
    query += `&sort_direction=${paginationOption.sort_direction}`
  }

  return query
}

export default class EventService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllEvents(paginationOption: PaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/events${query}`, this.options)
  }

  async getById(eventId: number) {
    return request('GET', `/events/${eventId}`, this.options)
  }

  async createEvent(data: EventRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/events`, requestOptions)
  }

  async createEventImage({
    upload,
    event_id,
    logo_image
  }: UpdateEventImageRequest) {
    const formData = new FormData()
    let query = ''

    formData.append('upload', upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }

    if (logo_image) {
      query = `?logo_image=${logo_image}`
    }

    return request('POST', `/events/${event_id}/images${query}`, requestOptions)
  }

  async editEvent(eventId: number, data: Event) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/events/${eventId}`, requestOptions)
  }

  async deleteEvent({ event_id }: GetEventByIdRequest) {
    return request(
      'DELETE',
      `/events/${event_id}`,
      this.options
    )
  }

  async deleteEventImage({ event_id, image_id }: DeleteEventImageRequest) {
    return request(
      'DELETE',
      `/events/${event_id}/images/${image_id}`,
      this.options
    )
  }
}
