enum RubricActionTypeEnum {
  List = "LIST_RUBRIC_MAGAZINE",
  Default = "ADD_RUBRIC_DEFAULT_MAGAZINE",
  DefaultEmtpy = "EMPTY_RUBRIC_DEFAULT_MAGAZINE",
  Add = "ADD_RUBRIC_MAGAZINE",
  Update = "UPDATE_RUBRIC_MAGAZIN",
  Delete = "DELETE_RUBRIC_MAGAZIN",
  Loading = "LOADING_RUBRIC",
}

export default RubricActionTypeEnum