import { Media, MediaRequest } from '../utils/DataSchemas';
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class MediaService {
  options: Options;
  constructor(options: Options) {
    this.options = options;
  }

  async getMedia(mediaId: string): Promise<Media> {
    return request(
      'GET',
      `/${this.options.version
      }/medias/${mediaId}`,
      this.options
    );
  }

  async getMedias(channelId: string, type : string = ''): Promise<Array<Media>> {
    return request(
      'GET',
      `/${this.options.version}/channels/${channelId}/medias${type ? `?type=${type}` : ''}`,
      this.options
    );
  }

  async getMediasWithOtherUserId(otherUserId: string, type : string = ''): Promise<Array<Media>> {
    return request(
      'GET',
      `/${this.options.version}/medias/me/with/${otherUserId}${type ? `?type=${type}` : ''}`,
      this.options
    );
  }

  async postMedia({ channelId, createdBy, media }: MediaRequest): Promise<Media> {
    const formData = new FormData()
    const filename: any = (media as File).name

    const newFileName = encodeURI(filename)
    formData.append('media', media, newFileName)


    const requestOptions: Options = {
      ...this.options,
      headers: {
        ...this.options.headers,
        'Content-Type': 'multipart/form-data;charset=utf-8',
      },
      data: formData
    };

    return request('POST', `/${this.options.version}/medias/${channelId}/${createdBy}`, requestOptions);
  }

  async deleteMedia(mediaId: string) {
    return request(
      'DELETE',
      `/${this.options.version}/medias/${mediaId}`,
      this.options
    );
  }
}
