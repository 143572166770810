import TreffpunkteService from './services/Treffpunkte'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {

  public options: Options;
  private treffpunkteService!: TreffpunkteService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.treffpunkteService = new TreffpunkteService(this.options)
  }

  /**
   * Get all treffpunktes
   */
  getAllTreffpunkte = (
    getAllQuery: dataSchemas.TreffpunkteGetAllRequest
  ): Promise<dataSchemas.Pagination> => this.treffpunkteService.getAll(getAllQuery)

  /**
   * Get my treffpunktes
   */
  getMyTreffpunkte = (
    getMyQuery: dataSchemas.TreffpunkteGetMyRequest
  ): Promise<dataSchemas.Pagination> => this.treffpunkteService.getMyTreffpunkte(getMyQuery)

  /**
   * Get treffpunkte by id
   * @param data Object with treffpunkte id and user id
   */
  getTreffpunkteById = (
    data: dataSchemas.TreffpunkteId
  ): Promise<dataSchemas.Treffpunkte> => this.treffpunkteService.getTreffpunktesById(data)

  /**
   * Get treffpunkte default tags
   */
  getDefaultTags = (): Promise<Array<dataSchemas.Tag>> => this.treffpunkteService.getDefaultTags()

  /**
   * Create treffpunkte
   * @param body Body of treffpunkte
   */
  createTreffpunkte = (
    body: dataSchemas.TreffpunkteCreateRequest
  ): Promise<dataSchemas.Treffpunkte> => this.treffpunkteService.createTreffpunkte(body)

  /**
   * Update treffpunkte
   * @param body Body of treffpunkte
   */
  updateTreffpunkte = (
    body: dataSchemas.TreffpunkteUpdateRequest
  ): Promise<dataSchemas.Treffpunkte> => this.treffpunkteService.updateTreffpunkte(body)

  /**
   * Update treffpunkte
   * @param body Body of treffpunkte
   */
  cancelTreffpunkte = (
    data: dataSchemas.TreffpunkteCancel
  ): Promise<dataSchemas.Treffpunkte> => this.treffpunkteService.cancelTreffpunkte(data)

  /**
   * Delete treffpunkte
   * @param treffpunkteId Object with treffpunkte id
   */
  deleteAttendFromTreffpunkte = async (
    data: dataSchemas.TreffpunkteId
  ): Promise<void> => this.treffpunkteService.deleteAttendFromTreffpunkte(data)

  /**
   * Create treffpunkte attend
   * @param body Body of treffpunkte
   */
  addAttendToTreffpunkte = async (data: dataSchemas.TreffpunkteId): Promise<string> =>
    this.treffpunkteService.addAttendToTreffpunkte(data)

  /**
   * Create treffpunkte image
   * @param body Body of treffpunkte
   */
  addImageToTreffpunkte = async (body: dataSchemas.AddImageToTreffpunkte): Promise<dataSchemas.Treffpunkte> =>
    this.treffpunkteService.addImageToTreffpunkte(body)

  /**
   * Remove treffpunkte image
   * @param body Body of treffpunkte
   */
  removeImageOnTreffpunkte = (
    data: dataSchemas.TreffpunkteIdImageId
  ): Promise<void> => this.treffpunkteService.removeImageOnTreffpunkte(data)

  /**
   * like a treffpunkte
   * @param body Body of treffpunkte
   */
  likeTreffpunkte = (body: dataSchemas.TreffpunkteId): Promise<string> =>
    this.treffpunkteService.likeTreffpunkte(body)

  /**
   * Dislike a treffpunkte
   * @param body Body of treffpunkte
   */
  dislikeTreffpunkte = (body: dataSchemas.TreffpunkteId): Promise<string> =>
    this.treffpunkteService.dislikeTreffpunkte(body)

  /**
   * Approve a treffpunkte
   * @param body Body of treffpunkte
   */
  approveTreffpunkte = (body: dataSchemas.TreffpunkteId): Promise<dataSchemas.Treffpunkte> =>
    this.treffpunkteService.approveTreffpunkte(body)

  deleteTreffpunkte = (body: dataSchemas.TreffpunkteId): Promise<string> =>
    this.treffpunkteService.deleteTreffpunkte(body)

  getAllImages = (body: dataSchemas.getAllImagesRequest): Promise<dataSchemas.PaginationWithoutRecommendation<dataSchemas.Image>> =>
    this.treffpunkteService.getAllImages(body)

  getImageWithMeetupId = (body: dataSchemas.getImageWithMeetupId): Promise<dataSchemas.Image> =>
    this.treffpunkteService.getImageWithMeetupId(body)

  likeImage = (body: dataSchemas.getImageWithMeetupId): Promise<string> =>
    this.treffpunkteService.likeImage(body)

  unlikeImage = (body: dataSchemas.getImageWithMeetupId): Promise<string> =>
    this.treffpunkteService.unlikeImage(body)

  updateImage = (request: dataSchemas.getImageWithMeetupId, body: dataSchemas.UpdateImageRequest): Promise<dataSchemas.Image> =>
    this.treffpunkteService.updateImage(request, body)

  /**
   * Get meetup calendar
   * @param meetupId meetup id
   */
    getMeetupCalendar = (meetupId: number) =>
    this.treffpunkteService.getMeetupCalendar(meetupId)
}

export * as types from './utils/DataSchemas'
