import {
  CommunityActionTypeEnum,
} from "../../helpers/enums/actionTypeEnums"
import { updatedArray } from "../../../../../../utility/helpers/functions";


const initialState = {
  AbusePosts: null,
  AbusePostsTotal: 0,
  AbuseComments: null,
  AbuseCommentsTotal: 0,
  CommentLoading: false,
  PostLoading: false,
  AbuseUsers: null,
  AbuseUsersTotal: 0,
  UsersLoading: false,
};



const CommunityReducer = (state = initialState, action) => {
  switch (action.type) {

    case CommunityActionTypeEnum.ListPost:
      return {
        ...state,
        AbusePosts: action.result,
        AbusePostsTotal: action.total,
        PostLoading: action.loading
      };

    case CommunityActionTypeEnum.ListUser:
      return {
        ...state,
        AbuseUsers: action.result,
        AbuseUsersTotal: action.total,
        UsersLoading: action.loading
      };

    case CommunityActionTypeEnum.FilterPost:
      return {
        ...state,
        AbusePosts: action.result
      };

    case CommunityActionTypeEnum.FilterUser:
      return {
        ...state,
        AbuseUsers: action.result
      };

    case CommunityActionTypeEnum.SetCommentLoading:
      return {
        ...state,
        CommentLoading: action.result
      };

    case CommunityActionTypeEnum.SetPostLoading:
      return {
        ...state,
        PostLoading: action.result
      };

    case CommunityActionTypeEnum.SetUserLoading:
      return {
        ...state,
        UserLoading: action.result
      };

    case CommunityActionTypeEnum.UpdatePost:
      let AbusePostsState = !state.AbusePosts ? [] : state.AbusePosts;
      let newArrayAbusePosts = updatedArray(AbusePostsState, action.result);
      return {
        ...state,
        AbusePosts: newArrayAbusePosts,
      };

    case CommunityActionTypeEnum.UpdateUser:
      let AbuseUsersState = !state.AbuseUsers ? [] : state.AbuseUsers;
      let newArrayAbuseUsers = updatedArray(AbuseUsersState, action.result);
      return {
        ...state,
        AbuseUsers: newArrayAbuseUsers,
      };

    case CommunityActionTypeEnum.ListComment:
      return {
        ...state,
        AbuseComments: action.result,
        AbuseCommentsTotal: action.total,
        CommentLoading: action.loading,
      };

    case CommunityActionTypeEnum.FilterComment:
      return {
        ...state,
        AbuseComments: action.result
      };

    case CommunityActionTypeEnum.UpdateComment:
      let AbuseCommentsState = !state.AbuseComments ? [] : state.AbuseComments;
      let newArrayAbuseComments = updatedArray(AbuseCommentsState, action.result);
      return {
        ...state,
        AbuseComments: newArrayAbuseComments,
      };

    default:
      return state;
  }
};

export default CommunityReducer;
