import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class MemberService {
    options: Options;
    constructor(options: Options) {
        this.options = options;
    }

    async getDistinctMembers(): Promise<Array<string>> {
        return request(
            'GET',
            `/${this.options.version
            }/members/distinct`,
            this.options
        );
    }

}
