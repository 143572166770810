import {
  CommunityPostImage,
  PaginationPostOption,
  CommunityEditPostRequest,
  BaseCommunityPostRequest,
  CommunityPostParams, PaginationReportAbusesPostOption, CommunityReportAbusesParams, CommunityPostReportRequest, PostReaction, LikeGetAllRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: PaginationPostOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

  if (paginationOption.search_text !== undefined) {
    query += `&search_text=${paginationOption.search_text}`
  }

  if (paginationOption.sort_by !== undefined) {
    query += `&sort_by=${paginationOption.sort_by}`
  }

  if (paginationOption.sort_direction !== undefined) {
    query += `&sort_direction=${paginationOption.sort_direction}`
  }

  if (paginationOption.my_post_only !== undefined) {
    query += `&my_post_only=${paginationOption.my_post_only}`
  }

  if (paginationOption.group_id !== undefined) {
    query += `&group_id=${paginationOption.group_id}`
  }

  if (paginationOption.status !== undefined) {
    query += `&status=${paginationOption.status}`
  }

  if (paginationOption.profile_id !== undefined) {
    query += `&profile_id=${paginationOption.profile_id}`
  }

  return query
}

function getReportAbusesPaginationQuery(paginationOption: PaginationReportAbusesPostOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

  if (paginationOption.filter_by_status !== undefined) {
    query += `&filter_by_status=${paginationOption.filter_by_status}`
  }

  return query
}

export default class PostService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  // POST
  async createPost(

    { content, title, group_id , tagged_user_ids}: BaseCommunityPostRequest
  ) {
    const data = { content:content, title:title, group_id:group_id, tagged_user_ids }

    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/posts`, requestOptions)
  }

  // GET ALL
  async getAllPost(getAllQuery: PaginationPostOption) {
    const query = getPaginationQuery(getAllQuery)
    return request('GET', `/posts${query}`, this.options)
  }

  // GET ALL
  async getAllReportAbuses(getAllQuery: PaginationReportAbusesPostOption) {
    const query = getReportAbusesPaginationQuery(getAllQuery)
    return request('GET', `/admin/posts/report-abuses${query}`, this.options)
  }

  // GET BY ID
  async getByIdPost({ post_id }: CommunityPostParams) {
    return request('GET', `/posts/${post_id}`, this.options)
  }

  // GET BY ID
  async getByIdReportAbuses({ abuse_id }: CommunityReportAbusesParams) {
    return request('GET', `/admin/posts/report-abuses/${abuse_id}`, this.options)
  }

  // UPDATE A Post
  async editPost(
    post_id: number,
    data: CommunityEditPostRequest
  ) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/posts/${post_id}`, requestOptions)
  }

  // UPDATE A Report Abuses
  async editReportAbuses(
    abuse_id: number,
    body: CommunityPostReportRequest
  ) {

    const requestOptions: Options = {
      ...this.options,
      data: body
    }
    return request('PUT', `/admin/posts/report-abuses/${abuse_id}/resolve/`, requestOptions)
  }

  // DELETE A POST

  async deletePost(post_id: number) {
    return request('DELETE', `/posts/${post_id}`, this.options)
  }

  // POST A POST IMAGE
  async addPostImage(
    post_id: number,
    { uploads }: CommunityPostImage
  ) {
    const formData = new FormData()

    uploads.forEach((upload) => {
      formData.append('uploads', upload)
    })

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
  return request(
      'POST',
      `/posts/${post_id}/images`,
      requestOptions
    )
  }

  // DELETE A POST IMAGE

  async deletePostImage(post_id: number, image_id: number) {
    return request(
      'DELETE',
      `/posts/${post_id}/images/${image_id}`,
      this.options
    )
  }

  async postReaction({ post_id, reaction }: PostReaction) {
    return request('POST', `/posts/${post_id}/reacts/${reaction}`, this.options)
  }

  async deleteReaction({ post_id }: CommunityPostParams) {
    return request('DELETE', `/posts/${post_id}/un-react`, this.options)
  }

  async getReactionsByPost(getAllQuery: LikeGetAllRequest) {
    let query = ''

    if (typeof getAllQuery.offset !== 'undefined') {
      query += `&offset=${getAllQuery.offset}`
    }

    if (typeof getAllQuery.limit !== 'undefined') {
      query += `&limit=${getAllQuery.limit}`
    }
    return request('GET', `/posts/${getAllQuery?.post_id}/reactions?${query}`, this.options)
  }
}
