import DashboardService from './services/dashboard'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'
const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private dashboardService!: DashboardService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.dashboardService = new DashboardService(this.options)
  }

  /**
   * Get all module config information
   */
  getAllModuleConfig = (): Promise<dataSchemas.Dashboard> => this.dashboardService.getAllModuleConfig()


  /**
   * Update module config information
   * @param data Body of module config
   */
  updateModuleConfig = (data: dataSchemas.Dashboard): Promise<dataSchemas.Dashboard> => this.dashboardService.updateModuleConfig(data)

  /**
    * Create module config image
    * @param data image blob
    */
  createModuleConfigImage = async (
    data: dataSchemas.CreateModuleConfigImageRequest,
  ): Promise<dataSchemas.ImageInterface> => this.dashboardService.createModuleConfigImage(data)

}

export * as types from './utils/DataSchemas'
