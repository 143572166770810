import ExpertService from './services/Expert'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private expertService!: ExpertService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.expertService = new ExpertService(this.options)
  }

  /**
   * Get all experts
   */
  getAllExpert = (
    getAllQuery: dataSchemas.ExpertGetAllRequest = {
      active_only: true,
      offset: 0,
      limit: 20,
      filter_by_role: dataSchemas.RoleName.expert,
      sort_direction: -1
    }
  ): Promise<dataSchemas.Pagination<dataSchemas.Expert>> => this.expertService.getAll({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })

  /**
   * Get all public experts
   */
  getAllPublicExperts = (
    getAllQuery: dataSchemas.ExpertGetAllRequest = {
      active_only: true,
      offset: 0,
      limit: 20,
      filter_by_role: dataSchemas.RoleName.expert,
      sort_direction: -1
    }
  ): Promise<dataSchemas.Pagination<dataSchemas.Expert>> => this.expertService.getAllPublicExperts({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })

  /**
   * Get expert by id
   * @param expert_id expert id
   */
  getExpertById = (
    expert_id: number
  ): Promise<dataSchemas.Expert> => this.expertService.getExpertsById(expert_id)

  /**
   * Create expert
   * @param body Body of expert
   */
  createExpert = (
    body: dataSchemas.ExpertCreateRequest
  ): Promise<dataSchemas.Expert> => this.expertService.createExpert(body)

  /**
   * Update expert
   * @param body Body of expert
   */
  updateExpert = (
    body: dataSchemas.ExpertUpdateRequest
  ): Promise<dataSchemas.Expert> => this.expertService.updateExpert(body)

  /**
   * Delete expert
   * @param expertId Object with expert id
   */
  deleteExpert = async (
    expert_id: number,
  ): Promise<string> => this.expertService.deleteExpert(expert_id)

  /**
   * Create expert image
   * @param body Body of expert
   */
  addImageToExpert = (body: dataSchemas.AddImageToExpert): Promise<dataSchemas.ImageInterface> =>
    this.expertService.addImageToExpert(body)

  /**
   * Remove expert image
   * @param expert_id expert id
   * @param image_id image id
   */
  removeImageOnExpert = (
    expert_id: number,
    image_id: number
  ): Promise<void> => this.expertService.removeImageOnExpert(expert_id, image_id)

  /**
   * Favorite an expert
   * @param expertId Expert id
   */
  favoriteExpert = (expertId: number): Promise<any> =>
    this.expertService.favoriteExpert(expertId)

  /**
   * Unfavorite an expert
   * @param expertId Expert id
   */
  unfavoriteExpert = (expertId: number): Promise<any> =>
    this.expertService.unfavoriteExpert(expertId)

  /**
   * Create expert attachement
   * @param body Body of expert
   */
  addAttachmentToExpert = (
    body: dataSchemas.AttachmentCreateRequest
  ): Promise<dataSchemas.AttachmentOrReference> => this.expertService.addAttachmentToExpert(body)

  /**
   * Create expert reference
   * @param body Body of expert
   */
  addReferenceToExpert = (
    body: dataSchemas.ReferenceCreateRequest
  ): Promise<dataSchemas.AttachmentOrReference> => this.expertService.addReferenceToExpert(body)

  /**
   * Remove expert attachement
   * @param body Body of expert
   */
  removeAttachmentOnExpert = (
    body: dataSchemas.AttachmentRemoveRequest
  ): Promise<string> => this.expertService.removeAttachmentOnExpert(body)

  /**
   * Remove expert reference
   * @param body Body of expert
   */
  removeReferenceOnExpert = (
    body: dataSchemas.ReferenceRemoveRequest
  ): Promise<any> => this.expertService.removeReferenceOnExpert(body)

  /**
 * Get all experts i follow
 */
  getAllFollowedExpert = (
    getAllQuery: dataSchemas.ExpertGetAllRequest = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.Pagination<dataSchemas.Expert>> => this.expertService.getAllFollowedExpert({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })

  /**
 * Get all experts a profile follow
 */
  getAllFollowedExpertByProfile = (
    getAllQuery: dataSchemas.ProfileExpertGetAllRequest = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.Pagination<dataSchemas.Expert>> => this.expertService.getAllFollowedExpertByProfile(getAllQuery)

}

export * as types from './utils/DataSchemas'
