import { EventsActionTypeEnum, } from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";

const initialState = {
  Events: [],
  Event: {},
  EventsTotal: null,
  DefaultLiveEvents: [],
  BlockModeLiveEventsLoading: false,
  IsLoading: false,
};

const getObjectsArrayReturnIdsArray = (array) => {
  let tab = [];
  for (let i = 0; i < array.length; i++) {
    tab.push(array[i].id);
  }
  return tab;
};


const LiveEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EventsActionTypeEnum.List:
      return {
        ...state,
        Events: action.events,
        EventsTotal: action.count,
        IsLoading: action.loading
      };

    case EventsActionTypeEnum.Filter:
      return {
        ...state,
        Events: action.events,
      };

    case EventsActionTypeEnum.Add:
      let newEventState = !state.Events ? [] : state.Events;
      newEventState.unshift(action.event);
      return {
        ...state,
        Events: newEventState,
        EventsTotal: newEventState.length
      };
    case EventsActionTypeEnum.SetEmpty:
      return {
        ...state,
        Events: [],
      };
    case EventsActionTypeEnum.BlockModeLoading:
      return {
        ...state,
        BlockModeRubricsLoading: action.data,
      };
    case EventsActionTypeEnum.Update:
      let eventState = !state.Events ? [] : state.Events;
      let newArrayEvent = updatedArray(eventState, action.event);
      return {
        ...state,
        Events: newArrayEvent,
      };
    case EventsActionTypeEnum.Delete:
      let oldEvents = !state.Events ? [] : state.Events;
      let updEventArray = arrayReduce(oldEvents, action.event);
      return {
        ...state,
        Events: updEventArray,
        EventsTotal: updEventArray.length
      };
    case EventsActionTypeEnum.Loading:
      return {
        ...state,
        IsLoading: action.data,
      };

    default:
      return state;
  }
};

export default LiveEventsReducer;
