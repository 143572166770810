import {
    UserGetAllRequest,
    CommunityMapRequest,
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: CommunityMapRequest) {
    let query = `?online_only=${paginationOption.online_only}&offset=${paginationOption.offset}&limit=${paginationOption.limit}`
  
    if (typeof paginationOption.longitude !== "undefined") {
      query += `&longitude=${paginationOption.longitude}`
    }

    if (typeof paginationOption.latitude !== "undefined") {
      query += `&latitude=${paginationOption.latitude}`
    }

    if (typeof paginationOption.radius !== "undefined") {
        query += `&radius=${paginationOption.radius}`
      }
   
    return query
}

function getBroadCastPaginationQuery (paginationOption: CommunityMapRequest) {
    let query = ''
    if (typeof paginationOption.offset !== 'undefined') {
    query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (typeof paginationOption.limit !== 'undefined') {
    query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }
    return query
}
  
export default class DashboardService {
    options: Options

    constructor(options: Options) {
        this.options = options
    }

    async getAllMembersBirthday(paginationOption: UserGetAllRequest) {
        let query = ''

        if (typeof paginationOption.offset !== 'undefined') {
            query
                ? (query += `&offset=${paginationOption.offset}`)
                : (query += `?offset=${paginationOption.offset}`)
        }

        if (typeof paginationOption.limit !== 'undefined') {
            query
                ? (query += `&limit=${paginationOption.limit}`)
                : (query += `?limit=${paginationOption.limit}`)
        }

        return request('GET', `/dashboard/today-members-birthday/${query}`, this.options)
    }
    
    async getCommunityMap(paginationOption: CommunityMapRequest) {
        const query = getPaginationQuery(paginationOption)
        return request('GET', `/community/map/${query}`, this.options)
    }

    async getBroadcast(paginationOption: CommunityMapRequest) {
        const query = getBroadCastPaginationQuery(paginationOption)
        return request('GET', `/broadcast/${query}`, this.options)
    }
}
