import {
  DailyCheckinCreateUpdateRequest,
  ModuleConfigRequest,
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import { Options } from '../../../services.utils/request-types'


export default class DailyBookModuleConfigService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getModuleConfig() {
    return request('GET', `/module-config`, this.options)
  }

  async editModuleConfig(data: ModuleConfigRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('PUT', `/module-config/`, requestOptions)
  }

  async createDailyChecking(data: DailyCheckinCreateUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request(
      'POST',
      `/module-config/daily-checkins`,
      requestOptions
    )
  }

  async editDailyChecking(dailyChekinId: number, data: DailyCheckinCreateUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request(
      'PUT',
      `/module-config/daily-checkins/${dailyChekinId}`,
      requestOptions
    )
  }

  async deleteDailyChecking(id: number) {
    return request(
      'DELETE',
      `/module-config/daily-checkins/${id}`,
      this.options
    )
  }
}
