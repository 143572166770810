import {
  Message,
  MessageRequest,
  GetAllMessageWithOptions,
  EventType,
  UpdateMessageRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class MessageService {
  options: Options
  constructor(options: Options) {
    this.options = options
  }

  async getMessages(
    channelId: String,
    limit?: number,
    beforeId?: string,
    aroundId?: string,
    afterId?: string,
    equal?: boolean,
    sort?: string
  ): Promise<Array<Message>> {
    let query = ''

    if (typeof limit !== 'undefined') {
      query += `?limit=${limit}`
    }

    if (typeof beforeId !== 'undefined') {
      query += `${query ? '&' : '?'}beforeId=${beforeId}`
    }

    if (typeof aroundId !== 'undefined') {
      query += `${query ? '&' : '?'}aroundId=${aroundId}`
    }

    if (typeof afterId !== 'undefined') {
      query += `${query ? '&' : '?'}afterId=${afterId}`
    }

    if (typeof equal !== 'undefined') {
      query += `${query ? '&' : '?'}equal=${equal}`
    }

    if (typeof sort !== 'undefined') {
      query += `${query ? '&' : '?'}sort=${sort}`
    }

    return request(
      'GET',
      `/${this.options.version}/channels/${channelId}/messages${query}`,
      this.options
    )
  }

  async getMessagesWithOptions({
    channelId,
    ...options
  }: GetAllMessageWithOptions) {
    let query = ''

    if (typeof options?.limit !== 'undefined') {
      query += `?limit=${options.limit}`
    }

    if (typeof options?.offset !== 'undefined') {
      query += `${query ? '&' : '?'}offset=${options.offset}`
    }

    if (typeof options?.search !== 'undefined') {
      query += `${query ? '&' : '?'}search=${options.search}`
    }

    return request(
      'GET',
      `/${this.options.version}/channels/${channelId}/messagesWithOptions${query}`,
      this.options
    )
  }

  async getCountUnreadMessage() {
    return request(
      'GET',
      `/${this.options.version}/messages/count/${EventType.MESSAGE_UNREAD}`,
      this.options
    )
  }

  async setMessageAsReceived(messageId: String) {
    return request(
      'GET',
      `/${this.options.version}/messages/setAsReceived/${messageId}`,
      this.options
    )
  }

  async updateMessagesToAddUserWhoReadAfterTurnOnReadIndicator() {
    return request(
      'GET',
      `/${this.options.version}/messages/addUserWhoReadAfterTurnOnReadIndicator`,
      this.options
    )
  }

  async postMessage(data: MessageRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request(
      'POST',
      `/${this.options.version}/messages`,
      requestOptions
    )
  }

  async editMessage(id: string ,data: UpdateMessageRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request(
      'PUT',
      `/${this.options.version}/messages/${id}`,
      requestOptions
    )
  }

  async deleteMessage(messageId: String, byAdmin: boolean) {
    return request(
      'DELETE',
      `/${this.options.version}/messages/${messageId}?byAdmin=${byAdmin}`,
      this.options
    )
  }

  async deleteMessageHideMode(messageId: String) {
    return request(
      'DELETE',
      `/${this.options.version}/messages/${messageId}/hideMode`,
      this.options
    )
  }
}
