export function getSectionName(type: number) {
    switch (type) {
        case 1:
            return 'photo'
        case 2:
            return 'value_as_text'
        case 3:
            return 'high_lighted_text'
        case 4:
            return 'bullet_list'
        case 5:
            return 'quotes'
        case 6:
            return 'questions'
        case 7:
            return 'value_as_list'

        default:
            return 'free_text'
    }

}