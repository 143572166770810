

export enum BannersActionTypeEnum {
    List = "LIST_LIST_BANNERS",
    BlockModeLoading = "SET_BLOCK_MODE_LOADING_BANNERS",
    Add = "ADD_BANNERS",
    Update = "UPDATE_BANNERS",
    Delete = "DELETE_BANNERS",
    Loading = "LOADING_BANNERS"
}

export enum PORTAL_LINKS_ENUM {
    VALUE = 'value',
    LABEL = 'label',
    ALL = 'all'
}
