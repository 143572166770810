
import {
  TreffpunkteActionTypeEnum,
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";

const initialState = {
  Treffpunkte: [],
  TreffpunkteTotal: null,
  DefaultTreffpunkte: null,
  BlockModeTreffpunkteLoading: false,
};


const TreffpunkteReducer = (state = initialState, action) => {
  switch (action.type) {
    case TreffpunkteActionTypeEnum.ListTreffpunkte:
      return {
        ...state,
        Treffpunkte: action.dailynews,
        TreffpunkteTotal: action.total,
        BlockModeTreffpunkteLoading: action.loading
      };
   
    case TreffpunkteActionTypeEnum.Loading:
    return {
      ...state,
      BlockModeTreffpunkteLoading: action.data,
    };
    case TreffpunkteActionTypeEnum.UpdateTreffpunkte:
      let dailynewsState = !state.Treffpunkte ? [] : state.Treffpunkte;
      let newArrayTreffpunkte = updatedArray(dailynewsState, action.dailynews);
      return {
        ...state,
        Treffpunkte: newArrayTreffpunkte,
      };
    case TreffpunkteActionTypeEnum.DeleteTreffpunkte:
      let DAILYNEWS = !state.Treffpunkte ? [] : state.Treffpunkte;
      let updTreffpunkteArray = arrayReduce(DAILYNEWS, action.dailynews);
      return {
        ...state,
        Treffpunkte: updTreffpunkteArray,
        TreffpunkteTotal: updTreffpunkteArray.length
      };
    default:
      return state;
  }
};

export default TreffpunkteReducer;
