import {
  AbusePaginationOption,
  UserReportAbusesParams,
  UserReportRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getReportAbusesPaginationQuery(paginationOption: AbusePaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

  if (paginationOption.sort_by !== undefined) {
    query += `&sort_by=${paginationOption.sort_by}`
  }

  if (paginationOption.filter_by_status !== undefined) {
    query += `&filter_by_status=${paginationOption.filter_by_status}`
  }

  return query
}

export default class AbuseService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  // GET ALL
  async getAllReportAbuses(getAllQuery: AbusePaginationOption) {
    const query = getReportAbusesPaginationQuery(getAllQuery)
    return request('GET', `/admin/profiles/report-abuses${query}`, this.options)
  }

  // GET BY ID
  async getByIdReportAbuses({ abuse_id }: UserReportAbusesParams) {
    return request('GET', `/admin/profiles/report-abuses/${abuse_id}`, this.options)
  }

  // UPDATE A Report Abuses
  async editReportAbuses(
    abuse_id: number,
    body: UserReportRequest
  ) {
    const requestOptions: Options = {
      ...this.options,
      data: body
    }
    return request('PUT', `/admin/profiles/report-abuses/${abuse_id}/resolve/`, requestOptions)
  }

}
