import {
  UsersActionTypeEnum,
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";

const initialState = {
  Users: [],
  UsersTotal: null,
  DefaultUsers: null,
  DailyMessages: [],
  DailyMessagesTotal: null,
  DefaultDailyMessages: null,
  BlockModeUsersLoading: false,
  DefaultCategory: null,
  DefaultMessage: null,
};


const UsersReducer = (state = initialState, action) => {
  
  switch (action.type) {

    case UsersActionTypeEnum.List:
     
      
      return {
        ...state,
        Users: action.users,
        UsersTotal: action.total,
        BlockModeUsersLoading: action.loading
      };
    case UsersActionTypeEnum.Loading:
      return {
        ...state,
        BlockModeUsersLoading: action.data,
      };
    case UsersActionTypeEnum.Filter:
      return {
        ...state,
        Users: action.users,
      };
    
    case UsersActionTypeEnum.ListInSelect:
      return {
        ...state,
        DefaultUsers: action.users,
      };
    case UsersActionTypeEnum.AddDefaultUsers:
      return {
        ...state,
        DefaultUsers: action.default,
      };
    case UsersActionTypeEnum.Add:
      let newUsersState = !state.Users ? [] : state.Users;
      newUsersState.unshift(action.users);
      return {
        ...state,
        Users: newUsersState,
        UsersTotal: newUsersState.count
      };
    case UsersActionTypeEnum.Update:
      let usersState = !state.Users ? [] : state.Users;
      let oldUser = usersState?.filter(elt => elt?.id == action.user?.id)
      let userUpdated = { ...oldUser[0], full_name: action.user?.basic_info?.full_name, internal_status: action.user?.status, free_user: action.user?.membership?.free_user }
      
      let newArrayUsers = updatedArray(usersState, userUpdated);
      return {
        ...state,
        Users: newArrayUsers,
      };
    case UsersActionTypeEnum.Delete:
      let USERS = !state.Users ? [] : state.Users;
      let updUsersArray = arrayReduce(USERS, action.users);
      return {
        ...state,
        Users: updUsersArray,
        UsersTotal: updUsersArray.count
      };
    case UsersActionTypeEnum.AddDefaultCategory:
      return {
        ...state,
        DefaultCategory: action.default,
      };
    case UsersActionTypeEnum.AddDefaultDailyMessages:
      return {
        ...state,
        DefaultMessage: action.default,
      };
    
    default:
      return state;
  }
};

export default UsersReducer;
