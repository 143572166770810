import DailySetMeService from './services/meService'
import DailySetAdminService from './services/adminService'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private dailySetMeService!: DailySetMeService
  private dailySetAdminService!: DailySetAdminService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.dailySetMeService = new DailySetMeService(this.options)
    this.dailySetAdminService = new DailySetAdminService(this.options)
  }

  /**
   * Get today set
   */
  getTodaySet = (): Promise<dataSchemas.TodaySet> =>
    this.dailySetMeService.getTodaySet()

  /**
   * Get public today set
   */
  getPublicTodaySet = (): Promise<dataSchemas.TodaySet> =>
    this.dailySetMeService.getPublicTodaySet()

  /**
   * Get today set
   */
  getTodaySetByDate = (data: dataSchemas.TodaySetByDateInterface): Promise<dataSchemas.TodaySet> =>
    this.dailySetMeService.getTodaySetByDate(data)

  /**
 * pick a daily card
 */
  pickDailyCard = (dailyCardId: number): Promise<any> =>
    this.dailySetMeService.pickDailyCard(dailyCardId)


  /**
   * get all daily sets
   */
  getAllDailySets = (paginationOption: dataSchemas.PaginationOption = { offset: 1, limit: 100 }): Promise<dataSchemas.Pagination<dataSchemas.DailySet>> =>
    this.dailySetAdminService.getAllDailySets(paginationOption)

  /**
* get daily set by id
*/
  getDailySetById = (dailySetId: number): Promise<dataSchemas.DailySet> =>
    this.dailySetAdminService.getDailySetById(dailySetId)

    /**
* get daily card by id
*/
getDailyCardById = (dailySetId: number, dailyCardId: number): Promise<dataSchemas.DailyCard> =>
  this.dailySetAdminService.getDailyCardById(dailySetId, dailyCardId)
  /**
   * create a daily set
   */
  createDailySet = (body: dataSchemas.DailySetRequest): Promise<dataSchemas.DailySet> =>
    this.dailySetAdminService.createDailySet(body)

  /**
 * create a daily card
 */
  createDailyCard = (daily_set_id: number, body: dataSchemas.DailyCardRequest): Promise<dataSchemas.DailyCard> =>
    this.dailySetAdminService.createDailyCard(daily_set_id, body)

  /**
* edit a daily set
*/
  updateDailySet = (daily_set_id: number, body: dataSchemas.DailySetRequest): Promise<dataSchemas.DailySet> =>
    this.dailySetAdminService.editDailySet(daily_set_id, body)

  /**
 * edit a daily card
 */
  updateDailyCard = (daily_set_id: number, daily_card_id: number, body: dataSchemas.DailyCardRequest): Promise<dataSchemas.DailyCard> =>
    this.dailySetAdminService.editDailyCard(daily_set_id, daily_card_id, body)

  /**
* edit a daily set
*/
  deleteDailySet = (daily_set_id: number): Promise<void> =>
    this.dailySetAdminService.deleteDailySet(daily_set_id)

  /**
 * delete a daily card
 */
  deleteDailyCard = (daily_set_id: number, daily_card_id: number): Promise<void> =>
    this.dailySetAdminService.deleteDailyCard(daily_set_id, daily_card_id)

  /**
* edit a daily set
*/
  createDailyCardImage = (daily_set_id: number, daily_card_id: number, body: dataSchemas.DailyCardImageRequest): Promise<void> =>
    this.dailySetAdminService.createDailyCardImage(daily_set_id, daily_card_id, body)

  /**
 * delete a daily card
 */
  createDailySetImage = (daily_set_id: number, body: dataSchemas.DailyCardImageRequest): Promise<void> =>
    this.dailySetAdminService.createDailySetImage(daily_set_id, body)

  /**
* edit a daily set
*/
  deleteDailySetImage = (daily_set_id: number): Promise<string> =>
    this.dailySetAdminService.deleteDailySetImage(daily_set_id)

  /**
 * delete a daily card
 */
  deleteDailyCardImage = (daily_set_id: number, daily_card_id: number): Promise<string> =>
    this.dailySetAdminService.deleteDailyCardImage(daily_set_id, daily_card_id)

}

export * as types from './utils/DataSchemas'
