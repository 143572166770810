import {
  PaginationOptionTag
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(options: PaginationOptionTag) {
  let query = ``

  if (typeof options?.limit !== 'undefined') {
    query += `?limit=${options.limit}`
  }

  if (typeof options?.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${options.offset}`
  }

  if (typeof options.tag_ids !== 'undefined') {
    const tagIds: string = options.tag_ids
      .map((p: any, index: number) =>
        index === 0 && !query ? `?tag_ids=${p}` : `&tag_ids=${p}`
      )
      .join('')
    query += tagIds
  }

  return query
}

export default class TagService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllTags(paginationOption: PaginationOptionTag) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/tags/${query}`, this.options, false)
  }

  async getDefaultTagSet(tag_set_name: string) {
    return request('GET', `/default/tag-sets/${tag_set_name}`, this.options)
  }
}
