import MeService from './services/meService'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private meService!: MeService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.meService = new MeService(this.options)
  }

  /**
   * Get all template
   */
  getFeeds = (
    paginationOption: dataSchemas.PaginationOption = { offset: 0, limit: 20 }
  ): Promise<dataSchemas.Pagination<dataSchemas.Feed>> =>
    this.meService.getFeeds(paginationOption)


  editFeed = (
    feed_ids: Array<number>
  ): Promise<dataSchemas.Feed> =>
    this.meService.editFeeds(feed_ids)


}

export * as types from './utils/DataSchemas'
