import {
  DailyBookCreateOrUpdateRequest,
  UploadDailyBookImage,
  DeleteDailyBookUploadImage,
  GetDailyBookByDay,
  MomentePaginationOption,
  SettingsRequest,
  DailyCheckinCompleteRequest,
  DailyCheckinDeleteRequest,
  DailyRoutineRequest,
  DailyRoutineUpdateQuery,
  TemplateCreateOrUpdateRequest,
  Template,
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import { Options } from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: MomentePaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

  if (typeof paginationOption.month_year !== 'undefined') {
    query += `&month_year=${paginationOption.month_year}`
  }

  if (typeof paginationOption.sort_by !== 'undefined') {
    query += `&sort_by=${paginationOption.sort_by}`
  }

  return query
}

export default class DailyBookService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getTodayDailyBook() {
    return request('GET', `/me/today`, this.options)
  }

  async getDailyBookByDay({ day }: GetDailyBookByDay) {
    return request('GET', `/me/book/${day}`, this.options)
  }

  async getMoment() {
    return request('GET', `/me/moments`, this.options)
  }

  async getSettings() {
    return request('GET', `/me/settings`, this.options)
  }

  async getMomentsImages(paginationOption: MomentePaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/me/moments/images${query}`, this.options)
  }

  async editDailyBook(day: string, data: DailyBookCreateOrUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/me/book/${day}`, requestOptions)
  }

  async editSetting(data: SettingsRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/me/settings`, requestOptions)
  }

  async createDailyBookImage({
    upload,
    day
  }: UploadDailyBookImage) {
    const formData = new FormData()

    formData.append('upload', upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }

    return request('POST', `/me/book/${day}/images`, requestOptions)
  }

  async deleteDailyBookImage({ day, image_id }: DeleteDailyBookUploadImage) {
    return request(
      'DELETE',
      `/me/book/${day}/images/${image_id}`,
      this.options
    )
  }

  async completeDailyChecking({ day, checkin_id, selection }: DailyCheckinCompleteRequest) {
    return request(
      'PUT',
      `/me/book/${day}/daily-checkins/${checkin_id}/set/${selection}/`,
      this.options
    )
  }

  async unsetDailyChecking({ day, checkin_id }: DailyCheckinDeleteRequest) {
    return request(
      'DELETE',
      `/me/book/${day}/daily-checkins/${checkin_id}/unset`,
      this.options
    )
  }

  async createDailyRoutine(day: string, data: DailyRoutineRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/me/book/${day}/daily-routines/`, requestOptions)
  }

  async editDailyRoutine({ day, routine_id }: DailyRoutineUpdateQuery, data: DailyRoutineRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/me/book/${day}/daily-routines/${routine_id}`, requestOptions)
  }

  async deleteDailyRoutine({ day, routine_id }: DailyRoutineUpdateQuery) {
    return request('DELETE', `/me/book/${day}/daily-routines/${routine_id}`, this.options)
  }

  async getTemplate(templateId: number) {
    return request('GET', `/templates/${templateId}`, this.options)
  }

  async getDefaultTemplate() {
    return request('GET', `/me/templates`, this.options)
  }

  async createDefaultTemplate(data: TemplateCreateOrUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/me/templates`, requestOptions)
  }

  async editDefaultTemplate(data: Template) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/me/templates/`, requestOptions)
  }
}
