export enum RubricActionTypeEnum {
    List = "LIST_RUBRICS_MEDIATHEK",
    ListInSelect = "SELECT_LIST_RUBRICS_MEDIATHEK",
    AddDefaultRubric = "ADD_DEFAULT_RUBRIC_MEDIATHEK",
    Add = "ADD_RUBRIC_MEDIATHEK",
    Update = "UPDATE_RUBRIC_MEDIATHEK",
    Delete = "DELETE_RUBRIC_MEDIATHEK",
    Loading = "LOADING_RUBRICS_MEDIATHEK",
}

export enum VideoActionTypeEnum {
    List = "LIST_VIDEOLIST_VIDEOS_MEDIATHEK",
    BlockModeLoading = "SET_BLOCK_MODE_VIDEOS_LOADING_MEDIATHEK",
    SetEmpty = "SET_VIDEOS_EMPTY_MEDIATHEK",
    Get = "GET_CONFIG_MAGAZINE",
    Update = "UPDATE_VIDEO_MEDIATHEK",
    Add = "ADD_VIDEO_MEDIATHEK",
    Delete = "DELETE_VIDEO_MEDIATHEK",
    DefaultExperts = "DEFAULT_EXPERTS",
    Loading = "LOADING_VIDEO_MEDIATHEK"
}

export enum ModuleConfigActionTypeEnum {
    GetData = "GET_CONFIG_MEDIATHEK",
    BlockModeLoading = "SET_BLOCK_MODE_VIDEOS_LOADING_MEDIATHEK",
    Update = "UPDATE_CONFIG_MEDIATHEK",
    AddVideo = "ADD_CONFIG_VIDEO_MEDIATHEK",
    DeleteVideo = "DELETE_CONFIG_VIDEO_MEDIATHEK",
    AddRubric = "ADD_CONFIG_RUBRIC_MEDIATHEK",
    DeleteRubric = "DELETE_CONFIG_RUBRIC_MEDIATHEK",
    AddRecommendedVideo = "ADD_CONFIG_RECOMMENDED_VIDEO_MEDIATHEK",
    DeleteRecommendedVideo = "DELETE_CONFIG_RECOMMENDED_VIDEO_MEDIATHEK",
    AddNewestVideo = "ADD_CONFIG_NEWEST_VIDEO_MEDIATHEK",
    DeleteNewestVideo = "DELETE_CONFIG_NEWEST_VIDEO_MEDIATHEK",
    AddPopularVideo = "ADD_CONFIG_POPULAR_VIDEO_MEDIATHEK",
    DeletePopularVideo = "DELETE_CONFIG_POPULAR_VIDEO_MEDIATHEK",
    AddTag = "ADD_CONFIG_TAG_MEDIATHEK",
    DeleteTag = "DELETE_CONFIG_TAG_MEDIATHEK",
}

export enum RubricConfigActionTypeEnum {
    GetData = "GET_RUBRIK_CONFIG_MEDIATHEK",
    LoadSections = "LOAD_SECTION_TO_UPDATE", // For mikel
    ClearSectionToUpdate = "CLEAR_SECTION_TO_UPDATE", //for mikel
}
