import {
    ContentRequest,
    Options, TdmRequestMonthYear, TdmRequestMonthYearFilename, MyTdmBodyRequest

} from '../utils/DataSchemas';
import request from '../../../services.utils/request'

export default class VideoService {
    options: Options

    constructor(options: Options) {
        this.options = options
    }

    async getMyTdmByMonthYear({ month, year }: TdmRequestMonthYear) {
        return request('GET', `/me/topics/${month}-${year}`, this.options)
    }

    async editMyTdmMonthYear({ month, year }: TdmRequestMonthYear, data: MyTdmBodyRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data
        }
        return request('PUT', `me/tdm/${month}-${year}/customization`, requestOptions)
    }

    async createMyTdmContent({ month, year }: TdmRequestMonthYear, { content }: ContentRequest) {
        const formData = new FormData()
        formData.append('content', content)
        const requestOptions: Options = {
            ...this.options,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...this.options.headers
            },
            data: formData
        }
        return request('POST', `me/tdm/${month}-${year}/upload`, requestOptions)
    }


    async deleteMyTdmContent({ month, year, filename }: TdmRequestMonthYearFilename) {
        return request('DELETE', `me/tdm/${month}-${year}/contents/?filename=${filename}`, this.options)
    }
}
