import CourseService from './services/courses'
import RubrikService from './services/rubriks'
import VideoService from './services/videos'
import UserConsentService from './services/userContent'
import { Options } from '../../services.utils/request-types';
import ModuleConfigService from './services/moduleConfig'

// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private rubrikService!: RubrikService
  private courseService!: CourseService
  private videoService!: VideoService
  private userContentService!: UserConsentService
  private moduleConfigService!: ModuleConfigService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.rubrikService = new RubrikService(this.options)
    this.courseService = new CourseService(this.options)
    this.videoService = new VideoService(this.options)
    this.userContentService = new UserConsentService(this.options)
    this.moduleConfigService = new ModuleConfigService(this.options)
  }

  /**
   * Get all rubrics
   */
  getAllRubriks = (
    paginationOption: dataSchemas.PaginationOption = { offset: 0, limit: 100, published: true, sort_direction: -1 }
  ): Promise<dataSchemas.Pagination<dataSchemas.Rubrik>> =>
    this.rubrikService.getAll({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get rubrics by id
   * @param categoryId Category id
   * @param rubrikId Rubrik id
   */
  getRubrikById = (rubrikId: number): Promise<dataSchemas.Rubrik> =>
    this.rubrikService.getById(rubrikId)

  /**
   * Post rubrics
   * @param categoryId Category id
   * @param body Body of rubrics
   */
  createRubriks = (
    body: dataSchemas.RubrikRequest
  ): Promise<dataSchemas.Rubrik> => this.rubrikService.createRubrik(body)


  /**
   * Update rubric
   * @param categoryId Category id
   * @param rubrikId Rubrik id
   * @param body Body of rubric
   */
  updateRubrik = (
    rubrikId: number,
    body: dataSchemas.RubrikRequest
  ): Promise<dataSchemas.Rubrik> =>
    this.rubrikService.editRubrik(rubrikId, body)

  /**
   * Delete rubric
   * @param categoryId Category id
   * @param rubrikId Rubrik id
   */
  deleteRubriks = async (rubrikId: number) => {
    const rubric = await this.getRubrikById(rubrikId)
    return this.rubrikService
      .deleteRubrik(rubric.id)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a rubric.',
          error: e
        })
      })
  }

  /**
   * Get all courses
   */
  getAllCourse = (
    getAllQuery: dataSchemas.CourseGetAllRequest = {
      offset: 0,
      limit: 15
    }
  ): Promise<dataSchemas.Pagination<dataSchemas.Course>> => this.courseService.getAll({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })

  /**
   * Get course by id
   * @param data Object with course id
   */
  getCourseById = (
    data: dataSchemas.GetCourseByIdRequest
  ): Promise<dataSchemas.Course> => this.courseService.getCoursesById(data)

  /**
   * Create course
   * @param body Body of course
   */
  createCourse = (
    body: dataSchemas.CourseCreateRequest
  ): Promise<dataSchemas.Course> => this.courseService.createCourse(body)

  /**
   * Update course
   * @param body Body of course
   */
  updateCourse = (
    body: dataSchemas.CourseUpdateRequest
  ): Promise<dataSchemas.Course> => this.courseService.updateCourse(body)

  /**
   * Delete course
   * @param courseId Object with course id
   */
  deleteCourse = async (
    courseId: number,
  ): Promise<dataSchemas.Course> => this.courseService.deleteCourse(courseId)

  /**
   * Create course image
   * @param body Body of course
   */
  addImageToCourse = (body: dataSchemas.AddImageToCourse): Promise<string> =>
    this.courseService.addImageToCourse(body)

  /**
   * Remove course image
   * @param body Body of course
   */
  removeImageOnCourse = (
    body: dataSchemas.RemoveImageOnCourse
  ): Promise<string> => this.courseService.removeImageOnCourse(body)

  /**
   * like a course
   * @param body Body of course
   */
  likeCourse = (body: dataSchemas.WithUserIdCourseId): Promise<string> =>
    this.userContentService.likeCourse(body)

  /**
   * Dislike a course
   * @param body Body of course
   */
  dislikeCourse = (body: dataSchemas.WithUserIdCourseId): Promise<string> =>
    this.userContentService.dislikeCourse(body)


  // VIDEOS

  /**
   * Create Video
   * @param data Object with video id
   */
  createVideo = (
    data: dataSchemas.VideoCreateRequest
  ): Promise<dataSchemas.Video> => this.videoService.createVideo(data)

  /**
   * Get video by id
   * @param data Object with course id
   */
  getVideoById = (
    data: dataSchemas.CourseVideoId
  ): Promise<dataSchemas.Video> => this.videoService.getVideoById(data)

  /**
   * Get all videos
   * @param data query
   */
  getAllVideo = (
    data: dataSchemas.VideoGetAllRequest = { offset: 0, limit: 15 }
  ): Promise<dataSchemas.PaginationVideo> => this.videoService.getAllVideo(data)

  /**
   * Get admin video by id
   * @param data course_id, video_id
   */
  getAdminVideoById = (
    data: dataSchemas.CourseVideoId
  ): Promise<dataSchemas.PaginationVideo> => this.videoService.getAdminVideoById(data)

  /**
   * Get admin course by id
   * @param data course_id, published_only
   */
  getAdminCoursesById = (
    data: dataSchemas.GetCourseByIdRequest
  ): Promise<dataSchemas.Course> => this.courseService.getAdminCoursesById(data)

  /**
   * re sync course duration
   */
  reSyncCourseDuration = (): Promise<string> => this.courseService.reSyncCourseDuration()


  /**
   * Get watch list by id
   * @param data Object with course id
   */
  getWacthList = (
    data: dataSchemas.CourseVideoId
  ): Promise<dataSchemas.PaginationVideo> => this.videoService.getWacthList(data)



  /**
   * Delete a video
   * @param data Object with course id
   */
  deleteVideo = async (data: dataSchemas.CourseVideoId): Promise<boolean> => {
    const video: dataSchemas.Video = await this.getVideoById(data)
    return this.videoService
      .deleteVideo({ course_id: data.course_id, video_id: video.id })
      .then(() => {
        return true
      })
      .catch((e: any) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a course.',
          error: e
        })
      })
  }

  /**
   * Update a video
   * @param data Object with video updatde data
   */
  updateVideo = async (
    data: dataSchemas.VideoUpdateRequest
  ): Promise<dataSchemas.Video> => {
    return this.videoService.updateVideo(data)
  }

  /**
   * Create video image
   * @param body Body of course
   */
  addImageToVideo = (body: dataSchemas.AddImageToVideo): Promise<dataSchemas.Image> =>
    this.videoService.addImageToVideo(body)

  /**
   * Create video attachement
   * @param body Body of course
   */
  addAttachmentToVideo = (
    body: dataSchemas.AttachmentCreateRequest
  ): Promise<dataSchemas.VideoReference> => this.videoService.addAttachmentToVideo(body)

  /**
   * Create video reference
   * @param body Body of course
   */
  addReferenceToVideo = (
    body: dataSchemas.ReferenceCreateRequest
  ): Promise<dataSchemas.VideoReference> => this.videoService.addReferenceToVideo(body)

  /**
   * Remove video attachement
   * @param body Body of course
   */
  removeAttachmentOnVideo = (
    body: dataSchemas.AttachmentRemoveRequest
  ): Promise<string> => this.videoService.removeAttachmentOnVideo(body)

  /**
   * Remove video reference
   * @param body Body of course
   */
  removeReferenceOnVideo = (
    body: dataSchemas.ReferenceRemoveRequest
  ): Promise<string> => this.videoService.removeReferenceOnVideo(body)

  /**
   * Remove image on video
   * @param body of course
   */
  removeImageOnVideo = (
    body: dataSchemas.RemoveImageOnVideo
  ): Promise<string> => this.videoService.removeImageOnVideo(body)

  /**
   * Set that a course video started
   */
  startViewingVideo = (
    body: dataSchemas.WithUserIdCourseIdVideoId
  ): Promise<string> => this.userContentService.startViewingVideo(body)

  /**
   * Stop viewing a course
   */
  stopViewingCourse = (
    course_id: number
  ): Promise<string> => this.userContentService.stopViewingCourse(course_id)

  /**
   * Set that a course video started
   */
  getUserFavoriteCourses = (): Promise<dataSchemas.GetUserFavoritesCourses> =>
    this.userContentService.getUserFavoriteCourses()

  /**
   * Set the video duration of the user
   */
  setVideoDurationFoUser = (
    data: dataSchemas.VideoDurationByUserRequest
  ): Promise<string> =>
    this.userContentService.setVideoDurationFoUser(data)

  /**
   * ModuleConfig
   */

  /**
   * Get the module configuration
   */
  getModuleConfig = (
  ): Promise<dataSchemas.ModuleConfig> =>
    this.moduleConfigService.getModuleConfig()

  /**
   * Update the page configuration
   * @param body Body of page configuration
   */
  updateModuleConfig = (
    body: dataSchemas.ModuleConfigRequest
  ): Promise<dataSchemas.ModuleConfig> =>
    this.moduleConfigService.updateModuleConfig(body)

  /**
   * Post a favorite course
   * @param course_id course id
   */
  createFavoriteCourse = (
    data: dataSchemas.UserContentRequest
  ): Promise<string> =>
    this.userContentService.createFavoriteCourse(data)


  /**
   * un favorite a course
   * @param course_id course id
   */
  unFavoriteCourse = (
    data: dataSchemas.UserContentRequest
  ): Promise<string> =>
    this.userContentService.unFavoriteCourse(data)


  /**
 * Post a favorite video with video_id
 * @param course_id course id
 */
  favoriteVideo = (
    data: dataSchemas.WithUserIdCourseIdVideoId
  ): Promise<string> =>
    this.userContentService.favoriteVideo(data)


  /**
   * un favorite a video with video_id
   * @param course_id course id
   */
  unfavoriteVideo = (
    data: dataSchemas.WithUserIdCourseIdVideoId
  ): Promise<string> =>
    this.userContentService.unfavoriteVideo(data)

  /**
 * get favorites videos
 */
  getUserFavoriteVideos = (paginationOption: dataSchemas.VideoPaginationOption): Promise<dataSchemas.VideoPagination> =>
    this.userContentService.getUserFavoriteVideos(paginationOption)
}

export * as types from './utils/DataSchemas'
