import {
    RegisterRequest,
    RegisterConfirmationCodeRequest,
    AuthorizeRequest,
    ChangePasswordRequest,
    ResetPasswordCodeRequest,
    PasswordResetCodeRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class AuthService {
    options: Options

    constructor(options: Options) {
        this.options = options
    }

    async register(data: RegisterRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/register`, requestOptions, false)
    }

    async confirmationCode(data: RegisterConfirmationCodeRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/confirmation-code`, requestOptions, false)
    }

    async authorize(data: AuthorizeRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/authorize`, requestOptions)
    }

    async changePassword(data: ChangePasswordRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/change-password`, requestOptions)
    }

    async refreshToken() {
        const requestOptions: Options = {
            ...this.options,
            data: ''
        }
        return request('POST', `/token`, requestOptions)
    }

    async resetPasswordCode(data: ResetPasswordCodeRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/reset-password-code`, requestOptions, false)
    }

    async passwordResetCode(data: PasswordResetCodeRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('POST', `/password-reset-code`, requestOptions, false)
    }

    async logout() {
        const requestOptions: Options = {
            ...this.options,
            data: ''
        }

        return request('POST', `/logout`, requestOptions)
    }

}
