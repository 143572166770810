import {
  TodaySetByDateInterface
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class DailyCardMeService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getTodaySet() {
    return request('GET', `/me/today`, this.options)
  }

  async getPublicTodaySet() {
    return request('GET', `/public/today`, this.options)
  }

  async getTodaySetByDate({day} : TodaySetByDateInterface) {
    return request('GET', `/me/today-by-date/${day}`, this.options)
  }

  async pickDailyCard(dailyCardId: number) {
    return request('POST', `/me/today/${dailyCardId}`, this.options)
  }
  
}
