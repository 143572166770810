import EventService from './services/events'
import PageConfigService from './services/moduleConfig'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private eventService!: EventService
  private pageConfigService!: PageConfigService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.eventService = new EventService(this.options)
    this.pageConfigService = new PageConfigService(this.options)
  }

  /**
   * Get all events
   */
  getAllEvents = (
    paginationOption: dataSchemas.EventGetAllRequest
  ): Promise<dataSchemas.EventPagination<dataSchemas.Event>> =>
    this.eventService.getAll({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get all public events
   */
  getAllPublicEvent = (
    paginationOption: dataSchemas.EventGetAllRequest
  ): Promise<dataSchemas.EventPagination<dataSchemas.Event>> =>
    this.eventService.getAllPublicEvent({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get all user events
   */
  getUserEvent = (
    paginationOption: dataSchemas.EventGetAllRequest
  ): Promise<dataSchemas.EventPagination<dataSchemas.Event>> =>
    this.eventService.getUserEvent({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get upcomming events
   */
  getAllUpcommingEvents = (
    paginationOption: dataSchemas.EventGetAllRequest
  ): Promise<dataSchemas.EventPagination<dataSchemas.Event>> =>
    this.eventService.getAllUpcommingEvents({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get event by id
   * @param eventId Event id
   */
  getEventById = (eventId: number): Promise<dataSchemas.Event> =>
    this.eventService.getById(eventId)

  /**
   * Get public event by id
   * @param eventId Event id
   */
  getPublicEventById = (eventId: number): Promise<dataSchemas.Event> =>
    this.eventService.getPublicEventById(eventId)

  /**
 * Get event calendar
 * @param eventId Event id
 */
  getEventCalendar = (eventId: number) =>
    this.eventService.getEventCalendar(eventId)

  /**
 * Get public event calendar
 * @param eventId Event id
 */
  getPublicEventCalendar = (eventId: number) =>
    this.eventService.getPublicEventCalendar(eventId)

  /**
   * Post event
   * @param body Body of event
   */
  createEvent = (body: dataSchemas.EventRequest): Promise<dataSchemas.Event> =>
    this.eventService.createEvent(body)

  /**
   * Attendee event
   * @param eventId Event Id
   */
  attendeeEvent = (eventId: number): Promise<dataSchemas.Event> =>
    this.eventService.attendeeEvent(eventId)

  /**
   * Unattendee event
   * @param eventId Event Id
   */
  unAttendeeEvent = (eventId: number): Promise<dataSchemas.Event> =>
    this.eventService.unAttendeeEvent(eventId)

  /**
   * Like event
   * @param eventId Event Id
   */
  likeEvent = (eventId: number) =>
    this.eventService.likeEvent(eventId)

  /**
   * Dislike event
   * @param eventId Event Id
   */
  dislike = (eventId: number) =>
    this.eventService.unLikeEvent(eventId)

  /**
   * Post event image
   * @param body Body of event
   */
  createEventImage = (
    body: dataSchemas.EventImageRequest
  ): Promise<dataSchemas.Image> =>
    this.eventService.createEventImage(body)

  /**
   * Delete event image
   * @param eventId event id
   */
  deleteEventImage = (eventId: number, imageId: number): Promise<string> =>
    this.eventService.deleteEventImage(eventId, imageId)

  /**
   * Update event
   * @param body Body of event
   */
  updateEvent = (
    body: dataSchemas.UpdateEventRequest
  ): Promise<dataSchemas.Event> => this.eventService.updateEvent(body)

  /**
   * Delete event
   * @param eventId Event id
   */
  deleteEvent = async (eventId: number): Promise<string> => {
    return this.eventService
      .deleteEvent(eventId)
  }


  /**
   * Clone event
   * @param eventId Event id
   */
  cloneEvent = async (eventId: number): Promise<dataSchemas.Event> => {
    return this.eventService
      .cloneEvent(eventId)
  }

  /**
   * Cancel event
   * @param eventId Event id
   * @param cancellationReason Reason id
   */
  cancelEvent = async (eventId: number, cancellationReason: string): Promise<dataSchemas.Event> => {
    return this.eventService.cancelEvent(eventId, cancellationReason)
  }


  /**
   * ModuleConfig
   */

  /**
   * Get the module configuration
   */
  getModuleConfig = (paginationOption: dataSchemas.ModuleGetAllRequest): Promise<dataSchemas.ModuleConfig> =>
    this.pageConfigService.getModuleConfig(paginationOption)

  /**
 * Create event attachement
 * @param body Body of event
 */
  addAttachmentToEvent = (
    body: dataSchemas.AttachmentCreateRequest
  ): Promise<dataSchemas.AttachmentOrReference> => this.eventService.addAttachmentToEvent(body)

  /**
   * Create event reference
   * @param body Body of event
   */
  addReferenceToEvent = (
    body: dataSchemas.ReferenceCreateRequest
  ): Promise<dataSchemas.AttachmentOrReference> => this.eventService.addReferenceToEvent(body)

  /**
   * Edit event reference
   * @param body Body of event
   */
  editReferenceToEvent = (
    body: dataSchemas.ReferenceUpdateRequest
  ): Promise<dataSchemas.AttachmentOrReference> => this.eventService.editReferenceToEvent(body)

  /**
   * Remove event attachement
   * @param body Body of event
   */
  removeAttachmentOnEvent = (
    body: dataSchemas.AttachmentRemoveRequest
  ): Promise<string> => this.eventService.removeAttachmentOnEvent(body)

  /**
   * Remove event reference
   * @param body Body of event
   */
  removeReferenceOnEvent = (
    body: dataSchemas.ReferenceRemoveRequest
  ): Promise<string> => this.eventService.removeReferenceOnEvent(body)
}

export * as types from './utils/DataSchemas'
