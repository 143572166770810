import React, { useEffect, useContext } from 'react';
import { UserClient as Client, userTypes as types } from '@happiness-house/sdk'
import { AuthContext } from "../../auth/contexts/authContext";

const client = new Client();

const useUser = () => {
    const { signOut } = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            client.configure({
                baseURL: `${process.env.REACT_APP_HH_API}/users/v1`, signOut
            })
        })()
    }, [])

    return { client, types }
}

export default useUser;