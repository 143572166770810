// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import GroupService from './services/Group'
import PostService from './services/Post'
import UserService from './services/Users'
import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'
import { Options } from '../../services.utils/request-types';

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private groupService!: GroupService
  private postService!: PostService
  private userService!: UserService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.groupService = new GroupService(this.options)
    this.postService = new PostService(this.options)
    this.userService = new UserService(this.options)
  }

  /**
   * Create a group
   */
  createGroup = (
    data: dataSchemas.CommunityGroupRequest
  ): Promise<dataSchemas.CommunityGroup> => this.groupService.createGroup(data)


  /**
   * Get all groups
   */
  getAllGroup = (
    getAllQuery: dataSchemas.PaginationGroupOption = {
      offset: 0,
      limit: 20,
      sort_direction: -1
    }
  ): Promise<dataSchemas.Pagination> => this.groupService.getAllGroup({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })

  /**
   * Get group by Id
   * @param data Object
   */
  getGroupById = (
    data: dataSchemas.CommunityGroupParams
  ): Promise<dataSchemas.CommunityGroup> => this.groupService.getGroupById(data)

  /**
    * Get group by User
    */
  getGroupByUser = (
  ): Promise<Array<dataSchemas.CommunityGroup>> => this.groupService.getGroupByUser()

  /**
   * edit a group
   * @param body Body of group
   * @param data edit data
   */
  editGroup = (
    data: dataSchemas.CommunityGroupParams,
    body: dataSchemas.CommunityGroupRequest
  ): Promise<dataSchemas.CommunityGroup> => this.groupService.editGroup(data, body)


  /**
   * delete a group
   * @param data delete data
   */
  deleteGroup = (
    data: dataSchemas.CommunityGroupDeleteParams,
  ): Promise<dataSchemas.CommunityGroup> => this.groupService.deleteGroup(data)

  /**
  * Get all group requests
  */
  getGroupRequests = (
    getAllQuery: dataSchemas.GroupRequestOption
  ): Promise<dataSchemas.BasePagination<dataSchemas.CommunityGroupRequestToJoin>> => this.groupService.getGroupRequests(getAllQuery)


  /**
  * Get group request by Id
  */
  getGroupRequestsById = (
    data: dataSchemas.CommunityApproveRequest
  ): Promise<dataSchemas.CommunityGroupRequestToJoin> => this.groupService.getGroupRequestsById(data)

  /**
 * approve a request
 */
  approveRequest = (
    data: dataSchemas.CommunityApproveRequest
  ): Promise<void> => this.groupService.approveRequest(data)

  /**
   * Create group image
   * @param body Body
   */
  addGroupImage = (
    data: dataSchemas.CommunityGroupParamsWithIsBackground,
    body: dataSchemas.CommunityGroupImage): Promise<dataSchemas.CommunityGroup> =>
    this.groupService.addGroupImage(data, body)

  /**
   * Remove group image
   */
  deleteGroupImage = (
    data: dataSchemas.CommunityDeleteImageRequest
  ): Promise<void> => this.groupService.deleteGroupImage(data)



  /**
   * Create a post
   */
  createPost = (
    body: dataSchemas.BaseCommunityPostRequest,
  ): Promise<dataSchemas.CommunityPost> => this.postService.createPost(body)

  /**
   * Get all posts
   */
  getAllPost = (
    getAllQuery: dataSchemas.PaginationPostOption = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.BasePagination<dataSchemas.CommunityPost>> => this.postService.getAllPost({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })


  getAllMyPost = (
    getAllQuery: dataSchemas.PaginationPostOption = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.BasePagination<dataSchemas.CommunityPost>> => this.userService.getAllMyPost({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })
  /**
   * Get all report_abuses
   */
  getAllReportAbuses = (
    getAllQuery: dataSchemas.PaginationReportAbusesPostOption = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.BasePagination<dataSchemas.CommunityPostReportAbuses>> => this.postService.getAllReportAbuses({
    ...getAllQuery
  })

  /**
   * get a report_abuses by id
   */
  getByIdReportAbuses = (
    data: dataSchemas.CommunityReportAbusesParams
  ): Promise<dataSchemas.CommunityPostReportAbuses> => this.postService.getByIdReportAbuses(data)

  /**
   * edit a post
   * @param abuse_id
   */
  editReportAbuses = (
    abuse_id: number,
    body: dataSchemas.CommunityPostReportRequest
  ): Promise<dataSchemas.CommunityPostReportAbuses> => this.postService.editReportAbuses(abuse_id, body)

  /**
   * report a post
   * @param data
   */
  reportPost = (
    data: dataSchemas.ReportPost
  ): Promise<dataSchemas.CommunityPostReportAbuses> => this.userService.reportAbuse(data)

  /**
   * cancel a report
   * @param post_id
   */
  cancelReport = (
    post_id: number
  ): Promise<any> => this.userService.cancelReport(post_id)

  /**
   * subscribe notification
   * @param post_id
   */
  subscribeNotif = (
    post_id: number
  ): Promise<any> => this.userService.subscribe(post_id)

  /**
   * unsubscribe notification
   * @param post_id
   */
  unsubscribeNotif = (
    post_id: number
  ): Promise<any> => this.userService.unsubscribe(post_id)

  /**
   * subscribe group notification
   * @param group_id
   */
  subscribeGroupNotif = (
    group_id: number
  ): Promise<any> => this.userService.subscribeGroup(group_id)

  /**
   * unsubscribe group notification
   * @param group_id
   */
  unsubscribeGroupNotif = (
    group_id: number
  ): Promise<any> => this.userService.unsubscribeGroup(group_id)

  /**
   * Leave a group
   * @param group_id
   */
  leaveGroup = (
    group_id: number
  ): Promise<any> => this.userService.leaveGroup(group_id)


  /**
    * get a post by id
    */
  getByIdPost = (
    data: dataSchemas.CommunityPostParams
  ): Promise<dataSchemas.CommunityPost> => this.postService.getByIdPost(data)

  /**
   * edit a post
   * @param post_id
   * @param body Body of post
   */
  editPost = (
    post_id: number,
    body: dataSchemas.CommunityEditPostRequest
  ): Promise<dataSchemas.CommunityPost> => this.postService.editPost(post_id, body)

  /**
   * delete a post
   */
  deletePost = (
    post_id: number
  ): Promise<dataSchemas.CommunityPost> => this.postService.deletePost(post_id)


  /**
   * Create poste image
   * @param body Body
   */
  addPostImage = (
    post_id: number,
    body: dataSchemas.CommunityPostImage): Promise<dataSchemas.CommunityPost> =>
    this.postService.addPostImage(post_id, body)

  /**
   * delete a post image
   */
  deletePostImage = (
    post_id: number,
    image_id: number,
  ): Promise<void> => this.postService.deletePostImage(post_id, image_id)

  /**
 * Join a group
 */
  JoinGroup = (
    data: dataSchemas.CommunityUserJoinRequest
  ): Promise<string> => this.userService.JoinGroup(data)

  /**
  * get dahsboard
  */
  getDashboard = (
  ): Promise<dataSchemas.CommunityDashboard> => this.userService.getDashboard()


  /**
  * get all groups requests
  */
  getAllGroupsRequests = (
    getAllQuery: dataSchemas.GroupRequestOption = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.BasePagination<dataSchemas.CommunityGroupRequestToJoin>> => this.groupService.getGroupRequests(getAllQuery)


  /**
* get a group member
*/
  getGroupMember = (
    data: dataSchemas.GroupRequestOption
  ): Promise<any> => this.groupService.getGroupMember(data)

  /**
* add user to group
*/
  addGroupMember = (
    group_id: number,
    user_id: number
  ): Promise<any> => this.groupService.addGroupMember(group_id, user_id)

  /**
* remove user from group
*/
  deleteGroupMember = (
    group_id: number,
    user_id: number
  ): Promise<any> => this.groupService.deleteGroupMember(group_id, user_id)

  postReaction = (reactionBody: dataSchemas.PostReaction): Promise<string> => this.postService.postReaction(reactionBody)
  deleteReaction = (data: dataSchemas.CommunityPostParams): Promise<string> =>
  this.postService.deleteReaction(data)

  getAllReactions = (getAllQuery: dataSchemas.LikeGetAllRequest = {
    post_id: 1,
    offset: 0,
    limit: 15
  }
  ): Promise<dataSchemas.BasePagination<dataSchemas.GetAllReactionResponse>> => this.postService.getReactionsByPost(getAllQuery)
}

export * as types from './utils/DataSchemas'
