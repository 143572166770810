import { AxiosRequestConfig } from 'axios'

export interface UserAccessLog {
  id?: number
  user_id?: number
  event_dt?: number
  status?: number
  notes: string
}
export interface TagInterface {
  id?: number
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
  value: string
}

export interface ImageInterface {
  id: number
  nb_likes: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  nb_loves: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  user_id: number
  album_id: number
  path: string
  cropped_path?: string
  description: string
  owner_id: number
  tagged_user_ids: Array<ImageTagInterface>
  cropped_data: CroppedDataInterface
  like_by_user_ids: Array<number>
}

export interface ImageExpandedInterface {
  id?: number
  nb_likes?: number
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
  album_id?: number
  path: string
  cropped_path?: string
  description?: string
  owner_id?: number
  tagged_user_ids?: Array<ImageTagInterface>
  tagged_users?: Array<LimitedUserProfile>
}

export interface ImageTagInterface {
  x: number
  y: number
  user_id: number
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}


export interface VisibilitySettinsInterface {
  anonymized: boolean
  online_visible: boolean
  search_visible: boolean
}


export interface ChatSettinsInterface {
  online_bar_visible: boolean
  community_chat_visible: boolean
  direct_message: boolean
  receive_messages: boolean
  typing_indicator: boolean
  read_indicator: boolean
  silence_message: boolean
  offline_notification: boolean
  font_size: number
}

export interface CommunitySettinsInterface {
  location_map_visible: boolean
  group_notification_enabled: boolean
  new_post: boolean
  activity_on_my_post: boolean
  activity_on_my_comment: boolean
  activity_on_any_post_comment_interacted: boolean
  community_point_visible: boolean
  community_chat_visible: boolean
}

export interface LiveEventySettinsInterface {
  attendee_visible: boolean
  registered_visible: boolean
  registration_alert: boolean
  registration_email: boolean
  reminder_before_start_event_alert: boolean
  reminder_before_start_event_email: boolean
  cancellation_event_alert: boolean
  cancellation_event_email: boolean
  update_event_alert: boolean
  update_event_email: boolean
  new_event_expert_alert: boolean
  new_event_expert_email: boolean
  new_event_recording_alert: boolean
  new_event_recording_email: boolean
  reminder_mail: boolean
}

export interface GeneralSettingsInterface {
  new_thema_des_monat_alert: boolean
  new_thema_des_monat_mail: boolean
  daily_nudges_alert: boolean
  daily_nudges_mail: boolean
  product_news: boolean
  newsletter: boolean
  community_news: boolean
  onboarding_mail: boolean
}

export interface TreffpunteSettinsInterface {
  registration_alert: boolean
  registration_email: boolean
  reminder_one_week_before_start_meetupt_alert: boolean
  reminder_one_week_before_start_meetupt_email: boolean
  reminder_one_day_before_start_meetup_alert: boolean
  reminder_one_day_before_start_meetup_email: boolean
  cancellation_meetup_alert: boolean
  cancellation_meetup_email: boolean
  update_meetup_alert: boolean
  update_meetup_email: boolean
  new_meetup_alert: boolean
  new_meetup_email: boolean
  new_meetup_activity_alert: boolean
  new_meetup_activity_email: boolean
}

export interface UserProfileSettingsInterface {
  friends_visible: boolean
  friend_birthday_alert: boolean
  friend_birthday_email: boolean
  new_friend_request_email: boolean
  accepted_friend_request_email: boolean
  activity_on_my_photo_email: boolean
  activity_on_any_photo_i_interacted_with_email: boolean
  birthday_visible: boolean
  experts_visible: boolean
}

export interface SettingsInterface {
  visibility: VisibilitySettinsInterface
  general_settings: GeneralSettingsInterface
  t_and_c_approved: boolean
  t_and_c_approval_dt: Date
  chat_settings: ChatSettinsInterface
  community_settings: CommunitySettinsInterface
  live_event_settings: LiveEventySettinsInterface
  treffpunkte_settings: TreffpunteSettinsInterface
  user_profile_settings: UserProfileSettingsInterface
  blocked_user_ids: Array<number>
}

export interface Album {
  id: number
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
  owner_id: number
  name: string
  images?: ImageInterface
  default_image_path: string
  nb_images?: number
}

export interface ImageLimited {
  path: string
  cropped_path?: string
  cropped_data: CroppedDataInterface
}

export interface LimitedUserProfile {
  id: number
  internal_status: number
  email: string
  membership_id: number
  full_name: string
  image_profile: ImageLimited | null
  image_background: ImageLimited | null
  status: string
  motto: string
  tags: Array<Tag>
  is_friend: boolean
  is_blocked: boolean
  is_blocked_by_user: boolean
  is_online: boolean
  online_visible: boolean
  attendee_visible: boolean
  community_chat_visible: boolean
  community_point_visible: boolean
  is_reported: boolean
  direct_message: boolean
  is_admin: boolean
  receive_messages: boolean
  friend_ids: Array<number>
}

export interface Tag {
  id: number
  value: string
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface FriendRequestInterface {
  id: number,
  created_at: Date,
  created_by: number,
  updated_at: Date,
  updated_by: number,
  requested_id: number,
  requester_id: number,
  status: string,
  request_reason: string,
  action_dt: Date
}

export interface InvoiceInterface {
  id?: number
  label?: string
  invoice_dt?: Date
  url?: string
  pay_method?: string
  currency?: string
  amount?: number
}

export interface MemberShipInterface {
  free_user?: boolean
  subscription_status?: string
  Subscription_status?: string
  membership_dt?: number
  cancelled_at?: number
  renewal_at?: number
  next_payment_at?: number
  can_cancel_before_at?: number
  buyer_id?: number
  active_purchase_id?: string
  payment_plan_msg?: string
  invoices?: Array<InvoiceInterface>
  renew_url?: string
  billing_type?: string
  billing_status?: string
}

export interface UserProfile {
  id?: number
  status?:number
  groups?: Array<string>
  nb_likes?: number
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
  basic_info: BasicInfoInterface
  contact_info: ContactInfoInterface
  about_info: AboutInfoInterface
  interest_info: InterestInfoInterface
  questions?: Array<QuestionInfoInterface>
  glueckskonto: GluecksKontoInfoInterface
  settings?: SettingsInterface
  is_friend: boolean
  friend_request?: FriendRequestInterface
  is_online: boolean
  membership?: MemberShipInterface
  is_blocked?: boolean
  is_blocked_by_user?: boolean
  access_logs?: Array<UserAccessLog>
}

export interface UserUpdateRequest {
  groups?: Array<string>
  status: number
  basic_info: BasicInfoInterface
  contact_info: ContactInfoInterface
  about_info: AboutInfoInterface
  interest_info: InterestInfoInterface
  questions?: Array<QuestionInfoInterface>
  glueckskonto: GluecksKontoInfoInterface
  settings?: SettingsInterface
  membership?: MemberShipInterface
}
export interface UserCreateRequest {
  email: string
  first_name: string
  last_name: string
  free_member: boolean,
  permissions: Array<Permissions>
}

export interface BasicInfoInterface {
  gender?: string
  first_name?: string
  middle_name?: string
  last_name?: string
  full_name?: string
  birthday?: string
  hometown?: string
  languages?: Array<String>
  relationship_status?: string
  zodiac_sign?: string
  children: number,
  rising_sign: string,
  education: string,
  profession: string,
  lived_places: string,
  favorite_quotes: Array<string>,
  is_membership_dt_visible: boolean,
  is_gender_visible: boolean,
  is_first_name_visible: boolean,
  is_middle_name_visible: boolean,
  is_last_name_visible: boolean,
  is_birthday_visible: boolean,
  is_hometown_visible: boolean,
  is_languages_visible: boolean,
  is_relationship_status_visible: boolean,
  is_children_visible: boolean,
  is_zodiac_sign_visible: boolean,
  is_rising_sign_visible: boolean,
  is_education_visible: boolean,
  is_profession_visible: boolean,
  is_lived_places_visible: boolean,
  is_favorite_quotes_visible: boolean
}

export interface AdressInterface {
  address_1?: string
  address_2?: string
  city?: string
  zip_code?: string
  state?: string
  country_code?: string
  geo_position?: GeoPositionInterface
}

export interface GeoPositionInterface {
  type: string;
  coordinates: Array<number>
}

export interface AboutInfoInterface {
  about_me: string,
  image_profile: ImageInterface | null,
  image_background: ImageInterface | null,
  status: string,
  motto: string
}

export interface ContactInfoInterface {
  primary_email: string
  phone_number?: string
  additional_emails?: Array<string>
  address?: AdressInterface
}

export interface InterestInfoInterface {
  talk_about: Array<Tag>,
  talk_about_ids: Array<number>,
  free_time: string,
  hobbies: string,
  interest: string,
  sport: string,
  inspiring_books: string,
  reading_books: string,
  favorite_movies: string,
  favorite_series: string,
  musics: string,
  memory_musics: string,
  is_talk_about_visible: boolean,
  is_free_time_visible: boolean,
  is_hobbies_visible: boolean,
  is_interest_visible: boolean,
  is_sport_visible: boolean,
  is_inspiring_books_visible: boolean,
  is_reading_books_visible: boolean,
  is_favorite_movies_visible: boolean,
  is_favorite_series_visible: boolean,
  is_memory_musics_visible: boolean,
  is_musics_visible: boolean
}

export interface QuestionInfoInterface {
  type?: Questions
  question?: string
  answer?: string
}

export interface Skill {
  name: string
  icon?: string
  is_visible?: boolean
  description?: string
}

export interface GluecksKontoInfoInterface {
  provided_skills?: Array<Skill>
  needed_skills?: Array<Skill>
}

export interface LifePathInfoInterface {
  id: number
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
  user_id: number
  title: string
  date?: Date
  icon?: string
  image?: ImageInterface
  is_visible?: boolean
  description?: string
}

export interface UserGetAllRequest {
  search_text?: string
  search_visible?: boolean
  hide_from_blocked_users?: boolean
  view_all_status?: boolean
  profile_ids?: Array<number>
  tag_ids?: Array<number>
  sort_by?: string
  sort_direction?: number
  offset?: number
  limit?: number
  free_user?: boolean
  status?: number
}

export interface LifePathRequest {
  title: string
  date?: Date
  icon?: string
  is_visible?: boolean
  description?: string
  image?: ImageInterface | null
}

export interface LifePathImageRequest {
  life_path_id?: number
  upload: Blob
}

export interface LifePathImageDeleteRequest {
  life_path_id?: number
  image_id?: number
}

export interface AlbumCreateRequest {
  owner_id: number
  name: string,
  description?: string,
}

export interface ChangeEmailRequest {
  new_login_email?: string,
  new_digistore_email?: string,
}

export interface AlbumUpdateRequest {
  owner_id: number
  name: string
  description?: string
}

export interface AlbumImageCreateRequest {
  album_id?: number
  description?: string
  upload: Blob
}

export interface AlbumImageUpdateRequest {
  clear_cache?: boolean
  album_id?: number
  description?: string
  tagged_user_ids?: Array<ImageTagInterface>
}

export interface FriendRequest {
  requested_id: number
  request_reason: string
}

export interface FriendRequestUpdate {
  request_id: number
  request_status: string
}

export interface ReportUserRequest {
  profile_id: number
  reason: string
}

export interface ImageMetaDataUpdateRequest {
  album_id?: number
  description?: string
  tagged_user_ids?: Array<ImageTagInterface>
}

export enum Questions {
  early_bird_or_night_owl = 'early_bird_or_night_owl',
  what_brings_me_joy = 'what_brings_me_joy',
  my_values = 'my_values',
  my_ideal_day_is = 'my_ideal_day_is',
  i_am_most_grateful_for = 'i_am_most_grateful_for',
  i_feel_confident_when = 'i_feel_confident_when',
  the_perfect_sunday_is = 'the_perfect_sunday_is',
  people_describes_me_as = 'people_describes_me_as',
  my_limiting_beliefs_are = 'my_limiting_beliefs_are',
  my_positive_affirmations = 'my_positive_affirmations',
  self_care_means = 'self_care_means',
  as_a_child_i_wanted_to = 'as_a_child_i_wanted_to',
  before_i_die = 'before_i_die',
  make_friendship_great = 'make_friendship_great',
  favourite_food = 'favourite_food',
  exercise_for_me_is = 'exercise_for_me_is',
  love_is = 'love_is',
  happiness_is = 'happiness_is',
  loyalty_means = 'loyalty_means',
  favourite_color = 'favourite_color',
  spirituality_means = 'spirituality_means',
  favourite_animal = 'favourite_animal',
  favourite_vacation = 'favourite_vacation',
  what_i_do_when_sad = 'what_i_do_when_sad',
  how_i_relax = 'how_i_relax',
  first_thing_in_morning = 'first_thing_in_morning',
  last_thing_in_evening = 'last_thing_in_evening',
  cooking_means = 'cooking_means',
  custom = 'custom'
}

export enum Permissions {
  admin = 'admin',
  live_event_manager = 'live_event_manager',
  community_manager = 'community_manager',
  tdm_manager = 'tdm_manager',
  academy_manager = 'academy_manager',
  magazin_manager = 'magazin_manager',
  mediathek_manager = 'mediathek_manager',
  meditation_manager = 'meditation_manager',
  experts_manager = 'experts_manager',
}

export enum RelationshipStatus {
  SINGLE = 'single',
  RELATIONSHIP = 'in_a_relationship',
  CIVIL = 'in_a_civil_union',
  MARRIED = 'married',
  SEPARATED = 'separated',
  DIVORCED = 'divorced',
  WIDOWED = 'widowed'
}

export enum RelationshipStatus {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other'
}

export enum FriendRequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DECLINED = 'declined'
}

export enum ZodiacSign {
  aquarius = 'aquarius',
  aries = 'aries',
  cancer = 'cancer',
  capricorn = 'capricorn',
  gemini = 'gemini',
  leo = 'leo',
  libra = 'libra',
  pisces = 'pisces',
  scorpio = 'scorpio',
  sagittarius = 'sagittarius',
  taurus = 'taurus',
  virgo = 'virgo'
}

// SDK OPTIONS
export interface Options extends AxiosRequestConfig {
  version?: string
  token?: string
  baseURL?: string
  timeout?: number
  socketEndpoint?: string
  userId?: string
  getNewToken?: Function
}

export interface Pagination<T> {
  result: Array<T>
  count: number
  next: string
  previous: string
}

export interface PaginationOption {
  search_text?: string
  profile_ids?: Array<number>
  offset: number
  limit: number
}

export interface LimitedPaginationOption {
  offset: number
  limit: number
}

export interface LifePathPaginationOption extends LimitedPaginationOption {
  profile_id?: number
}

export interface AlbumImagePaginationOption {
  sort_by?: string
  offset: number
  limit: number
}

export interface UserImagePaginationOption {
  sort_by?: string
  sort_direction?: number
  offset: number
  limit: number
}

export interface UserFriendPaginationOption {
  search_text?: string
  offset: number
  limit: number
}

export interface UserFriendsPaginationOption {
  search_text?: string
  offset: number
  limit: number
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface BasePagination<T> {
  count: number;
  next: string | null;
  previous: string | null;
  result: Array<T>;
}

export interface UserReportAbusesParams {
  abuse_id: number
}

export interface BasePaginationOption {
  search_text?: string
  sort_by?: string
  offset?: number
  limit?: number
}

export interface AbusePaginationOption extends BasePaginationOption {
  filter_by_status?: UserReportAbusesStatus
}

export interface UserReportAbuses {
  id: number;
  profile_id: number;
  reported_dt?: Date;
  reason?: string;
  notes: string;
  status?: UserReportAbusesStatus;
  reported_by: number;
  reported_by_user: User;
  reported_profile: User;
}
export interface UserReportRequest {
  notes: string;
}
export enum UserReportAbusesStatus {
  pending = "pending",
  resolved = "resolved"
}

export enum UserRoles {
  admin = "admin"
}

export interface UserReportAbusesPostRequest {
  content: string
  title?: string
  group_id?: number
}

export interface ErrorSdk {
  detail: string
}

export interface User {
  id: number
  full_name: string
  image_profile: string
  image_background: string
  is_online: boolean;
  status: string
  tags: Array<Tag>
}

export interface RegistrationStatus {
  reason_code: string
  status?: string,
  message: string
}

export interface RegisterRequest {
  first_name: string
  last_name: string
  email: string
  t_and_c_approved: boolean,
  privacy_approved: boolean,
  newsletter_subscribe: boolean
}

export interface CommunityMapRequest {
  longitude?: number
  latitude?: number
  radius?: number
  online_only?: boolean
  offset?: number
  limit?: number
}

export interface ScheduleDisablingUserRequest {
  schedule_dt: number
}
