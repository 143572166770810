import {
  ArtikelPaginationOption,
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import { Options } from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: ArtikelPaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`;


  if (paginationOption.search_text) {
    query += `&search_text=${paginationOption.search_text}`;
  }

  if (paginationOption.sort_by) {
    query += `&sort_by=${paginationOption.sort_by}`;
  }

  if (paginationOption.sort_direction) {
    query += `&sort_direction=${paginationOption.sort_direction}`;
  }

  if (Number.isFinite(paginationOption.reading_duration)) {
    query += `&reading_duration=${paginationOption.reading_duration}`;
  }

  return query
}
export default class UserContentService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAll(paginationOption: ArtikelPaginationOption) {
    const query = getPaginationQuery(paginationOption);

    return request('GET', `/me/articles/favorites/${query}`, this.options);
  }

  async createView(article_id: number) {
    return request('POST', `/me/articles/${article_id}/viewed`, this.options);
  }

  async createFavorite(article_id: number) {
    return request('POST', `/me/articles/${article_id}/favorites`, this.options);
  }

  async unFavorite(article_id: number) {
    return request('DELETE', `/me/articles/${article_id}/un-favorite`, this.options);
  }

}
