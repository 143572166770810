import { AxiosRequestConfig } from 'axios'

export interface Expert {
  id: number
  nb_likes: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  first_name: string
  last_name: string
  description: string
  role?: string
  active: boolean
  tag_ids: Array<number>
  tags: Array<Tag>
  children_image?: ImageInterface
  current_image?: ImageInterface
  attachments: Array<AttachmentOrReference>
  links: Array<AttachmentOrReference>
  is_favorite_by_user: false
  user_profile_id: number
}

export interface Tag {
  id: number
  value: string
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface ExpertCreateRequest {
  first_name: string
  last_name: string
  description?: string
  role?: string
  children_image?: ImageInterface
  current_image?: ImageInterface
  active?: boolean
  tag_ids?: Array<number>
}

export interface ExpertUpdateRequest extends ExpertCreateRequest {
  expert_id: number
}

export interface AddImageToExpert {
  expert_id: number
  image: Blob
}

export interface WithUserIdExpertId {
  expert_id: number
}

export interface ExpertGetAllRequest {
  active_only?: boolean
  offset?: number
  limit?: number
  search_text?: string
  filter_by_role?: string
  sort_by?: string
  sort_direction?: number
}

export interface ProfileExpertGetAllRequest extends ExpertGetAllRequest {
  profile_id?: number
}

export interface Pagination<T> {
  count: number
  next: string | null
  previous: string | null
  result: Array<T>
  tags: Array<Tag>
}

export interface ImageInterface {
  id: number
  nb_likes?: number
  created_at?: Date
  created_by: number
  updated_at: Date
  updated_by: number
  user_id: number
  album_id?: number
  path: string
  description?: string
  owner_id?: number
  tagged_user_ids?: Array<ImageTagInterface>
  cropped_data?: CroppedDataInterface
  like_by_user_ids?: Array<number>
}

export interface ImageTagInterface {
  x: number
  y: number
  user_id: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area?: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area?: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

// SDK OPTIONS
export interface Options extends AxiosRequestConfig {
  version?: string
  token?: string
  baseURL?: string
  timeout?: number
  socketEndpoint?: string
  userId?: string
  getNewToken?: Function
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface ErrorSdk {
  detail: string
}

export interface AttachmentCreateRequest {
  expert_id: number
  title?: string
  attachment: Blob
}

export interface AttachmentRemoveRequest {
  expert_id: number
  attachment_id: number
}

export interface ReferenceCreateRequest {
  expert_id: number
  title: string
  external_url: string
}

export interface ReferenceRemoveRequest {
  expert_id: number
  reference_id: number
}


export interface AttachmentOrReference {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  type: AttachementOrReferenceEnum
  title: string
  icon: string
  url: string
}

export enum RoleName {
  expert = 'expert',
  guest = 'guest',
  other = 'other'
}

export enum SortBy {
  created_at = 'created_at',
  name = 'name',
}

export enum AttachementOrReferenceEnum {
  attachment = 'attachment',
  external_link = 'external_link'
}