import { AxiosRequestConfig } from 'axios'

export interface VideoChapter {
  position: number
  title: string
}

export interface Tag {
  id: number
  value: string
  emoticon: string
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface UserContentRequest {
  course_id: number
}

export interface ImageRequest {
  image: Blob
}

export interface PaginationOption {
  published?: boolean
  offset?: number
  limit?: number
  search_text?: string
  sort_direction?: number
  sort_by?: string
}


export interface Course {
  id: number
  clicks: number
  nb_likes: number
  nb_loves: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  title: string
  published: true
  published_dt: Date
  description: string
  short_description: string
  related_description: string
  tag_ids: Array<number>
  tags: Array<Tag>
  image: any
  image_large: any
  sort_index: number
  rubric_ids?: Array<number>
  author_ids?: Array<number>
  authors: Array<Author>
  episodes: Array<Video>
  video_duration: number
  is_liked_by_user: boolean
  is_favorite_by_user: boolean
  nb_viewed_episodes: number
  is_completed: boolean
  next_episode_id: number
}
export interface VideoReference {
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  id: number
  title: string
  type: string
  url: string
  video_id: number
}

export interface VideoReferenceRequest {
  title: string
  external_url: string
}
export interface Video {
  id: number
  clicks: number
  nb_likes: number
  nb_loves: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  title: string
  image: any
  sort_index: number
  published: false
  published_dt: Date
  video_url: string
  video_duration: number
  description: string
  short_description: string
  related_description: string
  tag_ids: Array<number>
  tags: Array<Tag>
  course_id: number
  owner_id: number
  author_ids?: Array<number>
  attachments: Array<VideoReference>
  references: Array<VideoReference>
  is_liked_by_user: boolean
  is_favorite_by_user: boolean
  is_viewed_by_user: boolean
  video_watched_position: number
  chapters?: Array<VideoChapter>
  other_watching_members?: Array<LimitedUserProfile>
  authors: Array<Author>
}

export interface Author {
  id: number
  full_name: string
  role: string
  children_image: ImageLimited | null
  current_image: ImageLimited | null
}

export interface ImageLimited {
  path: string
  cropped_data?: CroppedDataInterface
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area?: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

export interface LimitedUserProfile {
  id: number,
  full_name: string,
  image_profile?: ImageLimited,
  image_background?: ImageLimited,
  status?: string,
  motto?: string,
  tags?: Array<string>,
  is_online?: boolean
  online_visible?: boolean
  attendee_visible?: boolean
}
export interface CourseCreateRequest {
  title: string
  published: boolean
  published_dt?: Date
  description?: string
  short_description?: string
  related_description?: string
  tag_ids?: Array<number>
  image?: any
  image_large?: any
  sort_index?: number
  rubric_ids?: Array<number>
  author_ids?: Array<number>
}

export interface VideoCreateRequest {
  course_id: number
  title: string
  image: any
  sort_index: number
  published: false
  published_dt: Date
  video_url: string
  video_duration: number
  description: string
  short_description: string
  related_description: string
  tag_ids?: Array<number>
  chapters?: Array<VideoChapter>
}

export interface CourseUpdateRequest {
  course_id: number
  title: string
  published: boolean
  published_dt?: Date
  description?: string
  short_description?: string
  related_description?: string
  tag_ids?: Array<number>
  image?: any
  image_large?: any
  sort_index?: number
  rubric_ids?: Array<number>
  author_ids?: Array<number>
}

export interface VideoUpdateRequest {
  course_id: number
  video_id: number
  title?: string
  published?: boolean
  published_dt?: Date
  description?: string
  short_description?: string
  related_description?: string
  tag_ids?: Array<number>
  image?: Image
  image_large?: Image
  sort_index?: number
  rubric_ids?: Array<number>
  author_ids?: Array<number>
}

export interface WithUserIdCourseId {
  course_id: number
}

export interface WithUserIdCourseIdVideoId {
  course_id: number
  video_id: number
}

export interface RemoveImageOnCourse {
  course_id: number
  field: string
}

export interface RemoveImageOnVideo {
  course_id: number
  video_id: number
}

export interface AttachmentCreateRequest {
  course_id: number
  video_id: number
  title?: string
  attachment: Blob
}

export interface AttachmentRemoveRequest {
  course_id: number
  video_id: number
  attachment_id: number
}

export interface ReferenceCreateRequest {
  course_id: number
  video_id: number
  title: string
  external_url: string
}

export interface ReferenceRemoveRequest {
  course_id: number
  video_id: number
  reference_id: number
}

export interface CourseVideoId {
  course_id: number
  video_id: number
}

export interface GetCourseByIdRequest {
  course_id: number
  published_only?: boolean
}


// SDK OPTIONS
export interface Options extends AxiosRequestConfig {
  version?: string
  token?: string
  baseURL?: string
  timeout?: number
  socketEndpoint?: string
  userId?: string
  getNewToken?: Function
}

export interface CourseGetAllRequest {
  published_only?: boolean
  offset?: number
  limit?: number
  tags?: Array<number>
  search_text?: string
  expert_ids?: Array<number>
  rubric_ids?: Array<number>
}

export interface VideoGetAllRequest {
  published?: boolean
  search_text?: string
  sort_by?: sortEnum
  sort_direction?: sortDirectionEnum
  offset?: number
  limit?: number
}

export enum sortEnum {
  title = 'title',
  published_dt = 'published_dt',
  clicks = 'clicks',
}

export enum sortDirectionEnum {
  ASC = 1,
  DESC = -1,
}

export interface Pagination<T> {
  count: number
  next: string | null
  previous: string | null
  result: Array<T>
  tags: Array<Tag>
}

export interface PaginationVideo {
  count: number
  next: string | null
  previous: string | null
  result: Array<Video>
}

export interface VideoPagination {
  results: any
  tags: Array<Tag>
}

export interface VideoPaginationOption {
  tags?: Array<number>
}

export interface AddImageToCourse {
  course_id: number
  field: ImageField
  image: Blob
}

export interface AddImageToVideo {
  course_id: number
  video_id: number
  image: Blob
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface ModuleConfig {
  id: number
  default_tags: Array<Tag>
  default_tag_ids: Array<number>
  highlight_course_ids: Array<number>
  highlight_course_objects: Array<Course>
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface ModuleConfigRequest {
  highlight_course_ids: Array<number>
  default_tag_ids: Array<number>
}

export interface GetUserFavoritesCourses {
  started_courses: Array<Course>
  not_yet_started_courses: Array<Course>
  completed_courses: Array<Course>
  recommendations: Array<Course>
}


export interface ErrorSdk {
  detail: string
}

export enum ImageField {
  image = 'image',
  image_large = 'image_large'
}

export interface VideoDurationByUserRequest {
  course_id: number
  video_id: number
  duration: number
}

export interface Image {
  id: number
  nb_likes: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  owner_id: number
  path: string
  album_id: number | null
  description: string | null
  tagged_user_ids: Array<ImageTaggedUserIds>
  cropped_data: CroppedData | null
  like_by_user_ids: Array<number>
}

export interface ImageTaggedUserIds extends X_Y {
  user_id: number
}
export interface X_Y {
  x: number
  y: number
}

export interface WidthHeightWithXY extends X_Y {
  width: number
  height: number
}

export interface CroppedData {
  crop: X_Y
  zoom: number
  cropped_area: WidthHeightWithXY
  cropped_area_pixels: WidthHeightWithXY
  rotation: number
}

export interface RubrikRequest {
  title: string
  published?: boolean
  published_dt?: Date
  sort_index?: number
  short_description?: string
  tag_ids?: Array<number>
}

export interface Rubrik {
  id: number
  title: string
  published?: boolean
  published_dt?: Date
  short_description?: string
  sort_index?: number
  tag_ids: Array<number>
  tags: Array<Tag>
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
}