import { AxiosRequestConfig } from 'axios'
import { LimitedUserProfile } from '../../user-v1/utils/DataSchemas';

export interface CommunityGroup {
  id: number
  name: string
  description: string
  tags: Array<Tag>
  tag_ids: Array<number>
  private: boolean
  published: boolean
  published_dt: Date | null
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  background_img: ImageInformation | null
  image: ImageInformation | null
  members: LimitedUserProfile[]
  is_member: boolean
  is_pending_request: boolean
  is_notification_on: boolean
  nb_members: number
}

export interface Tag {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  value: string
  emoticon: string | null
}

export interface CommunityPost {
  id: number;
  nb_likes: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  nb_loves: number
  created_at: Date;
  updated_at: Date;
  created_by: number;
  updated_by: number;
  group_id: null | number;
  title: string;
  content: string;
  status: PostStatus;
  images: Array<ImageInformation> | null;
  user_ids: Array<number>;
  is_liked_by_user: boolean;
  like_by_users: Array<User>;
  created_by_user: User;
  group: CommunityGroup
  is_reported_by_user: boolean
  is_notification_on: boolean
  tagged_user_ids: Array<number>
  tagged_users: Array<any>;
  is_reacted_by_user: boolean
  user_reaction: number
}

export interface CommunityPostReportAbuses {
  id: number;
  post_id: number;
  reported_dt: Date;
  reason: string;
  notes: string;
  status: ReportAbusesStatus;
  user_id: number;
  reported_by_user: LimitedUserProfile;
  reported_post: CommunityPost;
}

export interface CommunityPostReportRequest {
  notes: string;
}

export enum ReportAbusesStatus {
  pending = "pending",
  resolved = "resolved"
}

export interface CommunityGroupRequest {
  name: string
  description?: string
  tag_ids?: Array<number>
  private?: boolean
  image?: ImageInformation
  background_img?: ImageInformation
  published?: boolean
  published_dt?: Date | null
}

export interface CommunityDashboard {
  matching_members: Array<LimitedUserProfile>
  new_members: Array<LimitedUserProfile>
  matching_groups: Array<CommunityGroup>
  new_posts: Array<CommunityPost>
}

export interface BaseCommunityPostRequest {
  content: string
  title?: string
  group_id?: number
  tagged_user_ids: Array<number>
  status?: PostStatus
}

export interface CommunityReportAbusesPostRequest {
  content: string
  title?: string
  group_id?: number
  tagged_user_ids: Array<number>
}

export interface CommunityPostRequest extends BaseCommunityPostRequest {
  image: Blob
}

export interface CommunityEditPostRequest extends BaseCommunityPostRequest {
  deleted?: boolean;
  images?: ImageInformation[];
}

export interface CommunityGroupRequestToJoin {
  id: number
  group_id: number
  user_id: number
  status: RequestStatus
  request_reason: string
  join_dt: Date
  user: LimitedUserProfile
  group: CommunityGroup
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface CommunityGroupImage {
  upload: Blob
}

export interface CommunityPostImage {
  uploads: Blob[]
}

export interface CommunityGroupParams {
  group_id?: number
}


export interface CommunityGroupDeleteParams {
  group_id: number
  force_deletion?: boolean
}

export interface LikeGetAllRequest {
  post_id: number
  offset?: number
  limit?: number
}

export interface CommunityPostParams {
  post_id: number
}

export interface PostReaction extends CommunityPostParams {
  reaction: number
}

export interface CommunityReportAbusesParams {
  abuse_id: number
}

export interface CommunityGroupParamsWithIsBackground extends CommunityGroupParams {
  is_background?: boolean
}

export interface CommunityApproveRequest {
  request_id: number
}

export interface CommunityDeleteImageRequest extends CommunityGroupParams {
  image_id: number
  is_background?: boolean
}

export interface CommunityUserJoinRequest extends CommunityGroupParams {
  reason: string
}

export interface GetAllReactionResponse {
  profile: LimitedUserProfile
  reaction: number
}

export interface BasePaginationOption {
  search_text?: string
  sort_by?: string
  sort_direction?: number;
  offset?: number
  limit?: number
}

export interface PaginationGroupOption extends BasePaginationOption {
  published_only?: boolean
  tags?: Array<number>
  private?: boolean
}

export interface PaginationPostOption extends BasePaginationOption {
  my_post_only?: boolean
  sort_direction?: number
  status?: PostStatus
  group_id?: number
  profile_id?: number
}

export interface PaginationReportAbusesPostOption {
  filter_by_status?: ReportAbusesStatus
  offset?: number
  limit?: number
}

export interface BasePagination<T> {
  count: number;
  next: string | null;
  previous: string | null;
  result: Array<T>;
}

export interface Pagination extends BasePagination<CommunityGroup> {
  recommendations: Array<CommunityGroup>;
  tags: Array<Tag>;
}

export interface PostPagination extends BasePagination<CommunityPost> {
  recommendations: Array<CommunityPost>;
}

export interface GroupRequestOption {
  group_id?: number
  request_status?: string
  offset?: number
  limit?: number
}

export interface User {
  id: number,
  full_name: string,
  image_profile: ImageLimited | null,
  image_background: ImageLimited | null,
  status: string,
  motto: string,
  tags: Array<Tag>,
  is_blocked: boolean
  is_blocked_by_user: boolean
  is_online: boolean
  online_visible: boolean
  attendee_visible: boolean
  community_chat_visible: boolean
}

export interface ImageLimited {
  path: string
  cropped_data?: CroppedDataInterface
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area?: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface ImageInformation {
  id: number
  nb_likes: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  owner_id: number
  path: string
  album_id: number | null
  description: string | null
  tagged_user_ids: Array<ImageTaggedUserIds>
  cropped_data: CroppedData | null
  like_by_user_ids: Array<number>
}

export enum RequestStatus {
  pending = "pending",
  approved = "approved",
}

export enum PostStatus {
  approved = "approved",
  deleted = "deleted"
}

export interface X_Y {
  x: number
  y: number
}

export interface WidthHeightWithXY extends X_Y {
  width: number
  height: number
}

export interface ImageTaggedUserIds extends X_Y {
  user_id: number
}

export interface CroppedData {
  crop: X_Y
  zoom: number
  cropped_area: WidthHeightWithXY
  cropped_area_pixels: WidthHeightWithXY
  rotation: number
}

export interface ReportPost {
  post_id: number
  reason: string
}


// SDK OPTIONS
export interface Options extends AxiosRequestConfig {
  version?: string
  token?: string
  baseURL?: string
  timeout?: number
  socketEndpoint?: string
  userId?: string
  getNewToken?: Function
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface ErrorSdk {
  detail: string
}
