import {
  BannerGetAllRequest,
  BannerRequest,
  CreateBannerImageRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(getAllQuery: BannerGetAllRequest) {
  let query = ``

  if (typeof getAllQuery.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${getAllQuery.offset}`
  }

  if (typeof getAllQuery.limit !== 'undefined') {
    query += `${query ? '&' : '?'}limit=${getAllQuery.limit}`
  }

  if (typeof getAllQuery.search_text !== 'undefined') {
    query += `${query ? '&' : '?'}search_text=${getAllQuery.search_text}`
  }

  if (typeof getAllQuery.active !== 'undefined') {
    query += `${query ? '&' : '?'}active=${getAllQuery.active}`
  }

  return query
}

export default class BannerService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllBanners(getAllQuery: BannerGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)
    return request('GET', `/admin/banners/${query}`, this.options)
  }

  // ADMIN
  async getWeekly(getAllQuery: BannerGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/me/weekly${query}`, this.options)
  }

  async getBannerById(banner_id: number) {
    return request('GET', `/admin/banners/${banner_id}`, this.options)
  }

  async createBanner(data: BannerRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('POST', `/admin/banners/`, requestOptions)
  }

  async updateBanner(banner_id: number,data: BannerRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('PUT', `/admin/banners/${banner_id}`, requestOptions)
  }

  async deleteBanner(banner_id: number) {
    return request('DELETE', `/admin/banners/${banner_id}`, this.options)
  }

  async createBannerImage(data: CreateBannerImageRequest) {

    const formData = new FormData()
    formData.append('upload', data.upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }

    return request(
      'POST',
      `/admin/banners/${data.banner_id}/images`,
      requestOptions
    )
  }

  async deleteBannerImage(banner_id: number) {
    return request(
      'DELETE',
      `/admin/banners/${banner_id}/images`,
      this.options
    )
  }

}
