// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import AuthService from './services/auth'
import ProfileService from './services/profile'
import AdminService from './services/admin'
import AbuseService from './services/abuses'
import DashboardService from './services/dashboard'
import TagService from './services/tags'
import { Options } from '../../services.utils/request-types';
import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private authService!: AuthService
  private profileService!: ProfileService
  private adminService!: AdminService
  private abuseService!: AbuseService
  private dashboardService!: DashboardService
  private tagService!: TagService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.authService = new AuthService(this.options)
    this.profileService = new ProfileService(this.options)
    this.adminService = new AdminService(this.options)
    this.abuseService = new AbuseService(this.options)
    this.dashboardService = new DashboardService(this.options)
    this.tagService = new TagService(this.options)
  }
  /**
   * Get authentication
   */
  getAuth = (): Promise<dataSchemas.UserProfile> =>
    this.authService.getAuth()

  /**
 * Get me id
 */
  getMeId = (): Promise<number> =>
    this.authService.getMeId()

  /**
   * Update User profile
   * @param data user data
   */
  updateUser = (data: dataSchemas.UserProfile): Promise<dataSchemas.UserProfile> =>
    this.authService.updateUser(data)

  /**
   * Get Life paths
   * @param data Life paths data
   */
  getUserLifePaths = (data: dataSchemas.LimitedPaginationOption): Promise<dataSchemas.Pagination<dataSchemas.LifePathInfoInterface>> =>
    this.authService.getUserLifePaths(data)

  /**
   * Create Life path
   * @param data Life path data
   */
  createLifePath = (data: dataSchemas.LifePathRequest): Promise<dataSchemas.LifePathInfoInterface> =>
    this.authService.createLifePath(data)

  /**
   * Get Life path
   * @param profile_id Life path id
   */
  getLifePath = (profile_id: number): Promise<dataSchemas.LifePathInfoInterface> =>
    this.authService.getLifePath(profile_id)

  /**
   * Update Life paths
   * @param profile_id profile id
   * @param data user data
   */
  updateLifePath = (data: dataSchemas.LifePathInfoInterface): Promise<dataSchemas.LifePathInfoInterface> =>
    this.authService.updateLifePath(data)

  /**
   * Delete Life path
   * @param lifepath_id
   */
  deleteLifePath = (lifepath_id: number) =>
    this.authService.deleteLifePath(lifepath_id)

  /**
   * Add Life path Image
   * @param data user data
   */
  addLifePathImage = (data: dataSchemas.LifePathImageRequest): Promise<dataSchemas.LifePathInfoInterface> =>
    this.authService.addLifePathImage(data)

  /**
   * Delete Life path Image
   * @param data user data
   */
  deleteLifePathImage = (data: dataSchemas.LifePathImageDeleteRequest) =>
    this.authService.deleteLifePathImage(data)

  /**
   * Get all albums
   * @param data album pagination data
   */
  getAllAlbums = (data: dataSchemas.LimitedPaginationOption): Promise<dataSchemas.Pagination<dataSchemas.Album>> =>
    this.authService.getAllAlbums(data)

  /**
   * Get all user album
   * @param album_id album id
   */
  getAlbum = (album_id: number): Promise<dataSchemas.Album> =>
    this.authService.getAlbum(album_id)

  /**
   * Update user album
   * @param album_id album id
   * @param data album data
   */
  updateAlbum = (album_id: number, album: dataSchemas.Album): Promise<dataSchemas.Album> =>
    this.authService.updateAlbum(album_id, album)

  /**
   * Delete album
   * @param album_id album id
   */
  deleteAlbum = (album_id: number) =>
    this.authService.deleteAlbum(album_id)

  /**
   * Create album
   * @param data album data
   */
  createAlbum = (data: dataSchemas.AlbumCreateRequest): Promise<dataSchemas.Album> =>
    this.authService.createAlbum(data)

  /**
   * Get albums images
   * @param album_id album id
   * @param data pagination option
   */
  getAllAlbumsImages = (album_id: number, data: dataSchemas.AlbumImagePaginationOption): Promise<dataSchemas.Pagination<dataSchemas.ImageInterface>> =>
    this.authService.getAllAlbumsImages(album_id, data)

  /**
   * Get all user images
   * @param data pagination option
   */
  getAllUserImages = (data: dataSchemas.UserImagePaginationOption): Promise<dataSchemas.Pagination<dataSchemas.ImageInterface>> =>
    this.authService.getAllUserImages(data)

  /**
   * Get all user images
   * @param data pagination option
   * @param user_id user Id
   */
  getAllProfileImages = (data: dataSchemas.UserImagePaginationOption, user_id: number): Promise<dataSchemas.Pagination<dataSchemas.ImageInterface>> =>
    this.profileService.getAllProfileImages(data, user_id)

  /**
   * Get all profile albums
   * @param data pagination option
   * @param user_id user Id
   */
  getAllProfileAlbums = (data: dataSchemas.UserImagePaginationOption, user_id: number): Promise<dataSchemas.Pagination<dataSchemas.Album>> =>
    this.profileService.getAllProfileAlbums(data, user_id)

  /**
 * Get albums
 * @param album_id
 * @param user_id
 */
  getProfileAlbum = (user_id: number, album_id: number): Promise<dataSchemas.Album> =>
    this.profileService.getProfileAlbum(user_id, album_id)

  /**
   * Get all profile albums
   * @param data pagination option
   * @param user_id user Id
   */
  getAllProfileAlbumsImages = (data: dataSchemas.UserImagePaginationOption, user_id: number, album_id: number): Promise<dataSchemas.Pagination<dataSchemas.ImageInterface>> =>
    this.profileService.getAllProfileAlbumsImages(data, user_id, album_id)

  /**
   * Create image
   * @param data image data
   */
  createImage = (data: dataSchemas.AlbumImageCreateRequest): Promise<dataSchemas.ImageInterface> =>
    this.authService.createImage(data)

  /**
   * Update user image
   * @param image_id image id
   * @param data image data
   */
  updateImage = (image_id: number, data: dataSchemas.AlbumImageUpdateRequest): Promise<dataSchemas.ImageInterface> =>
    this.authService.updateImage(image_id, data)

  /**
   * Update user image
   * @param image_id image id
   */
  getImage = (image_id: number): Promise<dataSchemas.ImageInterface> =>
    this.authService.getImage(image_id)

  /**
   * Delete image
   * @param image_id image id
   */
  deleteImage = (image_id: number) =>
    this.authService.deleteImage(image_id)

  /**
   * Get all friends
   * @param data friend pagination data
   */
  getAllFriends = (data: dataSchemas.UserFriendPaginationOption): Promise<dataSchemas.Pagination<dataSchemas.LimitedUserProfile>> =>
    this.authService.getAllFriends(data)

  /**
   * Create friend request
   * @param data friend request data
   */
  createFriendRequest = (data: dataSchemas.FriendRequest): Promise<dataSchemas.FriendRequestInterface> =>
    this.authService.createFriendRequest(data)


  /**
 * Get All freiends request
 */
  getFriendRequest = (request_status?: string) =>
    this.authService.getFriendsRequest(request_status)


  /**
*Delete a friend request
*/

  deleteFriendRequest = (request_id: number) =>
    this.authService.deleteFriendRequest(request_id)

  /**
* Block an user
*/

  blockUser = (profile_id: number) =>
    this.authService.blockUser(profile_id)

  /**
* Unblock an user
*/

  unBlockUser = (profile_id: number) =>
    this.authService.unBlockUser(profile_id)

  /**
* report an user
*/

  reportUser = (data: dataSchemas.ReportUserRequest) =>
    this.authService.reportUser(data)

  /**
  * cancel abuse
  */

  cancelAbuse = (profile_id: number) =>
    this.authService.cancelAbuse(profile_id)

  /**
   * Update friends request
   * @param data friend pagination data
   */
  updateFriendRequest = (data: dataSchemas.FriendRequestUpdate) =>
    this.authService.updateFriendRequest(data)

  /**
   * Get all users
   */
  getAllUsers = (
    paginationOption: dataSchemas.UserGetAllRequest = { offset: 0, limit: 100 }
  ): Promise<dataSchemas.Pagination<dataSchemas.LimitedUserProfile>> =>
    this.profileService.getAllUsers({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get detailed profile
   * @param profile_id profile id
   */
  getDetailedProfile = (profile_id: number): Promise<dataSchemas.UserProfile> =>
    this.profileService.getDetailedProfile(profile_id)

  /**
   * Get limited profile
   * @param profile_id profile id
   */
  getLimitedProfile = (profile_id: number): Promise<dataSchemas.LimitedUserProfile> =>
    this.profileService.getLimitedProfile(profile_id)

  /**
   * Get all user friends
   * @param data friend request data
   */
  getAllUserFriends = (profile_id: number, paginationOption: dataSchemas.UserFriendPaginationOption): Promise<dataSchemas.Pagination<dataSchemas.LimitedUserProfile>> =>
    this.profileService.getAllUserFriends(profile_id, {
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Create user profile
   * @param logged_user_id admin id

   createUserProfile = (logged_user_id: number): Promise<dataSchemas.UserProfile> =>
   this.authService.createUserProfile(logged_user_id)
   */

  /**
   * Get profilelife paths
   * @param data life path request data
   */
  getProfileLifePath = (data: dataSchemas.LifePathPaginationOption = { offset: 0, limit: 20 }): Promise<dataSchemas.Pagination<dataSchemas.LifePathInfoInterface>> =>
    this.profileService.getAllProfileLifePaths(data)

  /**
 * Like profile image
 */
  likeProfileImage = (profile_id: number, image_id: number): Promise<string> =>
    this.profileService.likeProfileImage(profile_id, image_id)

  /**
   * UnLike profile image
   */
  unlikeProfileImage = (profile_id: number, image_id: number): Promise<string> =>
    this.profileService.unlikeProfileImage(profile_id, image_id)

  /**
   * like user image
   * @param image_id image id
   */
  likeImage = (image_id: number): Promise<string> =>
    this.authService.likeImage(image_id)
  /**
   * unlike user image
   * @param image_id image id
   */
  unlikeImage = (image_id: number): Promise<string> =>
    this.authService.unlikeImage(image_id)

  /**
   * Get all report_abuses
   */
  getAllReportAbuses = (
    getAllQuery: dataSchemas.AbusePaginationOption = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.BasePagination<dataSchemas.UserReportAbuses>> => this.abuseService.getAllReportAbuses(getAllQuery)

  /**
   * get a report_abuses by id
   */
  getByIdReportAbuses = (
    data: dataSchemas.UserReportAbusesParams
  ): Promise<dataSchemas.UserReportAbuses> => this.abuseService.getByIdReportAbuses(data)

  /**
   * edit a post
   * @param abuse_id
   */
  editReportAbuses = (
    abuse_id: number,
    body: dataSchemas.UserReportRequest
  ): Promise<dataSchemas.UserReportAbuses> => this.abuseService.editReportAbuses(abuse_id, body)

  /**
   * get all members birthday
   */
  getAllMembersBirthday = (
    paginationOption: dataSchemas.PaginationOption = { offset: 0, limit: 20 }
  ): Promise<dataSchemas.Pagination<dataSchemas.LimitedUserProfile>> => this.dashboardService.getAllMembersBirthday({
    ...paginationOption,
    search_text: escapedString(paginationOption?.search_text)
  })


  /**
   * get all default tags
   */
  getAllDefaultTags = (): Promise<Array<dataSchemas.Tag>> => this.tagService.getAllTags()

  /**
   * get registration status
   */
  getMembershipStatus = (): Promise<dataSchemas.RegistrationStatus> => this.authService.getMembershipStatus()

  /**
* Get community map users
*/
  getCommunityMap = (
    getAllQuery: dataSchemas.CommunityMapRequest = {
      radius: 0,
      online_only: false,
      offset: 0,
      limit: 500
    }
  ): Promise<dataSchemas.Pagination<dataSchemas.UserProfile>> => this.dashboardService.getCommunityMap(getAllQuery)

  /**
  * Get broadcast
  */
  getBroadcast = (
    getAllQuery: dataSchemas.CommunityMapRequest = {
      offset: 0,
      limit: 25
    }
  ): Promise<dataSchemas.Pagination<dataSchemas.LimitedUserProfile>> => this.dashboardService.getBroadcast(getAllQuery)

  /**
 * Create a profile on admin
 */
  createProfileOnAmin = (
    body: dataSchemas.UserCreateRequest
  ): Promise<dataSchemas.UserProfile> => this.adminService.createProfileOnAmin(body)


  /**
 * Update a profile on admin
 */

  updateProfileByIdOnAmin = (
    profile_id: number,
    data: dataSchemas.UserUpdateRequest
  ): Promise<dataSchemas.UserProfile> => this.adminService.updateProfileByIdOnAmin(profile_id, data)


  /**
 * reset a profile password on admin
 */

  resetPasswordOnAmin = (
    profile_id: number,
  ): Promise<void> => this.adminService.resetPasswordOnAmin(profile_id)

  /**
   * Get all users profile on admin
   */
  getAllUsersOnAmin = (
    paginationOption: dataSchemas.UserGetAllRequest = { offset: 0, limit: 20 }
  ): Promise<dataSchemas.Pagination<dataSchemas.LimitedUserProfile>> =>
    this.adminService.getAllUsersOnAmin({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Change user email on admin
   */
  changeUserEmail = (
    profil_id: number,
    data: dataSchemas.ChangeEmailRequest
  ): Promise<dataSchemas.Pagination<dataSchemas.LimitedUserProfile>> =>
    this.adminService.ChangeUserEmail(profil_id, data)

  /**
   * Get detailed profile on damin
   * @param profile_id profile id
   */
  getDetailedProfileOnAmin = (profile_id: number): Promise<dataSchemas.UserProfile> =>
    this.adminService.getDetailedProfileOnAmin(profile_id)

  clearCaches = (): Promise<string> =>
    this.adminService.clearCaches()

  cancelSubscription = (): Promise<string> =>
    this.authService.cancelSubscription()

  /**
   * Schedule desactivating user
   */
  scheduleDisablingUser = (
    profil_id: number,
    data: dataSchemas.ScheduleDisablingUserRequest
  ): Promise<dataSchemas.Pagination<dataSchemas.LimitedUserProfile>> =>
    this.adminService.scheduleDisablingUser(profil_id, data)
}

export * as types from './utils/DataSchemas'
