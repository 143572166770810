import {
   AbuseRequest,
   GetAllAbuseWithOptions,
   ResolveAbuseRequest,
   } from '../utils/DataSchemas';
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class AbuseService {
  options: Options;
  constructor(options: Options) {
    this.options = options;
  }

  async getAllAbuse(options: GetAllAbuseWithOptions) {
    let query = ''

    if (typeof options?.limit !== 'undefined') {
      query += `?limit=${options.limit}`
    }

    if (typeof options?.offset !== 'undefined') {
      query += `${query ? '&' : '?'}offset=${options.offset}`
    }

    if (typeof options?.status !== 'undefined') {
      query += `${query ? '&' : '?'}status=${options.status}`
    }
    
    return request(
      'GET',
      `/${this.options.version}/report-abuses${query}`,
      this.options
    );
  }

  async getAbuseById(id: String) {
    return request(
      'GET',
      `/${this.options.version}/report-abuses/${id}`,
      this.options
    );
  }

  async createAbuse(data: AbuseRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    };

    return request('POST', `/${this.options.version}/report-abuses`, requestOptions);
  }

  async resolveAbuse({id, ...data}: ResolveAbuseRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    };

    return request('PUT', `/${this.options.version}/report-abuses/${id}/resolve`, requestOptions);
  }

  async deleteAbuse(id: String) {
    return request(
      'DELETE',
      `/${this.options.version}/report-abuses/${id}/cancel-abuse`,
      this.options
    );
  }
}
