import {
  CommentGetAllRequest,
  CommentById,
  CommentCreateRequest,
  CommentUpdateRequest,
  CommentReportAbusesParams,
  PaginationReportAbusesOption,
  ReportComment,
  StatusInterface,
  CommentPostReportRequest,
  CommentCreateImageRequest,
  CommentDeleteImage,
  LikeGetAllRequest,
  PostReaction
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(getAllQuery: CommentGetAllRequest) {
  let query = `?owner_domain=${getAllQuery.owner_domain}&owner_object=${getAllQuery.owner_object}&owner_id=${getAllQuery.owner_id}`

  if (typeof getAllQuery.offset !== 'undefined') {
    query += `&offset=${getAllQuery.offset}`
  }

  if (typeof getAllQuery.limit !== 'undefined') {
    query += `&limit=${getAllQuery.limit}`
  }

  if (typeof getAllQuery.status !== 'undefined') {
    query += `&status=${getAllQuery.status}`
  }

  if (typeof getAllQuery.parent_id !== 'undefined') {
    query += `&parent_id=${getAllQuery.parent_id}`
  }


  if (typeof getAllQuery.sort_by !== 'undefined') {
    query += `&sort_by=${getAllQuery.sort_by}`
  }

  return query
}

export default class CommentService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAll(getAllQuery: CommentGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/comments/${query}`, this.options)
  }

  async getById({ comment_id }: CommentById) {
    return request('GET', `/comments/${comment_id}`, this.options)
  }

  async createComment(data: CommentCreateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/comments/`, requestOptions)
  }

  async createCommentImage({ comment_id, uploads }: CommentCreateImageRequest) {
    const formData = new FormData()

    uploads.forEach((upload) => {
      formData.append('uploads', upload)
    })

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }

    return request('POST', `/comments/${comment_id}/images`, requestOptions)
  }

  async editComment({ comment_id }: CommentById, data: CommentUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/comments/${comment_id}`, requestOptions)
  }

  async deleteComment({ comment_id }: CommentById) {
    return request('DELETE', `/comments/${comment_id}`, this.options)
  }

  async deleteCommentImage({ comment_id, image_id }: CommentDeleteImage) {
    return request('DELETE', `/comments/${comment_id}/images/${image_id}`, this.options)
  }

  async getStatus(data: StatusInterface) {
    let query = `?owner_domain=${data.owner_domain}&owner_object=${data.owner_object}&owner_id=${data.owner_id}`

    return request('GET', `/stats/comments/${query}`, this.options)
  }

  getReportAbusesPaginationQuery(paginationOption: PaginationReportAbusesOption) {
    let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

    if (paginationOption.filter_by_status !== undefined) {
      query += `&filter_by_status=${paginationOption.filter_by_status}`
    }

    return query
  }
  // GET ALL
  async getAllReportAbuses(getAllQuery: PaginationReportAbusesOption) {
    const query = this.getReportAbusesPaginationQuery(getAllQuery)
    return request('GET', `/admin/comments/report-abuses${query}`, this.options)
  }


  // GET BY ID
  async getByIdReportAbuses({ abuse_id }: CommentReportAbusesParams) {
    return request('GET', `/admin/comments/report-abuses/${abuse_id}`, this.options)
  }
  // UPDATE A Report Abuses
  async editReportAbuses(
    abuse_id: number,
    body: CommentPostReportRequest
  ) {
    const requestOptions: Options = {
      ...this.options,
      data: body
    }
    return request('PUT', `/admin/comments/report-abuses/${abuse_id}/resolve/`, requestOptions)
  }

  // REPORT ABUSE
  async reportAbuse(data: ReportComment) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/me/comments/report-abuse`, requestOptions)
  }

  //CANCEL REPORT
  async cancelReport(comments: number) {
    return request('DELETE', `/me/comments/${comments}/cancel-abuse`, this.options)
  }

  async postReaction({ comment_id, reaction }: PostReaction) {
    return request('POST', `/comments/${comment_id}/reacts/${reaction}`, this.options)
  }

  async deleteReaction({ comment_id }: CommentById) {
    return request('DELETE', `/comments/${comment_id}/un-react`, this.options)
  }
  async getReactionsByComment(getAllQuery: LikeGetAllRequest) {
    let query = ''

    if (typeof getAllQuery.offset !== 'undefined') {
      query += `&offset=${getAllQuery.offset}`
    }

    if (typeof getAllQuery.limit !== 'undefined') {
      query += `&limit=${getAllQuery.limit}`
    }
    return request('GET', `/comments/${getAllQuery?.comment_id}/reactions?${query}`, this.options)
  }
}
