enum RubricActionTypeEnum {
  Get = "GET_CONFIG_MAGAZINE",
  Update = "UPDATE_CONFIG_MAGAZINE",
  AddHighlighted = "ADD_CONFIG_HIGHLIGHTED_ARTICLE_MAGAZINE",
  DeleteHighlighted = "DELETE_CONFIG_HIGHLIGHTED_ARTICLE_MAGAZINE",
  AddSuggested = "ADD_CONFIG_SUGGESTED_ARTICLE_MAGAZINE",
  DeleteSuggested = "DELETE_CONFIG_SUGGESTED_ARTICLE_MAGAZINE",
  AddMostPopular = "ADD_CONFIG_MOST_POPULAR_ARTICLE_MAGAZINE",
  DeleteMostPopular = "DELETE_CONFIG_MOST_POPULAR_ARTICLE_MAGAZINE",
  AddTag = "ADD_CONFIG_TAG_MAGAZINE",
  DeleteTag = "DELETE_CONFIG_TAG_MAGAZINE",
  AddAuthor = "ADD_CONFIG_AUTHOR_MAGAZINE",
  ListAuthors = "LIST_CONFIG_AUTHOR_MAGAZINE"
}

export default RubricActionTypeEnum;