
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'
import { Options } from '../../services.utils/request-types';
import SupportService from './services/supportService'
const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  public options: Options
  private supportService!: SupportService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.supportService = new SupportService(this.options)
  }

 /**
   * Get all categories
   */
 getAllCategories = (
  paginationOption: dataSchemas.PaginationOption = { offset: 0, limit: 20 }
): Promise<dataSchemas.Pagination<dataSchemas.Category>> =>
  this.supportService.getAllCategories(paginationOption)

/**
 * Get all faqs by category id
 */
getAllFaqsByCategory = (
categoryId: number,
paginationOption: dataSchemas.PaginationOption = { offset: 0, limit: 20 }
): Promise<dataSchemas.Pagination<dataSchemas.Faq>> =>
this.supportService.getAllFaqsByCategory(categoryId, paginationOption)


/**
 * Getfaq by id
 */
getFaqById = (faqId: number): Promise<dataSchemas.Faq> =>
  this.supportService.getFaqById(faqId)

/**
 * Post faq
 * @param body Body of faq
 */
createFaq = (
  body: dataSchemas.FaqRequest
): Promise<dataSchemas.Faq> => this.supportService.createFaq(body)


 /**
 * Post category
 * @param body Body of category
 */
 createCategory = (
  body: dataSchemas.CategoryRequest
): Promise<dataSchemas.Category> => this.supportService.createCategory(body)

/**
 * Update faq
 * @param faqId
 * @param body Body of faq
 */
updateFaq = (
  faqId: number,
  body: dataSchemas.FaqRequest
): Promise<dataSchemas.Faq> =>
  this.supportService.updateFaq(faqId, body)


  /**
 * Update category
 * @param categoryId
 * @param body Body of category
 */
updateCategory = (
  categoryId: number,
  body: dataSchemas.CategoryRequest
): Promise<dataSchemas.Category> =>
  this.supportService.updateCategory(categoryId, body)

/**
* Delete faq
* @param faqId
*/
deleteFaq = async (faqId: number) => {
  const faq = await this.getFaqById(faqId)
  return this.supportService
    .deleteFaq(faq.id)
}


contactUs = async (body: dataSchemas.ContactFormRequest) : Promise<void> => this.supportService.contactUs(body)
}

export * as types from './utils/DataSchemas'
