import {
  GoodNewsActionTypeEnum,
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";

const initialState = {
  DailyMails: [],
  DailyMailsTotal: null,
  DefaultDailyMails: null,
  DailyMessages: [],
  DailyMessagesTotal: null,
  DefaultDailyMessages: null,
  BlockModeDailyMailsLoading: false,
  DefaultCategory: null,
  DefaultMessage: null,
  DailyNews: [],
  DailyNewsTotal: null,
  DefaultDailyNews: null,
  BlockModeDailyNewsLoading: false,
};


const DailyMailsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Daily News reducers
    case GoodNewsActionTypeEnum.ListDailyNews:
      return {
        ...state,
        DailyNews: action.dailynews,
        DailyNewsTotal: action.total,
        BlockModeDailyNewsLoading: action.loading
      };

      case GoodNewsActionTypeEnum.GoodNewsLoading:
        return {
          ...state,
          BlockModeDailyNewsLoading: action.data,
        };
    case GoodNewsActionTypeEnum.AddDefaultDailyNews:
      return {
        ...state,
        DefaultDailyNews: action.default,
      };
    case GoodNewsActionTypeEnum.AddDailyNews:
      let newDailyNewsState = !state.DailyNews ? [] : state.DailyNews;
      newDailyNewsState.unshift(action.dailynews);
      return {
        ...state,
        DailyNews: newDailyNewsState,
        DailyNewsTotal: newDailyNewsState.length
      };
    case GoodNewsActionTypeEnum.UpdateDailyNews:
      let dailynewsState = !state.DailyNews ? [] : state.DailyNews;
      let newArrayDailyNews = updatedArray(dailynewsState, action.dailynews);
      return {
        ...state,
        DailyNews: newArrayDailyNews,
        DailyNewsTotal: newArrayDailyNews.length
      };
    case GoodNewsActionTypeEnum.DeleteDailyNews:
      let DAILYNEWS = !state.DailyNews ? [] : state.DailyNews;
      let updDailyNewsArray = arrayReduce(DAILYNEWS, action.dailynews);
      return {
        ...state,
        DailyNews: updDailyNewsArray,
        DailyNewsTotal: updDailyNewsArray.length
      };
    default:
      return state;
  }
};

export default DailyMailsReducer;
