import { GroupActionTypeEnum } from "../../helpers/enums/actionTypeEnums"

const initialState = {
  Groups: [],
  GroupsCount: 0,
  Members: [],
  MembersCount: null,
  Loading: true
};

const getObjectsArrayReturnIdsArray = (array) => {
  let tab = [];
  for (let i = 0; i < array.length; i++) {
    tab.push(array[i].id);
  }
  return tab;
};

const arrayReduce = (array, element) => {
  let tab = array;
  const index = tab.indexOf(element);
  if (index > -1) {
    tab.splice(index, 1);
  }
  return tab;
};

const CommunityReducer = (state = initialState, action) => {
  switch (action.type) {

    case GroupActionTypeEnum.List:
      return {
        ...state,
        Members: action.members,
        MembersCount: action.count,
        Loading: false
      };

    case GroupActionTypeEnum.Delete:
      let members = !state.Members ? [] : state.Members;
      let updMembersArray = arrayReduce(members, action.member);
      return {
        ...state,
        Members: updMembersArray,
      };

    case GroupActionTypeEnum.Loading:
      return {
        ...state,
        Loading: true,
      };

    case GroupActionTypeEnum.DefaultList:
      return {
        ...state,
        Members: [],
      };

    default:
      return state;
  }
};

export default CommunityReducer;
