import WishService from './services/Wish'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private wishService!: WishService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.wishService = new WishService(this.options)
  }

  /**
   * Get all wishes
   */
  getAll = (
    getAllQuery: dataSchemas.WishGetAllRequest = {
      offset: 0,
      limit: 20,
      sort_direction: -1
    }
  ): Promise<dataSchemas.Pagination> => this.wishService.getAll({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })

  /**
   * Get all my wishes
   */
  getAllMyWhishes = (
    getAllQuery: dataSchemas.WishGetAllRequest = {
      month: -1,
      year: -1,
      offset: 0,
      limit: 20,
      sort_direction: -1
    }
  ): Promise<dataSchemas.Pagination> => this.wishService.getAllMyWhishes({
    ...getAllQuery,
    search_text: escapedString(getAllQuery?.search_text)
  })

  /**
   * Get wish by id
   * @param wish_id wish id
   */
  getWishById = (
    wish_id: number
  ): Promise<dataSchemas.Wish> => this.wishService.getWishById(wish_id)

  /**
   * Create wish
   * @param body Body of wish
   */
  createWish = (
    body: dataSchemas.CreateWishRequest
  ): Promise<dataSchemas.Wish> => this.wishService.createWish(body)

  /**
   * Update wish
   * @param body Body of wish
   */
  updateWish = (
    body: dataSchemas.UpdateWishRequest
  ): Promise<dataSchemas.Wish> => this.wishService.updateWish(body)

  /**
   * Delete wish
   * @param wish_id Object with wish id
   */
  deleteWish = async (
    wish_id: number,
  ): Promise<void> => this.wishService.deleteWish(wish_id)
}

export * as types from './utils/DataSchemas'
