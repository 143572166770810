

export enum DiariesActionTypeEnum {
    List = "LIST_LIST_DIARIES",
    Loading = "LOADING",
    Saving = "SAVING",
    Add = "ADD_DIARIES",
    Update = "UPDATE_DIARIES",
    Delete = "DELETE_DIARIES",
    DailyQuoteList = "DAILY_QUOTE_LIST",
    DailyQuoteAdd = "DAILY_QUOTE_ADD",
    DailyQuoteUpdate = "DAILY_QUOTE_UPDATE",
    DailyQuoteDelete = "DAILY_QUOTE_DELETE",
    DailyQuoteLoading = "DAILY_QUOTE_LOADING"
}
