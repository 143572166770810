import request from '../../../services.utils/request'
import { Options } from '../../../services.utils/request-types'
import {
  CmsGetAllRequest,
  CreateCmsRequest,
  UpdateCmsRequest,
  ImageRequest
} from '../utils/DataSchemas'

function getPaginationQuery(getAllQuery: CmsGetAllRequest) {
  let query = ``

  if (typeof getAllQuery.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${getAllQuery.offset}`
  }

  if (typeof getAllQuery.limit !== 'undefined') {
    query += `${query ? '&' : '?'}limit=${getAllQuery.limit}`
  }

  return query
}

export default class CmsService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  // ADMIN
  async getAll(getAllQuery: CmsGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/admin/pages/${query}`, this.options)
  }

  async getCmsById(cms_id: number) {
    return request('GET', `/admin/pages/${cms_id}`, this.options)
  }

  async createCms(data: CreateCmsRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('POST', `/admin/pages/`, requestOptions)
  }

  async updateCms({ cms_id, cms }: UpdateCmsRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: cms
    }

    return request('PUT', `/admin/pages/${cms_id}`, requestOptions)
  }

  async deleteCms(cms_id: number) {
    return request('DELETE', `/admin/pages/${cms_id}`, this.options)
  }

  async createCmsImage(data: ImageRequest) {

    const formData = new FormData()
    formData.append('image', data.image)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }

    return request(
      'POST',
      `/admin/pages/${data.page_id}/images`,
      requestOptions
    )
  }

  async deleteCmsImage(page_id: number, image_id: number) {
    return request(
      'DELETE',
      `/admin/pages/${page_id}/images/${image_id}`,
      this.options
    )
  }

  // PUBLIC
  async getAllPublicPages(getAllQuery: CmsGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/public/pages/${query}`, this.options, false)
  }

  async getPublicPageById(page_id: number) {
    return request('GET', `/public/pages/${page_id}`, this.options, false)
  }

  async getPublicPageByName(internal_name: string) {
    return request('GET', `/public/pages/internal-name/${internal_name}`, this.options, false)
  }
}