import {
  CmsActionTypeEnum,
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";


const initialState = {
  Pages: [],
  PagesTotal: 0,
  DefaultPages: [],
  DataLoading: false
};


const PagesReducer = (state = initialState, action) => {
  switch (action.type) {

    case CmsActionTypeEnum.List:
      return {
        ...state,
        Pages: action.pages,
        PagesTotal: action.total,
        DataLoading: action.loading
      };

    case CmsActionTypeEnum.Filter:
      return {
        ...state,
        Pages: action.pages,
      };

    case CmsActionTypeEnum.Update:
      let pagesState = !state.Pages ? [] : state.Pages;
      let newArrayPages = updatedArray(pagesState, action.page);
      return {
        ...state,
        Pages: newArrayPages,
      };

    case CmsActionTypeEnum.Delete:
      let PAGES = !state.Pages ? [] : state.Pages;
      let updatePagesArray = arrayReduce(PAGES, action.page);
      return {
        ...state,
        Pages: updatePagesArray,
        PagesTotal: updatePagesArray.length
      };

    case CmsActionTypeEnum.Add:
      let newPagesState = !state.Pages ? [] : state.Pages;
      newPagesState.unshift(action.page);
      return {
        ...state,
        Pages: newPagesState,
        PagesTotal: newPagesState.length
      };

    case CmsActionTypeEnum.Loading:
      return {
        ...state,
        DataLoading: action.loading
      };

    default:
      return state;
  }
};

export default PagesReducer;
