import axios, {AxiosRequestConfig, Method} from 'axios'
import {Options} from './request-types'
import noResponseError from './error'

axios.defaults.withCredentials = true

const request = async (method: string, path: string, options: Options, withCredentials: boolean = true) => {
  const baseUrl = options?.baseURL?.split('/').slice(0, 3).join('/') || ""

  const requestOptions: AxiosRequestConfig = {
    headers: options.headers,
    baseURL: options.baseURL,
    timeout: options.timeout,
    method: method as Method,
    url: path,
    data: options.data,
    withCredentials: withCredentials
  }

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    async function (error) {

      try {
        if (
          error?.response?.status === 403
          //&& error.response.data?.detail === 'Token expired'
        ) {

          const originalRequest = {...error.config}
          if (originalRequest && originalRequest.url !== "/token") {

            originalRequest.baseURL = `${baseUrl}/oauth2`
            originalRequest.method = "POST"
            originalRequest.data = ""
            originalRequest.url = "/token"
             await axios(originalRequest)
            return axios(error.config)

          } else {
            console.log("refresh token has expired")
            if (options && options.signOut) {
              return await options?.signOut()
            }
          }
        }

        if (
          error?.response?.status === 401
          //&& error.response.data?.detail === 'Not authenticated'
        ){
          if (options && options.signOut) {
            return await options?.signOut()
          }
        }

        return Promise.reject(error)
      } catch (error) {
        console.log(error)
        return Promise.reject(error)
      }
    }
  )

  return axios(requestOptions).then(
    (response) => response.data,
    (err) => errorHanding(err)
  )
}

function errorHanding(err: any) {
  if (
    err.response !== null &&
    err.response !== undefined &&
    err.response.data !== null
  ) {
    return Promise.reject(err.response.data)
  }
  const errorMessages: any[] = []
  errorMessages.push(noResponseError(err))
  return Promise.reject({errors: errorMessages})
}

export default request
