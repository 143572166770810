export enum MembersActionTypeEnum {
    List = "LIST_MEMBERS",
    Filter = "FILTER_MEMBERS",
    ListInSelect = "SELECT_LIST_MEMBERS",
    Add = "ADD_MEMBER",
    Update = "UPDATE_MEMBER",
    Delete = "DELETE_MEMBER",
    AddDefaultMembers = "ADD_DEFAULT_MEMBER",
    Loading = "LOADING_MEMBER"
}
