import {
  TagRequest,
  TagSetRequest,
  PaginationOption
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(options: PaginationOption) {
  let query = ``

  if (typeof options?.limit !== 'undefined') {
    query += `?limit=${options.limit}`
  }

  if (typeof options?.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${options.offset}`
  }

  if (typeof options?.search_text !== 'undefined') {
    query += `${query ? '&' : '?'}search_text=${options.search_text}`
  }

  if (typeof options?.sort_by !== 'undefined') {
    query += `${query ? '&' : '?'}sort_by=${options.sort_by}`
  }

  if (typeof options?.sort_direction !== 'undefined') {
    query += `${query ? '&' : '?'}sort_direction=${options.sort_direction}`
  }

  return query
}

export default class TagAdminService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllTagSets(paginationOption: PaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/admin/tag-sets/${query}`, this.options)
  }

  async getAllTagsOnAdmin(paginationOption: PaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/admin/tags/${query}`, this.options)
  }

  async createTagSet(data: TagSetRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/tag-sets/`, requestOptions)
  }

  async getTagSetById(tagSetId: number) {
    return request('GET', `/admin/tag-sets/${tagSetId}`, this.options)
  }

  async editTagSet(tagSetId: number, data: TagSetRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/admin/tag-sets/${tagSetId}`, requestOptions)
  }

  async deleteTagSet(tagSetId: number) {
    return request('DELETE', `/admin/tag-sets/${tagSetId}`, this.options)
  }

  async createTag(data: TagRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/tags/`, requestOptions)
  }

  async getTagById(tagId: number) {
    return request('GET', `/admin/tags/${tagId}`, this.options)
  }

  async editTag(tagId: number, data: TagRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/admin/tags/${tagId}`, requestOptions)
  }

  async deleteTag(tagId: number) {
    return request('DELETE', `/admin/tags/${tagId}`, this.options)
  }
}
