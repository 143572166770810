import {
  RubricActionTypeEnum,
  VideoActionTypeEnum,
  ModuleConfigActionTypeEnum,
  RubricConfigActionTypeEnum
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";


const initialState = {
  Categories: [],
  Rubrics: [],
  Videos: [],
  total: 0,
  RubricsTotal: null,
  VideosTotal: null,
  params: {},
  allData: [],
  ConfigData: {},
  ConfigDataVideos: null,
  ConfigDataPopularVideos: [],
  ConfigDataRubrics: [],
  ConfigDataRecommendedVideos: [],
  ConfigDataNewestVideos: [],
  Sections: null,
  SectionToUpdate: {},
  selectDefaultRubrics: [],
  DefaultRubrics: [],
  BlockModeVideosLoading: false,
  DefaultExperts: [],
  IsLoadingRubric: false,
  IsLoadingVideo: false,
  ConfigDataLoading: true
};

const getObjectsArrayReturnIdsArray = (array) => {
  if (array) {
    let tab = [];
    for (let i = 0; i < array.length; i++) {
      tab.push(array[i].id);
    }
    return tab;
  }
};


const MediathekReducer = (state = initialState, action) => {
  switch (action.type) {
    case RubricActionTypeEnum.AddDefaultRubric:
      return {
        ...state,
        DefaultRubrics: action.default,
      };

    case RubricActionTypeEnum.List:
      return {
        ...state,
        Rubrics: action.rubrics,
        RubricsTotal: action.total,
        IsLoadingRubric: action.loading
      };
    case RubricActionTypeEnum.ListInSelect:
      return {
        ...state,
        selectDefaultRubrics: action.rubrics,
      };
    case RubricActionTypeEnum.Add:
      let newRubricState = !state.Rubrics ? [] : state.Rubrics;
      newRubricState.unshift(action.rubric);
      return {
        ...state,
        Rubrics: newRubricState,
        RubricsTotal: newRubricState.length
      };
    case RubricActionTypeEnum.Update:
      let rubricState = !state.Rubrics ? [] : state.Rubrics;
      let newArrayRubric = updatedArray(rubricState, action.rubric);
      return {
        ...state,
        Rubrics: newArrayRubric,
      };
    case RubricActionTypeEnum.Delete:
      let RUBRIC = !state.Rubrics ? [] : state.Rubrics;
      let updRubricArray = arrayReduce(RUBRIC, action.rubric);
      return {
        ...state,
        Rubrics: updRubricArray,
        RubricsTotal: updRubricArray.length
      };

    case VideoActionTypeEnum.List:
      return {
        ...state,
        Videos: action.videos,
        VideosTotal: action.total,
        IsLoadingVideo: action.loading
      };

    case VideoActionTypeEnum.Add:
      let newVideoState = !state.Videos ? [] : state.Videos;
      newVideoState.unshift(action.video);
      return {
        ...state,
        Videos: newVideoState,
        VideosTotal: newVideoState.total,
      };
    case VideoActionTypeEnum.SetEmpty:
      return {
        ...state,
        Videos: [],
      };
    case VideoActionTypeEnum.BlockModeLoading:
      return {
        ...state,
        BlockModeVideosLoading: action.data,
      };
    case VideoActionTypeEnum.Update:
      let videoState = !state.Videos ? [] : state.Videos;
      let newArrayVideo = updatedArray(videoState, action.video);
      return {
        ...state,
        Videos: newArrayVideo,
      };
    case VideoActionTypeEnum.Delete:
      let VIDEO = !state.Videos ? [] : state.Videos;
      let updVideoArray = arrayReduce(VIDEO, action.video);
      return {
        ...state,
        Videos: updVideoArray,
        VideosTotal: updVideoArray.length,
      };

    case ModuleConfigActionTypeEnum.GetData:
      return {
        ...state,
        ConfigData: action.configData,
        ConfigDataVideos: action.configDataVideos,
        ConfigDataPopularVideos: action.configDataPopularVideos,
        ConfigDataRubrics: action.configDataRubrics,
        ConfigDataRecommendedVideos: action.configDataRecommendedVideos,
        ConfigDataNewestVideos: action.configDataNewestVideos,
        ConfigDataLoading: false
      };

    case ModuleConfigActionTypeEnum.Update:
      let config = !state.ConfigData ? {} : state.ConfigData;

      config = {
        ...config,
        highlight_video_ids: getObjectsArrayReturnIdsArray(
          state.ConfigDataVideos
        ),
        highlight_rubric_ids: getObjectsArrayReturnIdsArray(
          state.ConfigDataRubrics
        ),
        most_popular_video_ids: getObjectsArrayReturnIdsArray(
          state.ConfigDataPopularVideos
        ),
        might_like_video_ids: getObjectsArrayReturnIdsArray(
          state.ConfigDataRecommendedVideos
        ),
        newest_video_ids: getObjectsArrayReturnIdsArray(
          state.ConfigDataNewestVideos
        ),
      };

      action.client.updateModuleConfig({
        highlight_video_ids: config.highlight_video_ids,
        highlight_rubric_ids: config.highlight_rubric_ids,
        most_popular_video_ids: config.most_popular_video_ids,
        might_like_video_ids: config.might_like_video_ids,
        newest_video_ids: config.newest_video_ids,
      }).then(async (res) => { }).catch(console.error);

      return {
        ...state,
        ConfigData: config,
      };

    case ModuleConfigActionTypeEnum.AddVideo:
      let configVideoState = !state.ConfigDataVideos
        ? []
        : state.ConfigDataVideos;
      configVideoState.unshift(action.configDataVideo);
      return {
        ...state,
        ConfigDataVideos: configVideoState,
      };
    case ModuleConfigActionTypeEnum.DeleteVideo:
      let configVideoArray = !state.ConfigDataVideos
        ? []
        : state.ConfigDataVideos;
      let configVideoUpdated = arrayReduce(
        configVideoArray,
        action.configDataVideo
      );
      return {
        ...state,
        ConfigDataVideos: configVideoUpdated,
      };

    case ModuleConfigActionTypeEnum.AddRubric:
      let configRubricState = !state.ConfigDataRubrics
        ? []
        : state.ConfigDataRubrics;
      configRubricState.unshift(action.configDataRubric);
      return {
        ...state,
        ConfigDataRubrics: configRubricState,
      };

    case ModuleConfigActionTypeEnum.DeleteRubric:
      let configRubricArray = !state.ConfigDataRubrics
        ? []
        : state.ConfigDataRubrics;
      let configRubricUpdated = arrayReduce(
        configRubricArray,
        action.configDataRubric
      );
      return {
        ...state,
        ConfigDataRubrics: configRubricUpdated,
      };

    case ModuleConfigActionTypeEnum.AddRecommendedVideo:
      let configRecommendedVideoState = !state.ConfigDataRecommendedVideos
        ? []
        : state.ConfigDataRecommendedVideos;
      configRecommendedVideoState.unshift(action.configDataRecommendedVideo);
      return {
        ...state,
        ConfigDataRecommendedVideos: configRecommendedVideoState,
      };

    case ModuleConfigActionTypeEnum.DeleteRecommendedVideo:
      let configRecommendedVideoArray = !state.ConfigDataRecommendedVideos
        ? []
        : state.ConfigDataRecommendedVideos;
      let configRecommendedVideoUpdated = arrayReduce(
        configRecommendedVideoArray,
        action.configDataRecommendedVideo
      );
      return {
        ...state,
        ConfigDataRecommendedVideos: configRecommendedVideoUpdated,
      };

    case ModuleConfigActionTypeEnum.AddNewestVideo:
      let configNewestVideoState = !state.ConfigDataNewestVideos
        ? []
        : state.ConfigDataNewestVideos;
      configNewestVideoState.unshift(action.configDataNewestVideo);
      return {
        ...state,
        ConfigDataNewestVideos: configNewestVideoState,
      };

    case ModuleConfigActionTypeEnum.DeleteNewestVideo:
      let configNewestVideoArray = !state.ConfigDataNewestVideos
        ? []
        : state.ConfigDataNewestVideos;
      let configNewestVideoUpdated = arrayReduce(
        configNewestVideoArray,
        action.configDataNewestVideo
      );
      return {
        ...state,
        ConfigDataNewestVideos: configNewestVideoUpdated,
      };

    case ModuleConfigActionTypeEnum.AddPopularVideo:
      let configPopularVideoState = !state.ConfigDataPopularVideos
        ? []
        : state.ConfigDataPopularVideos;
      configPopularVideoState.unshift(action.configDataPopularVideo);
      return {
        ...state,
        ConfigDataPopularVideos: configPopularVideoState,
      };

    case ModuleConfigActionTypeEnum.DeletePopularVideo:
      let configPopularVideoArray = !state.ConfigDataPopularVideos
        ? []
        : state.ConfigDataPopularVideos;
      let configPopularVideoUpdated = arrayReduce(
        configPopularVideoArray,
        action.configDataPopularVideo
      );
      return {
        ...state,
        ConfigDataPopularVideos: configPopularVideoUpdated,
      };

    case RubricConfigActionTypeEnum.ClearSectionToUpdate: //For mikel
      return {
        ...state,
        SectionToUpdate: {},
      };

    case RubricConfigActionTypeEnum.GetData:
      return {
        ...state,
        Sections: action.sections,
      };

    case RubricConfigActionTypeEnum.LoadSections: //For mikel
      return {
        ...state,
        SectionToUpdate: action.sectionToUpdate,
      };

    case VideoActionTypeEnum.DefaultExperts:
      return {
        ...state,
        DefaultExperts: action.expert,
      };

    case RubricActionTypeEnum.Loading:
      return {
        ...state,
        IsLoadingRubric: action.data,
      };

    case VideoActionTypeEnum.Loading:
      return {
        ...state,
        IsLoadingVideo: action.data,
      };

    default:
      return state;
  }
};

export default MediathekReducer;
