import {
  TreffpunkteUpdateRequest,
  TreffpunkteCreateRequest,
  AddImageToTreffpunkte,
  TreffpunkteId,
  TreffpunkteGetAllRequest,
  TreffpunkteCancel,
  TreffpunkteIdImageId,
  TreffpunkteGetMyRequest,
  getAllImagesRequest,
  getImageWithMeetupId,
  UpdateImageRequest,
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(getAllQuery: TreffpunkteGetAllRequest) {
  let query = ``

  if (typeof getAllQuery?.longitude !== 'undefined') {
    query += `${query ? '&' : '?'}longitude=${getAllQuery?.longitude}`
  }

  if (typeof getAllQuery?.latitude !== 'undefined') {
    query += `${query ? '&' : '?'}latitude=${getAllQuery?.latitude}`
  }

  if (typeof getAllQuery?.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${getAllQuery?.offset}`
  }

  if (typeof getAllQuery?.limit !== 'undefined') {
    query += `${query ? '&' : '?'}limit=${getAllQuery?.limit}`
  }

  if (typeof getAllQuery?.meetup_type !== 'undefined') {
    query += `${query ? '&' : '?'}meetup_type=${getAllQuery?.meetup_type}`
  }

  if (typeof getAllQuery?.meetup_status !== 'undefined') {
    query += `${query ? '&' : '?'}meetup_status=${getAllQuery?.meetup_status}`
  }

  if (typeof getAllQuery?.radius !== 'undefined') {
    query += `${query ? '&' : '?'}radius=${getAllQuery?.radius}`
  }

  if (typeof getAllQuery?.expired !== 'undefined') {
    query += `${query ? '&' : '?'}expired=${getAllQuery?.expired}`
  }

  if (typeof getAllQuery?.country !== 'undefined') {
    query += `${query ? '&' : '?'}country=${getAllQuery?.country}`
  }

  return query
}

function getMyPaginationQuery(getMyQuery: TreffpunkteGetMyRequest) {
  let query = ``

  if (typeof getMyQuery.is_organizer !== 'undefined') {
    query += `${query ? '&' : '?'}is_organizer=${getMyQuery.is_organizer}`
  }

  if (typeof getMyQuery.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${getMyQuery.offset}`
  }

  if (typeof getMyQuery.limit !== 'undefined') {
    query += `${query ? '&' : '?'}limit=${getMyQuery.limit}`
  }

  if (typeof getMyQuery.expired !== 'undefined') {
    query += `${query ? '&' : '?'}expired=${getMyQuery.expired}`
  }

  if (typeof getMyQuery?.longitude !== 'undefined') {
    query += `${query ? '&' : '?'}longitude=${getMyQuery?.longitude}`
  }

  if (typeof getMyQuery?.latitude !== 'undefined') {
    query += `${query ? '&' : '?'}latitude=${getMyQuery?.latitude}`
  }

  if (typeof getMyQuery?.radius !== 'undefined') {
    query += `${query ? '&' : '?'}radius=${getMyQuery?.radius}`
  }

  return query
}

function getAllImagesPaginationQuery(getQuery: getAllImagesRequest) {
  let query = ``

  if (typeof getQuery.meetup_id !== 'undefined') {
    query += `${query ? '&' : '?'}meetup_id=${getQuery.meetup_id}`
  }

  if (typeof getQuery.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${getQuery.offset}`
  }

  if (typeof getQuery.limit !== 'undefined') {
    query += `${query ? '&' : '?'}limit=${getQuery.limit}`
  }

  if (typeof getQuery.sort_direction !== 'undefined') {
    query += `${query ? '&' : '?'}sort_direction=${getQuery.sort_direction}`
  }

  if (typeof getQuery?.sort_by !== 'undefined') {
    query += `${query ? '&' : '?'}sort_by=${getQuery?.sort_by}`
  }

  return query
}

export default class TreffpunkteService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  // GET

  async getAll(getAllQuery: TreffpunkteGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/meetups${query}`, this.options)
  }

  async getMyTreffpunkte({ ...data }: TreffpunkteGetMyRequest) {
    const query = getMyPaginationQuery(data)

    return request('GET', `/me/meetups${query}`, this.options)
  }

  async getTreffpunktesById({ meetup_id }: TreffpunkteId) {
    return request('GET', `/meetups/${meetup_id}`, this.options)
  }

  async getDefaultTags() {
    return request('GET', `/default-tags`, this.options)
  }

  async getAllImages(getAllQuery: getAllImagesRequest) {
    const query = getAllImagesPaginationQuery(getAllQuery)

    return request('GET', `/meetups/${getAllQuery.meetup_id}/images${query}`, this.options)
  }

  async getImageWithMeetupId({meetup_id, image_id}: getImageWithMeetupId) {

    return request('GET', `/meetups/${meetup_id}/images/${image_id}`, this.options)
  }

  // POST

  async createTreffpunkte({ ...data }: TreffpunkteCreateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/meetups`, requestOptions)
  }

  async addImageToTreffpunkte({
    meetup_id,
    image_type,
    upload
  }: AddImageToTreffpunkte) {
    const formData = new FormData()
    formData.append('upload', upload)

    let query = ''

    if (typeof image_type !== 'undefined') {
      query += `?image_type=${image_type}`
    }

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request(
      'POST',
      `/meetups/${meetup_id}/images${query}`,
      requestOptions
    )
  }

  async likeTreffpunkte({ meetup_id }: TreffpunkteId) {
    return request('POST', `/me/meetups/${meetup_id}/likes`, this.options)
  }

  async likeImage({meetup_id, image_id}: getImageWithMeetupId) {
    return request('POST', `/meetups/${meetup_id}/images/${image_id}/likes`, this.options)
  }

  async addAttendToTreffpunkte({ meetup_id }: TreffpunkteId) {
    return request('POST', `/me/meetups/${meetup_id}/attend`, this.options)
  }

  // PUT

  async updateTreffpunkte({ meetup_id, ...data }: TreffpunkteUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/meetups/${meetup_id}`, requestOptions)
  }

  async updateImage({ meetup_id, image_id }: getImageWithMeetupId, data: UpdateImageRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('PUT', `/meetups/${meetup_id}/images/${image_id}`, requestOptions)
  }

  async cancelTreffpunkte({ meetup_id, ...data }: TreffpunkteCancel) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('PUT', `/meetups/${meetup_id}/cancel`, requestOptions)
  }

  // DELETE

  async deleteAttendFromTreffpunkte({ meetup_id }: TreffpunkteId) {
    return request('DELETE', `/me/meetups/${meetup_id}/un-attend`, this.options)
  }

  async deleteTreffpunkte({ meetup_id }: TreffpunkteId) {
    return request('DELETE', `/meetups/${meetup_id}`, this.options)
  }

  async removeImageOnTreffpunkte({
    meetup_id,
    image_id
  }: TreffpunkteIdImageId) {
    return request(
      'DELETE',
      `/meetups/${meetup_id}/images/${image_id}`,
      this.options
    )
  }

  async dislikeTreffpunkte({ meetup_id }: TreffpunkteId) {
    return request('DELETE', `/me/meetups/${meetup_id}/unlike`, this.options)
  }

  async unlikeImage({ meetup_id, image_id }: getImageWithMeetupId) {
    return request('DELETE', `/meetups/${meetup_id}/images/${image_id}/unlike`, this.options)
  }

  async approveTreffpunkte({ meetup_id }: TreffpunkteId) {
    return request('PUT', `/meetups/${meetup_id}/approve`, this.options)
  }

  async getMeetupCalendar(meetup_id: number) {
    return request('GET', `/me/meetups/${meetup_id}/calendar`, this.options)
  }
}
