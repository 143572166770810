
import ArticleActionTypeEnum from "../../helpers/enums/ArticleActionTypeEnum";
import ConfigActionTypeEnum from "../../helpers/enums/ConfigActionTypeEnum";
import RubricActionTypeEnum from "../../helpers/enums/RubricActionTypeEnum";
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";


const initialState = {
  params: {},
  // Rubrics
  Rubrics: [],
  IsLoading: false,
  IsLoadingArticle: false,
  DefaultRubrics: [],
  rubricsTotal: 0,
  articlesTotal: 0,
  // Articles
  Articles: [],
  allData: [],
  ArticleContent: {},
  DefaultExperts: [],
  // Module Config
  ConfigData: {},
  ConfigDataTags: [],
  ConfigDataHighlightedArticles: [],
  ConfigDataSuggestedArticles: [],
  ConfigDataMostPopularArticles: [],
  ConfigDataLoading: true,
  Authors: [],
  SelectedAuthors: []
};

const getObjectsArrayReturnIdsArray = (array) => {
  let tab = [];
  for (let i = 0; i < array.length; i++) {
    tab.push(array[i].id);
  }
  return tab;
};


const MagazinReducer = (state = initialState, action) => {
  switch (action.type) {
    // RUBRICS
    case RubricActionTypeEnum.List:
      return {
        ...state,
        Rubrics: action.rubrics,
        rubricsTotal: action.count,
        IsLoading: action.loading
      };
    case RubricActionTypeEnum.Add:
      let newRubricState = !state.Rubrics ? [] : state.Rubrics;
      newRubricState.unshift(action.rubric);
      return {
        ...state,
        Rubrics: newRubricState,
        rubricsTotal: newRubricState.length
      };
    case RubricActionTypeEnum.Update:
      let rubricState = !state.Rubrics ? [] : state.Rubrics;
      let newArrayRubric = updatedArray(rubricState, action.rubric);
      return {
        ...state,
        Rubrics: newArrayRubric
      };
    case RubricActionTypeEnum.Delete:
      let RUBRIC = !state.Rubrics ? [] : state.Rubrics;
      let updRubricArray = arrayReduce(RUBRIC, action.rubric);
      return {
        ...state,
        Rubrics: updRubricArray,
        rubricsTotal: updRubricArray.length
      };
    // DEFAULT RUBRICS
    case RubricActionTypeEnum.Default:
      return {
        ...state,
        DefaultRubrics: action.default
      };
    case RubricActionTypeEnum.DefaultEmtpy:
      return {
        ...state,
        DefaultRubrics: []
      };
    // ARTICLE
    case ArticleActionTypeEnum.List:
      return {
        ...state,
        Articles: action.articles,
        articlesTotal: action.count,
        IsLoadingArticle: action.loading,
      };
    case ArticleActionTypeEnum.Emtpy:
      return {
        ...state,
        Articles: []
      };
    case ArticleActionTypeEnum.Add:
      let newArticleState = !state.Articles ? [] : state.Articles;
      newArticleState.unshift(action.article);
      return {
        ...state,
        Articles: newArticleState,
        articlesTotal: newArticleState.length
      };
    case ArticleActionTypeEnum.Update:
      let articleState = !state.Articles ? [] : state.Articles;
      let newArticles = updatedArray(articleState, action.article);
      return {
        ...state,
        Articles: newArticles
      };
    case ArticleActionTypeEnum.Delete:
      let ARTICLE = !state.Articles ? [] : state.Articles;
      let updatedArticles = arrayReduce(ARTICLE, action.article);
      return {
        ...state,
        Articles: updatedArticles,
        articlesTotal: updatedArticles.length
      };
    case ArticleActionTypeEnum.UpdateContent:
      return {
        ...state,
        ArticleContent: action.content
      }
    case ArticleActionTypeEnum.EmtpyContent:
      return {
        ...state,
        ArticleContent: { blocks: [] }
      }
    // MODULE CONFIG
    case ConfigActionTypeEnum.Get:
      return {
        ...state,
        ConfigData: action.configData,
        ConfigDataTags: action.configDataTags,
        ConfigDataHighlightedArticles: action.configDataHighlightedArticles,
        ConfigDataSuggestedArticles: action.configDataSuggestedArticles,
        ConfigDataMostPopularArticles: action.configDataMostPopularArticles,
        ConfigDataLoading: false
      };
    case ConfigActionTypeEnum.Update:
      let config = !state.ConfigData ? {} : state.ConfigData;
      config = {
        ...config,
        default_tag_ids: getObjectsArrayReturnIdsArray(state.ConfigDataTags),
        highlight_article_ids: getObjectsArrayReturnIdsArray(
          state.ConfigDataHighlightedArticles
        ),
        suggestion_article_ids: getObjectsArrayReturnIdsArray(
          state.ConfigDataSuggestedArticles
        ),
        most_popular_article_ids: getObjectsArrayReturnIdsArray(
          state.ConfigDataMostPopularArticles
        )
      };

      action.client.updateModuleConfig({
          default_tag_ids: config.default_tag_ids,
          highlight_article_ids: config.highlight_article_ids,
          suggestion_article_ids: config.suggestion_article_ids,
          most_popular_article_ids: config.most_popular_article_ids
        }).then(async (res) => { }).catch(console.error);

      return {
        ...state,
        ConfigData: config
      };
    // ADD and DELETE TAGS
    case ConfigActionTypeEnum.AddTag:
      let configTagState = !state.ConfigDataTags ? [] : state.ConfigDataTags;
      configTagState.unshift(action.configDataTag);
      return {
        ...state,
        ConfigDataTags: configTagState
      };
    case ConfigActionTypeEnum.DeleteTag:
      let configTagArray = !state.ConfigDataTags ? [] : state.ConfigDataTags;
      let configTagUpdated = arrayReduce(configTagArray, action.configDataTag);
      return {
        ...state,
        ConfigDataTags: configTagUpdated
      };
    // ADD and DELETE highlighted Article
    case ConfigActionTypeEnum.AddHighlighted:
      let configHighlightedArticlesState = !state.ConfigDataHighlightedArticles ? [] : state.ConfigDataHighlightedArticles;
      configHighlightedArticlesState.unshift(action.configDataHighlightedArticle);
      return {
        ...state,
        ConfigDataHighlightedArticles: configHighlightedArticlesState
      };
    case ConfigActionTypeEnum.DeleteHighlighted:
      let configHighlightedArticlesArray = !state.ConfigDataHighlightedArticles ? [] : state.ConfigDataHighlightedArticles;
      let configHighlightedArticlesUpdated = arrayReduce(
        configHighlightedArticlesArray,
        action.configDataHighlightedArticle
      );
      return {
        ...state,
        ConfigDataHighlightedArticles: configHighlightedArticlesUpdated
      };
    // ADD and DELETE suggested Article
    case ConfigActionTypeEnum.AddSuggested:
      let configSuggestedArticlesState = !state.ConfigDataSuggestedArticles ? [] : state.ConfigDataSuggestedArticles;
      configSuggestedArticlesState.unshift(action.configDataSuggestedArticles);
      return {
        ...state,
        ConfigDataSuggestedArticles: configSuggestedArticlesState
      };
    case ConfigActionTypeEnum.DeleteSuggested:
      let configSuggestedArticlesArray = !state.ConfigDataSuggestedArticles ? [] : state.ConfigDataSuggestedArticles;
      let configSuggestedArticlesUpdated = arrayReduce(
        configSuggestedArticlesArray,
        action.configDataSuggestedArticles
      );
      return {
        ...state,
        ConfigDataSuggestedArticles: configSuggestedArticlesUpdated
      };
    // ADD and DELETE most popular Article
    case ConfigActionTypeEnum.AddMostPopular:
      let configPopularVideoState = !state.ConfigDataMostPopularArticles ? [] : state.ConfigDataMostPopularArticles;
      configPopularVideoState.unshift(action.configDataMostPopularArticles);
      return {
        ...state,
        ConfigDataPopularVideos: configPopularVideoState
      };
    case ConfigActionTypeEnum.DeleteMostPopular:
      let configMostPopularArticlesArray = !state.ConfigDataMostPopularArticles ? [] : state.ConfigDataMostPopularArticles;
      let configMostPopularArticlesUpdated = arrayReduce(
        configMostPopularArticlesArray,
        action.configDataMostPopularArticles
      );
      return {
        ...state,
        ConfigDataMostPopularArticles: configMostPopularArticlesUpdated
      };
    case ConfigActionTypeEnum.ListAuthors:
      return {
        ...state,
        Authors: action.authors
      };

    case ArticleActionTypeEnum.DefaultExperts:
      return {
        ...state,
        DefaultExperts: action.expert,
      };

    case ConfigActionTypeEnum.AddAuthor:
      let authors = !state.SelectedAuthors ? [] : state.SelectedAuthors;
      authors.unshift(action.author);
      return {
        ...state,
        SelectedAuthors: authors
      };

    case RubricActionTypeEnum.Loading:
      return {
        ...state,
        IsLoading: action.data,
      };

      case ArticleActionTypeEnum.Loading:
      return {
        ...state,
        IsLoadingArticle: action.data,
      };
    // Return state if no case match
    default:
      return state;
  }
};

export default MagazinReducer;
