
export interface Comment {
  id: number
  parent_id?: number
  owner_domain: OwnerDomain
  owner_object: OwnerObject
  owner_id: number
  content: string
  sticky: boolean
  deletion_dt: Date | null
  status: CommentStatus
  score: number
  nb_reply: number
  nb_likes: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  nb_loves: number
  is_reported_by_user: boolean
  tagged_user_ids: Array<number>
  tagged_users: Array<LimitedUserProfile>;
  owner_parent_id?: number
  owner_parent_object?: OwnerObject
  created_by_user: LimitedUserProfile
  images: Array<ImageInterface>
  is_reacted_by_user: boolean
  user_reaction: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface LimitedUserProfile {
  id: number
  internal_status: number
  email: string
  membership_id: number
  full_name: string
  image_profile: ImageLimited | null
  image_background: ImageLimited | null
  status: string
  motto: string
  tags: Array<Tag>
  is_friend: boolean
  is_blocked: boolean
  is_blocked_by_user: boolean
  is_online: boolean
  online_visible: boolean
  attendee_visible: boolean
  community_chat_visible: boolean
  community_point_visible: boolean
  is_reported: boolean
  direct_message: boolean
  receive_messages: boolean
  friend_ids: Array<number>
}

export interface PostReaction extends CommentById {
  reaction: number
}

export interface ImageLimited {
  path: string
  cropped_data: CroppedDataInterface
}

export interface ImageInterface {
  id: number
  nb_likes: number
  nb_loves: number
  nb_smiles: number
  nb_screams: number
  nb_cries: number
  album_id: number
  path: string
  description: string
  owner_id: number
  tagged_user_ids: Array<ImageTagInterface>
  cropped_data: CroppedDataInterface
  like_by_user_ids: Array<number>
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface ImageTagInterface {
  x: number
  y: number
  user_id: number
}

export interface DataFromStat {
  nb_comments: number
  user_infos: LimitedUserProfile[]
}

export interface ReactionData {
  profile: LimitedUserProfile
  reaction: number
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

export interface CommentCreateRequest {
  parent_id?: number
  owner_domain: OwnerDomain
  owner_object: OwnerObject
  owner_id: number
  content: string
  tagged_user_ids: Array<number>
  owner_parent_id?: number
  owner_parent_object?: OwnerObject
}

export interface CommentUpdateRequest {
  content: string
  sticky?: boolean
  owner_parent_id?: number
  owner_parent_object?: OwnerObject
  tagged_user_ids: Array<number>
}

export interface CommentById {
  comment_id: number
}

export interface CommentCreateImageRequest extends CommentById {
  uploads: Array<Blob>
}

export interface CommentDeleteImage extends CommentById {
  image_id: number
}

export interface LikeRequest {
  comment_id: number
}

export interface StatusInterface {
  owner_domain: string
  owner_object: string
  owner_id: number
}

export interface CommentGetAllRequest {
  owner_domain: OwnerDomain
  owner_object: OwnerObject
  owner_id: number
  sort_by?: SortBy
  status?: CommentStatus
  offset?: number
  limit?: number
  parent_id?: number
}

export interface LikeGetAllRequest {
  comment_id: number
  offset?: number
  limit?: number
}

export interface Pagination {
  count: number
  next: string | null
  previous: string | null
  result: Array<Comment>
}

export interface PaginationGeneric<T> {
  count: number
  next: string | null
  previous: string | null
  result: Array<T>
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface ErrorSdk {
  detail: string
}

export enum CommentStatus {
  approved = 'approved',
  unapproved = 'unapproved',
  deleted = 'deleted'

}

export enum OwnerObject {
  video = 'video',
  article = 'article',
  course = 'course',
  event = 'event',
  meetup = 'meetup',
  group = 'group',
  post = 'post',
  image = 'image',
  tdm = 'tdm'
}

export enum OwnerDomain {
  mediathek = 'mediathek',
  meditation = 'meditation',
  magazin = 'magazin',
  thema_des_monats = 'thema_des_monats',
  academy = 'academy',
  live_events = 'live_events',
  community = 'community',
  treffpunkte = 'treffpunkte',
  profile = 'profile',
  daily_mail = 'daily_mail',
  adventskalender = 'adventskalender',
}

export enum SortBy {
  nb_likes = 'nb_likes',
  created_at = 'created_at'
}

export enum VoteDirection {
  one = '1',
  minusOne = '-1'
}

export enum ReportAbusesStatus {
  pending = "pending",
  resolved = "resolved"
}

export interface BasePaginationOption {
  search_text?: string
  sort_by?: string
  offset?: number
  limit?: number
}
export interface BasePagination<T> {
  count: number;
  next: string | null;
  previous: string | null;
  result: Array<T>;
}


export interface PaginationReportAbusesOption {
  offset?: number
  limit?: number
  filter_by_status?: ReportAbusesStatus
}

export interface CommentReportAbusesParams {
  abuse_id: number
}

export interface ReportComment {
  comment_id: number
  reason: string
}

export interface User {
  id: number
  full_name: string
  image_profile: string
  image_background: string
  is_online: boolean;
  status: string
  tags: Array<Tag>
}

export interface Tag {
  id: number
  value: string
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface CommentPostReport {
  id: number;
  comment_id: number;
  reported_dt: Date;
  reason: string;
  notes: string;
  status: ReportAbusesStatus;
  user_id: number;
  reported_by_user: LimitedUserProfile;
  reported_comment: Comment;
}

export interface CommentPostReportRequest {
  notes: string;
}
