import {
    ArtikelRequest,
    ImageRequest,
    AttachmentRequest,
    ReferenceRequest,
    VideoRequest,
    AudioRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import { Options } from '../../../services.utils/request-types'


export default class AdminService {
    options: Options

    constructor(options: Options) {
        this.options = options
    }

    async createArticle(data: ArtikelRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data
        }
        return request('POST', `/admin/articles`, requestOptions)
    }

    async createArticleImage(articleId: number, data: ImageRequest) {
        const query = `?title=${data.title}`

        const formData = new FormData()
        formData.append('image', data.image)

        const requestOptions: Options = {
            ...this.options,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...this.options.headers
            },
            data: formData
        }

        return request(
            'POST',
            `/admin/articles/${articleId}/images${query}`,
            requestOptions
        )
    }

    async createArticleVideo(articleId: number, data: VideoRequest) {
        const query = `?title=${data.title}`

        const formData = new FormData()
        formData.append('video', data.video)

        const requestOptions: Options = {
            ...this.options,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...this.options.headers
            },
            data: formData
        }

        return request(
            'POST',
            `/admin/articles/${articleId}/videos${query}`,
            requestOptions
        )
    }

    async createArticleAudio(articleId: number, data: AudioRequest) {
        const query = `?title=${data.title}`

        const formData = new FormData()
        formData.append('audio', data.audio)

        const requestOptions: Options = {
            ...this.options,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...this.options.headers
            },
            data: formData
        }

        return request(
            'POST',
            `/admin/articles/${articleId}/audios${query}`,
            requestOptions
        )
    }

    async createArticleAttachment(articleId: number, data: AttachmentRequest) {
        const query = `?title=${data.title}`

        const formData = new FormData()
        formData.append('attachment', data.attachment)

        const requestOptions: Options = {
            ...this.options,
            headers: {
                'Content-Type': 'multipart/form-data',
                ...this.options.headers,
                ...this.options.headers
            },
            data: formData
        }

        return request(
            'POST',
            `/admin/articles/${articleId}/attachments${query}`,
            requestOptions
        )
    }

    async createArticleReference(articleId: number, data: ReferenceRequest) {
        const query = `?title=${data.title}&external_url=${data.external_url}`

        return request(
            'POST',
            `/admin/articles/${articleId}/references${query}`,
            this.options
        )
    }

    async editArticle(articleId: number, data: ArtikelRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data
        }
        return request('PUT', `/admin/articles/${articleId}`, requestOptions)
    }

    async deleteArticle(articleId: number) {
        return request('DELETE', `/admin/articles/${articleId}`, this.options)
    }

    async deleteArticleImage(articleId: number, imageId: number) {
        return request(
            'DELETE',
            `/admin/articles/${articleId}/images/${imageId}`,
            this.options
        )
    }

    async deleteArticleVideo(articleId: number, videoId: number) {
        return request(
            'DELETE',
            `/admin/articles/${articleId}/videos/${videoId}`,
            this.options
        )
    }

    async deleteArticleAudio(articleId: number, audioId: number) {
        return request(
            'DELETE',
            `/admin/articles/${articleId}/audios/${audioId}`,
            this.options
        )
    }

    async deleteArticleAttachment(articleId: number, attachmentId: number) {
        return request(
            'DELETE',
            `/admin/articles/${articleId}/attachments/${attachmentId}`,
            this.options
        )
    }

    async deleteArticleReference(articleId: number, referenceId: number) {
        return request(
            'DELETE',
            `/admin/articles/${articleId}/references/${referenceId}`,
            this.options
        )
    }

    async reSynDuration() {
        return request('GET', `/admin/articles/re-sync-duration`, this.options)
    }
}
