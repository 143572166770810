import {
  UserGetAllRequest,
  UserFriendsPaginationOption,
  UserImagePaginationOption,
  LifePathPaginationOption
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class ProfileService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllUsers(paginationOption: UserGetAllRequest) {
    let query = ''

    if (typeof paginationOption.search_text !== 'undefined') {
      query
        ? (query += `&search_text=${paginationOption.search_text}`)
        : (query += `?search_text=${paginationOption.search_text}`)
    }

    if (typeof paginationOption.search_visible !== 'undefined') {
      query
        ? (query += `&search_visible=${paginationOption.search_visible}`)
        : (query += `?search_visible=${paginationOption.search_visible}`)
    }

    if (typeof paginationOption.hide_from_blocked_users !== 'undefined') {
      query
        ? (query += `&hide_from_blocked_users=${paginationOption.hide_from_blocked_users}`)
        : (query += `?hide_from_blocked_users=${paginationOption.hide_from_blocked_users}`)
    }

    if (typeof paginationOption.view_all_status !== 'undefined') {
      query
        ? (query += `&view_all_status=${paginationOption.view_all_status}`)
        : (query += `?view_all_status=${paginationOption.view_all_status}`)
    }

    if (typeof paginationOption.offset !== 'undefined') {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (typeof paginationOption.limit !== 'undefined') {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (typeof paginationOption.sort_by !== 'undefined') {
      query
        ? (query += `&sort_by=${paginationOption.sort_by}`)
        : (query += `?sort_by=${paginationOption.sort_by}`)
    }

    if (typeof paginationOption.sort_direction !== 'undefined') {
      query
        ? (query += `&sort_direction=${paginationOption.sort_direction}`)
        : (query += `?sort_direction=${paginationOption.sort_direction}`)
    }


    if (typeof paginationOption.profile_ids !== 'undefined') {
      const profils: string = paginationOption.profile_ids
        .map((p: any, index: number) =>
          index === 0 && !query ? `?profile_ids=${p}` : `&profile_ids=${p}`
        )
        .join('')
      query += profils
    }

    if (typeof paginationOption.tag_ids !== 'undefined') {
      const tagIds: string = paginationOption.tag_ids
        .map((p: any, index: number) =>
          index === 0 && !query ? `?tag_ids=${p}` : `&tag_ids=${p}`
        )
        .join('')
      query += tagIds
    }

    return request('GET', `/profiles/${query}`, this.options)
  }

  async getDetailedProfile(profile_id: number) {
    return request('GET', `/profiles/${profile_id}/detailed-view`, this.options)
  }

  async getLimitedProfile(profile_id: number) {
    return request('GET', `/profiles/${profile_id}/limited-view`, this.options)
  }

  async getAllUserFriends(
    profile_id: number,
    paginationOption: UserFriendsPaginationOption
  ) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }
    if (paginationOption.search_text) {
      query
        ? (query += `&search_text=${paginationOption.search_text}`)
        : (query += `?search_text=${paginationOption.search_text}`)
    }

    return request(
      'GET',
      `/profiles/${profile_id}/friends/${query}`,
      this.options
    )
  }

  async getAllProfileImages(
    paginationOption: UserImagePaginationOption,
    user_id: number
  ) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (paginationOption.sort_by) {
      query
        ? (query += `&sort_by=${paginationOption.sort_by}`)
        : (query += `?sort_by=${paginationOption.sort_by}`)
    }

    if (paginationOption.sort_direction) {
      query
        ? (query += `&sort_direction=${paginationOption.sort_direction}`)
        : (query += `?sort_direction=${paginationOption.sort_direction}`)
    }

    return request('GET', `/profiles/${user_id}/images${query}`, this.options)
  }

  async getAllProfileAlbums(paginationOption: UserImagePaginationOption, user_id: number) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (paginationOption.sort_by) {
      query
        ? (query += `&sort_by=${paginationOption.sort_by}`)
        : (query += `?sort_by=${paginationOption.sort_by}`)
    }
    return request('GET', `/profiles/${user_id}/albums${query}`, this.options)
  }

  async getProfileAlbum(profile_id: number, album_id: number) {
    return request('GET', `/profiles/${profile_id}/albums/${album_id}`, this.options)
  }

  async getAllProfileAlbumsImages(paginationOption: UserImagePaginationOption, user_id: number, album_id: number) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (paginationOption.sort_by) {
      query
        ? (query += `&sort_by=${paginationOption.sort_by}`)
        : (query += `?sort_by=${paginationOption.sort_by}`)
    }

    if (paginationOption.sort_direction) {
      query
        ? (query += `&sort_direction=${paginationOption.sort_direction}`)
        : (query += `?sort_direction=${paginationOption.sort_direction}`)
    }
    
    return request('GET', `/profiles/${user_id}/albums/${album_id}/images${query}`, this.options)
  }

  async getAllProfileLifePaths(paginationOption: LifePathPaginationOption) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }
    return request(
      'GET',
      `/profiles/${paginationOption.profile_id}/life-paths${query}`,
      this.options
    )
  }

  async likeProfileImage(profile_id: number, image_id: number) {
    const requestOptions: Options = {
      ...this.options
    }
    return request('POST', `/profiles/${profile_id}/images/${image_id}/like`, requestOptions)
  }

  async unlikeProfileImage(profile_id: number, image_id: number) {
    const requestOptions: Options = {
      ...this.options
    }
    return request('DELETE', `/profiles/${profile_id}/images/${image_id}/unlike`, requestOptions)
  }

}
