import {
  RubrikRequest,
  PaginationOption,
  ImageRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import { Options } from '../../../services.utils/request-types'

export default class RubricService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAll(paginationOption: PaginationOption) {
    let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`;

    if (typeof paginationOption.published !== 'undefined') {
      query += `&published=${paginationOption.published}`;
    }

    if (typeof paginationOption.search_text !== 'undefined') {
      query += `&search_text=${paginationOption.search_text}`;
    }

    if (typeof paginationOption.sort_direction !== 'undefined') {
      query += `&sort_direction=${paginationOption.sort_direction}`;
    }

    return request('GET', `rubrics${query}`, this.options);
  }

  async getById(rubrikId: number) {
    return request('GET', `rubrics/${rubrikId}`, this.options);
  }

  async createRubrik(data: RubrikRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `rubrics/`, requestOptions);
  }

  async createRubrikImage(rubrikId: number, data: ImageRequest) {
    const formData = new FormData()

    formData.append('image', data.image)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }
    return request('POST', `rubrics/${rubrikId}/images`, requestOptions);
  }

  async editRubrik(rubrikId: number, data: RubrikRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `rubrics/${rubrikId}`, requestOptions);
  }

  async deleteRubrik(rubrikId: number) {
    return request('DELETE', `rubrics/${rubrikId}`, this.options);
  }

  async deleteRubrikImage(rubrikId: number) {
    return request('DELETE', `rubrics/${rubrikId}/images`, this.options);
  }
}
