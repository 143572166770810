import {
  AlbumImageCreateRequest,
  LifePathRequest,
  UserProfile,
  LimitedPaginationOption,
  LifePathImageRequest,
  LifePathImageDeleteRequest,
  AlbumUpdateRequest,
  AlbumCreateRequest,
  AlbumImagePaginationOption,
  UserImagePaginationOption,
  AlbumImageUpdateRequest,
  FriendRequest,
  UserFriendPaginationOption,
  FriendRequestUpdate,
  ReportUserRequest,
  LifePathInfoInterface,
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class AuthService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAuth() {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `/me`, requestOptions)
  }

  async getMeId() {
    const requestOptions: Options = {
      ...this.options,
    }
    return request('GET', `/me:id`, requestOptions)
  }

  async updateUser(data: UserProfile) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/me`, requestOptions)
  }

  async getUserLifePaths(paginationOption: LimitedPaginationOption) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }
    return request('GET', `/me/life-paths${query}`, this.options)
  }

  async createLifePath(data: LifePathRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data,
    }
    return request('POST', `/me/life-paths`, requestOptions)
  }

  async getLifePath(life_path_id: number) {
    return request('GET', `/me/life-paths/${life_path_id}`, this.options)
  }

  async updateLifePath(data: LifePathInfoInterface) {
    const requestOptions: Options = {
      ...this.options,
      data: data,
    }
    return request('PUT', `/me/life-paths/${data.id}`, requestOptions)
  }

  async deleteLifePath(life_path_id: number) {
    return request('DELETE', `/me/life-paths/${life_path_id}`, this.options)
  }

  async addLifePathImage(data: LifePathImageRequest) {
    const formData = new FormData()

    formData.append('upload', data.upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData,
    }
    return request('POST', `/me/life-paths/${data.life_path_id}/images`, requestOptions)
  }

  async deleteLifePathImage(data: LifePathImageDeleteRequest) {
    return request('DELETE', `/me/life-paths/${data.life_path_id}/images/${data.image_id}`, this.options)
  }

  async getAllAlbums(paginationOption: LimitedPaginationOption) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }
    return request('GET', `/me/albums/${query}`, this.options)
  }

  async getAlbum(album_id: number) {
    return request('GET', `/me/albums/${album_id}`, this.options)
  }

  async updateAlbum(album_id: number, data: AlbumUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data,
    }
    return request('PUT', `/me/albums/${album_id}`, requestOptions)
  }

  async deleteAlbum(album_id: number) {
    return request('DELETE', `/me/albums/${album_id}`, this.options)
  }

  async createAlbum(data: AlbumCreateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/me/albums`, requestOptions)
  }

  async getAllAlbumsImages(album_id: number, paginationOption: AlbumImagePaginationOption) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (paginationOption.sort_by) {
      query
        ? (query += `&sort_by=${paginationOption.sort_by}`)
        : (query += `?sort_by=${paginationOption.sort_by}`)
    }
    return request('GET', `/me/albums/${album_id}/images${query}`, this.options)
  }

  async getAllUserImages(paginationOption: UserImagePaginationOption) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (paginationOption.sort_by) {
      query
        ? (query += `&sort_by=${paginationOption.sort_by}`)
        : (query += `?sort_by=${paginationOption.sort_by}`)
    }
    return request('GET', `/me/images/${query}`, this.options)
  }

  async createImage(data: AlbumImageCreateRequest) {
    const formData = new FormData()

    formData.append('upload', data.upload)
    if (data.description) formData.append('description', data.description)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData,
    }
    if (data.album_id) return request('POST', `/me/images?album_id=${data.album_id}`, requestOptions)
    else return request('POST', `/me/images`, requestOptions)
  }

  async updateImage(image_id: number, data: AlbumImageUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data,
    }
    return request('PUT', `/me/images/${image_id}`, requestOptions)
  }

  async getImage(image_id: number) {
    const requestOptions: Options = {
      ...this.options
    }
    return request('GET', `/me/images/${image_id}`, requestOptions)
  }

  async deleteImage(image_id: number) {
    return request('DELETE', `/me/images/${image_id}`, this.options)
  }

  async getAllFriends(paginationOption: UserFriendPaginationOption) {
    let query = ''

    if (paginationOption.offset) {
      query
        ? (query += `&offset=${paginationOption.offset}`)
        : (query += `?offset=${paginationOption.offset}`)
    }

    if (paginationOption.limit) {
      query
        ? (query += `&limit=${paginationOption.limit}`)
        : (query += `?limit=${paginationOption.limit}`)
    }

    if (paginationOption.search_text) {
      query
        ? (query += `&search_text=${paginationOption.search_text}`)
        : (query += `?search_text=${paginationOption.search_text}`)
    }
    return request('GET', `/me/friends/${query}`, this.options)
  }

  async createFriendRequest(data: FriendRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data,
    }
    return request('POST', `/me/friend-requests/invite`, requestOptions)
  }

  async getFriendsRequest(request_status?: string) {
    let query = ''
    if (request_status) {
      query
        ? (query += `&request_status=${request_status}`)
        : (query += `?request_status=${request_status}`)
    }

    return request('GET', `/me/friend-requests/${query}`, this.options)
  }

  async updateFriendRequest(data: FriendRequestUpdate) {
    let query = ''

    if (data.request_status) {
      query
        ? (query += `&request_status=${data.request_status}`)
        : (query += `?request_status=${data.request_status}`)
    }
    return request('PUT', `/me/friend-requests/${data.request_id}/${query}`, this.options)
  }

  async deleteFriendRequest(request_id: number) {
    return request('DELETE', `/me/friend-requests/${request_id}`, this.options)
  }

  async blockUser(profile_id: number) {
    return request('POST', `/me/profiles/${profile_id}/block`, this.options)
  }

  async unBlockUser(profile_id: number) {
    return request('DELETE', `/me/profiles/${profile_id}/unblock`, this.options)
  }

  async reportUser(data: ReportUserRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data,
    }
    return request('POST', `/me/profiles/report-abuse`, requestOptions)
  }

  async cancelAbuse(profile_id: number) {

    return request('DELETE', `/me/profiles/${profile_id}/cancel-abuse`, this.options)
  }

  async likeImage(image_id: number) {
    const requestOptions: Options = {
      ...this.options
    }
    return request('POST', `/me/images/${image_id}/like`, requestOptions)
  }

  async unlikeImage(image_id: number) {
    const requestOptions: Options = {
      ...this.options
    }
    return request('DELETE', `/me/images/${image_id}/unlike`, requestOptions)
  }

  async getMembershipStatus() {
    return request('GET', `/me:membership-status`, this.options)
  }

  async cancelSubscription() {
    const requestOptions: Options = {
      ...this.options
    }
    return request('POST', `/me/cancel-subscription`, requestOptions)
  }
}

