import {
  PaginationDailyNewsOption,
  PaginationOption
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: PaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`;

  if (paginationOption.active) {
    query += `&active=${paginationOption.active}`;
  }

  if (paginationOption.search_text) {
    query += `&search_text=${paginationOption.search_text}`;
  }

  if (paginationOption.month) {
    query += `&month=${paginationOption.month}`;
  }

  if (paginationOption.year) {
    query += `&year=${paginationOption.year}`;
  }
  if(paginationOption.sort_direction){
    query += `&year=${paginationOption.year}`;
  }

  return query
}


export default class DailyMailMeService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllArchivedMails(paginationOption: PaginationOption) {
    const query = getPaginationQuery(paginationOption)

    return request('GET', `/me/daily-mails/archived${query}`, this.options)
  }

  async getTodayMails() {
    return request('GET', `/me/today`, this.options)
  }

  async getDailyNews(paginationOption: PaginationDailyNewsOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/me/daily-news/${query}`, this.options)
  }

  async getDailyMailByDay(daily_mail_date: string) {
    return request('GET', `/me/daily-mail-by-day/${daily_mail_date}`, this.options)
  }

}
