export enum RubricActionTypeEnum {
    List = "LIST_RUBRICS_MEDITATION",
    ListInSelect = "SELECT_LIST_RUBRICS_MEDITATION",
    AddDefaultRubric = "ADD_DEFAULT_RUBRIC_MEDITATION",
    Add = "ADD_RUBRIC_MEDITATION",
    Update = "UPDATE_RUBRIC_MEDITATION",
    Delete = "DELETE_RUBRIC_MEDITATION",
    Loading = "LOADING_RUBRICS_MEDITATION",
}

export enum VideoActionTypeEnum {
    List = "LIST_VIDEOLIST_VIDEOS_MEDITATION",
    BlockModeLoading = "SET_BLOCK_MODE_VIDEOS_LOADING_MEDITATION",
    SetEmpty = "SET_VIDEOS_EMPTY_MEDITATION",
    Get = "GET_CONFIG_MAGAZINE",
    Update = "UPDATE_VIDEO_MEDITATION",
    Add = "ADD_VIDEO_MEDITATION",
    Delete = "DELETE_VIDEO_MEDITATION",
    DefaultExperts = "DEFAULT_EXPERTS",
    Loading = "LOADING_VIDEO_MEDITATION"
}

export enum ModuleConfigActionTypeEnum {
    GetData = "GET_CONFIG_MEDITATION",
    BlockModeLoading = "SET_BLOCK_MODE_VIDEOS_LOADING_MEDITATION",
    Update = "UPDATE_CONFIG_MEDITATION",
    AddVideo = "ADD_CONFIG_VIDEO_MEDITATION",
    DeleteVideo = "DELETE_CONFIG_VIDEO_MEDITATION",
    AddRubric = "ADD_CONFIG_RUBRIC_MEDITATION",
    DeleteRubric = "DELETE_CONFIG_RUBRIC_MEDITATION",
    AddRecommendedVideo = "ADD_CONFIG_RECOMMENDED_VIDEO_MEDITATION",
    DeleteRecommendedVideo = "DELETE_CONFIG_RECOMMENDED_VIDEO_MEDITATION",
    AddNewestVideo = "ADD_CONFIG_NEWEST_VIDEO_MEDITATION",
    DeleteNewestVideo = "DELETE_CONFIG_NEWEST_VIDEO_MEDITATION",
    AddPopularVideo = "ADD_CONFIG_POPULAR_VIDEO_MEDITATION",
    DeletePopularVideo = "DELETE_CONFIG_POPULAR_VIDEO_MEDITATION",
    AddTag = "ADD_CONFIG_TAG_MEDITATION",
    DeleteTag = "DELETE_CONFIG_TAG_MEDITATION",
}

export enum RubricConfigActionTypeEnum {
    GetData = "GET_RUBRIK_CONFIG_MEDITATION",
    LoadSections = "LOAD_SECTION_TO_UPDATE_MEDITATION",
    ClearSectionToUpdate = "CLEAR_SECTION_TO_UPDATE_MEDITATION",
}
