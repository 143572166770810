import {
  DailySetsActionTypeEnum,
  DailyCardActionTypeEnum
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";


const initialState = {
  DailySets: [],
  DailyCards: [],
  DailySetsTotal: null,
  DailyCardsTotal: 0,
  params: {},
  allData: [],
  selectDefaultDailySets: [],
  DefaultDailySets: [],
  BlockModeDailySetsLoading: false,
  DataLoading: true
};

const DailySetReducer = (state = initialState, action) => {
  switch (action.type) {
    case DailySetsActionTypeEnum.AddDefaultDailySets:
      return {
        ...state,
        DefaultDailySets: action.default,
      };

    case DailySetsActionTypeEnum.List:
      return {
        ...state,
        DailySets: action.DailySets,
        DailySetsTotal: action.count,
        DataLoading: action.loading
      };

      case DailySetsActionTypeEnum.Loading:
        return {
          ...state,
          DataLoading: action.loading
        };

    case DailySetsActionTypeEnum.Filter:
      return {
        ...state,
        DailySets: action.DailySets,
      };

    case DailySetsActionTypeEnum.ListInSelect:
      return {
        ...state,
        selectDefaultDailySets: action.DailySets,
      };
    case DailySetsActionTypeEnum.Add:
      let newDailySetsState = !state.DailySets ? [] : state.DailySets;
      newDailySetsState.unshift(action.DailySets);
      return {
        ...state,
        DailySets: newDailySetsState,
        DailySetsTotal: newDailySetsState.count
      };
    case DailySetsActionTypeEnum.Update:
      let DailySetsState = !state.DailySets ? [] : state.DailySets;
      let newArrayDailySets = updatedArray(DailySetsState, action.DailySets);
      return {
        ...state,
        DailySets: newArrayDailySets,
      };
    case DailySetsActionTypeEnum.Delete:
      let DailySets = !state.DailySets ? [] : state.DailySets;
      let updDailySetsArray = arrayReduce(DailySets, action.DailySets);
      return {
        ...state,
        DailySets: updDailySetsArray,
        DailySetsTotal: updDailySetsArray.length
      };

    case DailyCardActionTypeEnum.List:
      return {
        ...state,
        DailyCards: action.DailyCards,
        DailyCardsTotal: action.DailyCards.length,
      };

    case DailyCardActionTypeEnum.Add:
      let newDailyCardstate = !state.DailyCards ? [] : state.DailyCards;
      newDailyCardstate.unshift(action.DailyCard);
      return {
        ...state,
        DailyCards: newDailyCardstate,
        DailyCardsTotal: newDailyCardstate.length,
      };
    case DailyCardActionTypeEnum.SetEmpty:
      return {
        ...state,
        DailyCards: [],
      };
    case DailyCardActionTypeEnum.BlockModeLoading:
      return {
        ...state,
        BlockModeDailySetsLoading: action.data,
      };
    case DailyCardActionTypeEnum.Update:
      let DailyCardstate = !state.DailyCards ? [] : state.DailyCards;
      let newArrayVideo = updatedArray(DailyCardstate, action.DailyCard);
      return {
        ...state,
        DailyCards: newArrayVideo,
      };
    case DailyCardActionTypeEnum.Delete:
      let VIDEO = !state.DailyCards ? [] : state.DailyCards;
      let updVideoArray = arrayReduce(VIDEO, action.DailyCard);
      return {
        ...state,
        DailyCards: updVideoArray,
        DailyCardsTotal: updVideoArray.length,
      };
    default:
      return state;
  }
};

export default DailySetReducer;
