import {
  Options,
  MemberUpdateRequest,
  MemberCreateRequest,
  AddImageToMember,
  MemberGetAllRequest,
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'

function getPaginationQuery(getAllQuery: MemberGetAllRequest) {
  let query = ``

  if (typeof getAllQuery.offset !== 'undefined') {
    query += `${query ? '&' : '?'}offset=${getAllQuery.offset}`
  }

  if (typeof getAllQuery.limit !== 'undefined') {
    query += `${query ? '&' : '?'}limit=${getAllQuery.limit}`
  }

  if (typeof getAllQuery.active_only !== 'undefined') {
    query += `${query ? '&' : '?'}active_only=${getAllQuery.active_only}`
  }

  if (typeof getAllQuery.search_text !== 'undefined') {
    query += `${query ? '&' : '?'}search_text=${getAllQuery.search_text}`
  }

  return query
}

export default class MemberService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  // GET

  async getAll(getAllQuery: MemberGetAllRequest) {
    const query = getPaginationQuery(getAllQuery)

    return request('GET', `/members/${query}`, this.options)
  }

  async getMembersById(member_id: number) {
    return request('GET', `/admin/members/${member_id}`, this.options)
  }

  // POST

  async createMember(data: MemberCreateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/members/`, requestOptions)
  }

  async addImageToMember({ member_id, image }: AddImageToMember) {
    const formData = new FormData()
    formData.append('image', image)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      ...this.options.headers,
      data: formData
    }
    return request('POST', `/admin/members/${member_id}/images`, requestOptions)
  }

  // PUT

  async updateMember({ member_id, ...data }: MemberUpdateRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/admin/members/${member_id}`, requestOptions)
  }

  // DELETE

  async deleteMember(member_id: number) {
    return request('DELETE', `/admin/members/${member_id}`, this.options)
  }

  async removeImageOnMember(member_id: number, image_id: number) {
    return request('DELETE', `/admin/members/${member_id}/images/${image_id}`, this.options)
  }
}
