import * as dataSchemas from './utils/DataSchemas';
import { Options } from '../../services.utils/request-types';
import API from '../../services.utils/config'
import ChannelService from './services/channels';
import MemberService from './services/members';
import MessageService from './services/messages';
import EventService from './services/events';
import MediaService from './services/medias';
import StarredMessageService from './services/starredMessages';
import LinkService from './services/links';
import AbuseService from './services/abuses';

const objectAssignDeep = require(`object-assign-deep`);

export class Client {

  options: Options;
  private memberService!: MemberService;
  private messageService!: MessageService;
  private mediaService!: MediaService;
  private channelService!: ChannelService;
  private eventService!: EventService;
  private starredMessageService!: StarredMessageService;
  private linkService!: LinkService;
  private abuseService!: AbuseService;
  private websocket!: any;

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json'
    };
    this.options = objectAssignDeep({}, defaultOptions, options);
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep({}, options);
    this.memberService = new MemberService(this.options);
    this.channelService = new ChannelService(this.options);
    this.messageService = new MessageService(this.options);
    this.mediaService = new MediaService(this.options);
    this.eventService = new EventService(this.options);
    this.starredMessageService = new StarredMessageService(this.options);
    this.abuseService = new AbuseService(this.options);
    this.linkService = new LinkService(this.options);
  }

  /**
   * For component which need socket notif
   */
  initSocket(
    // set all your notif functions
    onConnected: () => {} | void,
    onDisconnected: () => {} | void,
    onMemberIn: (userId: string) => {} | void,
    onMemberOut: (userId: string) => {} | void,
    onChannelNew: (channel: dataSchemas.Channel) => {} | void,
    onChannelEdit: (channel: dataSchemas.Channel) => {} | void,
    onChannelDelete: (channel: dataSchemas.Channel) => {} | void,
    onChannelMember: (data: {}) => {} | void, // notify in/out channel's member
    onMessageNew: (message: dataSchemas.Message) => {} | void,
    onMessageEdit: (message: dataSchemas.EditMessageSocketRequest) => {} | void,
    onMessageDelete: (message: dataSchemas.Message) => {} | void,
    onAdminMessageDelete: (message: dataSchemas.Message) => {} | void,
    onStartTyping: (data: dataSchemas.TypingData) => {} | void,
    onStopTyping: (data: dataSchemas.TypingData) => {} | void,
    onManageWishingWell: (data: dataSchemas.WishWellPayload) => {} | void,
    onManageBlockingUserProcess: (data: dataSchemas.BlockingUserProcessPayload) => {} | void,
    onManageUserReadMessages: (data: dataSchemas.UserReadMessagesPayload) => {} | void,
    onManageMessageReceived: (data: dataSchemas.SetMessageAsReceivedPayload) => {} | void,
    onManageUserSetting: (data: dataSchemas.ManageUserSettingPayload) => {} | void,
  ) {
    if (!this.websocket || this.websocket.readyState === WebSocket.CLOSED) {
      this.websocket = new WebSocket(
        `${this.options.socketEndpoint}?userId=${this.options.userId}`
      );
    }

    this.websocket.onopen = onConnected;

    this.websocket.onerror = (error: any) => {
      console.log("WebSocket Error " + JSON.stringify(error));
    };

    this.websocket.onclose = function (e: any) {

      if (e.reason !== "logout") {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second.",
          e.reason
        );
        setTimeout(onDisconnected, 1000);
      }
    };

    this.websocket.onmessage = (event: any) => {
      const { type, data } = JSON.parse(event.data.toString());
      switch (type) {
        case "memberIn":
          onMemberIn(data.userId);
          break;
        case "memberOut":
          onMemberOut(data.userId);
          break;
        case "channelNew":
          onChannelNew({ ...data });
          break;
        case "channelEdit":
          onChannelEdit({ ...data });
          break;
        case "channelDelete":
          onChannelDelete({ ...data });
          break;
        case "channelMember":
          onChannelMember({ ...data });
          break;
        case "messageNew":
          onMessageNew({ ...data });
          break;
        case "messageDelete":
          onMessageDelete({ ...data });
          break;
        case "adminMessageDelete":
          onAdminMessageDelete({ ...data });
          break;
        case "startTypeMessage":
          onStartTyping({ ...data });
          break;
        case "stopTypeMessage":
          onStopTyping({ ...data });
          break;
        case "manageWishingWell":
          onManageWishingWell({ ...data });
          break;
        case "manageBlockingUserProcess":
          onManageBlockingUserProcess({ ...data });
          break;
        case "messageEdit":
          onMessageEdit({ ...data });
          break;
        case "manageUserReadMessages":
          onManageUserReadMessages({ ...data });
          break;
        case "manageMessageReceived":
          onManageMessageReceived({ ...data });
          break;
        case "manageUserSetting":
          onManageUserSetting({ ...data });
          break;
        default:
          break;
      }
    }
  }

  /**
   * Close websocket connection
   */
  closeSocket = () => {
    this.websocket?.close(1000, "logout");
  }

  /**
   * Get all channels
   * @param options Optional. Set options for GET requests
   */
  getAllChannels = async (options: dataSchemas.paginationQuery): Promise<dataSchemas.Pagination<dataSchemas.Channel>> => {
    return this.channelService.getAll(options);
  }

  /**
  * Get main channel
  */
  getMainChannel = async () => {
    return this.channelService.getMainChannel();
  }

  /**
   * Get all broadcast channels
   * @param options Optional. Set options for GET requests
   */
  getAllBroadCastChannels = async (options?: dataSchemas.GetAllWithOptions) => {
    return this.channelService.getAllBroadCast(options);
  }


  searchText = async (channelId: string, searchText: string): Promise<Array<dataSchemas.Message>> => this.channelService.search(channelId, searchText);
  searchTextAll = async (data: dataSchemas.searchTextAllInfo): Promise<dataSchemas.Pagination<dataSchemas.Message>> => this.channelService.searchAll(data);

  /**
   * Get channels by id
   * @param channelId Channel id
   * @param options Optional. Set options for GET requests
   */
  getChannelById = async (channelId: string): Promise<dataSchemas.Channel> => this.channelService.getById(channelId);

  /**
   * Get channels unread messages
   * @param channelId Channel id
   * @param options Optional. Set options for GET requests
   */
  getChannelUnreadMessages = async (channelId: string): Promise<dataSchemas.Message[]> => this.channelService.getChannelUnreadMessages(channelId);

  /**
   * Get channel's messages
   * @param channelId Channel id
   * @param options Optional. Set options for GET requests
   */
  getMessages = async (channelId: string, options?: dataSchemas.GetOptions) => this.messageService.getMessages(channelId,
    (options && options.limit) || 50,
    (options && options.beforeId),
    (options && options.aroundId),
    (options && options.afterId),
    (options && options.equal || false),
    (options && options.sort))

  /**
* Get count unread message
*/
  getCountUnreadMessage = async () => {
    return this.messageService.getCountUnreadMessage();
  }

  /**
 * Get medias by channel id
 * @param channelId Channel id
 * @param type file type
 */
  getMedias = async (channelId: string, type: string = '') => {
    return this.mediaService.getMedias(channelId, type);
  }

  /**
* Get media by id
* @param mediaId media id
*/
  getMedia = async (mediaId: string) => {
    return this.mediaService.getMedia(mediaId);
  }

  /**
 * Get medias by channel id
 * @param channelId Channel id
 * @param type file type
 */
  getMediasWithOtherUserId = async (otherUserId: string, type: string = '') => {
    return this.mediaService.getMediasWithOtherUserId(otherUserId, type);
  }

  /**
   * Get channel's message
   * @param options . Set options for GET requests
   */
  getMessagesWithOptions = async (options: dataSchemas.GetAllMessageWithOptions) => {
    return this.messageService.getMessagesWithOptions(options);
  }

  /**
   * edit channel's unread property
   * @param channelId Channel id
   */
  editChannelUnread = async (channelId: string) => {
    return this.channelService.editChannelUnread(channelId);
  }

  /**
   * edit channel's favorite property
   * @param channelId Channel id
   */
  editChannelFavorite = async (channelId: string) => {
    return this.channelService.editChannelFavorite(channelId);
  }
  /**
   * edit channel's archive property
   * @param channelId Channel id
   */
  editChannelArchive = async (channelId: string) => {
    return this.channelService.editChannelArchive(channelId);
  }
  /**
   * edit channel's notification property
   * @param channelId Channel id
   */
  editChannelNotification = async (channelId: string) => {
    return this.channelService.editChannelNotification(channelId);
  }

  /**
   * Block or unblock user process
   * @param channelId Channel id
   * @param userId User id
   */
  handleBlockUser = async (channelId: string, userId: string) => {
    return this.channelService.handleBlockUser(channelId, userId);
  }

  /**
   * Block or unblock user process version 2
   * @param userId User id
   */
  handleBlockUserFromIds = async (userId: string) => {
    return this.channelService.handleBlockUserFromIds(userId);
  }

  /**
   * Add or remove admin user process
    * @param channelId Channel id
   * @param userId User id
   */
  handleAdminUser = async (channelId: string, userId: string) => {
    return this.channelService.handleAdminUser(channelId, userId);
  }

  /**
   * Post channel
   * @param body Body of channel
   */
  createChannel = async (body: dataSchemas.ChannelRequest) => this.channelService.createChannel(body);

  /**
   * add member to a channel
   * @param channelId channel's id
   * @param userId userId to add on the channel members list
   */
  addMemberChannel = async (channelId: string, userId: string) => this.channelService.manageMember(channelId, userId, dataSchemas.actionTypes.add);

  /**
   * remove member to a channel
   * @param channelId channel's id
   * @param userId userId to add on the channel members list
   */
  removeMemberChannel = async (channelId: string, userId: string) => this.channelService.manageMember(channelId, userId, dataSchemas.actionTypes.remove);

  /**
   * Delete channel
   * @param channelId Channel id
   */
  deleteChannel = async (channelId: string) => this.channelService.deleteChannel(channelId);

  /**
   * Edit channel
   * @param channelId Channel id
   */
  editChannel = async (channelId: string, data: dataSchemas.ChannelRequest) => this.channelService.editChannel(channelId, data);

  /**
   * Get common channel group
   * @param otherUserId other user id
   */
  getCommonGroupChannel = async (otherUserId: string) => this.channelService.getCommonGroupChannel(otherUserId);

  /**
  * Post new message
  * @param body Body of message
  */
  postMessage = async (message: dataSchemas.MessageRequest) => this.messageService.postMessage(message);

  /**
  * Edit message
  * @param body Body of message
  */
  editMessage = async (id: string, data: dataSchemas.UpdateMessageRequest) => this.messageService.editMessage(id, data);

  /**
 * Delete message
 * @param messageId Message id
 */
  deleteMessage = async (messageId: string, byAdmin: boolean) => this.messageService.deleteMessage(messageId, byAdmin);

  /**
 * Delete message for other user
 * @param messageId Message id
 */
  deleteMessageHideMode = async (messageId: string) => this.messageService.deleteMessageHideMode(messageId);

  /**
 * Set message as received
 * @param messageId Message id
 */
  setMessageAsReceived = async (messageId: string) => this.messageService.setMessageAsReceived(messageId);

  /**
 * Update messages to add user who read after turn on read indicator
 */
  updateMessagesToAddUserWhoReadAfterTurnOnReadIndicator = async () => this.messageService.updateMessagesToAddUserWhoReadAfterTurnOnReadIndicator();

  /**
  * Get list of unread messages
  * @param userId userId
  */
  getUnreadMessages = async (): Promise<Array<dataSchemas.Event>> => this.eventService.getUnreadEvents();

  /**
  * Get list of unread event information
  * @param userId userId
  */
  getUnreadEventsLimitedInfo = async (): Promise<Array<string>> => this.eventService.getUnreadEventsLimitedInfo();

  /**
  * Set list of channel's messages as read
  * @param userId userId
  */
  setMessagesAsRead = async (channelId: string, isUserReadView: boolean, canSetUnread?: boolean) => {
    const result = await this.eventService.deleteEventsByUserIdAndEventType(channelId, isUserReadView);

    if (canSetUnread) {
      await this.editChannelUnread(channelId)
    }

    return result;
  }

  /**
   * create new media for a channel
   * @param options Optional. Set options for GET requests
   */
  createMedia = async (body: dataSchemas.MediaRequest) => {
    return this.mediaService.postMedia(body);
  }

  startTyping = async (channelId: string, members: Array<string>, userId: string, isChannelPublic: boolean = false): Promise<void> => {

    try {

      const membersWithoutUserId = members.filter(m => m !== userId);

      const wsMessage = {
        channelId,
        userId,
        members: membersWithoutUserId,
        type: "startTypeMessage",
        isChannelPublic
      }
      this.websocket && this.websocket.send(JSON.stringify({
        event: "manageMessages",
        data: JSON.stringify(wsMessage),
      }));
    } catch (error) {
      console.error(error)
    }
  }

  stopTyping = async (channelId: string, members: Array<string>, userId: string, isChannelPublic: boolean = false): Promise<void> => {
    try {

      const membersWithoutUserId = members.filter(m => m !== userId);

      const wsMessage = {
        channelId,
        userId,
        members: membersWithoutUserId,
        type: "stopTypeMessage",
        isChannelPublic
      };

      this.websocket && this.websocket.send(JSON.stringify({
        event: "manageMessages",
        data: JSON.stringify(wsMessage),
      }));
    } catch (error) {
      console.error(error)
    }
  }

  manageWishingWell = async (payload: dataSchemas.WishWellPayload): Promise<void> => {

    try {
      this.websocket && this.websocket.send(JSON.stringify({
        event: "manageWishingWells",
        data: payload,
      }));
    } catch (error) {
      console.error(error)
    }
  }

  manageUserDirectMessageSetting = async (payload: dataSchemas.DirectMessagePayload): Promise<void> => {

    try {
      this.websocket && this.websocket.send(JSON.stringify({
        event: "manageUserDirectMessage",
        data: payload,
      }));
    } catch (error) {
      console.error(error)
    }
  }

  manageUserSetting = async (payload: dataSchemas.UserSettingPayload): Promise<void> => {

    try {
      this.websocket && this.websocket.send(JSON.stringify({
        event: "manageUserSetting",
        data: payload,
      }));
    } catch (error) {
      console.error(error)
    }
  }


  /**
  * Get current online members distinctly (by user id)
  */
  getDistinctMembers = async (): Promise<Array<string>> => this.memberService.getDistinctMembers();


  getStarredMessages = async (channelId: string): Promise<Array<dataSchemas.StarredMessage>> => this.starredMessageService.getStarredMessages(channelId);

  getStarredMessagesWithOtherUserId = async (otherUserId: string): Promise<Array<dataSchemas.StarredMessage>> => this.starredMessageService.getStarredMessagesWithOtherUserId(otherUserId);

  createStarredMessage = async (data: dataSchemas.StarredMessageRequest): Promise<dataSchemas.StarredMessage> => this.starredMessageService.createStarredMessage(data);

  deleteStarredMessages = async (id: String): Promise<boolean> => this.starredMessageService.deleteStarredMessages(id);

  deleteStarredMessagesByChannelIdMessageIdAndUserId = async (data: dataSchemas.StarredMessageRequest): Promise<boolean> => this.starredMessageService.deleteStarredMessagesByChannelIdMessageIdAndUserId(data);


  getLinks = async (channelId: string): Promise<Array<dataSchemas.Link>> => this.linkService.getLinks(channelId);

  getLinksWithOtherUserId = async (otherUserId: string): Promise<Array<dataSchemas.Link>> => this.linkService.getLinksWithOtherUserId(otherUserId);

  deleteLinks = async (id: String): Promise<boolean> => this.linkService.deleteLinks(id);

  /**
  * delete a media
  */
  deleteMedia = async (mediaId: string) => {
    return this.mediaService.deleteMedia(mediaId);
  }

  /**
  * Notify others that I receive unread messages
  */
  notifyThatIReceivedAllMessages = async (): Promise<boolean> => this.eventService.notifyThatIReceivedAllMessages();

  /**
  * Report abuse
  */
  getAllAbuse = async (options: dataSchemas.GetAllAbuseWithOptions): Promise<dataSchemas.Pagination<dataSchemas.Abuse>> => {
    return this.abuseService.getAllAbuse(options);
  }

  getAbuseById = async (id: string): Promise<dataSchemas.Abuse> => {
    return this.abuseService.getAbuseById(id);
  }

  createAbuse = async (data: dataSchemas.AbuseRequest): Promise<dataSchemas.Abuse> => {
    return this.abuseService.createAbuse(data);
  }

  resolveAbuse = async (data: dataSchemas.ResolveAbuseRequest): Promise<dataSchemas.Abuse> => {
    return this.abuseService.resolveAbuse(data);
  }

  deleteAbuse = async (id: string): Promise<boolean> => {
    return this.abuseService.deleteAbuse(id);
  }
}

export * as types from "./utils/DataSchemas"
