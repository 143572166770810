import {
  UserGetAllRequest,
  UserUpdateRequest,
  UserCreateRequest, ChangeEmailRequest, ScheduleDisablingUserRequest
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class AdminService {
    options: Options

    constructor(options: Options) {
        this.options = options
    }

    async createProfileOnAmin(data: UserCreateRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data
        }
        return request('POST', `/admin/profiles/`, requestOptions)
    }

    async updateProfileByIdOnAmin(profile_id: number, data: UserUpdateRequest) {
        const requestOptions: Options = {
            ...this.options,
            data: data,
        }
        return request('PUT', `/admin/profiles/${profile_id}/`, requestOptions)
    }

    async resetPasswordOnAmin(profile_id: number) {
        return request('PUT', `/admin/profiles/${profile_id}/reset-password`, this.options)
    }

    async getAllUsersOnAmin(paginationOption: UserGetAllRequest) {
        let query = ''

        if (typeof paginationOption.search_text !== 'undefined') {
            query
                ? (query += `&search_text=${paginationOption.search_text}`)
                : (query += `?search_text=${paginationOption.search_text}`)
        }

        if (typeof paginationOption.free_user !== 'undefined') {
            query
                ? (query += `&free_user=${paginationOption.free_user}`)
                : (query += `?free_user=${paginationOption.free_user}`)
        }

        if (typeof paginationOption.status !== 'undefined') {
            query
                ? (query += `&status=${paginationOption.status}`)
                : (query += `?status=${paginationOption.status}`)
        }

        if (typeof paginationOption.offset !== 'undefined') {
            query
                ? (query += `&offset=${paginationOption.offset}`)
                : (query += `?offset=${paginationOption.offset}`)
        }

        if (typeof paginationOption.limit !== 'undefined') {
            query
                ? (query += `&limit=${paginationOption.limit}`)
                : (query += `?limit=${paginationOption.limit}`)
        }

        if (typeof paginationOption.sort_by !== 'undefined') {
            query
                ? (query += `&sort_by=${paginationOption.sort_by}`)
                : (query += `?sort_by=${paginationOption.sort_by}`)
        }

        if (typeof paginationOption.sort_direction !== 'undefined') {
            query
                ? (query += `&sort_direction=${paginationOption.sort_direction}`)
                : (query += `?sort_direction=${paginationOption.sort_direction}`)
        }

        return request('GET', `/admin/profiles/${query}`, this.options)
    }

    async getDetailedProfileOnAmin(profile_id: number) {
        return request('GET', `/admin/profiles/${profile_id}/detailed-view`, this.options)
    }

    async clearCaches() {
        return request('GET', `/admin/clear-caches`, this.options)
    }

  async ChangeUserEmail(profile_id: number, data: ChangeEmailRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `admin/profiles/${profile_id}/change-digistore-email/`, requestOptions)
  }


  async scheduleDisablingUser(profile_id: number, data: ScheduleDisablingUserRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data,
    }
    return request('PUT', `admin/profiles/${profile_id}/schedule-disabling-user/`, requestOptions)
  }
}
