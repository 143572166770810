import { AxiosRequestConfig } from 'axios'

// CHANNEL
export interface Channel {
  _id: string
  name: string
  members: Array<string>
  unreads: Array<string>
  favorites: Array<string>
  archives: Array<string>
  notifications: Array<string>
  blocked: Array<string>
  admins: Array<string>
  excludedUsers: Array<string>
  isActive: boolean
  isPrivate: boolean
  isGroup: boolean
  isBroadcast: boolean
  isPublic: boolean
  isMeetupChannel: boolean
  isLiveEventChannel: boolean
  isMainChannel: boolean
  createdOn: Date
  createdBy: string
  is_reported: boolean
  is_reported_abuse: Abuse | null
  lastMessage: Message
  hasNewMessage?: boolean
}

export interface ChannelRequest {
  name?: string
  members?: Array<string>
  isActive?: boolean
  isPrivate?: boolean
  isGroup?: boolean
  isBroadcast?: boolean
  isPublic?: boolean
  isMeetupChannel?: boolean
  isLiveEventChannel?: boolean
}

// MESSAGE
export interface Message {
  _id: string
  channelId: string
  content?: string
  medias?: Media[]
  createdOn: Date
  createdBy: string
  updatedAt?: Date
  isHidden: boolean,
  parentMessage?: any
  taggedUsers?: Array<string>
  usersWhoStarred?: Array<string>
  usersWhoRead?: Array<string>
  isEdited?: boolean
  isDeleted?: boolean
  isParentDeleted?: boolean
  isReceived?: boolean
}

export interface Abuse {
  _id: string
  status: ABUSE_TYPE_ENUM
  notes: string
  reason: string
  reported_dt: Date
  reported_by: string
  channel: string
}

export interface Pagination<T> {
  total: number
  data: Array<T>
}

export interface AbuseRequest {
  reason: string
  channel: string
}

export interface ResolveAbuseRequest {
  id: string
  notes: string
}

export enum ABUSE_TYPE_ENUM {
  PENDING = 'pending',
  RESOLVE = 'resolved',
}

export interface MessageRequest {
  channelId: string
  content?: string
  medias?: Array<string>
  createdBy: string
  parentMessage?: string
  taggedUsers?: Array<string>
}

// MEDIA
export interface Media {
  _id: string
  channel: string
  url: string
  createdOn: Date
  createdBy: string
  name: string
  type: string
  size: number
}

export interface MediaRequest {
  channelId: string
  createdBy: string
  media: Blob
}

// MEMBER
export interface Member {
  _id: number
  connectionId: string
  userId: string
  createdAt: Date
}

export interface MemberRequest {
  connectionId: string
  userId: string
}

// EVENT
export interface Event {
  _id: string
  channelId: string
  userId: string
  messageId: string
  eventType: EventType
  createdOn: Date
}

export interface EventRequest {
  channelId: string
  userId: string
  messageId?: string
  eventType: EventType
}

export interface UpdateMessageRequest {
  content: string;
  medias?:  string[];
  parentMessage?: string;
  taggedUsers?: Array<string>
}

export interface EditMessageSocketRequest {
  messageId: string
  channelId: string
  values: any
}

export enum EventType {
  MESSAGE_UNREAD = 'MESSAGE_UNREAD'
}

// STARRED MESSAGE
export interface StarredMessage {
  _id: string
  userId: string
  createdOn: Date
  channelId: string
  message: Message
}

export interface StarredMessageRequest {
  channelId: string
  message: string
}

// LINK
export interface Link {
  _id: string
  userId: string
  createdOn: Date
  channelId: string
  link: string
  message: Message
}

// SDK OPTIONS
export interface Options extends AxiosRequestConfig {
  version?: string
  token?: string
  baseURL?: string
  timeout?: number
  socketEndpoint?: string
  userId?: string
}

export interface GetOptions {
  limit?: number
  page?: number
  sort?: string
  beforeId?: string
  aroundId?: string
  afterId?: string
  equal?: boolean
}

export interface GetAllWithOptions {
  limit: number
  offset: number
  search?: string
}

export interface GetAllAbuseWithOptions {
  limit: number
  offset: number
  status?: ABUSE_TYPE_ENUM
}

export interface GetAllMessageWithOptions extends GetAllWithOptions {
  channelId: string
}

export interface SocketPushMessage {
  connectionIds?: Array<string>
  socketData: SocketData
}

export interface TypingData {
  channelId: string
  userId: string
}

export interface SocketData {
  type: string
  data: any
}

export interface WishWellPayload {
  type: actionTypesWishingWell
  data: any
}

export interface DirectMessagePayload {
  type: MANAGE_USER_SETTING_ACTIONS_ENUM
  userId: any
}

export interface UserSettingPayload {
  type: MANAGE_USER_SETTING_ACTIONS_ENUM
  userId: any
  settingOption: string
}

export interface BlockingUserProcessPayload {
  channelId: string
  userId: string
  isBlocked: boolean
}

export interface UserReadMessagesPayload {
  channelIds: Array<string>
  userId: string
  messageIds: Array<string>
}

export interface SetMessageAsReceivedPayload {
  channelIds: Array<string>
  messageIds: Array<string>
}

export enum MANAGE_USER_SETTING_ACTIONS_ENUM {
  ENABLE = 'enable',
  DISABLE = 'disable',
}

export interface ManageUserSettingPayload {
  settingOption: string
  userId: string
  actionType: MANAGE_USER_SETTING_ACTIONS_ENUM
}

// TODO
export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export enum actionTypes {
  add = 'add',
  remove = 'remove'
}

export enum actionTypesWishingWell {
  add = 'add',
  remove = 'remove',
  edit = 'edit'
}

export interface searchTextAllInfo {
  searchText: string
  offset: number
  limit: number
}

export interface paginationQuery {
  offset: number
  limit: number
}
