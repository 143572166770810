export enum RubricsActionTypeEnum {
    List = "LIST_RUBRICS",
    Filter = "FILTER_RUBRICS",
    ListInSelect = "SELECT_LIST_RUBRICS",
    AddDefaultRubric = "ADD_DEFAULT_RUBRIC",
    Add = "ADD_RUBRICS",
    Update = "UPDATE_RUBRICS",
    Delete = "DELETE_RUBRICS"
}

export enum EventsActionTypeEnum {
    List = "LIST_EVENTS",
    Filter = "FILTER_EVENTS",
    ListExperts = "LIST_EXPERTS",
    DefaultExperts = "DEFAULT_EXPERTS",
    DefaultMediathekRubric = "DEFAULT_MEDIATHEK_RUBRIC",
    DefaultMediathekVideo = "DEFAULT_MEDIATHEK_VIDEO",
    AddDefaultEvent = "ADD_DEFAULT_EVENT",
    BlockModeLoading = "SET_BLOCK_MODE_LOADING_EVENTS",
    SetEmpty = "SET_EMPTY_EVENTS",
    Add = "ADD_EVENTS",
    Update = "UPDATE_EVENTS",
    Delete = "DELETE_EVENTS"
}

export enum ModuleConfigActionTypeEnum {
    GetData = "GET_CONFIG_LIVE_EVENTS",
    BlockModeLoading = "SET_BLOCK_MODE_VIDEOS_LOADING_LIVE_EVENTS",
    Update = "UPDATE_CONFIG_LIVE_EVENTS",
    AddLiveEventsIds = "ADD_CONFIG_LIVE_EVENTS_ID",
    DeleteLiveEventsId = "DELETE_CONFIG_LIVE_EVENTS_ID",
    AddTag = "ADD_CONFIG_TAG_LIVE_EVENTS",
    DeleteTag = "DELETE_CONFIG_TAG_LIVE_EVENTS",
    AddHighlightEvents = "ADD_CONFIG_HIGHLIGHT_LIVE_EVENTS",
    DeleteHighlightEvents = "DELETE_CONFIG_HIGHLIGHT_LIVE_EVENTS"
}