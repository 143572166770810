import CommentService from './services/Comment'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private commentService!: CommentService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.commentService = new CommentService(this.options)
  }

  /**
   * Get all comments
   */
  getAllComments = (
    getAllQuery: dataSchemas.CommentGetAllRequest = {
      owner_domain: dataSchemas.OwnerDomain.mediathek,
      owner_object: dataSchemas.OwnerObject.video,
      owner_id: 1,
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.Pagination> => this.commentService.getAll(getAllQuery)

  /**
   * Get comment by id
   * @param data Object with comment id
   */
  getCommentById = (
    data: dataSchemas.CommentById
  ): Promise<dataSchemas.Comment> => this.commentService.getById(data)

  /**
   * Post comment
   * @param body Body of comment
   */
  createComment = (
    body: dataSchemas.CommentCreateRequest
  ): Promise<dataSchemas.Comment> => this.commentService.createComment(body)

  /**
   * Post comment image
   * @param body Body of comment
   */
  createCommentImages = (
    body: dataSchemas.CommentCreateImageRequest
  ): Promise<dataSchemas.Comment> => this.commentService.createCommentImage(body)

  /**
   * Update comment
   * @param commentId Object with comment id
   * @param body Body of comment
   */
  updateComment = (
    commentId: dataSchemas.CommentById,
    body: dataSchemas.CommentUpdateRequest
  ): Promise<dataSchemas.Comment> =>
    this.commentService.editComment(commentId, body)

  /**
   * Delete comment
   * @param commentId Object with comment id
   */
  deleteComment = async (
    { comment_id }: dataSchemas.CommentById
  ): Promise<dataSchemas.Comment> => {
    return this.commentService.deleteComment({ comment_id: comment_id })
  }

  /**
   * Get object status
   * @param data
   */
  getStatus = (data: dataSchemas.StatusInterface): Promise<dataSchemas.DataFromStat> =>
    this.commentService.getStatus(data)

  /**
  * Delete comment image
  * @param data Object with comment id and image id
  */
  deleteCommentImage = (data: dataSchemas.CommentDeleteImage): Promise<string> =>
    this.commentService.deleteCommentImage(data)


  /**
   * Get all report_abuses
   */
  getAllReportAbuses = (
    getAllQuery: dataSchemas.PaginationReportAbusesOption = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.BasePagination<dataSchemas.CommentPostReport>> => this.commentService.getAllReportAbuses(getAllQuery)

  /**
   * get a report_abuses by id
   */
  getByIdReportAbuses = (
    data: dataSchemas.CommentReportAbusesParams
  ): Promise<dataSchemas.CommentPostReport> => this.commentService.getByIdReportAbuses(data)

  /**
   * edit a post
   * @param abuse_id
   */
  editReportAbuses = (
    abuse_id: number,
    body: dataSchemas.CommentPostReportRequest
  ): Promise<dataSchemas.CommentPostReport> => this.commentService.editReportAbuses(abuse_id, body)

  /**
 * report a comment
 * @param data
 */
  reportPost = (
    data: dataSchemas.ReportComment
  ): Promise<dataSchemas.CommentPostReport> => this.commentService.reportAbuse(data)

  /**
   * cancel a report
   * @param comment_id
   */
  cancelReport = (
    comment_id: number
  ): Promise<dataSchemas.CommentPostReport> => this.commentService.cancelReport(comment_id)

  postReaction = (reactionBody: dataSchemas.PostReaction): Promise<void> => this.commentService.postReaction(reactionBody)
  deleteReaction = (data: dataSchemas.CommentById): Promise<void> =>
    this.commentService.deleteReaction(data)
  getAllReactions = (getAllQuery: dataSchemas.LikeGetAllRequest = {
    comment_id: 1,
    offset: 0,
    limit: 15
  }
  ): Promise<dataSchemas.PaginationGeneric<dataSchemas.ReactionData>> => this.commentService.getReactionsByComment(getAllQuery)
}

export * as types from './utils/DataSchemas'
