// eslint-disable-next-line no-unused-vars
import { AxiosRequestConfig } from 'axios'

export interface ImageTagInterface {
  x: number
  y: number
  user_id: number
}

export interface ImageInterface {
  id?: number
  nb_likes?: number
  created_at?: Date
  created_by?: number
  updated_at?: Date
  updated_by?: number
  user_id?: number
  album_id?: number
  path: string
  description?: string
  owner_id?: number
  tagged_user_ids?: Array<any>
  cropped_data?: CroppedDataInterface
  like_by_user_ids?: Array<number>
  title?: string
  url?: string
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area?: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}

export interface Rubrik {
  id: number
  image?: any
  title: string
  published: boolean
  // eslint-disable-next-line camelcase
  short_description?: string
  // eslint-disable-next-line camelcase
  sort_index?: number
  tag_ids: Array<number>
  tags: Array<Tag>
  // eslint-disable-next-line camelcase
  created_at?: Date
  // eslint-disable-next-line camelcase
  created_by?: number
  // eslint-disable-next-line camelcase
  updated_at?: Date
  // eslint-disable-next-line camelcase
  updated_by?: number
  published_dt?: Date
}

export interface Tag {
  id: number
  value: string
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}

export interface RubrikRequest {
  image?: any
  title: string
  published?: boolean
  // eslint-disable-next-line camelcase
  sort_index?: number
  // eslint-disable-next-line camelcase
  short_description?: string
  tag_ids?: Array<number>
  published_dt?: Date
}

export interface ImageRequest {
  title: string
  image: Blob
}

export interface VideoRequest {
  title: string
  video: Blob
}

export interface AudioRequest {
  title: string
  audio: Blob
}

export interface AttachmentRequest {
  title: string
  attachment: Blob
}

export interface ReferenceRequest {
  title: string
  // eslint-disable-next-line camelcase
  external_url: string
}

export interface Artikel {
  id: number
  // eslint-disable-next-line camelcase
  rubric_ids?: Array<number>
  images?: Array<any>
  title: string
  // eslint-disable-next-line camelcase
  sort_index?: number
  published: boolean
  is_public: boolean
  // eslint-disable-next-line camelcase
  published_dt?: Date
  content?: string
  videos?: Array<any>
  audios?: Array<any>
  // eslint-disable-next-line camelcase
  reading_duration: number
  // eslint-disable-next-line camelcase
  short_description?: string
  description?: string
  attachments?: Array<AttachmentOrReference>
  references?: Array<AttachmentOrReference>
  is_liked_by_user: boolean
  is_favorite_by_user: boolean
  tag_ids: Array<number>
  tags: Array<Tag>
  author_ids?: Array<number>
  // eslint-disable-next-line camelcase
  created_at: Date
  // eslint-disable-next-line camelcase
  created_by?: number
  // eslint-disable-next-line camelcase
  updated_at: Date
  // eslint-disable-next-line camelcase
  updated_by?: number
  seo_metadata: SeoMetaData
  clicks?: number
  nb_likes?: number
  has_audio: boolean
  has_video: boolean
  rubric_objets?: Array<Rubrik>
  authors?: Array<LimitedExpertProfile>
}

export interface SeoMetaData {
  // eslint-disable-next-line camelcase
  title?: string
  description?: string
  og_image?: any
}

export interface ArtikelRequest {
  // eslint-disable-next-line camelcase
  rubric_ids?: Array<number>
  title: string
  // eslint-disable-next-line camelcase
  sort_index?: number
  published?: boolean
  is_public?: boolean
  content?: string
  // eslint-disable-next-line camelcase
  published_dt?: Date
  // eslint-disable-next-line camelcase
  reading_duration?: number
  // eslint-disable-next-line camelcase
  short_description?: string
  description?: string
  tag_ids?: Array<number>
  authors?: Array<string>
  seo_metadata?: SeoMetaData
  author_ids?: Array<number>
}

export interface LimitedExpertProfile {
  id: number,
  full_name: string,
  role?: string,
  children_image?: any,
  current_image?: any,
  linked_profile_id?: number
}

export interface LikeRequest {
  // eslint-disable-next-line camelcase
  article_id: number
  user_id: number
}

export interface ModuleConfig {
  id: number
  // eslint-disable-next-line camelcase
  highlight_article_ids: Array<number>
  highlight_article_objects?: Array<any>
  // eslint-disable-next-line camelcase
  suggestion_article_ids: Array<number>
  suggestion_article_objects?: Array<any>
  // eslint-disable-next-line camelcase
  most_popular_article_ids: Array<number>
  most_popular_article_objects?: Array<any>
  // eslint-disable-next-line camelcase
  default_tag_ids: Array<number>
  default_tags: Array<Tag>
  // eslint-disable-next-line camelcase
  created_at: Date
  // eslint-disable-next-line camelcase
  created_by: number
  // eslint-disable-next-line camelcase
  updated_at: Date
  // eslint-disable-next-line camelcase
  updated_by: number
}

export interface ModuleConfigRequest {
  // eslint-disable-next-line camelcase
  highlight_article_ids: Array<number>
  // eslint-disable-next-line camelcase
  suggestion_article_ids: Array<number>
  // eslint-disable-next-line camelcase
  most_popular_article_ids: Array<number>
  // eslint-disable-next-line camelcase
  default_tag_ids: Array<number>
}

export interface RubrikConfig {
  id: number
  sections: Array<Section>
  // eslint-disable-next-line camelcase
  created_at: Date
  // eslint-disable-next-line camelcase
  created_by: number
  // eslint-disable-next-line camelcase
  updated_at: Date
  // eslint-disable-next-line camelcase
  updated_by: number
}

export interface Section {
  id: number
  ids: Array<number>
  title: string
  // eslint-disable-next-line camelcase
  nb_element: number
  // eslint-disable-next-line camelcase
  created_at: Date
  // eslint-disable-next-line camelcase
  created_by: number
  // eslint-disable-next-line camelcase
  updated_at: Date
  // eslint-disable-next-line camelcase
  updated_by: number
  objects: Array<Rubrik>
}

export interface SectionRequest {
  ids: Array<number>
  title: string
  // eslint-disable-next-line camelcase
  nb_element: number
}

// SDK OPTIONS
export interface Options extends AxiosRequestConfig {
  version?: string
  token?: string
  baseURL?: string
  timeout?: number
  socketEndpoint?: string
  userId?: string
  getNewToken?: Function
}

export interface PaginationOption {
  published?: boolean
  offset?: number
  limit?: number
  search_text?: string
  //reading_duration?: number
  sort_direction?: number
}

export interface ArtikelPaginationOption extends PaginationOption {
  tags?: Array<number>
  // eslint-disable-next-line camelcase
  rubric_ids?: Array<number>
  // eslint-disable-next-line camelcase
  sort_by?: string
  // eslint-disable-next-line camelcase
  reading_duration?: number
  // eslint-disable-next-line camelcase
  user_id?: number
  expert_ids?: Array<number>
}

export interface AttachmentOrReference {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  type: AttachementOrReferenceEnum
  title: string
  icon: string
  url: string
}

export enum AttachementOrReferenceEnum {
  attachment = 'attachment',
  external_link = 'external_link'
}

export interface RubrikConfigPaginationOption {
  expand?: boolean
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface Pagination<T> {
  result: Array<T>
  count: number
  next: string
  previous: string
}

export interface ArticlePagination<T> extends Pagination<T> {
  tags: Array<Tag>
}

export interface Reference {
  id: number
  // eslint-disable-next-line camelcase
  article_id: number
  type: string
  title: string
  url: string
}

export interface ErrorSdk {
  detail: string
}
