import {
  RubrikRequest,
  PaginationOption
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

export default class RubrikService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAll(paginationOption: PaginationOption) {
    let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

    if (typeof paginationOption.published !== 'undefined') {
      query += `&published=${paginationOption.published}`
    }

    if (typeof paginationOption.search_text !== 'undefined') {
      query += `&search_text=${paginationOption.search_text}`
    }

    if (typeof paginationOption.sort_direction !== 'undefined') {
      query += `&sort_direction=${paginationOption.sort_direction}`
    }

    if (typeof paginationOption.sort_by !== "undefined") {
      query += `&sort_by=${paginationOption.sort_by}`
    }

    return request('GET', `rubrics${query}`, this.options)
  }

  async getById(rubrikId: number) {
    return request('GET', `rubrics/${rubrikId}`, this.options)
  }

  async createRubrik(data: RubrikRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/admin/rubrics/`, requestOptions)
  }

  async editRubrik(rubrikId: number, data: RubrikRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/admin/rubrics/${rubrikId}`, requestOptions)
  }

  async deleteRubrik(rubrikId: number) {
    return request('DELETE', `/admin/rubrics/${rubrikId}`, this.options)
  }
}
