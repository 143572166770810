import { AxiosRequestConfig } from 'axios'
import { Expert } from '../../experts-v1/utils/DataSchemas'

export interface Tag {
  id: number
  value: string
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
}


export interface ImageRequest {
  upload: Blob
}

export interface Event {
  id: number,
  nb_likes: number,
  created_at: Date,
  created_by: number,
  updated_at: Date,
  updated_by: number,
  category_id: number,
  title: string,
  internal_title: string,
  description: string,
  image?: any,
  start_dt: number,
  end_dt: number,
  duration: number,
  url: string,
  status: string,
  notify_user: boolean,
  public: boolean,
  repeat_weekly: boolean,
  rubric_ids: Array<number>,
  tag_ids: Array<number>
  tags: Array<Tag>
  expert_ids: Array<number>,
  attachments: Array<AttachmentOrReference>,
  references: Array<AttachmentOrReference>,
  attendees: Array<LimitedUserProfile>,
  is_attending: boolean,
  is_liked_by_user: boolean,
  follow_up?: Array<any>,
  mediathek_rubric_id?: number,
  mediathek_video_id?: number,
  content?: string,
  max_attendees_number: number,
  cancellation_dt: Date,
  cancellation_reason: string,
  clicks: number,
  experts: Array<Expert>,
  channel_id: string,
  recorded: boolean,
  square_image?: any,
  highlighted?: boolean
}

export interface EventRequest {
  category_id: number
  title: string
  internal_title: string
  description?: string
  image?: Image
  start_dt: number,
  end_dt: number,
  duration: number,
  url?: string,
  status?: string,
  notify_user?: boolean,
  public?: boolean,
  repeat_weekly?: boolean,
  rubric_ids?: Array<number>,
  tag_ids?: Array<number>
  expert_ids?: Array<number>
  mediathek_rubric_id?: number,
  mediathek_video_id?: number,
  content?: string,
  max_attendees_number?: number,
  channel_id?: string,
  recorded: boolean,
  square_image?: Image,
  attachments?: Array<AttachmentOrReference>,
  references?: Array<AttachmentOrReference>,
  highlighted?: boolean
}

export interface CreateEventRequest {
  event: EventRequest
}

export interface UpdateEventRequest {
  event_id: number
  event: EventRequest
}

export interface EventImageRequest {
  event_id: number,
  upload: Blob
}

export interface ModuleConfig {
  highlight_event_objects: Array<Event>
  might_like_live_event_objects: Array<Event>
}

// SDK OPTIONS
export interface Options extends AxiosRequestConfig {
  version?: string
  token?: string
  baseURL?: string
  timeout?: number
  socketEndpoint?: string
  getNewToken?: Function
}

export interface Image {
  id: number
  nb_likes: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  owner_id: number
  path: string
  album_id: number | null
  description: string | null
  tagged_user_ids: Array<ImageTaggedUserIds>
  cropped_data: CroppedData | null
  like_by_user_ids: Array<number>
}

export interface X_Y {
  x: number
  y: number
}

export interface ImageTaggedUserIds extends X_Y {
  user_id: number
}

export interface WidthHeightWithXY extends X_Y {
  width: number
  height: number
}

export interface CroppedData {
  crop: X_Y
  zoom: number
  cropped_area: WidthHeightWithXY
  cropped_area_pixels: WidthHeightWithXY
  rotation: number
}

export interface Headers {
  'x-request-id'?: string
  'x-session-id'?: string
  'x-authorization'?: string
  'x-api-key': string
}

export interface Pagination<T> {
  result: Array<T>
  count: number
  next: any
  previous: any
}

export interface EventPagination<T> extends Pagination<T> {
  tags: Array<Tag>
}

export interface ErrorSdk {
  detail: string
}

export interface EventGetAllRequest {
  status?: string,
  highlighted_only?: boolean,
  search_text?: string
  offset?: number
  limit?: number
  expert_ids?: Array<number>
  passed_event_only?: boolean,
  category_id?: number,
  filter_period?: number
  rubric_id?: number
  upcomming?: any
}

export interface ModuleGetAllRequest {
  expand?: boolean
}

export enum Category {
  Live_Stream = 1,
  Live_Video = 2,
  Live_Chat = 3,
  Zoom_Event = 4
}

export enum Status {
  active = 'active',
  in_active = 'in_active',
  cancelled = 'cancelled',
}

export interface AttachmentCreateRequest {
  event_id: number
  title?: string
  attachment: Blob
}

export interface AttachmentRemoveRequest {
  event_id: number
  attachment_id: number
}

export interface ReferenceCreateRequest {
  event_id: number
  title: string
  external_url: string
}


export interface ReferenceUpdateRequest extends ReferenceCreateRequest {
  reference_id: number
}

export interface ReferenceRemoveRequest {
  event_id: number
  reference_id: number
}
export interface AttachmentOrReferenceRequest {
  title: string
  external_url: string
}
export interface AttachmentOrReference {
  id: number
  created_at: Date
  created_by: number
  updated_at: Date
  updated_by: number
  type: AttachementOrReferenceEnum
  title: string
  icon: string
  url: string
}

export enum AttachementOrReferenceEnum {
  attachment = 'attachment',
  external_link = 'external_link'
}

export interface LimitedUserProfile {
  id: number
  internal_status: number
  email: string
  membership_id: number
  full_name: string
  image_profile: ImageLimited | null
  image_background: ImageLimited | null
  status: string
  motto: string
  tags: Array<Tag>
  is_friend: boolean
  is_blocked: boolean
  is_blocked_by_user: boolean
  is_online: boolean
  online_visible: boolean
  attendee_visible: boolean
  registered_visible: boolean
  community_chat_visible: boolean
  community_point_visible: boolean
  is_reported: boolean
  direct_message: boolean
  receive_messages: boolean
  friend_ids: Array<number>
}
export interface ImageLimited {
  path: string
  cropped_data: CroppedDataInterface
}

export interface CropPixelInterface {
  x: number
  y: number
}

export interface CroppedAreaInterface {
  width: number
  height: number
  x: number
  y: number
}

export interface CroppedDataInterface {
  crop: CropPixelInterface
  zoom: number
  cropped_area: CroppedAreaInterface
  cropped_area_pixels: CroppedAreaInterface
  rotation: number
}
