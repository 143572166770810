export enum CategoryActionTypeEnum {
    List = "LIST_SUPPORT_CATEGORIES",
    Filter = "FILTER_SUPPORT_CATEGORIES",
    ListInSelect = "SELECT_LIST_SUPPORT_CATEGORIES",
    AddDefaultCategory = "ADD_DEFAULT_SUPPORT_CATEGORY",
    Add = "ADD_SUPPORT_CATEGORY",
    Update = "UPDATE_SUPPORT_CATEGORY",
    Loading = "DATA_LOADING"
}

export enum FaqActionTypeEnum {
    List = "LIST_FAQS",
    BlockModeLoading = "SET_BLOCK_MODE_FAQS_LOADING",
    SetEmpty = "SET_FAQS_EMPTY",
    Update = "UPDATE_FAQ",
    Add = "ADD_FAQ",
    Delete = "DELETE_FAQ"
}