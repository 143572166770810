import {
  Dashboard,
  CreateModuleConfigImageRequest,
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'
export default class ModuleConfigService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAllModuleConfig() {
    return request('GET', `/module-config/`, this.options)
  }

  async updateModuleConfig(data: Dashboard) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('PUT', `/module-config/`, requestOptions)
  }

  async createModuleConfigImage(data: CreateModuleConfigImageRequest) {

    const formData = new FormData()
    formData.append('upload', data.upload)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }

    return request(
      'POST',
      `/module-config/images`,
      requestOptions
    )
  }

}
