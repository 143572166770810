import {
  ExpertsActionTypeEnum,
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";

const initialState = {
  Experts: [],
  ExpertsTotal: null,
  DefaultExperts: [],
  BlockModeExpertsLoading: false,
  loading: false
};

const ExpertsReducer = (state = initialState, action) => {
  switch (action.type) {

    case ExpertsActionTypeEnum.List:
      return {
        ...state,
        Experts: action.experts,
        ExpertsTotal: action.total,
        loading: action.loading
      };
      case ExpertsActionTypeEnum.Loading:
        return {
          ...state,
          loading: action.data,
        };
    case ExpertsActionTypeEnum.Filter:
      return {
        ...state,
        Experts: action.experts,
      };
    
    case ExpertsActionTypeEnum.ListInSelect:
      return {
        ...state,
        DefaultExperts: action.experts,
      };
    case ExpertsActionTypeEnum.AddDefaultExperts:
      return {
        ...state,
        DefaultExperts: action.default,
      };
    case ExpertsActionTypeEnum.Add:
      let newExpertsState = !state.Experts ? [] : state.Experts;
      newExpertsState.unshift(action.experts);
      return {
        ...state,
        Experts: newExpertsState,
        ExpertsTotal: state.ExpertsTotal + 1
      };
    case ExpertsActionTypeEnum.Update:
      let expertsState = !state.Experts ? [] : state.Experts;
      let newArrayExperts = updatedArray(expertsState, action.experts);
      return {
        ...state,
        Experts: newArrayExperts,
      };
    case ExpertsActionTypeEnum.Delete:
      let EXPERTS = !state.Experts ? [] : state.Experts;
      let updExpertsArray = arrayReduce(EXPERTS, action.experts);
      return {
        ...state,
        Experts: updExpertsArray,
        ExpertsTotal: state.ExpertsTotal - 1
      };

    default:
      return state;
  }
};

export default ExpertsReducer;
