import React, { useState, useEffect, useContext } from "react";
import { isExpired, decodeToken } from "react-jwt";
import { UserClient as Client } from "@happiness-house/sdk";
import { OAuth2Client as OauthClient } from "@happiness-house/sdk";
import { useHistory } from "react-router-dom";
import useUser from "../../utility/hooks/useUser";
import useOauth from "../../utility/hooks/useOauth";

const defaultState = {
  sessionInfo: {},
  authStatus: "Loading",
};

const ALL_ADMIN_GROUPS = [
  "admin",
  "live_event_manager",
  "community_manager",
  "tdm_manager",
  "academy_manager",
  "magazin_manager",
  "mediathek_manager",
  "meditation_manager",
  "experts_manager",
];

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }) => {
  const { authStatus, isAdmin } = useContext(AuthContext);
  if (authStatus === "SignedIn") {
    if (isAdmin === false) {
      window.location.href = "/not-authorized";
    } else {
      return <>{children}</>;
    }
  }
  return null;
};

export const AuthIsNotSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext);

  return <>{authStatus === "SignedOut" ? children : null}</>;
};

const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState("Loading");
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const history = useHistory();

  function setUserInfo(data) {
    setSessionInfo((oldSessionInfo) => {
      return {
        ...oldSessionInfo,
        userInfo: data,
      };
    });
  }

  const data = useOauth()
  const oauthClient = data.client
  let {client} = useUser()

  useEffect(() => {
    (async () => {
      try {
        await getSessionInfo();
      } catch (error) {
        console.log(error);
        setAuthStatus("SignedOut");
      }
    })();
  }, [authStatus]);

  if (authStatus === "Loading") {
    return null;
  }

  async function getSessionInfo() {
    client.configure({
      baseURL: `${process.env.REACT_APP_HH_API}/users/v1`,
      getNewToken: getRefreshToken,
    });

    oauthClient.configure({
      baseURL: `${process.env.REACT_APP_HH_API}/oauth2`,
      getNewToken: getRefreshToken,
    });

    let user = await client.getAuth();

    const simpleUser = {
      id: user.id,
      basic_info: user.basic_info,
      about_info: user.about_info,
      contact_info: user.contact_info,
    };

    setSessionInfo({
      groups: user.groups,
      userInfo: simpleUser,
    });

    if (user?.groups?.some((g) => ALL_ADMIN_GROUPS.includes(g))) {
      setIsAdmin(true);
    }else{
      await signOut();
      history.push("/login");
      throw new Error("User is not an admin")
    }

    setAuthStatus("SignedIn");
  }

  async function signInWithEmail(email, password) {
    try {
      await oauthClient.authorize({
        email: email,
        password: password,
      });

      setAuthStatus("Loading");
    } catch (err) {
      console.log(err);
      setIsAdmin(false);
      setAuthStatus("SignedOut");
      throw err;
    }
  }

  async function signUpWithEmail(data) {
    try {
      await oauthClient.register(data);
    } catch (err) {
      throw err;
    }
  }

  async function signOut() {
    try {
      await oauthClient.logout();

      setAuthStatus("SignedOut");
      setIsAdmin(false);
      setSessionInfo({});
    } catch (error) {
      console.error(error);
    }
  }

  async function verifyCode(email, code) {
    try {
      await oauthClient.confirmationCode({
        email: email,
        code: code,
      });
    } catch (err) {
      throw err;
    }
  }

  async function sendCode(email) {
    try {
      await oauthClient.resetPasswordCode({
        email: email,
      });
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(data) {
    try {
      await oauthClient.passwordResetCode(data);
    } catch (err) {
      throw err;
    }
  }

  async function changePassword(data) {
    try {
      await oauthClient.changePassword(data);
    } catch (err) {
      throw err;
    }
  }

  async function getRefreshToken() {
    try {
      const session = await oauthClient.refreshToken();

      return session?.access_token;
    } catch (error) {
      await signOut();
      history.push("/login");
      return ""
    }
  }

  const state = {
    authStatus,
    sessionInfo,
    isAdmin,
    attrInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    sendCode,
    forgotPassword,
    changePassword,
    setUserInfo,
    getRefreshToken,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
