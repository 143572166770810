import {
  CategoryActionTypeEnum,
  FaqActionTypeEnum
} from "../../helpers/enums/actionTypeEnums"
import { arrayReduce, updatedArray } from "../../../../../utility/helpers/functions";


const initialState = {
  Categories: [],
  Faqs: [],
  CategoriesTotal: null,
  FaqsTotal: 0,
  params: {},
  allData: [],
  selectDefaultCategories: [],
  DefaultCategories: [],
  BlockModeCategoriesLoading: false,
  DataLoading: true
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CategoryActionTypeEnum.AddDefaultCategories:
      return {
        ...state,
        DefaultCategories: action.default,
      };

    case CategoryActionTypeEnum.List:
      return {
        ...state,
        Categories: action.Categories,
        CategoriesTotal: action.count,
        DataLoading: action.loading
      };

      case CategoryActionTypeEnum.Loading:
        return {
          ...state,
          DataLoading: action.loading
        };

    case CategoryActionTypeEnum.Filter:
      return {
        ...state,
        Categories: action.Categories,
      };

    case CategoryActionTypeEnum.ListInSelect:
      return {
        ...state,
        selectDefaultCategories: action.Categories,
      };
    case CategoryActionTypeEnum.Add:
      let newCategoriesState = !state.Categories ? [] : state.Categories;
      newCategoriesState.unshift(action.Categories);
      return {
        ...state,
        Categories: newCategoriesState,
        CategoriesTotal: newCategoriesState.count
      };
    case CategoryActionTypeEnum.Update:
      let CategoriesState = !state.Categories ? [] : state.Categories;
      let newArrayCategories = updatedArray(CategoriesState, action.Categories);
      return {
        ...state,
        Categories: newArrayCategories,
      };

    case FaqActionTypeEnum.List:
      return {
        ...state,
        Faqs: action.Faqs,
        FaqsTotal: action.Faqs.length,
      };

    case FaqActionTypeEnum.Add:
      let newFaqstate = !state.Faqs ? [] : state.Faqs;
      newFaqstate.unshift(action.Faq);
      return {
        ...state,
        Faqs: newFaqstate,
        FaqsTotal: newFaqstate.length,
      };
    case FaqActionTypeEnum.SetEmpty:
      return {
        ...state,
        Faqs: [],
      };
    case FaqActionTypeEnum.BlockModeLoading:
      return {
        ...state,
        BlockModeCategoriesLoading: action.data,
      };
    case FaqActionTypeEnum.Update:
      let Faqstate = !state.Faqs ? [] : state.Faqs;
      let newArrayVideo = updatedArray(Faqstate, action.Faq);
      return {
        ...state,
        Faqs: newArrayVideo,
      };
    case FaqActionTypeEnum.Delete:
      let VIDEO = !state.Faqs ? [] : state.Faqs;
      let updVideoArray = arrayReduce(VIDEO, action.Faq);
      return {
        ...state,
        Faqs: updVideoArray,
        FaqsTotal: updVideoArray.length,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
