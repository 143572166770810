export enum EventsActionTypeEnum {
    List = "LIST_EVENTS",
    Filter = "FILTER_EVENTS",
    AddDefaultEvent = "ADD_DEFAULT_EVENT",
    BlockModeLoading = "SET_BLOCK_MODE_LOADING_EVENTS",
    SetEmpty = "SET_EMPTY_EVENTS",
    Add = "ADD_EVENTS",
    Update = "UPDATE_EVENTS",
    Delete = "DELETE_EVENTS",
    Loading = "LOADING_EVENTS"
}