import TagAdminService from './services/adminService'
import TagService from './services/tagService'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {

  public options: Options;
  private tagAdminService!: TagAdminService
  private tagService!: TagService


  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.tagAdminService = new TagAdminService(this.options)
    this.tagService = new TagService(this.options)
  }

  /**
   * Get tag
   */
  getAllTags = (data: dataSchemas.PaginationOptionTag): Promise<dataSchemas.Pagination<dataSchemas.Tag>> =>
  this.tagService.getAllTags(data)

  /**
 * Get tag
 */
  getDefaultTagSet = (tag_set_name: string): Promise<dataSchemas.TagSet> =>
  this.tagService.getDefaultTagSet(tag_set_name)

/**
 * Get tag set
 */
 getAllTagSets = (data: dataSchemas.PaginationOption): Promise<dataSchemas.Pagination<dataSchemas.TagSet>> =>
  this.tagAdminService.getAllTagSets(data)

  /**
 * Get tags on admin area
 */
  getAllTagsOnAdmin = (data: dataSchemas.PaginationOption = {offset: 0, limit: 20, sort_by: dataSchemas.sortEnum.value, sort_direction: dataSchemas.sortDirectionEnum.ASC}): Promise<dataSchemas.Pagination<dataSchemas.Tag>> =>
 this.tagAdminService.getAllTagsOnAdmin(data)

  /**
 * create tag set
 */
   createTagSet = (data: dataSchemas.TagSetRequest): Promise<dataSchemas.TagSet> =>
  this.tagAdminService.createTagSet(data)

   /**
 * get tag set by id
 */
    getTagSetById = (tagSetId: number): Promise<dataSchemas.TagSet> =>
    this.tagAdminService.getTagSetById(tagSetId)

     /**
 * edit tag set
 */
    editTagSet = (tagSetId: number, data: dataSchemas.TagSetRequest): Promise<dataSchemas.TagSet> =>
   this.tagAdminService.editTagSet(tagSetId, data)

    /**
 * delete tag set by id
 */
    deleteTagSet = async (tagSetId: number): Promise<any> =>{
      const tagSet = await this.getTagSetById(tagSetId)
      return this.tagAdminService
        .deleteTagSet(tagSet.id)
        .then(async () => {
          return true
        })
        .catch((e) => {
          return Promise.reject({
            errors: 'Something went wrong when deleting a tag set.',
            error: e
          })
        })
    }


 /**
* create tag
*/
  createTag = (data: dataSchemas.TagRequest): Promise<dataSchemas.Tag> =>
 this.tagAdminService.createTag(data)

  /**
* get tag by id
*/
   getTagById = (tagId: number): Promise<dataSchemas.Tag> =>
   this.tagAdminService.getTagById(tagId)

    /**
* edit tag
*/
     editTag = (tagId: number, data: dataSchemas.TagRequest): Promise<dataSchemas.Tag> =>
  this.tagAdminService.editTag(tagId, data)

   /**
* delete tag by id
*/
    deleteTag = async (tagId: number): Promise<any> =>{
      const tag = await this.getTagById(tagId)
      return this.tagAdminService
        .deleteTag(tag.id)
        .then(async () => {
          return true
        })
        .catch((e) => {
          return Promise.reject({
            errors: 'Something went wrong when deleting a tag.',
            error: e
          })
        })
    }
}

export * as types from './utils/DataSchemas'
