import CmsService from './services/Cms'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private cmsService!: CmsService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.cmsService = new CmsService(this.options)
  }

  /**
   * Get all cms
   */
  getAllCms = (
    getAllQuery: dataSchemas.CmsGetAllRequest = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.Pagination> => this.cmsService.getAll(getAllQuery)

  /**
   * Get cms by id
   * @param cms_id cms id
   */
  getCmsById = (
    cms_id: number
  ): Promise<dataSchemas.Cms> => this.cmsService.getCmsById(cms_id)

  /**
   * Create cms
   * @param body Body of cms
   */
  createCms = (
    body: dataSchemas.CreateCmsRequest
  ): Promise<dataSchemas.Cms> => this.cmsService.createCms(body)

  /**
   * Update cms
   * @param body Body of cms
   */
  updateCms = (
    body: dataSchemas.UpdateCmsRequest
  ): Promise<dataSchemas.Cms> => this.cmsService.updateCms(body)

  /**
   * Delete cms
   * @param cms_id Object with cms id
   */
  deleteCms = async (
    cms_id: number,
  ): Promise<void> => this.cmsService.deleteCms(cms_id)

  /**
   * Add page meta-data image
   * @param data create image Object
   */
  createImage = async (
    data: dataSchemas.ImageRequest,
  ): Promise<any> => this.cmsService.createCmsImage(data)

  /**
   * Delete page meta-data image
   * @param page_id page id
   * @param image_id image id
   */
  deleteImage = async (
    page_id: number,
    image_id: number,
  ): Promise<any> => this.cmsService.deleteCmsImage(page_id, image_id)

  /**
   * Get all public page
   */
  getAllPublicPages = (
    getAllQuery: dataSchemas.CmsGetAllRequest = {
      offset: 0,
      limit: 20
    }
  ): Promise<dataSchemas.Pagination> => this.cmsService.getAllPublicPages(getAllQuery)

  /**
   * Get page by id
   * @param page_id cms id
   */
  getPublicPageById = (
    page_id: number
  ): Promise<dataSchemas.Cms> => this.cmsService.getPublicPageById(page_id)

  /**
   * Get page by internal name
   * @param internal_name page internal_name
   */
  getPublicPageByName = (
    internal_name: string
  ): Promise<dataSchemas.Cms> => this.cmsService.getPublicPageByName(internal_name)
}

export * as types from './utils/DataSchemas'
