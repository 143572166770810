export const LIMIT_UPLOAD_FILE = 5
export const URL_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
export const TIME_WITH_MINUTE_REGEX = /^([0-5]\d:[0-5]\d$)/;
export const TIME_WITH_HOUR_REGEX = /^((?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$)/;
export const IMAGE_ACCEPT = ".jpg,.jpeg,.png,.heic,.gif,.webp";
export const DOCUMENT_ACCEPT = ".pdf, .doc";
export const AUDIO_ACCEPT = ".mp3,.aac,.m4a,.ogg,.wav";
export const AUDIO_ACCEPT_FILESNAME_ARRAY: Array<string> = ["m4a","ogg","wav"];
export const TAG_REGEX = /@(\d+)/g;
export const TAG_REGEX_VERSION_WITH_FULL_NAME3 = /{{{([a-zA-Z0-9'-._\s?]+)}}}/g;
export enum MediaType {
    NONE = 'none',
    IMAGE = 'image',
    PDF = 'pdf',
}
export const REACT_MENTIONS_INPUT_STYLE = {
    suggestions: {
      list: {
        backgroundColor: 'white',
        fontSize: 14,
        maxHeight: '200px',
        overflow: 'auto',
        borderRadius: '10px',
        boxShadow: '1px 1px 15px 1px rgba(232,232,232,0.80)'
      },
      item: {
        padding: '5px 15px',
        '&focused': {
          backgroundColor: '#d5d9ea'
        }
      }
    }
}
  
export const REACT_MENTIONS_STYLE = {
    backgroundColor: 'transparent'
}

export const DEFAULT_PROFILE_PICTURE_CROP_DATA = {
    "crop": {
      "x": 0,
      "y": 0
    },
    "zoom": 1,
    "cropped_area": {
      "width": 100,
      "height": 100,
      "x": 0,
      "y": 0
    },
    "cropped_area_pixels": {
      "width": 252,
      "height": 168,
      "x": 0,
      "y": 0
    },
    "rotation": 0
}

export const EMOJI_REGEX = /([\u20a0-\u32ff]|[\uD83C-\uDBFF][\uDC00-\uDFFF])/g;