import TdmkService from './services/tdm';
import MyTdmService from './services/me';
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas';
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`);

export class Client {

  options: Options;
  private tdmService!: TdmkService;
  private myTdmService!: MyTdmService;

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.tdmService = new TdmkService(this.options)
    this.myTdmService = new MyTdmService(this.options)
  }

  /**
   * Get tdm by month & year
   */
  getTdmByMonthYear = (data: dataSchemas.TdmRequestMonthYear): Promise<dataSchemas.TdmInterface> =>
    this.tdmService.getByMonthYear(data)


  /**
   * Get public tdm by month & year
   */
  getByMonthYearPublic = (data: dataSchemas.TdmRequestMonthYear): Promise<dataSchemas.TdmInterface> =>
    this.tdmService.getByMonthYearPublic(data)

  /**
   * Edit tdm by month & year
   */
  updateTdmMonthYear = (
    data: dataSchemas.TdmRequestMonthYear,
    body: dataSchemas.TdmBodyRequest
  ): Promise<dataSchemas.TdmInterface> =>
    this.tdmService.editTdmMonthYear(data, body)

  /**
   * Delete tdm by month year
   */
  deleteTdmMonthYear = async (data: dataSchemas.TdmRequestMonthYear) => {
    return this.tdmService
      .deleteTdmMonthYear(data)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a tdm.',
          error: e
        })
      })
  }

  /**
   * Create Tdm content
   */
  createTdmContent = async (data: dataSchemas.TdmRequestMonthYear, body: dataSchemas.ContentRequest)
    : Promise<dataSchemas.TdmRequestMonthYearFilename> => this.tdmService.createTdmContent(data, body)


  /**
  * Delete tdm by month year
  */
  deleteTdmContent = async (data: dataSchemas.TdmRequestMonthYearFilename) => {
    return this.tdmService
      .deleteTdmContent(data)
  }

  /**
   * Get my tdm by month & year
   */
  getMyTdmByMonthYear = (data: dataSchemas.TdmRequestMonthYear): Promise<dataSchemas.UserTdmInterface> =>
    this.myTdmService.getMyTdmByMonthYear(data)

  /**
   * Edit my tdm by month & year
   */
  updateMyTdmMonthYear = (
    data: dataSchemas.TdmRequestMonthYear,
    body: dataSchemas.MyTdmBodyRequest
  ): Promise<dataSchemas.UserTdmInterface> =>
    this.myTdmService.editMyTdmMonthYear(data, body)

  /**
   * Create my Tdm content
   */
  createMyTdmContent = async (data: dataSchemas.TdmRequestMonthYear, body: dataSchemas.ContentRequest)
    : Promise<dataSchemas.TdmRequestMonthYearFilename> => this.myTdmService.createMyTdmContent(data, body)


  /**
  * Delete my tdm by month year
  */
  deleteMyTdmContent = async (data: dataSchemas.TdmRequestMonthYearFilename) => {
    return this.myTdmService
      .deleteMyTdmContent(data)
  }
}

export * as types from './utils/DataSchemas';
