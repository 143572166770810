import { useEffect, useContext } from 'react';
import {OAuth2Client as Client, oauth2Types as types} from '@happiness-house/sdk'
import {AuthContext} from "../../auth/contexts/authContext";

const client = new Client();

const useOauth = () => {
    const { signOut } = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            client.configure({
                baseURL: `${process.env.REACT_APP_HH_API}/oauth2`, signOut
            })
        })()
    }, [])

    return { client, types }
}

export default useOauth;
